import React, { Component } from 'react';
import { Button, Layout, Card, TextStyle, ButtonGroup, Link } from '@shopify/polaris';
import axios from 'axios';
import AccountSetupInfo from './version210/AccountSetupInfo';
import ToastMessage from '../Common/ToastMessage';

var imgCompleted = '/images/100-status-complete.jpg';
var imgIncompleted = '/images/75-status-complete.jpg';

export default class InstallationStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            install_status: this.props.install_status,
            install_status_page: 1,
            showToast: false,
            toastMsg: '',
            toastError: false
        }
    }

    showToastMessage(msg, error){
        this.setState({
          showToast: true,
          toastMsg: msg,
          toastError: error||false,
        });
    }
    
    dissmissToast = () =>{
        this.setState({
          showToast: false,
        });
    }

    install_completed() {
        var data = {
            "shop": this.props.shop,
            "status": "completed"
        }
        axios.put(this.props.host_name + '/admin/liquid-install-status', data).then(response => {
            this.showToastMessage(response.data.message);
            this.setState({
                install_status: "completed"
            });
        }).catch(e => {
            
        })
    }
    install_in_development() {
        var data = {
            "shop": this.props.shop,
            "status": "in_development"
        }
        axios.put(this.props.host_name + '/admin/liquid-install-status', data).then(response => {
            this.showToastMessage(response.data.message);
            this.setState({
                install_status: "in_development"
            });
        }).catch(e => {
            
        })
    }
    lets_go = () => {
        var data = {
            "shop": this.props.shop,
            "status": "completed"
        }
        axios.put(this.props.host_name + '/admin/liquid-install-status', data).then(response => {
            // this.setState({
            //     install_status: "completed"
            // });
        }).catch(e => {
            // 
        });
        this.redirectToDG();
    }

    redirectToDG = () => {
        if (this.props.history) {
            this.props.history.push('/pricing-groups');
        }
        else {
            window.location.reload(true)
        }
    }

    render() {
        const {install_status_page} = this.state;
        
        const install_status_page_1 = <div>
            <Layout>
                <Layout.Section>
                    <Card sectioned>
                        <div className="install-status-title">
                            Install Status
                        </div>
                        {
                            this.state.install_status !== "completed" ?
                                <div>
                                    <div style={{ textAlign: "center", margin: "20px 0px" }}>
                                        <strong>
                                            Our Shopify experts will finish your install
                                    </strong>
                                    </div>
                                    <div className="create-action-buttons">
                                        <Button primary
                                            onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                                this.setState({install_status_page:2})
                                            }}
                                        >
                                            Action required - Click here to Setup staff account
                                        </Button>
                                    </div>
                                </div>
                                : null
                        }
                        <div className="img-container">
                            {
                                this.state.install_status === "completed" ?
                                <img src={imgCompleted} width="100%" style={{ maxWidth: '100%', width: 'auto' }} alt="completed"></img>
                                :<img src={imgIncompleted} width="100%" style={{ maxWidth: '100%', width: 'auto' }} alt="incompleted"></img>
                            }
                        </div>
                        <div style={{ textAlign: "center", margin: "1rem 0rem" }}>
                            {
                                this.state.install_status === "completed" ?
                                    <Button size="large" primary
                                        onClick={() => {
                                            this.lets_go();
                                        }}>
                                        Let's Get Started
                                    </Button>
                                    : ""
                            }
                        </div>
                        {
                            this.state.install_status !== "completed" ?
                                <div>
                                    <div className="install-status-description">
                                        <p style={{ maxWidth: '750px', margin: 'auto', textAlign:'center' }} >
                                            In order to ensure your theme will run smoothly with our app, we’ll make all of the necessary adjustments depending on other apps or scripts your theme is running. We provide this service free of charge.
                                        </p>
                                    </div>
                                    <div style={{marginTop:"1rem"}}>
                                        <div style={{ textAlign: "center" }}>
                                            <strong>
                                                Please allow up to 1 to 3 business days for us to complete the process.
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                            :null
                        }
                    </Card>
                </Layout.Section>

                <Layout.Section>
                <div>
                    <Card
                        title="PreSale Frequently Asked Questions"
                    >
                        <Card.Section>
                        You can read PreSale frequently asked questions in our <Link url="https://support.digitalcoo.com/hc/en-us/articles/360045114172" external>  Knowledge Base [PreSale FAQ]</Link>.
                        </Card.Section>
                    </Card>
                </div>
                </Layout.Section>
                
                <Layout.Section>
                    <Layout>
                        <Layout.Section secondary>
                            <Card title="Wholesale Demos" sectioned>
                                <div>
                                    <TextStyle variation="subdued">A list of demos are ready!</TextStyle>
                                </div>
                                <div style={{marginTop:"1rem"}}>
                                    <TextStyle>
                                            We have created a list of Wholesale demos that helps to understand the application features.
                                    </TextStyle>
                                    <div style={{ textAlign: "center", marginTop: "18px" }}>
                                    <ButtonGroup>
                                        <Button primary url="https://wholesale-all-in-one.myshopify.com/" external={true}>
                                            View Demos
                                        </Button>
                                        <Button primary url="https://support.digitalcoo.com/hc/en-us/articles/360045114172" external={true}>
                                            PreSale FAQ
                                        </Button>
                                        </ButtonGroup>
                                    </div>
                                    
                                </div>
                            </Card>
                        </Layout.Section>
                        <Layout.Section secondary>
                        <Card title="Questions">
                            <Card.Section>
                                <TextStyle>Do you have any questions? Please write us an email?</TextStyle>
                            </Card.Section>
                            <Card.Section>
                                <TextStyle>
                                    <div style={{ textAlign: "center" }}>
                                        <p>Our hours are</p>
                                        <p>9am-5pm EST (Mon-Fri)</p>
                                        <p>Email us: support@digitalCoo.com</p>
                                    </div>
                                </TextStyle>
                            </Card.Section>
                        </Card>
                    </Layout.Section>
                    </Layout>
                </Layout.Section>
                

            </Layout>
        </div>;

        const install_status_page_2 = <AccountSetupInfo app_configured={this.props.app_configured} install_status={this.props.install_status} shop={this.props.shop}/>;

        var toast = this.state.showToast? (
            <ToastMessage
              onDismiss={this.dissmissToast}
              message={this.state.toastMsg}
              error={this.state.toastError}
            />
          ): (null);

          
        return (
            <div>
                {toast}
                {
                    install_status_page === 1?
                    install_status_page_1
                    :install_status_page_2
                }
            </div>
        );
    }
}