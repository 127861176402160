import React, { Component } from "react";
import { Card, Collapsible, Button, Link } from "@shopify/polaris";

export default class CustomersFAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            questions: [
                {
                    question: " There is no option to set the  password field on the wholeslae registratin form?",
                    answer: "Yes, There is no option in the Customer Registration form to set new password. However when store owner will approve the account then customer will receive an email to set new password for the account. Plesae read this article for more details: ",
                    link: {
                        external: true,
                        label: "Read article",
                        url: "https://support.digitalcoo.com/hc/en-us/articles/360054029012"
                    }
                }
            ],
            expand: {
                q_0: false,
                q_1: false,
                q_2: false,
                q_3: false,
                q_4: false,
                q_5: false
            }
        };
    }

    toggleState(i){
        var expand = this.state.expand;
        expand["q_"+i] = !expand["q_"+i];
        this.setState({expand});
    }

    render() {
        const {expand} = this.state;
        const faqSec = this.state.questions.map((el,i) => {
            return <Card.Section>
                <div className="collapsible-item">
                    <div className="collapsible-item-header">
                        <Button plain id="text-left"
                            onClick={ () => {
                              this.toggleState(i)
                            }}
                            ariaExpanded={expand["q_"+i]}
                        >
                         {el.question}
                        </Button>
                    </div>
                    <Collapsible open={expand["q_"+i]}>
                        <p className="">
                            {el.answer} 
                            {
                                el.link?el.link.label?
                                <span>
                                    <a href={el.link.url} target={el.link.external?"_new":"_self"} > {el.link.label}</a>
                                </span>
                                :null:null
                            }
                        </p>
                    </Collapsible>
                </div>
            </Card.Section>
        })

        return (
          <div>
            <Card
                title="Quick answers"
            >
                <Card.Section>
                Are you looking for quick help? Read articles <Link url="https://support.digitalcoo.com/hc/en-us/sections/360007123071" external> Knowledge Base (Manage Customers)</Link>.
                </Card.Section>
                {faqSec}
            </Card>
          </div>
        );
    }
}
