import React, { Component } from 'react';
import axios from 'axios';
import {Popover,PageActions,DatePicker,Checkbox,Stack,Layout,Tabs,FormLayout,TextField,Select,Card,Button,RadioButton,ButtonGroup,List, TextStyle,Tooltip, Icon} from '@shopify/polaris';
import * as PropTypes from 'prop-types';
import FooterHelpLinks from './FooterHelpLinks';
import store from 'store2';
import PageTitleBar from '../Common/TitleBar';
import ResourcePicker from '../Common/ResourcePicker';
import ToastMessage from '../Common/ToastMessage';
import {QuestionMarkMajor, CircleInformationMajor, DeleteMinor} from '@shopify/polaris-icons';
import {Modal} from '@shopify/app-bridge-react';

export default class CreatePricingGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab_selected: 0,
            tags: '',
            discount_value: '',
            discount_type: 'fixed_amount',
            entire_store: true,
            status: 'published',
            product_modal_open: false,
            collection_modal_open: false,
            selected_products: '',
            selected_collections: '',
            products: [],
            __maxq: '1',
            __dq: '1',
            __enableQty: false,
            __minq: '1',
            __qi: '1',
            collections: [],
            product_counts: '',
            collection_counts: '',
            customers_id: '',
            products_array: [],
            collections_array: [],
            host_name: this.props.host_name,
            shop_id: this.props.shop_id,
            shop: this.props.shop,
            value: 'entire_store',
            specific_products:false,
            specific_collections: false,
            specific_variants:false,
            variants_array: [],
            variants: [],
            applies_to: 'entire_store',
            min_quantity_requirment: false,
            min_quantity_req_fields: [],
            variant_modal_open: false,
            update_products__modal_open: false,
            update_collections__modal_open: false,
            update_variants__modal_open: false,
            customers_group: 'all',
            specific_tag_base_customers: false,
            options: [
                {label: 'Fixed amount', value: 'fixed_amount'},
                {label: 'Percentage', value: 'percentage'},
            ],
            settings: [],
            product_modal_for_variant_open: false,
            discount_value__error: false,
            volume_discount_value__error: false,
            schedule_checked: false,
            schedule_end_date: false,
            start_month: new Date().getMonth(),
            start_year: new Date().getFullYear(),
            end_month: new Date().getMonth(),
            end_year: new Date().getFullYear(),
            start_date_selected: new Date(),
            end_date_selected: new Date(),
            start_date_popup: false,
            start_date: new Date().toISOString().split('T')[0],
            end_date_popup: false,
            end_date: new Date().toISOString().split('T')[0],
            start_time:'00:00:00',
            end_time: '23:30:00',
            end_date_time_error: false,
            customer_tag_missing_error: false,
            exclude_product_modal_open: false,
            exclude_collection_modal_open: false,
            excluded_products: [],
            excluded_collections: [],
            update_ex_collections__modal_open: false,
            update_ex_products__modal_open: false,
            step_three_error: false,
            exclude_collections_checkbox: false,
            exclude_products_checkbox: false,
            save_discount_rule: false,
            step1_help_popover: false,
            step2_help_popover: false,
            step3_help_popover: false,
            step4_help_popover: false,
            tag_popover: false,
            internal_name: '',
            showToast: false,
            toastMsg: '',
            toastError: false
        }
        store.remove('products');
        store.remove('variants');
        store.remove('selected_products');
        store.remove('collections');
        store.remove('excludedProducts');
        store.remove('excludedCollections');
        if(!this.props.shop){
            this.props.history.push('/install');
        }
    }
    
    static contextTypes = {
        easdk: PropTypes.object,
    };

    componentDidMount() {
        axios.get(this.props.host_name+'/admin/settings/'+this.props.shop)
        .then(response => {
            if(response.data){
                this.setState({
                    settings: response.data.settings
                });
            }
        })
        .catch(error => {
            this.showToastMessage(error, true);
        });
    }
    
    showToastMessage(msg, error){
    	this.setState({
    	  showToast: true,
    	  toastMsg: msg,
    	  toastError: error||false,
    	});
    }

    dissmissToast = () =>{
    	this.setState({
    	  showToast: false,
    	});
    }

    handleSubmit = (event) => {
        // event.preventDefault();
        let excluded_collections = this.state.excluded_collections;
        let excluded_products = this.state.excluded_products;
        let products = this.state.products_array;
        let collections = this.state.collections_array;
        let variants = this.state.variants_array;
        let validation = true;
        let start_date_time = this.get_est_dateTime(this.state.start_date,this.state.start_time);
        let start_time = this.state.start_time;
        let end_date_time = this.get_est_dateTime(this.state.end_date,this.state.end_time);
        let end_time = this.state.end_time;
        if(this.state.value === 'specific_collections'){
            excluded_collections = [];products = [];variants = [];
            if(!this.state.exclude_products_checkbox){
                excluded_products = [];
            }
        }
        else if(this.state.value !== 'entire_store'){
            excluded_collections = [];excluded_products = [];
            if(this.state.value === 'specific_products'){
                collections = [];variants = [];
            }else{
                collections = [];products = [];
            }
        }
        else{
            collections = [];products = [];variants = [];
            if(!this.state.exclude_products_checkbox){
                excluded_products = [];
            }
            if(!this.state.exclude_collections_checkbox){
                excluded_collections = [];
            }
        }
        if(!this.state.schedule_checked){
            start_date_time = null;
            start_time = null;
            end_date_time = null;
            end_time = null;
        }
        if(!this.state.schedule_end_date){
            end_date_time = null;
            end_time = null;
        }
        const formData = {
            internal_name: this.state.internal_name,
            qty_ctrl:{
                enabled: this.state.__enableQty,
                default_qty: this.state.__dq,
                min_qty: this.state.__minq,
                max_qty: this.state.__maxq,
                inc_qty: this.state.__qi
            },
            tags: this.state.tags,
            type: this.state.discount_type,
            value: this.state.discount_value,
            entire_store: this.state.entire_store,
            status: this.state.status,
            shop_id: this.state.shop_id,
            shop: this.state.shop,
            applies_to: this.state.value,
            products: products,
            collections: collections,
            variants: variants,
            min_quantity_requirment: this.state.min_quantity_requirment,
            minimum_quantities: this.state.min_quantity_req_fields,
            customers_group: {
                customers: this.state.specific_tag_base_customers?'only_logged_in_customers':this.state.customers_group,
                tag_base_customers: this.state.specific_tag_base_customers,
            },
            settings: this.state.settings,
            schedule_active: this.state.schedule_checked,
            schedule: {
                start_date: start_date_time,
                end_date: end_date_time,
                start_time_est: start_time,
                end_time_est: end_time
            },
            excluded_products: excluded_products,
            excluded_collections: excluded_collections
        };
        if(this.state.min_quantity_requirment){
            let error; let less_than_zero_price = false;
            let discount_type = this.state.discount_type;
            if(this.state.min_quantity_req_fields.length < 1){
                error = true;
                this.setState({volume_discount_value__error:true});
                this.showToastMessage("Step 2: volume discount error", true);
                validation = false;
            }
            this.state.min_quantity_req_fields.forEach( function(item,idx){
                if(item.min_quantity_value === '' || item.min_discount_value === ''){
                    error = true;
                    validation = false;
                }
                else if(item.min_discount_value <= 0){
                    less_than_zero_price = true;
                }
                if(discount_type === 'percentage' && item.min_discount_value > 100){
                    error = true;
                    validation = false;
                }
            });
            if(less_than_zero_price){
                this.setState({volume_discount_value__error:true});
                this.showToastMessage("Step 2: price must greater than zero", true);
                validation = false;
            }
            if(error){
                this.setState({volume_discount_value__error:true});
                this.showToastMessage("Step 2: volume discount error", true);
                validation = false;
            }
        }
        else{
            if(this.state.discount_value === ''){
                this.setState({discount_value__error:true});
                this.showToastMessage("Step 2: price is required", true);
                validation = false;
            }
        }
        if(!this.state.entire_store && this.state.value === 'specific_products' && products.length < 1){
            this.showToastMessage("Step 3: select products", true);
            this.setState({
                step_three_error: true
            })
            validation = false;
        }
        else if(!this.state.entire_store && this.state.value === 'specific_collections' && collections.length < 1){
            this.showToastMessage("Step 3: select collections", true);
            this.setState({
                step_three_error: true
            })
            validation = false;
        }
        else if(!this.state.entire_store && this.state.value === 'specific_variants' && variants.length < 1){
            this.showToastMessage("Step 3: select variants", true);
            this.setState({
                step_three_error: true
            })
            validation = false;
        }
        if(!this.state.min_quantity_requirment){
            if(this.state.discount_type === 'percentage' && this.state.discount_value > 100){
                this.setState({discount_value__error:true});
                this.showToastMessage("Value can't exceed 100%", true);
                validation = false;
                // return false;
            }
            if(this.state.discount_value < 0.01){
                this.setState({discount_value__error:true});
                this.showToastMessage("Value can't exceed 100%", true);
                validation = false;
            }
        }
        if(this.state.customers_group === 'specific_tag_base_customers' && this.state.specific_tag_base_customers && this.state.tags === ''){
            this.showToastMessage("Step 1: customer tag can't be blank", true);
            this.setState({customer_tag_missing_error:true});
            validation = false;
        }
        if(this.state.schedule_checked && this.state.schedule_end_date){
            if(this.diff_days(this.state.end_date,this.state.start_date) === 0){
                if(this.state.start_time === this.state.end_time){
                    this.setState({end_date_time_error:true});
                    validation = false;
                    // return false;
                }
                else if(this.state.start_time > this.state.end_time){
                    this.setState({end_date_time_error:true});
                    validation = false;
                    // return false;
                }
    
            }
            else if(this.diff_days(this.state.end_date,this.state.start_date) < 0){
                this.setState({end_date_time_error:true});
                validation = false;
                // return false;
            }
        }
        if(validation){
            this.setState({save_discount_rule:true});
            axios.post(this.state.host_name+'/add-pricing-group', formData)
            .then( response => {
                if(response.data.status === 1){
                    this.showToastMessage(response.data.message);
                    this.props.history.push('/pricing-groups');
                }
                else{
                    this.setState({save_discount_rule:false});
                    this.showToastMessage(response.data.message, true);
                    return false;
                }
            })
            .catch(error => {
                this.setState({save_discount_rule:false});
            });
        }
    };
    diff_days(new_date, old_date) {
        var t1 = new Date(new_date);
        var t2 = new Date(old_date);
        let result = parseInt((t1-t2)/(24*3600*1000),10);
        if(isNaN(result)) result = 0;
        return result;
    }
    get_est_date_time(datetime){
        var estTime = new Date(datetime); // get local time to be calculated into EST
        return estTime.setHours(estTime.getHours() + estTime.getTimezoneOffset()/60 - 5); // getTimezoneOffset returns in minutes hence /60  
    }
    est_date_time_now(){
        var estTime = new Date(); // get local time to be calculated into EST
        return estTime.setHours(estTime.getHours() + estTime.getTimezoneOffset()/60 - 5); // getTimezoneOffset returns in minutes hence /60  
    }
    get_est_date_only(date){
        let date_only = new Date(date);
        if(typeof date_only === 'object'){
          date_only = date_only.toString();
          if(typeof date_only === 'string'){
            date_only = date_only.replace(this.getTimezoneOffset(), '+0000');
          }
        }
        date_only = new Date(date_only).toISOString();
        date_only = date_only.split('T')[0];
        return date_only;
    }
    deleteProduct(item){
        this.setState({
            products_array: this.state.products_array.filter(i => i.id !== item.id)
        });
    }
    deleteCollection(item){
        this.setState({
            collections_array:this.state.collections_array.filter(i => i.id !== item.id)
        });
    }
    handleAppliesOn = (checked, newValue) => {
        this.setState({
            value: newValue,
        });
        switch (newValue) {
            case 'entire_store':
                this.setState({
                    entire_store: true,
                    specific_collections: false,
                    specific_products:false,
                    specific_variants:false,
                });
                break;
            case 'specific_collections':
                this.setState({
                    entire_store: false,
                    specific_collections: true,
                    specific_products:false,
                    specific_variants:false,
                    collection_modal_open: true
                });
                break;
            case 'specific_products':
                this.setState({
                    entire_store: false,
                    specific_collections: false,
                    specific_products:true,
                    specific_variants:false,
                    product_modal_open: true
                });
                break;
            case 'specific_variants':
                this.setState({
                    entire_store: false,
                    specific_collections: false,
                    specific_products:false,
                    specific_variants:true
                });
                break;
            default:
                break;
        }
    };
    handleMinimumQuantityRequirment = (value) => {
        if(value){
            this.setState(prevState =>({
                min_quantity_requirment: value,
                discount_type: this.state.settings.discount_method === 'line_items'?"fixed_per_item_discount":this.state.discount_type,
                options: [
                    {label: 'Fixed amount', value: 'fixed_amount'},
                    {label: 'Fixed amount (per item discount)', value: 'fixed_per_item_discount'},
                    {label: 'Percentage', value: 'percentage'}
                ],
                min_quantity_req_fields: this.state.min_quantity_req_fields.length < 1 ?[...prevState.min_quantity_req_fields, {min_quantity_value:'', min_discount_value:''}]:this.state.min_quantity_req_fields
            }));
        }
        else{
            
            let min_quantity_req_fields = [...this.state.min_quantity_req_fields];
            if(this.state.min_quantity_req_fields[this.state.min_quantity_req_fields.length-1].min_quantity_value === ''){
                min_quantity_req_fields.splice(this.state.min_quantity_req_fields.length-1, 1);
            }
            this.setState({
                discount_type: this.state.discount_type==='fixed_per_item_discount'?'fixed_amount':this.state.discount_type,
                min_quantity_requirment: value,
                options: [
                    {label: 'Fixed amount', value: 'fixed_amount'},
                    {label: 'Percentage', value: 'percentage'},
                ],
                min_quantity_req_fields: min_quantity_req_fields
            });
        }
    };
    addClick(){
        this.setState(prevState => ({ 
            min_quantity_req_fields: [...prevState.min_quantity_req_fields, {min_quantity_value:'', min_discount_value:''}]
        }))
    }
    removeClick(i){
        let min_quantity_req_fields = [...this.state.min_quantity_req_fields];
        min_quantity_req_fields.splice(i, 1);
        this.setState({ min_quantity_req_fields });
    }
    createUI(){
        return this.state.min_quantity_req_fields.map((el, i) => (
          <div key={i}>
            <Stack alignment="leading">
            <Stack.Item fill>
            <Stack distribution="equalSpacing" spacing="extraTight">
            <div className="QuantityValueField">
                <div className="Polaris-TextField Polaris-TextField--hasValue">
                <input id="TextField1" className="Polaris-TextField__Input" aria-labelledby="TextField1Label" aria-invalid="false" min="1" minLength="3" type="number" name="min_quantity_value" value={el.min_quantity_value||''} onChange={this.handleChangeMinDiscountsGroupValue.bind(this, i)} placeholder="Minimum quantity"></input>
                <div className="Polaris-TextField__Backdrop"></div>
                </div>
            </div>
            <div className="DiscountValueField">
                <div className="Polaris-TextField Polaris-TextField--hasValue">
                {this.state.discount_type === 'fixed_amount' ?<div className="Polaris-TextField__Prefix" id="TextField2Prefix">$</div>:''}
                <input id="TextField2" className="Polaris-TextField__Input" aria-labelledby="TextField2Label" aria-invalid="false" max={this.state.discount_type === 'percentage' ?'100':'1000000'} type="number" name="min_discount_value" value={el.min_discount_value ||''} onChange={this.handleChangeMinDiscountsGroupValue.bind(this, i)} placeholder="Discont amount"></input>
                {this.state.discount_type === 'percentage' ?<div className="Polaris-TextField__Suffix"
                    id="TextField2Suffix">%</div>:''}
                <div className="Polaris-TextField__Backdrop"></div>
                </div>
            </div>
            <Button
                onClick={this.removeClick.bind(this, i)}
                disabled={this.state.min_quantity_req_fields.length < 2}
            ><Icon source={DeleteMinor}/></Button>
          </Stack></Stack.Item></Stack>
          </div>          
        ))
    }
    handleChangeMinDiscountsGroupValue(i, e) {
        var { name, value } = e.target;
        if(this.state.discount_type === 'percentage' && value > 100){
            value = 100;
        }
        if(value !== '' && value < 0){
            value = 1;
        }
        let min_quantity_req_fields = [...this.state.min_quantity_req_fields];
        min_quantity_req_fields[i] = {...min_quantity_req_fields[i], [name]: value};
        this.setState({ min_quantity_req_fields });
    }
    handleSubmitSelectedVariants = (selected) => {
        let variants = store('variants') || [];
        if(variants.length < 1){
            this.setState({
                variant_modal_open: false,
                selected_products_variant_picker_open: false,
                product_modal_for_variant_open: false
            });
        }
        else{
            this.setState({
                variants: variants,
                variants_array: variants,
                variant_modal_open: false,
                selected_products_variant_picker_open: false,
                product_modal_for_variant_open: false
            });
        }
    };
    handleSubmitUpdateProducts = (selected) => {
        let pro_selected = store('products') || [];
        let p_arr = [];
        if(pro_selected.length > 0){
            pro_selected.forEach(x => {
                p_arr.push(x.id);
            });
        }
        this.setState({
            products_array: pro_selected,
            products: p_arr,
            update_products__modal_open: false
        })
    }
    handleSubmitUpdateCollections = (selected) => {
        let col_selected = store('collections') || [];
        let c_arr = [];
        if(col_selected.length > 0){
            col_selected.forEach(x => {
                c_arr.push(x.id);
            });
        }
        this.setState({
            collections_array: col_selected,
            collections: c_arr,
            update_collections__modal_open: false
        })
    }
    handleSubmitUpdateVariants = (selected) => {
        let variants = store('variants') || [];
        this.setState({
            variants: variants,
            variants_array: variants,
            update_variants__modal_open: false,
        });
    }
    upgrade = () => {
        this.props.history.push('/pricing-plans');
    }
    getTimezoneOffset() {
        function z(n){return (n<10? '0' : '') + n}
        var offset = new Date().getTimezoneOffset();
        var sign = offset < 0? '+' : '-';
        offset = Math.abs(offset);
        return sign + z(offset/60 | 0) + z(offset%60);
    }
    get_est_dateTime(date,time){
        let dateTimeValue = new Date(date);
        if(typeof dateTimeValue === 'object'){
          dateTimeValue = dateTimeValue.toString();
          if(typeof dateTimeValue === 'string'){
            dateTimeValue = dateTimeValue.replace(this.getTimezoneOffset(), '+0000');
          }
        }
        dateTimeValue = new Date(dateTimeValue).toISOString();
        dateTimeValue = dateTimeValue.split('T')[0] + " " +time;
        return dateTimeValue;
    }
    handleSubmitUpdateExcProducts = (selected) => {
        this.setState({
            excluded_products: store('excludedProducts') || [],
            update_ex_products__modal_open: false
        })
    }
    handleSubmitUpdateExcCollections = (selected) => {
        this.setState({
            excluded_collections: store('excludedCollections') || [],
            update_ex_collections__modal_open: false
        })
    }
    render() {
        Date.prototype.addHours = function (h) {
            this.setTime(this.getTime() + (h * 60 * 60 * 1000));
            return this;
        }
        let {value,discount_value__error,min_quantity_requirment,schedule_checked,schedule_end_date,end_month,end_year,start_month,start_year,start_date_selected,end_date_selected,start_date_popup,start_date,end_date_popup,end_date,start_time,end_time,end_date_time_error,excluded_products,excluded_collections,exclude_collections_checkbox,exclude_products_checkbox,customers_group} = this.state;
        let time_options = [
            {label:'12:00 am',value:'00:00:00'},
            {label:'12:30 am',value:'00:30:00'},
            {label:'01:00 am',value:'01:00:00'},
            {label:'01:30 am',value:'01:30:00'},
            {label:'02:00 am',value:'02:00:00'},
            {label:'02:30 am',value:'02:30:00'},
            {label:'03:00 am',value:'03:00:00'},
            {label:'03:30 am',value:'03:30:00'},
            {label:'04:00 am',value:'04:00:00'},
            {label:'04:30 am',value:'04:30:00'},
            {label:'05:00 am',value:'05:00:00'},
            {label:'05:30 am',value:'05:30:00'},
            {label:'06:00 am',value:'06:00:00'},
            {label:'06:30 am',value:'06:30:00'},
            {label:'07:00 am',value:'07:00:00'},
            {label:'07:30 am',value:'07:30:00'},
            {label:'08:00 am',value:'08:00:00'},
            {label:'08:30 am',value:'08:30:00'},
            {label:'09:00 am',value:'09:00:00'},
            {label:'09:30 am',value:'09:30:00'},
            {label:'10:00 am',value:'10:00:00'},
            {label:'10:30 am',value:'10:30:00'},
            {label:'11:00 am',value:'11:00:00'},
            {label:'11:30 am',value:'11:30:00'},
            {label:'12:00 pm',value:'12:00:00'},
            {label:'12:30 pm',value:'12:30:00'},
            {label:'01:00 pm',value:'13:00:00'},
            {label:'01:30 pm',value:'13:30:00'},
            {label:'02:00 pm',value:'14:00:00'},
            {label:'02:30 pm',value:'14:30:00'},
            {label:'03:00 pm',value:'15:00:00'},
            {label:'03:30 pm',value:'15:30:00'},
            {label:'04:00 pm',value:'16:00:00'},
            {label:'04:30 pm',value:'16:30:00'},
            {label:'05:00 pm',value:'17:00:00'},
            {label:'05:30 pm',value:'17:30:00'},
            {label:'06:00 pm',value:'18:00:00'},
            {label:'06:30 pm',value:'18:30:00'},
            {label:'07:00 pm',value:'19:00:00'},
            {label:'07:30 pm',value:'19:30:00'},
            {label:'08:00 pm',value:'20:00:00'},
            {label:'08:30 pm',value:'20:30:00'},
            {label:'09:00 pm',value:'21:00:00'},
            {label:'09:30 pm',value:'21:30:00'},
            {label:'10:00 pm',value:'22:00:00'},
            {label:'10:30 pm',value:'22:30:00'},
            {label:'11:00 pm',value:'23:00:00'},
            {label:'11:30 pm',value:'23:30:00'}
        ]
        let remove_error_when_value = false;
        if(discount_value__error){
            if(!min_quantity_requirment){
                if(this.state.discount_value !== '' && this.state.discount_value > 0){
                    remove_error_when_value = true;
                }
            }
        }
        let start_date_piker = <DatePicker
            month={start_month}
            year={start_year}
            disableDatesBefore={new Date().addHours(-24)}
            onChange={(value)=>{
                let a = value.start;
                if(typeof a === 'object'){
                    a = a.toString();
                    if(typeof a === 'string'){
                        a = a.replace(this.getTimezoneOffset(), '+0000');
                    }
                }
                this.setState({
                    start_date_selected: value,
                    start_date:new Date(a).toISOString().split('T')[0],
                    end_date:this.diff_days(this.state.end_date,a)<=0?new Date(a).toISOString().split('T')[0]:this.state.end_date,
                    start_date_popup:false
                })
            }}
            onMonthChange={(month, year) => {
                this.setState({
                    start_month:month,
                    start_year:year,
                });
            }}
            selected={start_date_selected}
        />;
        let end_date_piker = <DatePicker
            month={end_month}
            year={end_year}
            disableDatesBefore={start_date_selected.start?start_date_selected.start:new Date().addHours(-24)}
            onChange={(value)=>{
                let a = value.start;
                if(typeof a === 'object'){
                    a = a.toString();
                    if(typeof a === 'string'){
                        a = a.replace(this.getTimezoneOffset(), '+0000');
                    }
                }
                this.setState({
                    end_date_selected: value,
                    end_date: new Date(a).toISOString().split('T')[0],
                    end_date_popup: false
                })
            }}
            onMonthChange={(month, year) => {
                this.setState({
                    end_month:month,
                    end_year:year,
                });
            }}
            selected={end_date_selected}
        />;
        const start_date_activator = (
            <TextField
                readOnly={true}
                label="Start date"
                value={start_date}
                autoComplete={false}
                onChange={(value)=>{
                    this.setState({start_date:value,start_date_popup:false});
                }}
                onFocus={()=>{
                    this.setState({start_date_popup:true});
                }}
            />
        );
        const end_date_activator = (
            <TextField
                readOnly={true}
                label="End date"
                value={end_date}
                autoComplete={false}
                onChange={(value)=>{
                    this.setState({end_date:value,end_date_popup:false});
                }}
                onFocus={()=>{
                    this.setState({end_date_popup:true});
                }}
                error={end_date_time_error?true:false}
            />
        );

        const step1_popup = (
            <span className="icons-styles" onClick={()=>{
                this.setState(({step1_help_popover}) => {
                    return {step1_help_popover: !step1_help_popover};
                });
            }} style={{display:'inline-block'}}>
            <Icon source={QuestionMarkMajor}></Icon>
            </span>
        );
        let step1_popover = <Popover
                active={this.state.step1_help_popover}
                activator={step1_popup}
                onClose={()=>{
                    this.setState(({step1_help_popover}) => {
                        return {step1_help_popover: !step1_help_popover};
                    });
                }}
            >
            <div style={{padding: '4px 8px'}}>
                This is section, you select what type of customers can get this discount. 
                <List type="number">
                    <List.Item>
                        <TextStyle variation="strong">All customers: </TextStyle>
                        Anyone who visit your store, and purchase items will get discount (if discount available).
                    </List.Item>
                    <List.Item>
                        <TextStyle variation="strong">Only logged in: </TextStyle>
                         Anyone who has an account created by your store, and he/she is logged in when purchasing items, will get this discount.
                    </List.Item>
                    <List.Item>
                        <TextStyle variation="strong">Tag based: </TextStyle>
                        Anyone who has an account and contain tag, will get discount.
                    </List.Item>
                </List>
            </div>
        
        </Popover>;

        const step2_popup = (
            <span className="icons-styles" onClick={()=>{
                this.setState(({step2_help_popover}) => {
                    return {step2_help_popover: !step2_help_popover};
                });
            }} style={{display:'inline-block'}}>
            <Icon source={QuestionMarkMajor}></Icon>
            </span>
        );
        let step2_popover = <Popover
                active={this.state.step2_help_popover}
                activator={step2_popup}
                onClose={()=>{
                    this.setState(({step2_help_popover}) => {
                        return {step2_help_popover: !step2_help_popover};
                    });
                }}
            >
            <div style={{padding: '4px 8px'}}>
            This is section, you can create
            <List>
                <List.Item>
                    <TextStyle variation="strong">Fixed amount discount: </TextStyle> A fixed amount will be discounted from each product's variant at checkout.
                </List.Item>
                <List.Item>
                    <TextStyle variation="strong">Percentage discount: </TextStyle>A percentage amount will be discounted from each product's variant at checkout.
                </List.Item>
                <List.Item>
                    <TextStyle variation="strong">Volume discount: </TextStyle>
                    This applies to total price of all the items in the cart, meaning quantity of each product is checked when applying discount, if the product has minimum quantity equal to or greater than volume discount's minimum quantity, then discount will apply at checkout. 
                    <p>E.g. Buy 3 and get 10% Off, where 3 is minimum quantity and 10 is discount amount.</p>
                    Read more about <Button plain external target="remote" url="https://digitalcoo.com/wholesale-all-in-one-documnetation/volume-based-discount">Volume discount</Button>
                </List.Item>
            </List>
            </div>
        </Popover>;

        const step3_popup = (
            <span className="icons-styles" onClick={()=>{
                this.setState(({step3_help_popover}) => {
                    return {step3_help_popover: !step3_help_popover};
                });
            }} style={{display:'inline-block'}}>
            <Icon source={QuestionMarkMajor}></Icon>
            </span>
        );
        let step3_popover = <Popover
                active={this.state.step3_help_popover}
                activator={step3_popup}
                onClose={()=>{
                    this.setState(({step3_help_popover}) => {
                        return {step3_help_popover: !step3_help_popover};
                    });
                }}
            >
            <div style={{padding: '4px 8px'}}>
                In this section, select this discount will apply to:
                <List type="number">
                    <List.Item>
                        <TextStyle variation="strong">Entire website: </TextStyle>
                        this discount rule will applies to all products/collections/variants in your store.
                        If you don not want some collections or products to be discounted, you can exclude them. Read our <Button plain external target="remote" url="https://digitalcoo.com/wholesale-all-in-one-documnetation/store-level-discoun">FAQ</Button>
                    </List.Item>
                    <List.Item>
                        <TextStyle variation="strong">Specific collections: </TextStyle>
                        set this discount rule only applies to specific collections in your store. Click [+Add], then select collections. If you want to edit/remove selected collection click [Edit]. Read our <Button plain external target="remote" url="https://digitalcoo.com/wholesale-all-in-one-documnetation/collections-level-discount">FAQ</Button>
                    </List.Item>
                    <List.Item>
                        <TextStyle variation="strong">Specific products: </TextStyle>
                        set this discount rule only applies to specific products in your store. Click [+Add], then select products. If you want to edit/remove selected products click [Edit]. Read our <Button plain external target="remote" url="https://digitalcoo.com/wholesale-all-in-one-documnetation/products-level-discount">FAQ</Button>
                    </List.Item>
                    <List.Item>
                        <TextStyle variation="strong">Specific variants: </TextStyle>
                        set this discount rule only applies to specific variants of the product in your store. Click [+Add], then select products. If you want to edit/remove selected products click [Edit]. Read our <Button plain external target="remote" url="https://digitalcoo.com/wholesale-all-in-one-documnetation/variants-level-discount">FAQ</Button>
                    </List.Item>
                </List>
            </div>
        </Popover>;

        const step4_popup = (
            <span className="icons-styles" onClick={()=>{
                this.setState(({step4_help_popover}) => {
                    return {step4_help_popover: !step4_help_popover};
                });
            }} style={{display:'inline-block'}}>
            <Icon source={QuestionMarkMajor}></Icon>
            </span>
        );
        let step4_popover = <Popover
                active={this.state.step4_help_popover}
                activator={step4_popup}
                onClose={()=>{
                    this.setState(({step4_help_popover}) => {
                        return {step4_help_popover: !step4_help_popover};
                    });
                }}
            >
            <div style={{padding: '4px 8px'}}>
            When this option is checked and select start date and time, and if end date is not available, this schedule discount will not expire automatically, until your disable it. Set end date, meaning your schedule discount will be active and only apply between the timespan you selected. 
            </div>
        </Popover>;

        const tag_popup = (
            <span className="icons-styles" onClick={()=>{
                this.setState(({tag_popover}) => {
                    return {tag_popover: !tag_popover};
                });
            }} style={{display:'inline-block'}}>
            <Icon source={QuestionMarkMajor}></Icon>
            </span>
        );
        
        let step0_popover = <Popover
                active={this.state.tag_popover}
                activator={tag_popup}
                onClose={()=>{
                    this.setState(({tag_popover}) => {
                        return {tag_popover: !tag_popover};
                    });
                }}
            >
            <div style={{padding: '4px 8px'}}> 
            This name will only be used in this admin dashboard to help differentiate your other discount groups.
            </div>
        </Popover>;

        let internal_name_section = <Layout sectioned={true}>
            <Layout.AnnotatedSection
                title={<div style={{display:'inline-flex'}}>Step 0: Title {step0_popover}</div>}
                description="Only admin can see the title">
                <Card sectioned>
                    <Stack vertical spacing="extraTight">
                        <TextField
                            label={
                            <div>
                                Internal Name 
                            </div>
                            }
                            placeholder="All customers - 10% discount"
                            value={this.state.internal_name}
                            onChange={(value) => {this.setState({internal_name: value})}}
                            autoComplete="false"
                        />
                    </Stack>
                </Card>
            </Layout.AnnotatedSection>
        </Layout>;
        
        let step_one = <Layout sectioned={true}>
            <Layout.AnnotatedSection
                title={<div style={{display:'inline-flex'}}>Step 1: Customers {step1_popover}</div>}
                description="Add discount rule to offer the best deals to these customers">
                <Card sectioned>
                    <Stack vertical spacing="extraTight">
                        <RadioButton
                            label="All customers"
                            helpText="Discount for guest customers [ Without login ]"
                            id="all"
                            name="customers_group"
                            checked={customers_group === 'all'}
                            onChange={(c,v)=>{this.setState({customers_group:v,specific_tag_base_customers:false})}}
                        />
                        <RadioButton
                            label="Only logged in customers"
                            helpText="Discount for only login customers"
                            id="only_logged_in_customers"
                            name="customers_group"
                            checked={customers_group === 'only_logged_in_customers'}
                            onChange={(c,v)=>{this.setState({customers_group:v,specific_tag_base_customers:false})}}
                        />
                        <RadioButton
                            label="Only tag based customers"
                            helpText="Discount for specific tag base customers, Wholesale, VIP etc."
                            id="specific_tag_base_customers"
                            name="customers_group"
                            checked={customers_group === 'specific_tag_base_customers'}
                            onChange={(c,v)=>{this.setState({customers_group:v,specific_tag_base_customers:c})}}
                        />
                        {
                            customers_group === 'specific_tag_base_customers'?
                            <TextField
                                label={
                                <div>
                                    Customer tag <Tooltip content="Customer tag is a case-insensitive. You can use uppercase or lowercase.">
                                    <span className="icons-styles" style={{display:'inline-block'}}>
                                        <Icon source={CircleInformationMajor}></Icon>
                                    </span>
                                    </Tooltip>
                                </div>
                                }
                                placeholder="E.g. wholesale"
                                helpText="Customers contain this tag, will get this discount"
                                value={this.state.tags}
                                onChange={(value) => {this.setState({tags: value})}}
                                autoComplete="false"
                                error={this.state.customer_tag_missing_error?true:false}
                            />
                            :null
                        }
                    </Stack>
                </Card>
            </Layout.AnnotatedSection>
        </Layout>;
        
        let step_two = <Layout sectioned={true}>
                <Layout.AnnotatedSection
                    title={<div style={{display:'inline-flex'}}>Step 2: Discount options {step2_popover}</div>}
                    description="Set discount either fixed amount, percentage discount or volume discount">
                    <Card sectioned>
                        <Stack vertical spacing="extraTight">
                            <RadioButton
                                label="Fixed amount discount"
                                id="fixed_amount"
                                name="discount_type"
                                checked={this.state.discount_type === 'fixed_amount'}
                                onChange={(c,v)=>{this.setState({discount_type:v})}}
                            />
                             <RadioButton
                                label="Percentage discount"
                                id="percentage"
                                name="discount_type"
                                checked={this.state.discount_type === 'percentage'}
                                onChange={(c,v)=>{this.setState({discount_type:v})}}
                            />
                            {
                                min_quantity_requirment?null:
                                <TextField
                                    type="number"
                                    label={<span>{this.state.discount_type === 'fixed_amount'?"Reduce Item Price By":"Percent Off Per Item"}</span>}
                                    placeholder={"Price"}
                                    value={getValue(this.state.discount_value)}
                                    onChange={(value) =>{
                                        if(this.state.discount_type === 'percentage' && value > 100){
                                            value = 100;
                                        }
                                        if(value !== '' && value < 0){
                                            value = 1;
                                        }
                                        this.setState({discount_value: value});
                                    }}
                                    prefix={this.state.discount_type === 'fixed_amount'?'$':''}
                                    suffix={this.state.discount_type === 'percentage'?'%':''}
                                    max={this.state.discount_type === 'percentage'?100:100000}
                                    step={1}
                                    disabled={min_quantity_requirment}
                                    error={discount_value__error?remove_error_when_value?false:true:false}
                                />
                            }
                        </Stack>
                    </Card>
                </Layout.AnnotatedSection>
            </Layout>;
        
        let step_three = <Layout sectioned={true}>
                <Layout.AnnotatedSection
                    title={<div style={{display:'inline-flex'}}>Step 3: Applies to {step3_popover}</div>}
                    description="Apply discount to entire store, specific products, specific variants or specific collections">
                    <Card sectioned>
                        <Stack vertical={true} spacing="extraTight">
                            <div>
                                <RadioButton
                                    label="Entire website"
                                    checked={value === 'entire_store'}
                                    id="entire_store"
                                    name="discount_applies_on"
                                    onChange={this.handleAppliesOn}
                                ></RadioButton>
                                {
                                     value === 'entire_store' ?
                                    <div style={{marginLeft:'2.4rem'}}>
                                        <div>
                                            <Checkbox
                                                checked={exclude_collections_checkbox}
                                                onChange={(value)=>{
                                                    this.setState({
                                                        exclude_collections_checkbox:value,
                                                        exclude_collection_modal_open:value
                                                    });
                                                    
                                                }}
                                                label={
                                                    <span>
                                                        Exclude collections
                                                        <Button size="slim" plain
                                                            // disabled={!exclude_collections_checkbox}
                                                            onClick={()=>{
                                                                this.setState({
                                                                    exclude_collection_modal_open:true
                                                                });
                                                                store('excludedCollections',this.state.excluded_collections);
                                                            }}
                                                        >
                                                        [ +Add ]
                                                        </Button>
                                                        {
                                                            excluded_collections.length > 0?
                                                            <span>
                                                                <Button size="slim" plain
                                                                    disabled={!exclude_collections_checkbox}
                                                                    onClick={()=>{
                                                                        this.setState({
                                                                            update_ex_collections__modal_open:true
                                                                        });
                                                                        store('excludedCollections',this.state.excluded_collections);
                                                                    }}
                                                                >
                                                                [ Edit <label style={{borderBottom:'1px dotted'}}>{excluded_collections.length}</label> ]
                                                                </Button>
                                                            </span>
                                                            :null
                                                        }
                                                    </span>
                                                }
                                            ></Checkbox>
                                        </div>
                                        <div>
                                            <Checkbox
                                                checked={exclude_products_checkbox}
                                                onChange={(value)=>{
                                                    this.setState({
                                                        exclude_products_checkbox: value,
                                                        exclude_product_modal_open: value
                                                    })
                                                }}
                                                label={
                                                    <span>
                                                        Exclude products
                                                        <Button size="slim" plain
                                                            // disabled={!exclude_products_checkbox}
                                                            onClick={()=>{
                                                                this.setState({
                                                                    exclude_product_modal_open: true
                                                                });
                                                                store('excludedProducts',this.state.excluded_products);
                                                            }}
                                                        >
                                                        [ +Add ]
                                                        </Button>
                                                        {
                                                            excluded_products.length > 0?
                                                            <span>
                                                                <Button size="slim" plain
                                                                    disabled={!exclude_products_checkbox}
                                                                    onClick={()=>{
                                                                        this.setState({
                                                                            update_ex_products__modal_open:true
                                                                        });
                                                                        store('excludedProducts',this.state.excluded_products);
                                                                    }}
                                                                >
                                                                [ Edit <label style={{borderBottom:'1px dotted'}}>{excluded_products.length}</label> ]
                                                                </Button>
                                                            </span>
                                                            :null
                                                        }
                                                    </span>
                                                }
                                            ></Checkbox>
                                        </div>
                                    </div>
                                    :null
                                }
                            </div>
                            <ButtonGroup>
                                <RadioButton
                                    label="Specific collections"
                                    checked={value === 'specific_collections'}
                                    id="specific_collections"
                                    name="discount_applies_on"
                                    onChange={this.handleAppliesOn}
                                    disabled={
                                        this.props.user_current_plan.plan_permissions?
                                        this.props.user_current_plan.plan_permissions[0].collections?
                                        false:true
                                        :false
                                    }
                                >
                                </RadioButton>
                                {
                                    this.props.user_current_plan.plan_permissions?
                                    !this.props.user_current_plan.plan_permissions[0].collections?
                                    <span>(<Button plain={true} size={'slim'} onClick={this.upgrade} > Upgrade plan </Button>)</span>
                                    :
                                    value === 'specific_collections'?
                                    <span>
                                        <Button
                                            plain
                                            onClick= { () => {
                                                this.setState({collection_modal_open:true});
                                                store('collections',this.state.collections_array);
                                            }}
                                        >
                                            [ +Add ]
                                        </Button>
                                        {
                                            this.state.collections.length > 0?
                                            <Button
                                                    onClick= {()=> {
                                                        this.setState({update_collections__modal_open: true});
                                                        store('collections',this.state.collections_array);
                                                    }}
                                                    plain={true}
                                                >
                                                &nbsp; [ Edit ({this.state.collections.length}) ]
                                            </Button>
                                            :null
                                        }
                                    </span>
                                    :null // 'no plan permission granted
                                    :null //no specific collection
                                }
                            </ButtonGroup>
                            {
                                value === 'specific_collections' && this.state.collections.length > 0?
                                <div style={{marginLeft:'2.4rem'}}>
                                    <div>
                                        <Checkbox
                                            checked={exclude_products_checkbox}
                                            onChange={(value)=>{
                                                this.setState({
                                                    exclude_products_checkbox: value,
                                                    exclude_product_modal_open: value
                                                })
                                            }}
                                            label={
                                                <span>
                                                    Exclude products
                                                    <Button size="slim" plain
                                                        // disabled={!exclude_products_checkbox}
                                                        onClick={()=>{
                                                            this.setState({
                                                                exclude_product_modal_open:true
                                                            });
                                                            store('excludedProducts',this.state.excluded_products);
                                                        }}
                                                    >
                                                    [ +Add ]
                                                    </Button>
                                                    {
                                                        excluded_products.length > 0?
                                                        <span>
                                                            <Button size="slim" plain
                                                                disabled={!exclude_products_checkbox}
                                                                onClick={()=>{
                                                                    this.setState({
                                                                        update_ex_products__modal_open:true
                                                                    });
                                                                    store('excludedProducts',this.state.excluded_products);
                                                                }}
                                                            >
                                                            [ Edit <label style={{borderBottom:'1px dotted'}}>{excluded_products.length}</label> ]
                                                            </Button>
                                                        </span>
                                                        :null
                                                    }
                                                </span>
                                            }
                                        ></Checkbox>
                                    </div>
                                </div>
                                :null
                            }
                            <ButtonGroup>
                                <RadioButton
                                    label="Specific products"
                                    checked={value === 'specific_products'}
                                    id="specific_products"
                                    name="discount_applies_on"
                                    onChange={this.handleAppliesOn}
                                    disabled={
                                        this.props.user_current_plan.plan_permissions?
                                        this.props.user_current_plan.plan_permissions[0].products?
                                        false:true
                                        :false
                                    }
                                />
                                {
                                    this.props.user_current_plan.plan_permissions?
                                    !this.props.user_current_plan.plan_permissions[0].products?
                                    <span>(<Button plain={true} size={'slim'} onClick={this.upgrade} > Upgrade plan </Button>)</span>
                                    :
                                    value === 'specific_products'?
                                    <span>
                                        <Button
                                            plain={true}
                                            onClick={ () => {
                                                this.setState({product_modal_open: true});
                                                store('products', this.state.products_array);
                                            }}
                                        >
                                        [ +Add ]
                                        </Button>
                                        {
                                            this.state.products.length > 0?
                                            <Button
                                                onClick= {()=> {
                                                    this.setState({update_products__modal_open: true});
                                                    store('products', this.state.products_array);
                                                }}
                                                plain={true}
                                            >
                                            &nbsp;[ Edit <label style={{borderBottom:'1px dotted'}}>{this.state.products.length}</label> ]
                                            </Button>
                                            :null
                                        }
                                    </span>
                                    :null
                                    :null
                                }
                            </ButtonGroup>
                            <ButtonGroup>
                                <RadioButton
                                    label="Specific variants"
                                    checked={value === 'specific_variants'}
                                    id="specific_variants"
                                    name="discount_applies_on"
                                    onChange={this.handleAppliesOn}
                                    disabled={
                                        this.props.user_current_plan.plan_permissions?
                                        this.props.user_current_plan.plan_permissions[0].variants?
                                        false:true
                                        :false
                                    }
                                />
                                {
                                    this.props.user_current_plan.plan_permissions?
                                    !this.props.user_current_plan.plan_permissions[0].variants?
                                    <span>(<Button plain={true} size={'slim'} onClick={this.upgrade} > Upgrade plan </Button>)</span>
                                    :
                                    value === 'specific_variants'?
                                    <span>
                                        <Button
                                            plain={true}
                                            onClick = { () => {
                                                this.setState({product_modal_for_variant_open:true});
                                                store('variants', this.state.variants_array);
                                                // if(this.props.total_products <= 250){
                                                //     this.setState({variant_modal_open:true});
                                                //     store('variants', this.state.variants_array);
                                                // }
                                                // else{
                                                //     this.setState({product_modal_for_variant_open:true});
                                                //     store('variants', this.state.variants_array);
                                                // }
                                            }}
                                        > [ +Add ]
                                        </Button>
                                        {
                                            this.state.variants.length > 0?
                                            <Button
                                                onClick= {()=> {
                                                    this.setState({update_variants__modal_open: true});
                                                    store('variants', this.state.variants_array);
                                                }}
                                                    plain={true}
                                                >
                                                &nbsp;[ Edit <label style={{borderBottom:'1px dotted'}}>{this.state.variants.length}</label> ]
                                            </Button>
                                            :null
                                        }
                                    </span>
                                    :null
                                    :null
                                }
                            </ButtonGroup>
                        </Stack>
                    </Card>
                </Layout.AnnotatedSection>
            </Layout>;
        
        let step_four = <Layout sectioned={true}>
            <Layout.AnnotatedSection
                title={<div style={{display:'inline-flex'}}>Step 4: Schedule (Optional) {step4_popover}</div>}
                description=''
            >
            <Card sectioned>
                <FormLayout>
                    <Checkbox
                        checked={schedule_checked}
                        label="Schedule this discount rule"
                        onChange={(value)=>{
                            this.setState({schedule_checked:value})
                        }}
                    />
                    {
                        schedule_checked?
                        <span>
                            <Stack wrap={false} alignment="leading" spacing="loose">
                                <Stack.Item fill>
                                    <Stack distribution="fill" spacing="loose">
                                        <Popover
                                            active={start_date_popup}
                                            activator={start_date_activator}
                                            onClose={(value)=>{ this.setState({start_date_popup:false})}}
                                            preferredPosition="above"
                                            sectioned
                                        >
                                        {start_date_piker}
                                        </Popover>
                                        <Select
                                            label={'Start time (EST)'}
                                            placeholder="Select time"
                                            options={time_options}
                                            onChange={(value)=>{this.setState({start_time:value})}}
                                            value={start_time}
                                        >
                                        </Select>
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                            <br></br>
                            <Checkbox
                                checked={schedule_end_date}
                                label="Set end date"
                                onChange={(value)=>{
                                    this.setState({schedule_end_date:value})
                                }}
                            />
                            <br></br>
                            {schedule_end_date?
                            <Stack wrap={false} alignment="leading" spacing="loose">
                                <Stack.Item fill>
                                    <Stack distribution="fill" spacing="loose">
                                        <Popover
                                            active={end_date_popup}
                                            activator={end_date_activator}
                                            onClose={(value)=>{this.setState({end_date_popup:false})}}
                                            preferredPosition="above"
                                            sectioned
                                        >
                                        {end_date_piker}
                                        </Popover>
                                        <Select
                                            label={'End time (EST)'}
                                            placeholder="Select time"
                                            options={time_options}
                                            onChange={(value)=>{this.setState({end_time:value})}}
                                            value={end_time}
                                            error={end_date_time_error?true:false}
                                        ></Select>
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                            :''
                            }
                        </span>
                        :null
                    }
                </FormLayout>
            </Card>
            </Layout.AnnotatedSection>
        </Layout>;
        
        const tab_1 = <div style={{padding:"10px 0"}}>
                <Checkbox
                    checked={this.state.__enableQty}
                    label="Enable Quantity controller"
                    onChange={(__enableQty)=>{
                        this.setState({__enableQty})
                    }}
                />
                <div style={{padding:"10px 0"}}>
                    <Stack distribution="fill"  wrap={false}>
                        <TextField helpText="The quantity set by default. Leave blank if not set" label="Default Quantity" type="number" value={getValue(this.state.__dq)} onChange={(__dq)=>{this.setState({ __dq })}} min={1} />

                        <TextField helpText="Set minimum quantity allowed to purchase. Leave blank if not set" label="Minimum Quantity" type="number" error={this.state.__minq !== '' && parseInt(this.state.__minq) > parseInt(this.state.__dq) ?"Minimum quantity must be greater than deafult quantity":(this.state.__minq !== '' && parseInt(this.state.__minq) % parseInt(this.state.__qi) !== 0?"Minimum quantity must be multiple of "+this.state.__qi:"")} value={getValue(this.state.__minq)} onChange={(__minq)=>{this.setState({ __minq })}} min={1} />      
                    </Stack>
                </div> 
                <div style={{padding:"10px 0"}}>
                    <Stack distribution="fill" wrap={false}>
                        <TextField helpText="Set maximum quantity allowed to purchase. Leave blank if not set" label="Maximum Quantity" type="number" value={this.state.__maxq} onChange={(__maxq)=>{this.setState({ __maxq })}} min={1} />

                        <TextField helpText="Set a quantity increment value. For example, allow to buy packs of 25 items, i.e. (25,50,75, etc). Leave blank if not set" label="Quantity Increments" type="number" value={getValue(this.state.__qi)} onChange={(__qi)=>{this.setState({ __qi })}} min={1} />
                    </Stack>
                </div>
            </div>;
        const tabs = [
            {
              id: 'qty-controller',
              content: 'Quantity controller',
              accessibilityLabel: 'Quantity controller',
              panelID: 'qty-controller-content',
            }
          ];
        let tabs_container = <Card sectioned>
            <Tabs tabs={tabs} selected={this.state.tab_selected} onSelect={(tab_selected)=>{this.setState({ tab_selected })}}>
            {
                tabs[this.state.tab_selected].id==='qty-controller'?
                tab_1:null
            }
            </Tabs> 
        </Card>
         
        let resource_pikers_And_models = <span>
            {
                this.state.product_modal_open && 
                <ResourcePicker
                    resourceType="Product"
                    open={this.state.product_modal_open}
                    onSelection={(resources) => {
                        let productsArray = [];
                        let productsTitleArray = [];
                        for(var j = 0; j < resources.selection.length; j++){
                            var product = resources.selection[j];
                            if(this.state.products.includes(product.id) === false){
                                productsArray.push(product.id);
                                productsTitleArray.push({
                                    id: product.id,
                                    title: product.title,
                                    variants: product.variants,
                                    handle: product.handle,
                                    tags: product.tags,
                                    vendor: product.vendor,
                                    image: product.image,
                                });
                            }
                        }
                        let prod = productsArray.concat(this.state.products);
                        let prod_title = productsTitleArray.concat(this.state.products_array);
                        this.setState({
                            products: prod,
                            products_array: prod_title,
                            product_modal_open: false
                        });
                    }}
                    onCancel={() => this.setState({product_modal_open: false})}
                />
            }
            {
                this.state.collection_modal_open && 
                <ResourcePicker
                    resourceType="Collection"
                    open={this.state.collection_modal_open}
                    onSelection={(resources) => {
                        let collectionArray = [];
                        let collectionsTitleArray = [];
                        for(var i=0;i<resources.selection.length;i++){
                            var collection = resources.selection[i];
                            if(this.state.collections.includes(collection.id) === false){
                                collectionArray.push(collection.id);
                                collectionsTitleArray.push({
                                    id: collection.id,
                                    title: collection.title,
                                    image: collection.image,
                                    handle: collection.handle
                                });
                            }
                        }
                        let collect = collectionArray.concat(this.state.collections);
                        let collect_title = collectionsTitleArray.concat(this.state.collections_array);
                        this.setState({
                            collections: collect,
                            collections_array:collect_title,
                            collection_modal_open: false
                        });
                    }}
                    onCancel={() => this.setState({collection_modal_open: false})}
                />
            }
            {
                this.state.variant_modal_open && 
                <Modal
                    size="Medium"
                    src={'/variant-picker'}
                    open={this.state.variant_modal_open}
                    onClose={() => this.setState({variant_modal_open: false})}
                    title={'Select variants'}
                    primaryAction={{
                        content: 'Select variants',
                        onAction: this.handleSubmitSelectedVariants,
                    }}
                    secondaryActions={[
                        {
                            content: 'Cancel',
                            onAction: () => { this.setState({variant_modal_open: false})},
                        },
                    ]}
                    sectioned={false}
                >
                </Modal>
            }
            {
                this.state.selected_products_variant_picker_open && 
                <Modal
                    size="Medium"
                    src={'/selected-products-variant-picker'}
                    open={this.state.selected_products_variant_picker_open}
                    onClose={() => this.setState({
                        selected_products_variant_picker_open: false,product_modal_for_variant_open: false
                    })}
                    title={'Select variants'}
                    primaryAction={{
                        content: 'Select variants',
                        onAction: this.handleSubmitSelectedVariants,
                    }}
                    secondaryActions={[
                        {
                            content: 'Cancel',
                            onAction: () => { this.setState({
                                selected_products_variant_picker_open: false,product_modal_for_variant_open: false
                            })},
                        },
                    ]}
                    sectioned={false}
                >
                </Modal>
            }
            {
                this.state.update_products__modal_open && 
                <Modal
                    size="Medium"
                    src={'/update-products-modal'}
                    open={this.state.update_products__modal_open}
                    onClose={() => this.setState({update_products__modal_open: false})}
                    title={'Products'}
                    primaryAction={{
                        content: 'Update',
                        onAction: this.handleSubmitUpdateProducts,
                    }}
                    secondaryActions={[
                        {
                            content: 'Cancel',
                            onAction: () => {this.setState({update_products__modal_open: false})},
                        }
                    ]}
                    sectioned={false}
                >
                </Modal>
            }
            {
                this.state.update_collections__modal_open && 
                    <Modal
                        size="Medium"
                        src={'/update-collections-modal'}
                        open={this.state.update_collections__modal_open}
                        onClose={() => this.setState({update_collections__modal_open: false})}
                        title={'Collections'}
                        primaryAction={{
                            content: 'Update',
                            onAction: this.handleSubmitUpdateCollections,
                        }}
                        secondaryActions={[
                            {
                                content: 'Cancel',
                                onAction: () => {this.setState({update_collections__modal_open: false})},
                            }
                        ]}
                        sectioned={false}
                    >
                    </Modal>
            }
            {
                this.state.update_variants__modal_open && 
                <Modal
                    size="Medium"
                    src={'/update-variants-modal'}
                    open={this.state.update_variants__modal_open}
                    onClose={() => this.setState({update_variants__modal_open: false})}
                    title={'Variants'}
                    primaryAction={{
                        content: 'Update',
                        onAction: this.handleSubmitUpdateVariants,
                    }}
                    secondaryActions={[
                        {
                            content: 'Cancel',
                            onAction: () => {this.setState({update_variants__modal_open: false})},
                        }
                    ]}
                    sectioned={false}
                >
                </Modal>
            }
        
        
        {
            this.state.product_modal_for_variant_open && 
            <ResourcePicker
                open={this.state.product_modal_for_variant_open}
                onSelection={ resources => {
                    this.setState({
                        selected_products_variant_picker_open: true,
                        product_modal_for_variant_open: false
                    });
                    store('selected_products', resources.selection);
                }}
                onCancel={() => this.setState({product_modal_for_variant_open: false})}
            />
        }
        
        </span>;
        
        let exclude_resource_pikers = <span>
            {
                this.state.exclude_product_modal_open &&
                <ResourcePicker
                    open={this.state.exclude_product_modal_open}
                    onSelection={(resources) => {
                        if(resources.selection.length > 0){
                            let tem_array = [];
                            resources.selection.forEach(product =>{
                                if(excluded_products.length > 0){
                                    if(excluded_products.findIndex(x=>x.id === product.id) === -1){
                                        tem_array.push({
                                            id: product.id,
                                            title: product.title,
                                            variants: product.variants,
                                            handle: product.handle,
                                            tags: product.tags,
                                            vendor: product.vendor,
                                            image: product.image,
                                        });
                                    }
                                }
                                else{
                                    tem_array.push({
                                        id: product.id,
                                        title: product.title,
                                        variants: product.variants,
                                        handle: product.handle,
                                        tags: product.tags,
                                        vendor: product.vendor,
                                        image: product.image,
                                    });
                                }
                            });
                            this.setState({
                                excluded_products: excluded_products.concat(tem_array),
                                exclude_product_modal_open: false,
                                update_ex_products__modal_open: false,
                                exclude_products_checkbox: tem_array.length>0?true:false
                            })
                        }
                    }}
                    onCancel={() => this.setState({exclude_product_modal_open: false})}
                />
            }
            {
                this.state.exclude_collection_modal_open && 
                <ResourcePicker
                    resourceType="Collection"
                    open={this.state.exclude_collection_modal_open}
                    onSelection={(resources) => {
                        if(resources.selection.length > 0){
                            let tem_array = [];
                            resources.selection.forEach(collection =>{
                                if(excluded_collections.length > 0){
                                    if(excluded_collections.findIndex(x=>x.id === collection.id) === -1){
                                        tem_array.push({
                                            id: collection.id,
                                            title: collection.title,
                                            handle: collection.handle,
                                            image: collection.image,
                                        });
                                    }
                                }
                                else{
                                    tem_array.push({
                                        id: collection.id,
                                        title: collection.title,
                                        handle: collection.handle,
                                        image: collection.image,
                                    });
                                }
                            });
                            this.setState({
                                excluded_collections: excluded_collections.concat(tem_array),
                                exclude_collection_modal_open: false,
                                update_ex_collections__modal_open: false,
                                exclude_collections_checkbox: tem_array.length>0?true:false
                            })
                        }
                    }}
                    onCancel={() => this.setState({exclude_collection_modal_open: false})}
                />
            }
        {
            this.state.update_ex_products__modal_open &&
            <Modal
            size="Medium"
            src={'/update-excluded-products'}
            open={this.state.update_ex_products__modal_open}
            onClose={() => this.setState({update_ex_products__modal_open: false})}
            title={'Excluded products'}
            primaryAction={{
                content: 'Update',
                onAction: this.handleSubmitUpdateExcProducts,
            }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: () => {this.setState({update_ex_products__modal_open: false})},
                },
            ]}
            sectioned={false}
        >
        </Modal>
        }
        {
            this.state.update_ex_collections__modal_open && 
            <Modal
            size="Medium"
            src={'/update-excluded-collections'}
            open={this.state.update_ex_collections__modal_open}
            onClose={() => this.setState({update_ex_collections__modal_open: false})}
            title={'Excluded collections'}
            primaryAction={{
                content: 'Update',
                onAction: this.handleSubmitUpdateExcCollections,
            }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: () => {this.setState({update_ex_collections__modal_open: false})},
                },
            ]}
            sectioned={false}
        >
        </Modal>
        }
       
        </span>;
        
        let create_discount_button = <PageActions
            primaryAction={{
                content: 'Save Discount Group',
                disabled: 
                    this.state.min_quantity_requirment?
                    this.state.discount_type === 'fixed_amount'? 
                    this.state.settings.discount_method === 'line_items'?
                    true:false:false:false
                ,
                loading: this.state.save_discount_rule,
                onAction: () =>{ this.handleSubmit(); }
            }}
            secondaryActions={[
                {
                    content: "Cancel",
                    target: "APP",
                    url: "/pricing-groups"
                }
            ]}
        ></PageActions>;

        var toast = this.state.showToast? (
            <ToastMessage
              onDismiss={this.dissmissToast}
              message={this.state.toastMsg}
              error={this.state.toastError}
            />
          ): (null);


        return(
            <div>
                <PageTitleBar
                    title="Create"
                    primaryAction={{
                        content:"Save Discount Group", onAction:()=>{this.handleSubmit();},
                        disabled:
                            this.state.__minq !== '' && parseInt(this.state.__minq) > parseInt(this.state.__dq) ? true :
                            this.state.__minq !== '' && parseInt(this.state.__minq) % parseInt(this.state.__qi) !== 0 ? true : false
                        ,
                    }}
                    secondaryActions={[
                        { content: 'Back', url: '/pricing-groups', target: "APP" },
                    ]}
                />
                {toast}
                {internal_name_section}
                {step_one}
                {step_two}
                {step_three}
                {step_four} 
                {this.props.shop_features && this.props.shop_features.findIndex((x)=>x==="quantity_controller") !== -1?tabs_container:''}
                {resource_pikers_And_models}
                {exclude_resource_pikers}
                <div style={{marginBottom:'8px'}}></div>
                {create_discount_button}
                <FooterHelpLinks help={this.props.help}/>
            </div>
        )
    }
}

function getValue(value){
	if(value){
		try {
            value = value.toString();
        } catch (e) { }
    }
    return value;
}