import React, { Component } from "react";
import {
    FormLayout, Stack, TextField, PageActions 
} from "@shopify/polaris";
import axios from 'axios';
import * as PropTypes from 'prop-types';
import ToastMessage from '../../Common/ToastMessage';


export default class Translations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            variant_title: "Variant Name",
            discount: "Discount",
            buy: "Buy",
            price: "Price per item",
            quantity: "Quantity",
            off: " Off",
            from: "From ",
            min_quantity: "Minimum Qty",
            max_quantity: "Maximum Qty",
            range_seperator: " to ",
            percent_symbol: "%",
            currency_symbol: "$",
            range_so_on: " + ",
            qualifies_full_message: "This product qualifies for [[discount]] discount!",
            coupon_code_btn: 'Apply coupon',
            buy_now_button: "Buy it now",
            showToast: false,
            toastMsg: '',
            toastError: false
            
        };
    }

    showToastMessage(msg, error){
        this.setState({
          showToast: true,
          toastMsg: msg,
          toastError: error||false,
        });
    }
    
    dissmissToast = () =>{
        this.setState({
          showToast: false,
        });
    }
    static contextTypes = {
        easdk: PropTypes.object,
    };

    componentDidMount() {
        axios.get(this.props.host_name + '/get-settings/' + this.props.shop)
            .then(response => {
                if (response && response.data && response.data.length > 0) {
                    var trans = response.data[0].translations;
                    if (trans && trans[0]) {
                        trans = trans[0];
                        this.setState({
                            "variant_title": trans.variant_title,
                            "discount": trans.discount,
                            "buy": trans.buy,
                            "price": trans.price,
                            "quantity": trans.quantity,
                            "off": trans.off,
                            "from": trans.from,
                            "min_quantity": trans.min_quantity,
                            "max_quantity": trans.max_quantity,
                            "range_seperator": trans.range_seperator,
                            "percent_symbol": trans.percent_symbol,
                            "currency_symbol": trans.currency_symbol,
                            "range_so_on": trans.range_so_on,
                            "qualifies_full_message": trans.qualifies_full_message,
                            "coupon_code_btn": trans.coupon_code_btn,
                            "buy_now_button": trans.buy_now_button, 
                        });
                    }

                }
            })
            .catch(error => {
                // console.log(error);
            });
    }

    saveTranslations = () => {
        var {
            variant_title,discount,buy,price,quantity,off,from,min_quantity,max_quantity,range_seperator,percent_symbol,currency_symbol,range_so_on,qualifies_full_message,coupon_code_btn,buy_now_button
        } = this.state;

        var data = {
            "shop": this.props.shop,
            "translations": {
                "variant_title": variant_title,
                "discount": discount,
                "buy": buy,
                "price": price,
                "quantity": quantity,
                "off": off,
                "from": from,
                "min_quantity": min_quantity,
                "max_quantity": max_quantity,
                "range_seperator": range_seperator,
                "percent_symbol": percent_symbol,
                "currency_symbol": currency_symbol,
                "range_so_on": range_so_on,
                "qualifies_full_message": qualifies_full_message,
                "coupon_code_btn": coupon_code_btn,
                "buy_now_button": buy_now_button, 
            }
        };
        if(
            !variant_title ||
            !discount ||
            !buy ||
            !price ||
            !quantity ||
            !off ||
            !from ||
            !min_quantity ||
            !max_quantity ||
            !range_seperator ||
            !percent_symbol ||
            !currency_symbol ||
            !range_so_on ||
            !qualifies_full_message ||
            !coupon_code_btn ||
            !buy_now_button ||
            variant_title.trim() === "" ||
            discount.trim() === "" ||
            buy.trim() === "" ||
            price.trim() === "" ||
            quantity.trim() === "" ||
            off.trim() === "" ||
            from.trim() === "" ||
            min_quantity.trim() === "" ||
            max_quantity.trim() === "" ||
            range_seperator.trim() === "" ||
            percent_symbol.trim() === "" ||
            currency_symbol.trim() === "" ||
            range_so_on.trim() === "" ||
            qualifies_full_message.trim() === "" ||
            coupon_code_btn.trim() === "" ||
            buy_now_button.trim() === ""
        ){
            this.showToastMessage("All fields are required!", true);
            return false;
        }
        axios.put(this.props.host_name + '/update-translations', data)
            .then(response => {
                if (response.data.status === 1) {
                    this.showToastMessage(response.data.message);
                } else {
                    this.showToastMessage(response.data.message, true);
                }
            }).catch(error => {
                
            });
    }

    resetToDefault = () => {
        this.setState({
            variant_title: "Variant Name",
            discount: "Discount",
            buy: "Buy",
            price: "Price per item",
            quantity: "Quantity",
            off: " Off",
            from: "From ",
            min_quantity: "Minimum Qty",
            max_quantity: "Maximum Qty",
            range_seperator: " to ",
            percent_symbol: "%",
            currency_symbol: "$",
            range_so_on: " + ",
            qualifies_full_message: "This product qualifies for [[discount]] discount!",
            coupon_code_btn: 'Apply coupon',
            buy_now_button: "Buy it now",
        })
    }

    render() {
        const {
            variant_title,discount,buy,price,quantity,off,from,min_quantity,max_quantity,range_seperator,percent_symbol,currency_symbol,range_so_on,qualifies_full_message,coupon_code_btn,buy_now_button
        } = this.state;

        const translations_section = <div>
            <Stack vertical spacing="tight">
                <FormLayout>
                    <FormLayout.Group>
                        <TextField
                            label="Variant title text"
                            value={variant_title}
                            type="text"
                            onChange={v => { this.setState({ variant_title: v }) }}
                            error={variant_title === "" ? "This field can't be blank" : false}
                        />
                        <TextField
                            label="Discount text"
                            value={discount}
                            type="text"
                            onChange={v => { this.setState({ discount: v }) }}
                            error={discount === "" ? "This field can't be blank" : false}
                        />
                    </FormLayout.Group>

                    <FormLayout.Group>
                        <TextField
                            label="Buy text"
                            value={buy}
                            type="text"
                            onChange={v => { this.setState({ buy: v }) }}
                            error={buy === "" ? "This field can't be blank" : false}
                        />
                        <TextField
                            label="Price text"
                            value={price}
                            type="text"
                            onChange={v => { this.setState({ price: v }) }}
                            error={price === "" ? "This field can't be blank" : false}
                        />
                    </FormLayout.Group>

                    <FormLayout.Group>
                        <TextField
                            label="Quantity text"
                            value={quantity}
                            type="text"
                            onChange={v => { this.setState({ quantity: v }) }}
                            error={quantity === "" ? "This field can't be blank" : false}
                        />
                        <TextField
                            label="Off text"
                            value={off}
                            type="text"
                            onChange={v => { this.setState({ off: v }) }}
                            error={off === "" ? "This field can't be blank" : false}
                        />
                    </FormLayout.Group>

                    <FormLayout.Group>
                        <TextField
                            label="From text"
                            value={from}
                            type="text"
                            onChange={v => { this.setState({ from: v }) }}
                            error={from === "" ? "This field can't be blank" : false}
                        />
                        <TextField
                            label="Minimum quantity text"
                            value={min_quantity}
                            type="text"
                            onChange={v => { this.setState({ min_quantity: v }) }}
                            error={min_quantity === "" ? "This field can't be blank" : false}
                        />
                    </FormLayout.Group>

                    <FormLayout.Group>
                        <TextField
                            label="Maximum quantity text"
                            value={max_quantity}
                            type="text"
                            onChange={v => { this.setState({ max_quantity: v }) }}
                            error={max_quantity === "" ? "This field can't be blank" : false}
                        />
                        <TextField
                            label="Quantity range seperator"
                            value={range_seperator}
                            type="text"
                            onChange={v => { this.setState({ range_seperator: v }) }}
                            error={range_seperator === "" ? "This field can't be blank" : false}
                        />
                    </FormLayout.Group>

                    <FormLayout.Group>
                        <TextField
                            label="Percent symbol"
                            value={percent_symbol}
                            type="text"
                            onChange={v => { this.setState({ percent_symbol: v }) }}
                            error={percent_symbol === "" ? "This field can't be blank" : false}
                        />
                        <TextField
                            label="Currency symbol"
                            value={currency_symbol}
                            type="text"
                            onChange={v => { this.setState({ currency_symbol: v }) }}
                            error={currency_symbol === "" ? "This field can't be blank" : false}
                        />
                    </FormLayout.Group>

                    <FormLayout.Group>
                        <TextField
                            label="Quantity range so on (to infinity) text"
                            value={range_so_on}
                            type="text"
                            onChange={v => { this.setState({ range_so_on: v }) }}
                            error={range_so_on === "" ? "This field can't be blank" : false}
                        />
                        <TextField
                            label="Product template discount message"
                            value={qualifies_full_message}
                            type="text"
                            onChange={v => { this.setState({ qualifies_full_message: v }) }}
                            error={qualifies_full_message === "" ? "This field can't be blank" : false}
                        />
                    </FormLayout.Group>
                    <FormLayout.Group>
                        <TextField
                            label="Coupon code button title"
                            value={coupon_code_btn}
                            onChange={v => { this.setState({ coupon_code_btn: v }) }}
                            error={coupon_code_btn === "" ? "This field can't be blank" : false}
                        />
                        <TextField
                            label="Buy it now button text"
                            value={buy_now_button}
                            onChange={v => { this.setState({ buy_now_button: v }) }}
                            error={buy_now_button === "" ? "This field can't be blank" : false}
                        />
                    </FormLayout.Group>

                </FormLayout>
            </Stack>
        </div>;

        const page_actions = <PageActions
            secondaryActions={[
                {
                    content: 'Reset to default',
                    onAction: () => {this.resetToDefault()}
                }
            ]}
            primaryAction={{
                content: 'Save translations',
                onAction: () => {this.saveTranslations()}
            }}
        ></PageActions>;

        var toast = this.state.showToast? (
            <ToastMessage
              onDismiss={this.dissmissToast}
              message={this.state.toastMsg}
              error={this.state.toastError}
            />
          ): (null);

  
        return (
            <div>
                {translations_section}
                {page_actions}
                {toast}
            </div>
        );
    }
}
