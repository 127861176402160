import React, { Component } from 'react';
import axios from 'axios';
import Install from './Install';
import {  Banner, Spinner, TextStyle } from '@shopify/polaris';
import CommonHelper from '../Helpers/Common';
import {Context} from '@shopify/app-bridge-react';
import { Redirect } from "@shopify/app-bridge/actions";

var shop = CommonHelper.getShop();
var host_name = CommonHelper.getHostName();

export default class Home extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      host_name: shop,
      shop: host_name,
      server_error: false
    };
  }
  componentDidMount() {
    if (shop) {
      // Redirect to oAuth

      const app = this.context;
      const redirect = Redirect.create(app);
      const host = CommonHelper.getHost();
      const redirect_uri = host_name + '/shopapp/2.0?shop=' + shop + '&host=' + host + '&embedded=1';

      // after 5 sec, if redirect still not happen, try to use native redirect instead of app bridge
      setTimeout(() => {
        window.top.location.href = redirect_uri;
      }, 5000);
      redirect.dispatch(
        Redirect.Action.REMOTE,
        redirect_uri
      );
    }
  }

  sendLogs(data){
    axios.post(host_name + '/logs?shop=' + shop, {data: data})
        .then(response => {
          // if (response.data.redirect_uri && response.data.shop === shop) {
          //   window.top.location.href = response.data.redirect_uri;
          // }
        })
        .catch(error => {
          // this.setState({server_error:true, serverErrorMessage: error});
        });
  }
  render() {
    if(this.state.server_error){
      return (
        <div>
          <Banner status="critical" title="Server is down for some reasons">
            <p>We expect to be back in a couple hours.</p>
          </Banner>
          <pre>
            {this.state.serverErrorMessage.toString()}
          </pre>
        </div>
      );
    }
    else if (!shop) {
      return (
        <div>
          <Install shop={shop} host_name={host_name} shop_id={null} help={this.props.help} ></Install>
        </div>
      )
    }
    else {
      return (
        <div>
          <div className="spinner-container" style={{textAlign:"center", height:"50vh", padding: "2rem 0rem"}}>
            <span style={{marginBottom: "2rem"}}><Spinner color="teal" size="large" /></span>
            <h1>Logging you into <TextStyle variation="strong">Wholesale All In One</TextStyle>. Please wait...</h1>
          </div>
        </div>
      );
    }
  }
}