import React, { Component } from 'react';
import axios from 'axios';
import { Card, Stack, FormLayout, TextField, Checkbox, Tag, Select, Button, RadioButton, ButtonGroup, Badge, Link, Icon, Banner, TextStyle } from '@shopify/polaris';
import store from 'store2';
import _Modules from '../../../modules.json';
import waio from '../../../Helpers/functions';
import * as PropTypes from 'prop-types';
import ToastMessage from '../../../Common/ToastMessage';
import PageTitleBar from '../../../Common/TitleBar';
import SkeletonPageComp from '../../SkeletonPageComp';
import { DeleteMinor } from '@shopify/polaris-icons';
import { Modal } from '@shopify/app-bridge-react';

var snippet_version = store('snippet_version') || "2.1.0";
var store_currency = store('store_currency') || null;
export default class CreateManageShipping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
            shop: this.props.shop,
            edit_rule_id: this.props.match.params.id,
            rules: [{ "column": "cart_original_total_price", "relation": "greater_than_or_equal_to", "condition": "" }],
            rules_operator: "and",
            message: "",
            title: "",
            status: "active",
            customer_group: "all",
            except_logged_in: false,
            except_tags: false,
            excepted_tags: [],
            temp_tag_value: "",
            customer_tags: [],
            temp_customer_tags_value: "",
            saveButtonLoading: false,
            allShippingRules: [],
            page_loaded: false,
            free_shipping: false,
            charges: 0,
            applies_to: 'entire_store',
            products: [],
            collections: [],
            variants: [],
            product_modal_open: false,
            update_collections__modal_open: false,
            update_products__modal_open: false,
            charges_type: "free",
            range_based_on: "cart_original_total_price",
            range: [{ "min": "", "max": "", "charges": "" }],
            ignore_rules: false,
            settings: [],
            showToast: false,
            toastMsg: '',
            toastError: false,
            selectCountryModalOpen: false,
            country_selection: "all",
            selected_countries: []

        };
        store.remove('products');
        store.remove('collections');
        store.remove("shipping_selected_countries");
    }

    disableSave = false;

    static contextTypes = {
        easdk: PropTypes.object,
    };

    showToastMessage(msg, error){
        this.setState({
          showToast: true,
          toastMsg: msg,
          toastError: error||false,
        });
    }
    
    dissmissToast = () =>{
        this.setState({
          showToast: false,
        });
    }
    
    column_cart_options = [
        { label: "Cart total amount (subtotal)", value: "cart_original_total_price", disabled: false },
        { label: "Cart total items (quantity)", value: "cart_item_count", disabled: false },
        { label: `Cart total weight (grams)`, value: "cart_total_weight", disabled: false }
    ];
    
    relation_options = [
        { label: "is greater than or equal to", value: "greater_than_or_equal_to", disabled: false },
        { label: "is less than or equal to", value: "less_than_or_equal_to", disabled: false },
        { label: "is equal to", value: "equal", disabled: false },
        { label: "is not equal to", value: "not_equal", disabled: false }
    ];

    needToUpgrade = false;

    componentDidMount(){
        this.getAllShippings();
        if(this.state.show === "edit"){
            this.getARuleForEdit();
        }
        
        if (snippet_version && waio.compareVersionNumbers(snippet_version, _Modules.find(x => x._id === 209).snippet_version) === -1) {
            this.needToUpgrade = true;
        }

        if(this.props.shop){
            this.getSettings();
        }
    }

    getSettings(){
        axios.get(this.props.host_name + '/app/settings/'+this.props.shop )
            .then(r => {
                if (r && r.data) {
                    this.setState({settings:r.data});
                }
                else {
                    this.showToastMessage("Server error", true);
                }
            })
            .catch(error => {
                this.showToastMessage("Server error", true);
            });
    }

    getARuleForEdit(){
        axios.get(this.props.host_name + '/app/get-shipping/'+this.state.edit_rule_id)
        .then(r => {
            if (r && r.data) {
                var {
                    rules,
                    rules_operator,
                    message,
                    title,
                    status,
                    customer_group,
                    except_logged_in,
                    except_tags,
                    excepted_tags,
                    customer_tags,
                    free_shipping,
                    charges,
                    applies_to,
                    products,
                    collections,
                    variants,
                    charges_type,
                    range,
                    range_based_on,
                    ignore_rules
                } = r.data;
                var selected_countries = this.state.selected_countries;
                var country_selection = this.state.country_selection;
                if(r.data && r.data.selected_countries){
                    selected_countries = r.data.selected_countries;
                }
                if(r.data && r.data.country_selection){
                    country_selection = r.data.country_selection;
                }
                this.setState({
                    selected_countries,
                    country_selection,
                    rules,
                    rules_operator,
                    message,
                    title,
                    status,
                    customer_group,
                    except_logged_in,
                    except_tags,
                    excepted_tags,
                    customer_tags,
                    free_shipping,
                    charges,
                    applies_to,
                    products,
                    collections,
                    variants,
                    charges_type,
                    range,
                    range_based_on,
                    ignore_rules
                });
            }
            else {
                this.showToastMessage("Server error", true);
            }
        })
        .catch(error => {
            this.showToastMessage("Server error", true);
        });
    }

    getAllShippings(){
        axios.get(this.props.host_name + '/app/all-shippings/'+this.props.shop )
        .then(r => {
            if (r && r.data) {
                this.setState({allShippingRules:r.data,page_loaded:true});
            }
            else {
                this.showToastMessage("Server error", true);
            }
            this.setState({page_loaded:true});
        })
        .catch(error => {
            this.showToastMessage("Server error", true);
            this.setState({page_loaded:true});
        });
    }

    addCondition() {
        var rules = this.state.rules;
        rules.push(
            { "column": "cart_original_total_price", "relation": "greater_than_or_equal_to", "condition": "" }
        );
        this.setState({rules});
    }

    removeCondition(i) {
        var rules = this.state.rules;
        rules.splice(i, 1);
        this.setState({ rules });
    }

    changeConditionValue(value, id, i) {
        var rules = this.state.rules;
        rules[i] = { ...rules[i], [id]: value };
        this.setState({ rules });
    }

    renderExceptedTags(){
        var excepted_tags = this.state.excepted_tags;
        return excepted_tags.map((tag,i) => {
            return <span key={i}>
                <Tag
                    onRemove={this.removeTag.bind(this,tag,i)}
                >{tag}</Tag>
            </span>
        });
    }

    removeTag(tag,i,el){
        var excepted_tags = this.state.excepted_tags;
        excepted_tags.splice([i],1);
        this.setState({ excepted_tags });
    }

    saveRule(){
        if(this.validation() === true){
            this.setState({saveButtonLoading: true});
            const data = {
                shop: this.props.shop,
                rules: this.state.rules,
                rules_operator: this.state.rules_operator,
                message: this.state.message,
                title: this.state.title,
                charges: this.state.free_shipping?"0.00":this.state.charges,
                free_shipping: this.state.free_shipping,
                status: this.state.status,
                customer_group: this.state.customer_group,
                customer_tags: this.state.customer_group === 'tag_based'? this.state.customer_tags:[],
                except_logged_in: this.state.except_logged_in,
                except_tags: this.state.except_tags,
                excepted_tags: this.state.excepted_tags,
                products: this.state.products,
                collections: this.state.collections,
                variants: [],
                applies_to: this.state.applies_to,
                charges_type: this.state.charges_type,
                range: this.state.range,
                range_based_on: this.state.range_based_on,
                ignore_rules: this.state.ignore_rules,
                country_selection: this.state.country_selection,
                selected_countries: this.state.selected_countries
            };
            if(this.state.show === "edit"){
                // /update-order-control
                axios.put(this.props.host_name + '/app/update-shipping/'+this.state.edit_rule_id, data)
                    .then(r => {
                        if (r && r.data && r.data.message) {
                            if(r.data.status === 1){
                                this.showToastMessage(r.data.message);
                                this.props.history.push('/manage-shipping');
                                this.getAllShippings();
                                this.setState({show:"list"});
                            }
                            else{
                                this.showToastMessage(r.data.message, true);
                            }
                        }
                        else {
                            this.showToastMessage("Server error", true);
                        }
                        this.setState({saveButtonLoading: false})
                    })
                    .catch(error => {
                        this.showToastMessage("Server error", true);
                        this.setState({saveButtonLoading: false});
                    });
            }
            else{
                axios.post(this.props.host_name + '/app/add-shipping', data)
                    .then(r => {
                        if (r && r.data && r.data.message) {
                            if(r.data.status === 1){
                                this.showToastMessage(r.data.message);
                                this.props.history.push('/manage-shipping');
                                this.getAllShippings();
                                this.setState({show:"list"});
                            }
                            else{
                                this.showToastMessage(r.data.message, true);
                            }
                        }
                        else {
                            this.showToastMessage("Server error", true);
                        }
                        this.setState({saveButtonLoading: false})
                    })
                    .catch(error => {
                        this.showToastMessage("Server error", true);
                        this.setState({saveButtonLoading: false});
                    });
            }
        }
    }

    disableSaveButton(){
        if(this.props.user_current_plan){
            var plan_id = this.props.user_current_plan?this.props.user_current_plan.plan_id:null;
            var valid = false;
            if(!plan_id){
                return !this.validation();
            }
            else{
                valid = plan_id.indexOf("basic") > -1;
                if(valid){
                    this.disableSave = true;
                    return true;
                }
                else{
                    return !this.validation();
                }
            }
        }else{
            return !this.validation();
        }
    }

    validation(){
        var valid = true;
        var {excepted_tags,except_tags,rules,customer_group,customer_tags,charges,title,applies_to,products,collections,variants,range,charges_type,ignore_rules} = this.state;
        if(except_tags && excepted_tags.length < 1) valid = false;
        if(customer_group === "tag_based" && customer_tags.length < 1) valid = false;
        if(!ignore_rules){
            for(var rule of rules){
                if(!rule.condition){ valid = false; break; }
                else if(rule.condition.trim() === "") { valid = false; break; }
            }
        }
        if(!title || title.trim() === ""){valid = false;}
        if(applies_to === "products" && products.length < 1){valid=false;}
        if(applies_to === "collections" && collections.length < 1){valid=false;}
        if(applies_to === "variants" && variants.length < 1){valid=false;}
        if(charges_type === "price_range"){
            for(var a of range){
                if(!a.min || a.min === null || Number(a.min) < 0){valid=false;break;}
                if(!a.max || a.max === null || Number(a.max) < 0){valid=false;break;}
                if(!a.charges || a.charges === null || Number(a.charges) < 0){valid=false;break;}
                if(Number(a.min) > Number(a.max)){valid=false;break;}
            }
        }
        else if(charges_type === "fixed_price"){
            if(!charges || Number(charges) < 0){valid = false;}
        }
        if(this.state.country_selection === "selected"){
            try {
                if(this.state.selected_countries.length < 1){
                    valid = false;
                }
            } catch (e) { }
        }
        return valid;
    }

    renderCustomerTags(){
        var customer_tags = this.state.customer_tags;
        return customer_tags.map((tag,i) => {
            return <span key={i}>
                <Tag
                    onRemove={this.removeCustomerTag.bind(this,tag,i)}
                >{tag}</Tag>
            </span>
        });
    }

    removeCustomerTag(tag,i,el){
        var customer_tags = this.state.customer_tags;
        customer_tags.splice([i],1);
        this.setState({ customer_tags });
    }

    deleteRule(item){
        if(window.confirm(`Delete ${item.title}?`)){
            axios.delete(this.props.host_name + '/app/delete-shipping/'+item._id+'/'+ this.props.shop)
            .then(r => {
                if (r && r.data && r.data.message) {
                    if(r.data.status === 1){
                        this.showToastMessage(r.data.message);
                        this.getAllShippings();
                    }
                    else{
                        this.showToastMessage(r.data.message, true);
                    }
                }
                else {
                    this.showToastMessage("Server error", true);
                }
            })
            .catch(error => {
                this.showToastMessage("Server error", true);
            });
        }
    }

    showTag(item){
        return item.customer_tags.map((el,i)=>{
            return i < 1 ? <span key={i}><Badge>{el}</Badge></span>:null
        });
    }

    handleSubmitUpdateProducts = (selected) => {
        let products = store('products') || [];
        this.setState({
            products,
            update_products__modal_open: false
        })
    }
    
    handleSubmitUpdateCollections = (selected) => {
        let collections = store('collections') || [];
        this.setState({
            collections,
            update_collections__modal_open: false
        })
    }

    disableRule(item){
        if(window.confirm(`Disable ${item.title}?`)){
            var data = {
                _id: item._id,
                status: "disabled"
            };
            axios.put(this.props.host_name + '/app/update-status-shipping/'+item._id+'/', data)
            .then(r => {
                if (r && r.data && r.data.message) {
                    if(r.data.status === 1){
                        this.showToastMessage(r.data.message);
                        this.getAllShippings();
                    }
                    else{
                        this.showToastMessage(r.data.message, true);
                    }
                }
                else {
                    this.showToastMessage("Server error", true);
                }
            })
            .catch(error => {
                this.showToastMessage("Server error", true);
            });
        }
    }

    enableRule(item){
        var data = {
            _id: item._id,
            status: "active"
        };
        axios.put(this.props.host_name + '/app/update-status-shipping/'+item._id+'/', data)
        .then(r => {
            if (r && r.data && r.data.message) {
                if(r.data.status === 1){
                    this.showToastMessage(r.data.message);
                    this.getAllShippings();
                }
                else{
                    this.showToastMessage("Server error", true);
                }
            }
            else {
                this.showToastMessage("Server error", true);
            }
        })
        .catch(error => {
            this.showToastMessage("Server error", true);
        });
    }

    removeShippingRange(i){
        var range = this.state.range;
        range.splice(i, 1);
        this.setState({ range });
    }

    addShippingRange() {
        var range = this.state.range;
        range.push(
            { "min": "", "max": "", "charges": "" }
        );
        this.setState({range});
    }

    changeShippingRangeValue(value, id, i) {
        var range = this.state.range;
        range[i] = { ...range[i], [id]: value };
        this.setState({ range });
    }

    shipping_range_validation(){
        var range = this.state.range;
        var valid = true;
        for(var a of range){
            if(!a.min || a.min === null || Number(a.min) < 0){valid=false;break;}
            if(!a.max || a.max === null || Number(a.max) < 0){valid=false;break;}
            if(!a.charges || a.charges === null || Number(a.charges) < 0){valid=false;break;}
            if(Number(a.min) > Number(a.max)){valid=false;break;}
        }
        return valid;
    }

    render() {
        const {
            rules,rules_operator,message,title,customer_group,except_logged_in,except_tags,excepted_tags,temp_tag_value, temp_customer_tags_value,free_shipping,charges,charges_type,range,range_based_on,ignore_rules
        } = this.state;

        var UpgradeBanner = null;
        if(this.needToUpgrade){
            UpgradeBanner = <div style={{margin:"1rem 0rem"}}>
                <Banner status="warning" title="Upgrade your script!">
                Order Limit feature requires script upgrade. Please email us at <TextStyle variation="strong">support@digitalcoo.com</TextStyle> We will update the relevant script.
                </Banner>
            </div>;
        }

        const customerTagsInputField = <TextField
            label="Customer tags"
            id="customer-tag-input"
            labelHidden
            value={temp_customer_tags_value}
            placeholder="Enter tags"
            onChange={(v)=>{
                if(v && v.indexOf(",") >-1 ){
                    v= v.replace(",","");
                    v = v.trim();
                    if(v === "") return false;
                    var customer_tags = this.state.customer_tags;
                    if(customer_tags.indexOf(v) > -1){
                        this.showToastMessage("Tag already exists", true);
                        return false;
                    }
                    customer_tags.push(v);
                    this.setState({ customer_tags, temp_customer_tags_value: "" });
                }
                else{
                    this.setState({temp_customer_tags_value:v});
                }
            }}
            onBlur={()=>{
                var v = this.state.temp_customer_tags_value;
                v = v.replace(",","");
                v = v.trim();
                if(v === "") return false;
                var customer_tags = this.state.customer_tags;
                if(customer_tags.indexOf(v) > -1){
                    return false;
                }
                customer_tags.push(v);
                this.setState({ customer_tags, temp_customer_tags_value: "" });
            }}
            connectedRight={
                <Button
                    primary
                    onClick={()=>{
                        var v = this.state.temp_customer_tags_value;
                        if(v && v.indexOf(",") >-1 ){
                            v= v.replace(",","");
                            v = v.trim();
                            if(v === "") return false;
                            var customer_tags = this.state.customer_tags;
                            if(customer_tags.indexOf(v) > -1){
                                this.showToastMessage("Tag already exists", true);
                                return false;
                            }
                            customer_tags.push(v);
                            this.setState({ customer_tags, temp_customer_tags_value: "" });
                        }
                        try {
                            document.getElementById("customer-tag-input").focus();
                        } catch (error) {
                            
                        }
                    }}
                >Add</Button>
            }
        />;

        const tags_input = <TextField
            label="Tags"
            labelHidden
            placeholder="Enter comma separated tags"
            id="exc-customer-tag-input"
            value={temp_tag_value}
            onChange={(v)=>{
                if(v && v.indexOf(",") >-1 ){
                    v= v.replace(",","");
                    v = v.trim();
                    if(v === "") return false;
                    var excepted_tags = this.state.excepted_tags;
                    if(excepted_tags.indexOf(v) > -1){
                        this.showToastMessage("Tag already exists", true);
                        return false;
                    }
                    excepted_tags.push(v);
                    this.setState({ excepted_tags, temp_tag_value: "" });
                }
                else{
                    this.setState({temp_tag_value:v});
                }
            }}
            onBlur={()=>{
                var v = this.state.temp_tag_value;
                v = v.replace(",","");
                v = v.trim();
                if(v === "") return false;
                var excepted_tags = this.state.excepted_tags;
                if(excepted_tags.indexOf(v) > -1){
                    return false;
                }
                excepted_tags.push(v);
                this.setState({ excepted_tags, temp_tag_value: "" });
            }}
            connectedRight={
                <Button
                    primary
                    onClick={()=>{
                        var v = this.state.temp_tag_value;
                        if(v && v.indexOf(",") >-1 ){
                            v= v.replace(",","");
                            v = v.trim();
                            if(v === "") return false;
                            var excepted_tags = this.state.excepted_tags;
                            if(excepted_tags.indexOf(v) > -1){
                                this.showToastMessage("Tag already exists", true);
                                return false;
                            }
                            excepted_tags.push(v);
                            this.setState({ excepted_tags, temp_tag_value: "" });
                        }
                        try {
                            document.getElementById("exc-customer-tag-input").focus();
                        } catch (error) {
                            
                        }
                    }}
                >Add</Button>
            }
        ></TextField>;

        const except_logged_in_section = <>
            <Checkbox
                label="Except logged in"
                id="except_logged_in"
                value={except_logged_in}
                checked={except_logged_in}
                onChange={(checked, value) => {
                    this.setState({ except_logged_in: checked, except_tags: false });
                }}
            />
        </>;

        const except_taged_section = <Stack vertical spacing="extraTight">
            <Checkbox
                disabled={except_logged_in}
                label="Except tags"
                id="except_tags"
                value={except_tags}
                checked={except_tags}
                onChange={(checked, value) => {
                    this.setState({ except_tags: checked });
                }}
            />
            {
                ( except_logged_in === false && except_tags ) &&
                <Stack>
                    <Stack.Item>{tags_input}</Stack.Item>
                    <Stack.Item fill><div></div></Stack.Item>
                </Stack>
            }
            {
                (except_logged_in === false && except_tags && excepted_tags.length > 0 ) &&
                <Stack spacing="tight">
                    {this.renderExceptedTags()}
                </Stack>
            }
        </Stack>;

        const customers_section = <>
            <Stack vertical spacing="extraTight">
                <RadioButton
                    label="All customers"
                    id="all"
                    name="customers_group"
                    checked={customer_group === "all"}
                    onChange={(checked, value) => {
                        this.setState({ customer_group: value, except_tags: false, except_logged_in: false  });
                    }}
                />
                {
                    customer_group === "all" &&
                    <div style={{marginLeft:"2rem"}}>
                        {except_logged_in_section}
                        {except_taged_section}
                    </div>
                }
                <RadioButton
                    label="Only logged in customers"
                    id="logged_in"
                    name="customers_group"
                    checked={customer_group === "logged_in"}
                    onChange={(checked, value) => {
                        this.setState({ customer_group: value,except_tags: false, except_logged_in: false });
                    }}
                />
                {
                    customer_group === "logged_in" &&
                    <div style={{marginLeft:"2rem"}}>
                        {except_taged_section}
                    </div>
                }
                <RadioButton
                    label={<span>Only tag based logged in customers </span>}
                    id="tag_based"
                    name="customers_group"
                    checked={customer_group === "tag_based"}
                    onChange={(checked, value) => {
                        this.setState({ customer_group: value, except_tags: false, except_logged_in: false  });
                    }}
                />
                {
                    customer_group === "tag_based" && 
                    <div style={{marginLeft: "2rem"}}>
                        <Stack>
                            <Stack.Item>
                                {customerTagsInputField}
                            </Stack.Item>
                            <Stack.Item fill><div></div></Stack.Item>
                        </Stack>
                    </div>
                }
                {
                    customer_group === "tag_based" &&
                    <div style={{marginLeft: "2rem"}}>
                        <Stack spacing="tight">
                            {this.renderCustomerTags()}
                        </Stack>
                    </div>
                }
            </Stack>
        </>;

        const shipp_charges_type = <div>
            <Stack>
                <div className={"shipp-charges-type " + (this.state.charges_type=== "free" ? "selected_price":'')} onClick={()=>{this.setState({charges_type:"free", ignore_rules: false})}}>
                    <p>Free Shipping</p>
                </div>
                <div className={"shipp-charges-type " + (this.state.charges_type=== "fixed_price" ? "selected_price":'')} onClick={()=>{this.setState({charges_type:"fixed_price", ignore_rules: false})}}>
                    <p>Fixed Price</p>
                </div>
                <div className={"shipp-charges-type " + (this.state.charges_type=== "price_range" ? "selected_price":'')} onClick={()=>{this.setState({charges_type:"price_range", ignore_rules: true})}}>
                    <p>Price Range</p>
                </div>
            </Stack>
        </div>;

        const conditions_section = rules.map((el, i) => {
            return <FormLayout.Group condensed key={i} fullWidth>
                <Select
                    label="Column"
                    labelHidden={true}
                    id="column"
                    name="column"
                    value={el.column}
                    options={this.column_cart_options}
                    onChange={(selected, id) => { this.changeConditionValue(selected, id, i) }}
                ></Select>
                <Select
                    label="Relation"
                    labelHidden={true}
                    id="relation"
                    name="relation"
                    value={el.relation}
                    options={this.relation_options}
                    onChange={(selected, id) => { this.changeConditionValue(selected, id, i) }}
                ></Select>
                <TextField
                    prefix={
                        el.column === "cart_original_total_price"?(store_currency)
                        :el.column === "cart_item_count"?("Qty")
                        :(null)
                    }
                    suffix={
                        el.column === "cart_total_weight"?("Grams"):(null)
                    }
                    type="number"
                    label="Condition"
                    labelHidden={true}
                    id="condition"
                    name="condition"
                    value={el.condition || ""}
                    onChange={(selected, id) => { this.changeConditionValue(selected, id, i) }}
                ></TextField>
                <Button
                    onClick={this.removeCondition.bind(this, i)}
                    disabled={rules.length === 1}
                ><Icon source={DeleteMinor}/></Button>
            </FormLayout.Group>;
        });

        const price_range_ui = range.map((el,i) => {
            return <FormLayout.Group condensed key={i} fullWidth>
                <TextField
                    placeholder={
                        range_based_on==="cart_original_total_price" ?
                        "Min amount":range_based_on==="cart_item_count" ?
                        "Min quantity":"Min weight"
                    }
                    value={getValue(el.min)}
                    id="min"
                    name="min"
                    type="number"
                    min="0"
                    onChange={(selected, id) => { this.changeShippingRangeValue(selected, id, i) }}
                />
                <TextField
                    placeholder={
                        range_based_on==="cart_original_total_price" ?
                        "Max amount":range_based_on==="cart_item_count" ?
                        "Max quantity":"Max weight"
                    }
                    value={getValue(el.max)}
                    id="max"
                    name="max"
                    type="number"
                    min="0"
                    onChange={(selected, id) => { this.changeShippingRangeValue(selected, id, i) }}
                    error={ (el.max && Number(el.min) > Number(el.max)) ? "Max value cannot less than min value":false }
                />
                <TextField
                    placeholder="Shipping charges"
                    value={getValue(el.charges)}
                    id="charges"
                    name="charges"
                    type="number"
                    min="0"
                    suffix={store_currency?store_currency:null}
                    onChange={(selected, id) => { this.changeShippingRangeValue(selected, id, i) }}
                />
                {
                    range.length > 1 ? 
                    <Button
                        onClick={this.removeShippingRange.bind(this, i)}
                        disabled={range.length === 1}
                    ><Icon source={DeleteMinor}/></Button>
                    : <div></div>
                }
            </FormLayout.Group>;
        });
        
        const add_or_edit_section = <>
            <Card title="Manage Shipping" actions={[{content:"Read tutorial", url:"https://digitalcoo.com/wholesale-all-in-one/", external: true}]}>
                <Card.Section>
                    <FormLayout fullWidth>
                        <FormLayout.Group>
                            <TextField
                                label={<span>Shipping title <span className="required">*</span></span>}
                                value={title || ""}
                                onChange={(value) => {
                                    this.setState({ title: value });
                                }}
                                helpText="Title of shipping line. e.g Free shipping"
                                maxLength={80}
                                showCharacterCount={true}
                                clearButton={true}
                                onClearButtonClick={() => { 
                                    this.setState({ title: "" });
                                }}
                            />
                            <TextField
                                label="Shipping message (optional)"
                                value={message}
                                onChange={(v) => {
                                    this.setState({ message: v });
                                }}
                                maxLength={160}
                                showCharacterCount={true}
                                clearButton={true}
                                onClearButtonClick={() => { 
                                    this.setState({ message: "" });
                                }}
                                helpText="This will display at cart page next to cart subtotal price"
                            />
                        </FormLayout.Group>
                    </FormLayout>
                </Card.Section>
            </Card>
            <Card title="Customer Selection">
                <Card.Section>
                    <div className="left_right_grid">
                        <div className="eligibility_customers">
                            <div className="left_ce">
                                {customers_section}
                            </div>
                            {
                                (customer_group === 'logged_in' || customer_group === 'tag_based') &&
                                <div className="right_side_bar">
                                    <Stack vertical spacing="extraTight">
                                        <RadioButton
                                            label="Apply rule for all countries"
                                            checked={this.state.country_selection === 'all'}
                                            id="all-countries"
                                            name="country-selection"
                                            onChange={(id,value)=>{this.setState({country_selection:"all"})}}
                                        />
                                        <RadioButton
                                            label="Apply rule for specific countries"
                                            checked={this.state.country_selection === 'selected'}
                                            id="selected-countries"
                                            name="country-selection"
                                            onChange={(id,value)=>{this.setState({country_selection:"selected"})}}
                                        />
                                        {
                                            this.state.country_selection === "selected" &&
                                            <div className="select_country_checkbox">
                                                <Checkbox
                                                    label={<div><span>Select Specific Countries </span> <span className="number_">{this.state.selected_countries.length}</span></div>}
                                                    checked={this.state.selectCountryModalOpen}
                                                    onChange={()=>{
                                                        if(!this.state.selectCountryModalOpen){
                                                            store("shipping_selected_countries", JSON.stringify(this.state.selected_countries));
                                                        }
                                                        this.setState({selectCountryModalOpen: !this.state.selectCountryModalOpen});
                                                    }}
                                                    error={this.state.selected_countries.length===0 ? "Select at least one country":''}
                                                />
                                            </div>
                                        }
                                    </Stack>
                                </div>
                            }
                        </div>
                    </div>
                </Card.Section>
            </Card>
            <Card title="Custom shipping charges">
                <Card.Section>
                    {shipp_charges_type}
                </Card.Section>
                {
                    this.state.charges_type !== "price_range" && 
                    <Card.Section title="Minimum cart requirements">
                    <FormLayout fullWidth>
                        <FormLayout.Group>
                            <Checkbox
                                label="Ignore minimum cart requirements"
                                checked={ignore_rules}
                                onChange={(v)=>{this.setState({ignore_rules:v})}}
                            />
                        </FormLayout.Group>
                        {
                            !ignore_rules? 
                                <FormLayout.Group>
                                    <div>
                                        Must match:&nbsp;&nbsp;&nbsp;
                                        <RadioButton
                                            name="condition_operator"
                                            checked={rules_operator === "and"}
                                            id="and"
                                            label="All conditions"
                                            onChange={(checked, value) => {
                                                this.setState({ rules_operator: value });
                                            }}
                                        />
                                        &nbsp;&nbsp;&nbsp;
                                        <RadioButton
                                            name="condition_operator"
                                            checked={rules_operator === "or"}
                                            id="or"
                                            label="Any condition"
                                            onChange={(checked, value) => {
                                                this.setState({ rules_operator: value });
                                            }}
                                        />
                                    </div>
                                </FormLayout.Group>
                            :null
                        }
                        {
                            !ignore_rules?
                                conditions_section
                            :null
                        }
                        {
                            !ignore_rules?
                                <FormLayout.Group fullWidth>
                                    <Button
                                        disabled={rules.length >= 3 ? true : false}
                                        onClick={this.addCondition.bind(this)}
                                    >
                                        Add another condition
                                    </Button>
                                </FormLayout.Group>
                            :null
                        }
                    </FormLayout>
                </Card.Section>
                }
                {
                    this.state.charges_type === "fixed_price" &&
                    <Card.Section>
                        <FormLayout>
                            <FormLayout.Group>
                                <TextField
                                    label={<span>Shipping charges <span className="required">*</span></span>}
                                    type="number"
                                    suffix={store_currency?store_currency:null}
                                    value={getValue(charges)}
                                    onChange={(v) => {
                                        this.setState({ charges: v });
                                    }}
                                    onBlur={()=>{
                                        var rate = charges;
                                        if(Number(rate < 0)){
                                            this.setState({charges:0});
                                        }
                                    }}
                                    disabled={free_shipping}
                                />
                                <div></div>
                            </FormLayout.Group>
                        </FormLayout>
                    </Card.Section>
                }
                {
                    charges_type === "price_range" &&
                    <>
                        <Card.Section title="Price Range Based On">
                            <FormLayout>
                                <Stack vertical spacing="extraTight">
                                <RadioButton
                                    name="range_based_on"
                                    label="Cart Total Amount (Subtotal)"
                                    id="cart_original_total_price"
                                    checked={range_based_on === "cart_original_total_price"}
                                    onChange={(v,id) => {
                                        this.setState({range_based_on: id});
                                    }}
                                />
                                <RadioButton
                                    name="range_based_on"
                                    label="Cart Total Items (Quantity)"
                                    id="cart_item_count"
                                    checked={range_based_on === "cart_item_count"}
                                    onChange={(v,id) => {
                                        this.setState({range_based_on: id});
                                    }}
                                />
                                <RadioButton
                                    name="range_based_on"
                                    label="Cart Total Weight (Grams)"
                                    id="cart_total_weight"
                                    checked={range_based_on === "cart_total_weight"}
                                    onChange={(v,id) => {
                                        this.setState({range_based_on: id});
                                    }}
                                />
                                </Stack>
                            </FormLayout>
                        </Card.Section>
                        <Card.Section title="Price Range Criteria">
                            <FormLayout>
                                {price_range_ui}
                                <FormLayout.Group>
                                    <Button onClick={()=>{ this.addShippingRange() }}>Add more</Button>
                                </FormLayout.Group>
                            </FormLayout>
                        </Card.Section>
                    </>
                }
            </Card>
            <div className="bottom-line"></div>
            <div className="bottom-save-buttons">
                {
                    this.props.is_basic_plan && <img style={{color:"#ffffff"}} width="36px" height="30px" src='/icons/lock.svg' alt="lock-icon" />
                }
                <ButtonGroup>
                    <Button
                        url="/shipping"
                        target="APP"
                    >Cancel</Button>
                    <Button primary
                        onClick = { () => this.saveRule() }
                        disabled = { this.props.is_basic_plan?true:this.needToUpgrade?true:this.disableSaveButton()}
                        loading = { this.state.saveButtonLoading }
                    >Save Shipping</Button>
                </ButtonGroup>
            </div>
            <div className="clearfix"></div>
        </>;

        var shippingNotWorkingBanner = null;
        if(this.state.settings){
            if(this.state.settings.discount_method && this.state.settings.discount_method === "coupon_code"){
                shippingNotWorkingBanner = <div style={{marginBottom: "2rem"}}><Banner status="critical">
                    Shipping doesn't work with Coupon Code discount method. <Link url="/settings" target="APP">Change Discount Method</Link> Or Contact us
                </Banner></div>;
            }
        }

        const modal = <>
        <Modal
            size="Large"
            open={this.state.selectCountryModalOpen}
            title="Select Countries"
            src="/select-shipping-countries"
            primaryAction={
                {
                  content: "Select",
                  onAction: () => {
                      var shipping_selected_countries = store("shipping_selected_countries") || [];
                      try {
                          if(shipping_selected_countries){
                            shipping_selected_countries = JSON.parse(shipping_selected_countries);
                          }
                      } catch (error) {
                          
                      }
                    //console.log("shipping_selected_countries",shipping_selected_countries);
                    this.setState({selectCountryModalOpen: false, selected_countries: shipping_selected_countries})
                  }
                }
            }
            secondaryActions={[
              {
                  content: "Cancel",
                  onAction: () => {
                      this.setState({selectCountryModalOpen: false})
                  }
              }
            ]}
            onClose={()=>this.setState({selectCountryModalOpen:false})}
          >
        </Modal>
        </>;

        var toast = this.state.showToast? (
            <ToastMessage
              onDismiss={this.dissmissToast}
              message={this.state.toastMsg}
              error={this.state.toastError}
            />
        ): (null);

        if(this.state.page_loaded){
            return (
                <div className="waio--manage--shipping_grid">
                    <PageTitleBar
                        title={this.state.show === "add"?"Add":"Edit"}
                        breadcrumbs={[{content: 'Manage shipping', url: '/manage-shipping', target: "APP"}]}
                        primaryAction={{content:" Save ", onAction: () => this.saveRule(), disabled: this.disableSaveButton()? true: this.state.saveButtonLoading }}
                        secondaryActions={[
                            {
                                content:"Cancel",
                                url: "/shipping",
                                target: "APP"
                            }
                        ]}
                    />
                    {modal}
                    {toast}
                    {shippingNotWorkingBanner}
                    {
                        this.needToUpgrade ? UpgradeBanner: null
                    }
                    {add_or_edit_section}
                </div>
            );
        }
        else{
            return(<SkeletonPageComp/>);
        }
    }
}

function getValue(value){
	if(value){
		try {
            value = value.toString();
        } catch (e) { }
    }
    return value;
}