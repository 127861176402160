import React, { Component } from 'react';
import store from 'store2';
import {Card, TextStyle, Button, ResourceList, Tabs, Banner, Link, Avatar, ResourceItem, EmptyState} from '@shopify/polaris';
import axios from 'axios';
import _Modules from '../../../modules.json';
import waio from '../../../Helpers/functions';
import PageTitleBar from '../../../Common/TitleBar';
import ToastMessage from '../../../Common/ToastMessage';

var snippet_version = store('snippet_version') || "2.1.8";

export default class CustomersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_current_plan: this.props.user_current_plan,
            shop: this.props.shop,
            all_customers: [],
            selectedCustomers: [],
            loading: true,
            appliedFilters: [],
            searchValue: "",
            filtered_all_customers: [],
            page_loaded: false,
            selected_tab: 0,
            customer_status: "pending",
            wholesale_form_exists: false,
            wholesale_form_fetched: false,
            showToast: false,
            toastMsg: '',
            toastError: false
        }
    }
    
    showToastMessage(msg, error){
    	this.setState({
    	  showToast: true,
    	  toastMsg: msg,
    	  toastError: error||false,
    	});
    }

    dissmissToast = () =>{
    	this.setState({
    	  showToast: false,
    	});
    }

    handleTabChange = (selected_tab) => {
        switch (selected_tab) {
            case 0:
                this.setState({selected_tab,customer_status: "pending"});
                this.fetchCustomers("pending");
                break;
            case 1:
                this.setState({selected_tab,customer_status: "rejected"});
                this.fetchCustomers("rejected");
                break;
            case 2:
                this.setState({selected_tab,customer_status: "approved"});
                this.fetchCustomers("approved");
                break;
            default:
                this.setState({selected_tab,customer_status: "pending"});
                this.fetchCustomers("pending");
                break;
        }
    };

    needToUpgrade = false;

    componentDidMount() {
        if(this.props.shop){
            this.fetchCustomers("all");
            this.getWholesaleForm();
        }
        else{
            this.setState({page_loaded: true, loading: false});
        }

        if (snippet_version && waio.compareVersionNumbers(snippet_version, _Modules.find(x => x._id === 218).snippet_version) === -1) {
            this.needToUpgrade = true;
        }
    }

    getWholesaleForm(){
        if(this.props.shop){
            axios.get(this.props.host_name + '/app/get-wholesale-form/'+ this.props.shop).then(r=>{
                if(r && r.data && r.data.status === 1){
                    if(r.data.wholesale_form){
                        this.setState({wholesale_form_exists:true,wholesale_form_fetched:true});
                    }
                    else{
                        this.setState({wholesale_form_exists:false,wholesale_form_fetched:true});
                    }
                }
                else{
                    this.setState({wholesale_form_exists:false,wholesale_form_fetched:true});
                }
            }).catch(e=>{
                this.setState({wholesale_form_exists:false,wholesale_form_fetched:true});
            })
        }
    }

    fetchCustomers(status){
        this.setState({loading:true});
        axios.get(this.props.host_name + '/customer/all-customers/'+ this.props.shop+'/'+status).then(r=>{
            if(r && r.data){
                var all_customers = r.data;
                var filtered_all_customers = r.data;
                this.setState({all_customers,filtered_all_customers})
            }
            else{
                this.flashMessage("Server error. Rrefresh page", true);
            }
            this.setState({page_loaded: true,loading:false});
        }).catch(e=>{
            this.flashMessage("Server error", true);
            this.setState({page_loaded: true,loading:false});
        });
    }

    flashMessage(message, error) {
        this.showToastMessage(message, error);
    }

    approveCustomer(item){
        axios.post(this.props.host_name + '/customer/approve', item).then(response => {
            if(response && response.data.status === 1){
                this.flashMessage(response.data.message);
                this.fetchCustomers("all");
            }
            else{
                this.flashMessage(response.data.message,true);
            }
        }).catch(e => {this.flashMessage("Server error",true)});
    }

    denyCustomer(item){
        axios.post(this.props.host_name + '/customer/deny', item).then(response => {
            if(response && response.data.status === 1){
                this.flashMessage(response.data.message);
                this.fetchCustomers("all");
            }
            else{
                this.flashMessage(response.data.message,true);
            }
        }).catch(e => {this.flashMessage("Server error",true)});
    }

    renderItem = (item, id) => {
        const {customer} = item;
        const key = item.id;
       
        var media = <Avatar customer size="medium" name={customer.first_name+" "+customer.last_name} />;
        var url_edit = this.state.selected_tab===2?"/customer/shopify/"+key:"/customer/"+key;
        
        if(!this.isPremiumPlan()){
            url_edit = "/pricing-plans";
        }

        return (
            <div className="CustomerListItem">
                <ResourceItem
                    id={id}
                    media={media}
                    url={url_edit}
                >
                <div>
                    {
                        !this.isPremiumPlan() ?
                        <span>
                            {customer.first_name} {customer.last_name}
                        </span>
                        :<Link url={this.state.selected_tab===2?"/customer/shopify/"+key:"/customer/"+key} target="APP">{customer.first_name} {customer.last_name}</Link>
                    }
                
                </div>
                <div>{customer.email}</div>
                </ResourceItem>
            </div>
        );
    };

    handleSelectionChange = (selectedItems) => {
        this.setState({
            selectedCustomers: selectedItems
        });
    };

    handleFiltersChange = (appliedFilters) => {
        this.setState({appliedFilters});
    };

    handleSearchChange = (searchValue) => {
        try{
            var tem_items = this.state.all_customers.filter(x => x.customer.first_name.match(new RegExp(searchValue, 'i')));
            if(tem_items.length < 1){
                tem_items = this.state.all_customers.filter(x => x.customer.last_name.match(new RegExp(searchValue, 'i')));
            }
            if(tem_items.length < 1){
                tem_items = this.state.all_customers.filter(x => x.customer.email.match(new RegExp(searchValue, 'i')));
            }
            this.setState({
                filtered_all_customers: tem_items,
                searchValue: searchValue
            })
        }catch(e){}
    };

    isPremiumPlan(){
        var isPremium = false;
        if(this.props.user_current_plan){
            var plan_id = this.props.user_current_plan?this.props.user_current_plan.plan_id:null;
            isPremium = false;
            if(!plan_id){
                isPremium = false;
            }
            else{
                isPremium = plan_id.indexOf("premium") > -1;
            }
        }
        return isPremium;
    }

    render() {
        
        const { selected_tab, wholesale_form_fetched } = this.state;

        var UpgradeBanner = null;
        if(this.needToUpgrade){
            UpgradeBanner = <div style={{margin:"2rem 0rem"}}>
                <Banner status="warning" title="Upgrade your script!">
                This feature requires script upgrade. Please email us at <TextStyle variation="strong">support@digitalcoo.com</TextStyle> We will update the relevant script.
                </Banner>
            </div>;
        }

        const tabs = [
            // {
            //     id: 'all-customers',
            //     content: 'All',
            //     accessibilityLabel: 'All customers',
            //     panelID: 'all-customers-content',
            // },
            {
                id: 'pending-approval',
                content: 'Pending approval',
                panelID: 'accepts-marketing-content',
            },
            {
                id: 'declined',
                content: 'Declined',
                panelID: 'declined-content',
            },
            {
                id: 'approved',
                content: 'Approved',
                panelID: 'approved-content',
            },
        ];

        const resourceName = {
            singular: 'customer',
            plural: 'customers',
        };

        const emptyStateMarkup = <EmptyState
            heading="No customers found"
        >
        <p>
            Nobody applied for wholesale account.
        </p>
        </EmptyState>;
       
        const resourceList = this.state.filtered_all_customers.length > 0 ?
        <span>
            <ResourceList
              resourceName={resourceName}
              items={this.state.filtered_all_customers}
              renderItem={this.renderItem}
              selectedItems={this.state.selectedCustomers}
              onSelectionChange={this.handleSelectionChange}
              loading={this.state.loading}
            ></ResourceList>
        </span>:
        <span>
            <ResourceList
              emptyState={emptyStateMarkup}
              resourceName={resourceName}
              items={[]}
              renderItem={() => {}}
            ></ResourceList>
        </span>;

        const customersListSection = <Card>
            <Tabs tabs={tabs} selected={selected_tab} onSelect={this.handleTabChange}>
            {resourceList}
            </Tabs>
        </Card>;

        const renderPageElements = <div>
            {
                wholesale_form_fetched && <Card title="Wholesale account signup form" actions={[{content:"Read tutorial", url:"https://digitalcoo.com/wholesale-all-in-one-documnetation/create-wholesale-registration-form/", external: true}]}>
                    <Card.Section>
                        <Button disabled={this.needToUpgrade} primary url={'/wholesale-forms'} target="APP">Manage Settings</Button>
                    </Card.Section>
                </Card>
            }
            <div style={{marginBottom:"2rem"}}></div>
            {this.state.page_loaded && customersListSection}
        </div>;

        const notAllowedToUseThisModule = <div style={{marginBottom: "2rem"}}>
            <Banner status="critical" title="Upgrade your plan!">
                <p>
                    This feature is not included in your current plan. <Link url="/pricing-plans" target="APP">CLICK HERE</Link> to upgrade to Premium Plan.
                </p>
            </Banner>
        </div>;

        var toast = this.state.showToast? (
            <ToastMessage
              onDismiss={this.dissmissToast}
              message={this.state.toastMsg}
              error={this.state.toastError}
            />
          ): (null);
          
        return (
            <div>
                <PageTitleBar
                    title="Customers"
                    breadcrumbs={[{content: 'Dashboard', url: '/dashboard', target: "APP"}]}
                    secondaryActions={[{content: 'Back', url: '/dashboard', target: "APP"}]}
                />
                {toast}
                {
                    !this.isPremiumPlan() ?
                    notAllowedToUseThisModule
                    :this.needToUpgrade ? UpgradeBanner
                    :null
                }
                {renderPageElements}
            </div>
        );

    }
}