import CommonHelper from './Helpers/Common';
import App from './App';
import App202 from './App202';
import App203 from './App203'
import App204 from './App204';
import App206 from './App206';
import App207 from './App207';
import App208 from './App208';
import App209 from './App209';
import App211 from './App211';
import App212 from './App212';
import App217 from './App217';
import App218 from './App218';
import App223 from './App223';
import App226 from './App226';
import App230 from './App230';
import App231 from './App231';
import App232 from './App232';
import App234 from './App234';
import App235 from './App235';
import App236 from './App236';
import App237 from './App237';

import store from 'store2';

// Always update default version, which will be loaded default when there is no installation files in the theme
var default_version = {
  "id": 237,
  "render": App237
}

// Add new version in the beginning of this array in DESC order
var versions = [
  {
    "id": 237,
    "render": App237
  },
  {
    "id": 236,
    "render": App236
  },
  {
    "id": 235,
    "render": App235
  },
  {
    "id": 234,
    "render": App234
  },
  {
    "id": 232,
    "render": App232
  },
  {
    "id": 231,
    "render": App231
  },
  {
    "id": 230,
    "render": App230
  },
  {
    "id": 226,
    "render": App226
  },
  {
    "id": 223,
    "render": App223
  },
  {
    "id": 218,
    "render": App218
  },
  {
    "id": 217,
    "render": App217
  },
  {
    "id": 212,
    "render": App212
  },
  {
    "id": 211,
    "render": App211
  },
  {
    "id": 209,
    "render": App209
  },
  {
    "id": 208,
    "render": App208
  },
  {
    "id": 207,
    "render": App207
  },
  {
    "id": 206,
    "render": App206
  },
  {
    "id": 204,
    "render": App204
  },
  {
    "id": 203,
    "render": App203
  },
  {
    "id": 202,
    "render": App202
  },
  {
    "id": 1,
    "render": App
  }
];

function exclude_this_page() {
  var exclude = false;
  var page = window.location.pathname;
  if(page === '/manual-draft-order'){
    exclude = true;
  }
  return exclude;
}

function LoadApp(){
  if(store('_wsvload')){
    var _wsvload = store('_wsvload');
    var _excluded = exclude_this_page();
    if(_excluded === false){
      if(_wsvload !== '237'){
        var shop = CommonHelper.getShop();
        store.remove('_wsvload');
        window.location.replace("/?shop="+shop+"&redirect_url="+window.location.href);
      }
    }
  }
  else{
    store('_wsvload', '237');
  }

  var renderMe = default_version.render;
  CommonHelper.getVersionComponent({
    default_version: default_version,
    versions: versions
  }, function (component) {
    renderMe = component.render;
  });
  return renderMe;
}

export default LoadApp();