import React, { Component } from 'react';
import axios from 'axios';
import { Spinner, Card } from '@shopify/polaris';
import * as PropTypes from 'prop-types';
import querystring from 'querystring';

const parsed_query = querystring.parse(window.location.search);

export default class ManualDraftOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            host_name: this.props.host_name,
            shop: this.props.shop,
            manual_redirect_message: null,
            msg_for_user: "Applying discount to draft order. Please wait..."
        };
    }

    static contextTypes = {
        easdk: PropTypes.object,
    };

    fetch_data() {
        if(parsed_query && parsed_query.id){
            axios.get(this.props.host_name + '/draft-order/api/215/' + this.props.shop + '/' + parsed_query.id)
            .then(response => {
                var data = response.data;
                var draft_order = data.draft_order || {};
                if(data.status === 1 && draft_order && Number(draft_order.total_discount) > 0){
                    this.setState({
                        msg_for_user: "Draft order successfully updated ",
                    })
                }
                window.top.location.href = 'https://'+this.props.shop+'/admin/draft_orders/'+parsed_query.id;
            })
            .catch(error => {
            });
        }
        else{
            this.setState({
                msg_for_user: "Invalid request",
            })
            window.top.location.href = 'https://'+this.props.shop+'/admin/draft_orders';
        }
    }

    componentDidMount() {
        this.fetch_data();
    }
    

    render() {
        const {msg_for_user,manual_redirect_message} = this.state;
        const  pageLoading = <div className="snipper-container">
            <center><Spinner color="teal" size="large" /></center>
        </div>;

        return (
            <Card sectioned>
                {pageLoading}
                <div>{msg_for_user}</div>
                {manual_redirect_message}
            </Card>
        );
    }
}