import React, { Component } from 'react';
import { Layout, Card, TextContainer, Link} from '@shopify/polaris';
import FooterHelpLinks from '../FooterHelpLinks';

export default class Configuration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
    if (!this.props.shop) {
      this.props.history.push('/install');
    }
  }


  render() {


    return (
      <div>
        <div style={{ marginBottom: '2rem', marginTop: '2rem' }}>
          <Layout>
            
            <Layout.Section>
              <Card sectioned title="Wholesale app script installation">
                <TextContainer>
                  <p>
                    In order to re-install the wholesale app script to a different theme, please <Link removeUnderline external url='https://support.digitalcoo.com/hc/en-us/requests/new'>contact us</Link>. 
                  </p>
                </TextContainer>
              </Card>
            </Layout.Section>
            
            <Layout.Section>
              <Card sectioned title="Wholesale app script removal">
                <TextContainer>
                  <p>
                    <Link removeUnderline external url='https://support.digitalcoo.com/hc/en-us/articles/4404783206420-Uninstalling-Wholesale-All-In-One'>CLICK HERE</Link> to follow the article to remove the Wholesale All In One app script from the theme, or <Link removeUnderline external url='https://support.digitalcoo.com/hc/en-us/requests/new'>contact us</Link>.
                  </p>
                </TextContainer>
              </Card>
            </Layout.Section>
            
          </Layout>
        </div>
        
        <FooterHelpLinks help={this.props.help} />
      </div>
    );

  }

}