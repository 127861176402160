import React, { Component } from 'react';
import axios from 'axios';
import {Popover,PageActions,DatePicker,Banner,Checkbox,Stack,Layout,FormLayout,TextField,Select,Card,Button,RadioButton,ButtonGroup,List, TextStyle,Tag, TextContainer, Icon} from '@shopify/polaris';
import FooterHelpLinks from '../../FooterHelpLinks';
import store from 'store2';
import PageTitleBar from '../../../Common/TitleBar';
import ResourcePicker from '../../../Common/ResourcePicker';
import ToastMessage from '../../../Common/ToastMessage';
import { DeleteMinor } from '@shopify/polaris-icons';
import {Modal} from '@shopify/app-bridge-react';

export default class CreateGeneralDiscount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab_selected: 0,
            tags: '',
            discount_value: '',
            discount_type: 'percentage',
            entire_store: true,
            status: 'draft',
            product_modal_open: false,
            collection_modal_open: false,
            selected_products: '',
            selected_collections: '',
            products: [],
            __maxq: '1',
            __dq: '1',
            __enableQty: false,
            __minq: '1',
            __qi: '1',
            collections: [],
            product_counts: '',
            collection_counts: '',
            customers_id: '',
            products_array: [],
            collections_array: [],
            host_name: this.props.host_name,
            shop_id: this.props.shop_id,
            shop: this.props.shop,
            value: 'entire_store',
            specific_products:false,
            specific_collections: false,
            specific_variants:false,
            variants_array: [],
            variants: [],
            applies_to: 'entire_store',
            min_quantity_requirment: false,
            min_quantity_req_fields: [],
            variant_modal_open: false,
            update_products__modal_open: false,
            update_collections__modal_open: false,
            update_variants__modal_open: false,
            customers_group: 'all',
            specific_tag_base_customers: false,
            options: [
                {label: 'Fixed amount', value: 'fixed_price'},
                {label: 'Percentage', value: 'percentage'},
            ],
            settings: [],
            product_modal_for_variant_open: false,
            discount_value__error: false,
            volume_discount_value__error: false,
            schedule_checked: false,
            schedule_end_date: false,
            start_month: new Date().getMonth(),
            start_year: new Date().getFullYear(),
            end_month: new Date().getMonth(),
            end_year: new Date().getFullYear(),
            start_date_selected: new Date(),
            end_date_selected: new Date(),
            start_date_popup: false,
            start_date: new Date().toISOString().split('T')[0],
            end_date_popup: false,
            end_date: new Date().toISOString().split('T')[0],
            start_time:'00:00:00',
            end_time: '23:30:00',
            end_date_time_error: false,
            customer_tag_missing_error: false,
            exclude_product_modal_open: false,
            exclude_collection_modal_open: false,
            excluded_products: [],
            excluded_collections: [],
            update_ex_collections__modal_open: false,
            update_ex_products__modal_open: false,
            step_three_error: false,
            exclude_collections_checkbox: false,
            exclude_products_checkbox: false,
            save_discount_rule: false,
            step1_help_popover: false,
            step2_help_popover: false,
            step3_help_popover: false,
            step4_help_popover: false,
            tag_popover: false,
            internal_name: '',
            except_tags: false,
            except_logged_in: false,
            excepted_tags: [],
            temp_tag_value: "",
            edit_id: this.props.match.params.id,
            highlighExecptedtags: false,
            errors: [],
            showToast: false,
            toastMsg: '',
            toastError: false            
        }
        store.remove('products');
        store.remove('variants');
        store.remove('selected_products');
        store.remove('collections');
        store.remove('excludedProducts');
        store.remove('excludedCollections');
        if(!this.props.shop){
            this.props.history.push('/install');
        }
    }
    showToastMessage(msg, error){
        this.setState({
          showToast: true,
          toastMsg: msg,
          toastError: error||false,
        });
    }
    
    dissmissToast = () =>{
        this.setState({
          showToast: false,
        });
    }
    
    componentDidMount() {
        if(this.props.show === "edit"){
            this.fetchForEdit();
        }
        axios.get(this.props.host_name+'/admin/settings/'+this.props.shop)
        .then(response => {
            if(response.data){
                this.setState({
                    settings: response.data.settings
                });
            }
        })
        .catch(error => {
            this.showToastMessage(error, true)
        });
    }

    fetchForEdit(){
        axios.get(this.state.host_name + '/get-a-pricing-group/' + this.props.match.params.id)
        .then(response => {
            this.setState({
                except_logged_in: response.data[0].except_logged_in || false,
                except_tags: response.data[0].except_tags || false,
                excepted_tags: response.data[0].excepted_tags || [],
                tags: response.data[0].tags,
                discount_value: response.data[0].value,
                discount_type: response.data[0].type,
                internal_name: response.data[0].internal_name,
                customers_id: response.data[0].customers,
                applies_to: response.data[0].applies_to,
                value: response.data[0].applies_to,
                __maxq: response.data[0].qty_ctrl.max_qty,
                __dq: response.data[0].qty_ctrl.default_qty,
                __enableQty: response.data[0].qty_ctrl.enabled,
                __minq: response.data[0].qty_ctrl.min_qty,
                __qi: response.data[0].qty_ctrl.inc_qty,
                products_array: response.data[0].products,
                collections_array: response.data[0].collections,
                variants_array: response.data[0].variants,
                products: response.data[0].products,
                collections: response.data[0].collections,
                variants: response.data[0].variants,
                min_quantity_requirment: response.data[0].min_quantity_requirment,
                min_quantity_req_fields: response.data[0].minimum_quantities,
                customers_group: response.data[0].customers_group.tag_base_customers ? 'specific_tag_base_customers' : response.data[0].customers_group.customers,
                specific_tag_base_customers: response.data[0].customers_group.tag_base_customers,
                schedule: response.data[0].schedule,
                schedule_checked: response.data[0].schedule_active,
                end_date: response.data[0].schedule.end_date ? (response.data[0].schedule.end_date).split('T')[0] : response.data[0].schedule.start_date ? this.get_est_date_only(response.data[0].schedule.start_date) : new Date().toISOString().split('T')[0],
                start_date: response.data[0].schedule.start_date ? this.get_est_date_only(response.data[0].schedule.start_date) : new Date().toISOString().split('T')[0],
                start_time: response.data[0].schedule.start_time_est ? response.data[0].schedule.start_time_est : this.state.start_time,
                end_time: response.data[0].schedule.end_time_est ? response.data[0].schedule.end_time_est : this.state.end_time,
                schedule_end_date: response.data[0].schedule.end_date ? true : false,
                excluded_products: response.data[0].excluded_products,
                excluded_collections: response.data[0].excluded_collections,
                status: response.data[0].status,
                entire_store: response.data[0].applies_to === 'entire_store',
                specific_collections: response.data[0].applies_to === 'specific_collections',
                specific_products: response.data[0].applies_to === 'specific_products',
                specific_variants: response.data[0].applies_to === 'specific_variants',
                exclude_products_checkbox: response.data[0].excluded_products.length > 0 ? true : false,
                exclude_collections_checkbox: response.data[0].excluded_collections.length > 0 ? true : false,
                data_loaded: true,
            });
        })
        .catch(function (error) {
        })
    }

    handleSubmit = (event) => {
        var errors = [];
        let excluded_collections = this.state.excluded_collections;
        let excluded_products = this.state.excluded_products;
        let products = this.state.products_array;
        let collections = this.state.collections_array;
        let variants = this.state.variants_array;
        let validation = true;
        let start_date_time = this.get_est_dateTime(this.state.start_date,this.state.start_time);
        let start_time = this.state.start_time;
        let end_date_time = this.get_est_dateTime(this.state.end_date,this.state.end_time);
        let end_time = this.state.end_time;
        if(this.state.value === 'specific_collections'){
            excluded_collections = [];
            if(!this.state.exclude_products_checkbox){
                excluded_products = [];
            }
        }
        else if(this.state.value !== 'entire_store'){
            excluded_collections = [];excluded_products = [];
        }
        else{
            if(!this.state.exclude_products_checkbox){
                excluded_products = [];
            }
            if(!this.state.exclude_collections_checkbox){
                excluded_collections = [];
            }
        }
        if(!this.state.schedule_checked){
            start_date_time = null;
            start_time = null;
            end_date_time = null;
            end_time = null;
        }
        if(!this.state.schedule_end_date){
            end_date_time = null;
            end_time = null;
        }
        const formData = {
            except_logged_in: this.state.except_logged_in,
            except_tags: this.state.except_tags,
            excepted_tags: this.state.excepted_tags,
            internal_name: this.state.internal_name,
            qty_ctrl:{
                enabled: this.state.__enableQty,
                default_qty: this.state.__dq,
                min_qty: this.state.__minq,
                max_qty: this.state.__maxq,
                inc_qty: this.state.__qi
            },
            tags: this.state.tags,
            type: this.state.discount_type,
            value: this.state.discount_value,
            entire_store: this.state.entire_store,
            status: this.state.status,
            shop_id: this.state.shop_id,
            shop: this.state.shop,
            applies_to: this.state.value,
            products: products,
            collections: collections,
            variants: variants,
            min_quantity_requirment: this.state.min_quantity_requirment,
            minimum_quantities: this.state.min_quantity_req_fields,
            customers_group: {
                customers: this.state.specific_tag_base_customers?'only_logged_in_customers':this.state.customers_group,
                tag_base_customers: this.state.specific_tag_base_customers,
            },
            settings: this.state.settings,
            schedule_active: this.state.schedule_checked,
            schedule: {
                start_date: start_date_time,
                end_date: end_date_time,
                start_time_est: start_time,
                end_time_est: end_time
            },
            excluded_products: excluded_products,
            excluded_collections: excluded_collections,
        };
        if(this.state.min_quantity_requirment){
            let error; let less_than_zero_price = false;
            let discount_type = this.state.discount_type;
            if(this.state.min_quantity_req_fields.length < 1){
                error = true;
                this.setState({volume_discount_value__error:true});
                this.showToastMessage("Step 2: volume discount error", true);
                validation = false;
            }
            this.state.min_quantity_req_fields.forEach( function(item,idx){
                if(item.min_quantity_value === '' || item.min_discount_value === ''){
                    error = true;
                    validation = false;
                }
                else if(item.min_discount_value <= 0){
                    less_than_zero_price = true;
                }
                if(discount_type === 'percentage' && item.min_discount_value > 100){
                    error = true;
                    validation = false;
                }
            });
            if(less_than_zero_price){
                this.setState({volume_discount_value__error:true});
                this.showToastMessage("Step 2: price must greater than zero", true);
                validation = false;
            }
            if(error){
                this.setState({volume_discount_value__error:true});
                this.showToastMessage("Step 2: volume discount error", true);
                validation = false;
            }
        }
        else{
            if(this.state.discount_value === ''){
                this.setState({discount_value__error:true});
                this.showToastMessage("Step 2: price is required", true);
                validation = false;
                errors.push("Discount price is missing");
            }
        }
        if(!this.state.entire_store && this.state.value === 'specific_products' && products.length < 1){
            this.showToastMessage("Step 3: select products", true);
            this.setState({
                step_three_error: true
            })
            validation = false;
            errors.push("No product was selected");
        }
        else if(!this.state.entire_store && this.state.value === 'specific_collections' && collections.length < 1){
            this.showToastMessage("Step 3: select collections", true);
            this.setState({
                step_three_error: true
            })
            validation = false;
            errors.push("No collection was selected");
        }
        else if(!this.state.entire_store && this.state.value === 'specific_variants' && variants.length < 1){
            this.showToastMessage("Step 3: select variants", true);
            this.setState({
                step_three_error: true
            })
            validation = false;
            errors.push("No variant was selected");
        }
        if(!this.state.min_quantity_requirment){
            if(this.state.discount_type === 'percentage' && this.state.discount_value > 100){
                this.setState({discount_value__error:true});
                this.showToastMessage("Value can't exceed 100%", true);
                validation = false;
                errors.push("Discount price can not axceed 100.");
                // return false;
            }
            if(this.state.discount_value < 0.01){
                this.setState({discount_value__error:true});
                // this.showToastMessage("Discount price must be greater than zero", true);
                validation = false;
                errors.push("Discount price must be greater than zero.");
                // return false;
            }
        }
        if(this.state.customers_group === 'specific_tag_base_customers' && this.state.specific_tag_base_customers && this.state.tags === ''){
            this.showToastMessage("Step 1: customer tag can't be blank", true);
            this.setState({customer_tag_missing_error:true});
            errors.push("Customer tag is missing");
            validation = false;
            // return false;
        }
        if(this.state.schedule_checked && this.state.schedule_end_date){
            if(this.diff_days(this.state.end_date,this.state.start_date) === 0){
                if(this.state.start_time === this.state.end_time){
                    this.setState({end_date_time_error:true});
                    validation = false;
                    errors.push("End date and start date can not be same");
                    // return false;
                }
                else if(this.state.start_time > this.state.end_time){
                    this.setState({end_date_time_error:true});
                    validation = false;
                    errors.push("Start date and time must be gearter than end date and time.");
                    // return false;
                }
    
            }
            else if(this.diff_days(this.state.end_date,this.state.start_date) < 0){
                this.setState({end_date_time_error:true});
                validation = false;
                errors.push("Date and time error");
                // return false;
            }
        }
        if(this.state.except_tags && this.state.excepted_tags.length === 0){
            validation = false;
            this.setState({highlighExecptedtags:true});
            this.showToastMessage("Step 1: Excepted tags are required", true);
            errors.push("Excepted tag is missing");
        }
        if(validation){
            this.setState({save_discount_rule:true,errors});
            if(this.props.show === "edit"){
                axios.put(this.state.host_name + '/update-pricing-group/' + this.props.match.params.id, formData)
                .then(response => {
                    if (response.data.status === 1) {
                        this.showToastMessage(response.data.message);
                        this.props.history.push('/general-discount-groups');
                    }
                    else {
                        this.setState({ save_discount_rule: false });
                        this.showToastMessage(response.data.message, true);
                        return false;
                    }
                })
                .catch(error => { });
            }
            else{
                axios.post(this.state.host_name+'/add-pricing-group', formData)
                .then( response => {
                    if(response.data.status === 1){
                        this.showToastMessage(response.data.message);
                        this.props.history.push('/general-discount-groups');
                    }
                    else{
                        this.setState({save_discount_rule:false})
                        this.showToastMessage(response.data.message, true);
                        return false;
                    }
                })
                .catch(error => {
                    this.setState({save_discount_rule:false});
                });
            }
        }
        else{
            window.scrollTo({top: 0, behavior: 'smooth'});
            this.setState({errors});
        }
    };

    diff_days(new_date, old_date) {
        var t1 = new Date(new_date);
        var t2 = new Date(old_date);
        let result = parseInt((t1-t2)/(24*3600*1000),10);
        if(isNaN(result)) result = 0;
        return result;
    }
    get_est_date_time(datetime){
        var estTime = new Date(datetime); // get local time to be calculated into EST
        return estTime.setHours(estTime.getHours() + estTime.getTimezoneOffset()/60 - 5); // getTimezoneOffset returns in minutes hence /60  
    }
    est_date_time_now(){
        var estTime = new Date(); // get local time to be calculated into EST
        return estTime.setHours(estTime.getHours() + estTime.getTimezoneOffset()/60 - 5); // getTimezoneOffset returns in minutes hence /60  
    }
    get_est_date_only(date){
        let date_only = new Date(date);
        if(typeof date_only === 'object'){
          date_only = date_only.toString();
          if(typeof date_only === 'string'){
            date_only = date_only.replace(this.getTimezoneOffset(), '+0000');
          }
        }
        date_only = new Date(date_only).toISOString();
        date_only = date_only.split('T')[0];
        return date_only;
    }
    deleteProduct(item){
        this.setState({
            products_array: this.state.products_array.filter(i => i.id !== item.id)
        });
    }
    deleteCollection(item){
        this.setState({
            collections_array:this.state.collections_array.filter(i => i.id !== item.id)
        });
    }
    handleAppliesOn = (checked, newValue) => {
        this.setState({
            value: newValue,
        });
        switch (newValue) {
            case 'entire_store':
                this.setState({
                    entire_store: true,
                    specific_collections: false,
                    specific_products:false,
                    specific_variants:false,
                });
                break;
            case 'specific_collections':
                this.setState({
                    entire_store: false,
                    specific_collections: true,
                    specific_products:false,
                    specific_variants:false
                });
                break;
            case 'specific_products':
                this.setState({
                    entire_store: false,
                    specific_collections: false,
                    specific_products:true,
                    specific_variants:false
                });
                break;
            case 'specific_variants':
                this.setState({
                    entire_store: false,
                    specific_collections: false,
                    specific_products:false,
                    specific_variants:true
                });
                break;
            default:
                break;
        }
    };
    handleMinimumQuantityRequirment = (value) => {
        if(value){
            this.setState(prevState =>({
                min_quantity_requirment: value,
                discount_type: this.state.settings.discount_method === 'line_items'?"fixed_per_item_discount":this.state.discount_type,
                options: [
                    {label: 'Fixed amount', value: 'fixed_price'},
                    {label: 'Fixed amount (per item discount)', value: 'fixed_per_item_discount'},
                    {label: 'Percentage', value: 'percentage'}
                ],
                min_quantity_req_fields: this.state.min_quantity_req_fields.length < 1 ?[...prevState.min_quantity_req_fields, {min_quantity_value:'', min_discount_value:''}]:this.state.min_quantity_req_fields
            }));
        }
        else{
            
            let min_quantity_req_fields = [...this.state.min_quantity_req_fields];
            if(this.state.min_quantity_req_fields[this.state.min_quantity_req_fields.length-1].min_quantity_value === ''){
                min_quantity_req_fields.splice(this.state.min_quantity_req_fields.length-1, 1);
            }
            this.setState({
                discount_type: this.state.discount_type==='fixed_per_item_discount'?'fixed_price':this.state.discount_type,
                min_quantity_requirment: value,
                options: [
                    {label: 'Fixed amount', value: 'fixed_price'},
                    {label: 'Percentage', value: 'percentage'},
                ],
                min_quantity_req_fields: min_quantity_req_fields
            });
        }
    };
    addClick(){
        this.setState(prevState => ({ 
            min_quantity_req_fields: [...prevState.min_quantity_req_fields, {min_quantity_value:'', min_discount_value:''}]
        }))
    }
    removeClick(i){
        let min_quantity_req_fields = [...this.state.min_quantity_req_fields];
        min_quantity_req_fields.splice(i, 1);
        this.setState({ min_quantity_req_fields });
    }
    createUI(){
        return this.state.min_quantity_req_fields.map((el, i) => (
          <div key={i}>
            <Stack alignment="leading">
            <Stack.Item fill>
            <Stack distribution="equalSpacing" spacing="extraTight">
            <div className="QuantityValueField">
                <div className="Polaris-TextField Polaris-TextField--hasValue">
                <input id="TextField1" className="Polaris-TextField__Input" aria-labelledby="TextField1Label" aria-invalid="false" min="1" minLength="3" type="number" name="min_quantity_value" value={el.min_quantity_value ||''} onChange={this.handleChangeMinDiscountsGroupValue.bind(this, i)} placeholder="Minimum quantity"></input>
                <div className="Polaris-TextField__Backdrop"></div>
                </div>
            </div>
            <div className="DiscountValueField">
                <div className="Polaris-TextField Polaris-TextField--hasValue">
                {this.state.discount_type === 'fixed_price' ?<div className="Polaris-TextField__Prefix" id="TextField2Prefix">$</div>:''}
                <input id="TextField2" className="Polaris-TextField__Input" aria-labelledby="TextField2Label" aria-invalid="false" max={this.state.discount_type === 'percentage' ?'100':'1000000'} type="number" name="min_discount_value" value={el.min_discount_value ||''} onChange={this.handleChangeMinDiscountsGroupValue.bind(this, i)} placeholder="Discont amount"></input>
                {this.state.discount_type === 'percentage' ?<div className="Polaris-TextField__Suffix"
                    id="TextField2Suffix">%</div>:''}
                <div className="Polaris-TextField__Backdrop"></div>
                </div>
            </div>
            <Button
                onClick={this.removeClick.bind(this, i)}
                disabled={this.state.min_quantity_req_fields.length < 2}
            ><Icon source={DeleteMinor}/></Button>
          </Stack></Stack.Item></Stack>
          </div>          
        ))
    }
    handleChangeMinDiscountsGroupValue(i, e) {
        var { name, value } = e.target;
        if(this.state.discount_type === 'percentage' && value > 100){
            value = 100;
        }
        if(value !== '' && value < 0){
            value = 1;
        }
        let min_quantity_req_fields = [...this.state.min_quantity_req_fields];
        min_quantity_req_fields[i] = {...min_quantity_req_fields[i], [name]: value};
        this.setState({ min_quantity_req_fields });
    }
    handleSubmitSelectedVariants = (selected) => {
        let variants = store('variants') || [];
        if(variants.length < 1){
            this.setState({
                variant_modal_open: false,
                selected_products_variant_picker_open: false,
                product_modal_for_variant_open: false
            });
        }
        else{
            this.setState({
                variants: variants,
                variants_array: variants,
                variant_modal_open: false,
                selected_products_variant_picker_open: false,
                product_modal_for_variant_open: false
            });
        }
    };
    handleSubmitUpdateProducts = (selected) => {
        let pro_selected = store('products') || [];
        let p_arr = [];
        if(pro_selected.length > 0){
            pro_selected.forEach(x => {
                p_arr.push(x.id);
            });
        }
        this.setState({
            products_array: pro_selected,
            products: p_arr,
            update_products__modal_open: false
        })
    }
    handleSubmitUpdateCollections = (selected) => {
        let col_selected = store('collections') || [];
        let c_arr = [];
        if(col_selected.length > 0){
            col_selected.forEach(x => {
                c_arr.push(x.id);
            });
        }
        this.setState({
            collections_array: col_selected,
            collections: c_arr,
            update_collections__modal_open: false
        })
    }
    handleSubmitUpdateVariants = (selected) => {
        let variants = store('variants') || [];
        this.setState({
            variants: variants,
            variants_array: variants,
            update_variants__modal_open: false,
        });
    }
    upgrade = () => {
        this.props.history.push('/pricing-plans');
    }
    getTimezoneOffset() {
        function z(n){return (n<10? '0' : '') + n}
        var offset = new Date().getTimezoneOffset();
        var sign = offset < 0? '+' : '-';
        offset = Math.abs(offset);
        return sign + z(offset/60 | 0) + z(offset%60);
    }
    get_est_dateTime(date,time){
        let dateTimeValue = new Date(date);
        if(typeof dateTimeValue === 'object'){
          dateTimeValue = dateTimeValue.toString();
          if(typeof dateTimeValue === 'string'){
            dateTimeValue = dateTimeValue.replace(this.getTimezoneOffset(), '+0000');
          }
        }
        dateTimeValue = new Date(dateTimeValue).toISOString();
        dateTimeValue = dateTimeValue.split('T')[0] + " " +time;
        return dateTimeValue;
    }
    handleSubmitUpdateExcProducts = (selected) => {
        this.setState({
            excluded_products: store('excludedProducts') || [],
            update_ex_products__modal_open: false
        })
    }
    handleSubmitUpdateExcCollections = (selected) => {
        this.setState({
            excluded_collections: store('excludedCollections') || [],
            update_ex_collections__modal_open: false
        })
    }
    renderExceptedTags(){
        var excepted_tags = this.state.excepted_tags;
        return excepted_tags.map((tag,i) => {
            return <span key={i} style={{margin:"4px"}}>
                <Tag
                    onRemove={this.removeTag.bind(this,tag,i)}
                >{tag}</Tag>
            </span>
        });
    }
    removeTag(tag,i,el){
        var excepted_tags = this.state.excepted_tags;
        excepted_tags.splice([i],1);
        this.setState({ excepted_tags });
    }

    render() {
        const {errors,except_tags,excepted_tags,temp_tag_value,except_logged_in,highlighExecptedtags} = this.state;
        let {value,schedule_checked,schedule_end_date,end_month,end_year,start_month,start_year,start_date_selected,end_date_selected,start_date_popup,start_date,end_date_popup,end_date,start_time,end_time,end_date_time_error,excluded_products,excluded_collections,exclude_collections_checkbox,exclude_products_checkbox,customers_group} = this.state;
      
        Date.prototype.addHours = function (h) {
            this.setTime(this.getTime() + (h * 60 * 60 * 1000));
            return this;
        }

        let time_options = [
            {label:'12:00 am',value:'00:00:00'},
            {label:'12:30 am',value:'00:30:00'},
            {label:'01:00 am',value:'01:00:00'},
            {label:'01:30 am',value:'01:30:00'},
            {label:'02:00 am',value:'02:00:00'},
            {label:'02:30 am',value:'02:30:00'},
            {label:'03:00 am',value:'03:00:00'},
            {label:'03:30 am',value:'03:30:00'},
            {label:'04:00 am',value:'04:00:00'},
            {label:'04:30 am',value:'04:30:00'},
            {label:'05:00 am',value:'05:00:00'},
            {label:'05:30 am',value:'05:30:00'},
            {label:'06:00 am',value:'06:00:00'},
            {label:'06:30 am',value:'06:30:00'},
            {label:'07:00 am',value:'07:00:00'},
            {label:'07:30 am',value:'07:30:00'},
            {label:'08:00 am',value:'08:00:00'},
            {label:'08:30 am',value:'08:30:00'},
            {label:'09:00 am',value:'09:00:00'},
            {label:'09:30 am',value:'09:30:00'},
            {label:'10:00 am',value:'10:00:00'},
            {label:'10:30 am',value:'10:30:00'},
            {label:'11:00 am',value:'11:00:00'},
            {label:'11:30 am',value:'11:30:00'},
            {label:'12:00 pm',value:'12:00:00'},
            {label:'12:30 pm',value:'12:30:00'},
            {label:'01:00 pm',value:'13:00:00'},
            {label:'01:30 pm',value:'13:30:00'},
            {label:'02:00 pm',value:'14:00:00'},
            {label:'02:30 pm',value:'14:30:00'},
            {label:'03:00 pm',value:'15:00:00'},
            {label:'03:30 pm',value:'15:30:00'},
            {label:'04:00 pm',value:'16:00:00'},
            {label:'04:30 pm',value:'16:30:00'},
            {label:'05:00 pm',value:'17:00:00'},
            {label:'05:30 pm',value:'17:30:00'},
            {label:'06:00 pm',value:'18:00:00'},
            {label:'06:30 pm',value:'18:30:00'},
            {label:'07:00 pm',value:'19:00:00'},
            {label:'07:30 pm',value:'19:30:00'},
            {label:'08:00 pm',value:'20:00:00'},
            {label:'08:30 pm',value:'20:30:00'},
            {label:'09:00 pm',value:'21:00:00'},
            {label:'09:30 pm',value:'21:30:00'},
            {label:'10:00 pm',value:'22:00:00'},
            {label:'10:30 pm',value:'22:30:00'},
            {label:'11:00 pm',value:'23:00:00'},
            {label:'11:30 pm',value:'23:30:00'}
        ];

        let start_date_piker = <DatePicker
            month={start_month}
            year={start_year}
            disableDatesBefore={new Date().addHours(-24)}
            onChange={(value)=>{
                let a = value.start;
                if(typeof a === 'object'){
                    a = a.toString();
                    if(typeof a === 'string'){
                        a = a.replace(this.getTimezoneOffset(), '+0000');
                    }
                }
                this.setState({
                    start_date_selected: value,
                    start_date:new Date(a).toISOString().split('T')[0],
                    end_date:this.diff_days(this.state.end_date,a)<=0?new Date(a).toISOString().split('T')[0]:this.state.end_date,
                    start_date_popup:false
                })
            }}
            onMonthChange={(month, year) => {
                this.setState({
                    start_month:month,
                    start_year:year,
                });
            }}
            selected={start_date_selected}
        />;
        let end_date_piker = <DatePicker
            month={end_month}
            year={end_year}
            disableDatesBefore={start_date_selected.start?start_date_selected.start:new Date().addHours(-24)}
            onChange={(value)=>{
                let a = value.start;
                if(typeof a === 'object'){
                    a = a.toString();
                    if(typeof a === 'string'){
                        a = a.replace(this.getTimezoneOffset(), '+0000');
                    }
                }
                this.setState({
                    end_date_selected: value,
                    end_date: new Date(a).toISOString().split('T')[0],
                    end_date_popup: false
                })
            }}
            onMonthChange={(month, year) => {
                this.setState({
                    end_month:month,
                    end_year:year,
                });
            }}
            selected={end_date_selected}
        />;
        const start_date_activator = (
            <TextField
                readOnly={true}
                label="Start date"
                value={start_date}
                autoComplete={false}
                onChange={(value)=>{
                    this.setState({start_date:value,start_date_popup:false});
                }}
                onFocus={()=>{
                    this.setState({start_date_popup:true});
                }}
            />
        );
        const end_date_activator = (
            <TextField
                readOnly={true}
                label="End date"
                value={end_date}
                autoComplete={false}
                onChange={(value)=>{
                    this.setState({end_date:value,end_date_popup:false});
                }}
                onFocus={()=>{
                    this.setState({end_date_popup:true});
                }}
                error={end_date_time_error?true:false}
            />
        );

        const tags_input = <TextField
            disabled={except_logged_in&&(customers_group === "all")}
            label="Tags"
            labelHidden
            autoFocus
            placeholder="Enter comma separated tags"
            value={temp_tag_value}
            onChange={(v)=>{
                this.setState({highlighExecptedtags: false});
                if(v && v.indexOf(",") >-1 ){
                    v= v.replace(",","");
                    v = v.trim();
                    if(v === "") return false;
                    var excepted_tags = this.state.excepted_tags;
                    if(excepted_tags.indexOf(v) > -1){
                        this.showToastMessage("Tag already exists", true);
                        return false;
                    }
                    excepted_tags.push(v);
                    this.setState({ excepted_tags, temp_tag_value: "" });
                }
                else{
                    this.setState({temp_tag_value:v});
                }
            }}
            onBlur={()=>{
                var v = this.state.temp_tag_value;
                v = v.replace(",","");
                v = v.trim();
                if(v === "") return false;
                var excepted_tags = this.state.excepted_tags;
                if(excepted_tags.indexOf(v) > -1){
                    return false;
                }
                excepted_tags.push(v);
                this.setState({ excepted_tags, temp_tag_value: "" });
            }}
            error={highlighExecptedtags}
        ></TextField>;
 
        let internal_name_section = <div>
            <Card sectioned title="Step 1: Regular Discount Title"
                actions={[{content:"Read tutorial", url:"https://digitalcoo.com/wholesale-all-in-one/", external: true}]}
            >
                <Stack vertical spacing="tight">
                    <TextField
                        autoFocus
                        label="Regular Discount Title"
                        labelHidden={true}
                        helpText="Write the title of your Regular Discount. This will not appear to the customers."
                        placeholder="e.g Discount for All customers"
                        value={this.state.internal_name}
                        onChange={(value) => {this.setState({internal_name: value})}}
                        autoComplete="false"
                    />
                    <RadioButton
                        label="Save as Test Mode"
                        helpText={<span>Test the discount rule before making it live to the customers. <a style={{textDecoration: "underline"}} href={`https://${this.props.shop}/?draftpreview=yes`} target="new">View Test Mode</a> </span>}
                        checked={"draft" === this.state.status}
                        id="draft"
                        onChange={(a,b) => {this.setState({status: "draft"});}}
                    />
                    <RadioButton
                        label="Save as Published"
                        helpText="Make discount rule available for the public to view."
                        checked={"published" === this.state.status}
                        id="published"
                        onChange={(a,b) => {this.setState({status: "published"});}}
                    />
                    <RadioButton
                        label="Save as Unpublished"
                        helpText="Make discount rule unavailable for the public to view."
                        checked={"published" !== this.state.status && "draft" !== this.state.status}
                        id="unpublished"
                        onChange={(a,b) => {this.setState({status: "unpublished"});}}
                    />
                </Stack>
            </Card>
        </div>;

        const except_taged_section = <Stack vertical spacing="tight">
            <Checkbox
                disabled={except_logged_in&&(customers_group === "all")}
                label={<span>Except tags</span>}
                id="except_tags"
                value={except_tags}
                checked={except_tags}
                onChange={(checked, value) => {
                    this.setState({ except_tags: checked });
                }}
            />
            {except_tags?tags_input: null}
            {
                except_tags && excepted_tags.length > 0?
                <Stack>
                    {this.renderExceptedTags()}
                </Stack>
                : null
            }
        </Stack>;
        
        let step_one = <div>
            <Card sectioned title="Step 2: Customer Groups"
                actions={[{content:"Read tutorial", url:"https://digitalcoo.com/wholesale-all-in-one/", external: true}]}
            >
                <TextContainer>
                  <TextStyle variation="subdued">The Customer Groups section will allow you to select the target customers that will receive discount pricing.</TextStyle>
                  <br></br>
                  <TextStyle variation="subdued">Select the customer group that will receive the offer discount</TextStyle>
                </TextContainer>
                <Stack vertical spacing="extraTight">
                    <RadioButton
                        label={<span>All Customers </span>}
                        helpText="All website visitors will see the discount price whether or not they are logged in."
                        id="all"
                        name="customers_group"
                        checked={customers_group === 'all'}
                        onChange={(c,v)=>{
                            this.setState({
                                customers_group:v,specific_tag_base_customers:false,
                                except_tags: false, except_logged_in: false 
                            })
                        }}
                    />
                {
                    customers_group === "all" ?
                        <div style={{marginLeft:"2.3rem"}}>
                            <Checkbox
                                label="Except logged in"
                                id="except_logged_in"
                                value={except_logged_in}
                                checked={except_logged_in}
                                onChange={(checked, value) => {
                                    this.setState({ except_logged_in: checked });
                                }}
                            />
                            {except_taged_section}
                        </div>
                    :null
                }
                <RadioButton
                    label="Only logged in customers "
                    helpText="Discount price will appear to only those customers who are logged in to their account. The customers who are not logged in will not receive the discount price. "
                    id="only_logged_in_customers"
                    name="customers_group"
                    checked={customers_group === 'only_logged_in_customers'}
                    onChange={(c,v)=>{
                        this.setState({
                            customers_group:v,specific_tag_base_customers:false,
                            except_tags: false, except_logged_in: false 
                        })
                    }}
                />
                {
                    customers_group === "only_logged_in_customers" ?
                        <div style={{marginLeft:"2.3rem"}}>
                            {except_taged_section}
                        </div>
                    :null
                }
                <RadioButton
                    label="Only Tagged customers "
                    helpText={<span>Discount price will appear to only those customers who are logged in and have matching customer tag. <br></br> Example: Let’s assume we apply 10% discount for “wholesale” tag based customers, then all the customers having “wholesale” tag will receive the relevant discount. </span>}
                    id="specific_tag_base_customers"
                    name="customers_group"
                    checked={customers_group === 'specific_tag_base_customers'}
                    onChange={(c,v)=>{
                        this.setState({
                            customers_group:v,specific_tag_base_customers:c,
                            except_tags: false, except_logged_in: false 
                        })
                    }}
                />
                {
                    customers_group === 'specific_tag_base_customers'?
                        <div style={{margin: "0rem 2.3rem;"}}>
                            <TextField
                                label="Enter a customer tag"
                                placeholder="E.g. wholesale"
                                helpText="Please write customer tag in lowercase. You can only enter one customer tag"
                                value={this.state.tags}
                                onChange={(value) => {this.setState({tags: value})}}
                                autoComplete="false"
                                error={this.state.customer_tag_missing_error?true:false}
                                onBlur={() => {
                                    var tags = this.state.tags;
                                    try{
                                        if(tags){
                                            tags = tags.trim().toLowerCase().replace(new RegExp(",","g"),"")
                                            this.setState({tags})
                                        }
                                    }catch(e){}
                                }}
                            />
                            <div className="customer-tag-tag">
                                {
                                    this.state.tags && <Tag onRemove={() => { this.setState({tags:""}) }}>{this.state.tags}</Tag>
                                }
                            </div>
                        </div>
                    :null
                }
            </Stack>
            </Card>
        </div>;
        
        let step_two = <div>
            <Card title="Step 3: Discount Methods"
                actions={[{content:"Read tutorial", url:"https://digitalcoo.com/wholesale-all-in-one/", external: true}]}
            >
                <Card.Section>
                    <Stack vertical spacing="extraTight">
                        <RadioButton
                            label="Percent Off "
                            helpText={<span><TextStyle variation="strong">Percent Off</TextStyle> will set the wholesale price to a % off the current retail price of the product. (not the​ compare-at-price). If you change the price of the item in Shopify, the wholesale price will be adjusted as well.<br></br> Example: Let’s assume retail price of the item is $100 and you set 30% as “Percent Off” then customers will see $70 as final price. </span>}
                            id="percentage"
                            name="discount_type"
                            checked={this.state.discount_type === 'percentage'}
                            onChange={(c,v)=>{this.setState({discount_type:v})}}
                        />
                        <RadioButton
                            label="Price Off "
                            helpText={<span><TextStyle variation="strong">Price Off</TextStyle> will set the wholesale price to a fixed amount off the current retail price of the product.​ (not the compare-at-price). If you change the price of the item in Shopify, the wholesale price will be adjusted as well. <br></br>Example: Let’s assume retail price of the item is $100 and you set $25 as “Price Off” then customers will see $75 as final price.</span>}
                            id="price_discount"
                            name="discount_type"
                            checked={this.state.discount_type === 'price_discount'}
                            onChange={(c,v)=>{this.setState({discount_type:v})}}
                        />
                        <RadioButton
                            label="Fixed Price "
                            helpText={<span><TextStyle variation="strong">Fixed Price</TextStyle> will create a fixed wholesale price for the product. It won’t change if you change the​	 retail price of the product in Shopify. <br></br>Example: Let’s assume retail price of the item is $100 and you set $70 as “Fixed Price” then customers will see $70 as final price. </span>}
                            id="fixed_price"
                            name="discount_type"
                            checked={this.state.discount_type === 'fixed_price'}
                            onChange={(c,v)=>{this.setState({discount_type:v})}}
                        />
                    </Stack>
                </Card.Section>
                <Card.Section>
                    <FormLayout>
                        <FormLayout.Group>
                            <TextField
                                type="number"
                                label={<span>{this.state.discount_type === 'fixed_price'?"Fixed Price": this.state.discount_type === 'price_discount'?"Reduce Item Price By":"Percent Off Per Item"}</span>}
                                placeholder={this.state.discount_type === "percentage"?"Value":"Amount"}
                                value={getValue(this.state.discount_value)}
                                onChange={(value) => {
                                    if (this.state.discount_type === 'percentage' && value > 100) {
                                        value = 100;
                                    }
                                    if (value !== '' && value < 0) {
                                        value = 1;
                                    }
                                    this.setState({ discount_value: value });
                                }}
                                // prefix={this.state.discount_type !== 'percentage' ? '$' : ''}
                                suffix={this.state.discount_type === 'percentage' ? '%' : ''}
                                max={this.state.discount_type === 'percentage' ? 100 : 100000}
                                step={1}
                                // error={!this.state.discount_value? "This field cannot be blank":null}
                            />
                            <div></div>
                        </FormLayout.Group>
                    </FormLayout>
                </Card.Section>
                    {/* <Banner status="info">
                    {
                        this.state.discount_type === 'percentage' ?
                        <span> <strong>Percent Off</strong> will set the wholesale price to a % off the current retail price of the product. (not the compare-at-price). If you change the price of the item in Shopify, the wholesale price will be adjusted as well. Example: Let's assume retail price of the item is $100 and you set 30% as "Percent Off" then customers will see $70 as final price.</span>
                        :this.state.discount_type === 'price_discount' ?
                        <span><strong>Price Off</strong> will set the wholesale price to a fixed amount off the current retail price of the product. (not the compare-at-price). If you change the price of the item in Shopify, the wholesale price will be adjusted as well. Example: Let's assume retail price of the item is $100 and you set $25 as "Price Off" then customers will see $75 as final price.</span>
                        :<span><strong>Set Fix Price</strong> will create a fixed wholesale price for the product. It won't change if you change the retail price of the product in Shopify. Example: Let's assume retail price of the item is $100 and you set $70 as "Set Fix Price" then customers will see $70 as final price.</span>
                    }
                    </Banner> */}
            </Card>
        </div>;

        let step_three_new = <div>
            <Card sectioned title="Step 4: Apply Discount To" actions={[{content:"Read tutorial", url:"https://digitalcoo.com/wholesale-all-in-one/", external: true}]}>
                <Stack vertical>
                    <Stack>
                        <RadioButton
                            label="Entire website"
                            checked={value === 'entire_store'}
                            id="entire_store"
                            name="discount_applies_on"
                            onChange={this.handleAppliesOn}
                        />
                        <RadioButton
                            label="Specific collections"
                            checked={value === 'specific_collections'}
                            id="specific_collections"
                            name="discount_applies_on"
                            onChange={this.handleAppliesOn}
                            disabled={
                                this.props.user_current_plan.plan_permissions?
                                this.props.user_current_plan.plan_permissions[0].collections?
                                false:true
                                :false
                            }
                        />
                        <RadioButton
                            label="Specific products"
                            checked={value === 'specific_products'}
                            id="specific_products"
                            name="discount_applies_on"
                            onChange={this.handleAppliesOn}
                            disabled={
                                this.props.user_current_plan.plan_permissions?
                                this.props.user_current_plan.plan_permissions[0].products?
                                false:true
                                :false
                            }
                        />
                        <RadioButton
                            label="Specific variants"
                            checked={value === 'specific_variants'}
                            id="specific_variants"
                            name="discount_applies_on"
                            onChange={this.handleAppliesOn}
                            disabled={
                                this.props.user_current_plan.plan_permissions?
                                this.props.user_current_plan.plan_permissions[0].variants?
                                false:true
                                :false
                            }
                        />
                    </Stack>
                    <div>
                        <TextContainer>
                            <TextStyle variation="subdued">
                            {
                                value === 'entire_store' && 
                                <p>This will allow you to apply the Regular Discount to all products available in your Shopify store. <br></br><b>Example:</b> You can apply a 10% discount for all products. ​</p>
                            }
                            {
                                value === 'specific_collections' && 
                                <p>This will allow you to apply the Regular Discount to specify one or more collections.  
                                <br></br><b>Example:</b> You can apply a 10% discount to “shirts” products only.​ ​</p>
                            }
                            {
                                value === 'specific_products' && 
                                <p>You can use the “Specific Products” option to apply the Regular Discount for selected products of your choice. In this case the Regular Discount will apply to all variants of selected products.  ​</p>
                            }
                            {
                                value === 'specific_variants' && 
                                <p>You can use “Specific Variants” option to apply the Regular Discount at selective variant level. <br></br><b>Example:</b> A T-Shirt has 4 variants: Red, Green, Black, Blue. You have an option to apply a discount to one or more variants of the product. 
                                Let’s assume we apply a 10% discount to only  Red & Green,  in this case the 10% discount will not apply to the Black and Blue shirts. 
                                ​</p>
                            }
                            </TextStyle>
                        </TextContainer>
                    </div>
                    {/* For entire store */}
                    {
                       value === 'entire_store' &&  <Stack vertical spacing="extraTight">
                            <Checkbox
                                checked={exclude_collections_checkbox}
                                onChange={(value)=>{
                                    this.setState({
                                        exclude_collections_checkbox:value,
                                        exclude_collection_modal_open:value
                                    });
                                    
                                }}
                                label={
                                    <span>
                                        Exclude collections
                                        <Button size="slim" plain
                                            // disabled={!exclude_collections_checkbox}
                                            onClick={()=>{
                                                this.setState({
                                                    exclude_collection_modal_open:true
                                                });
                                                store('excludedCollections',this.state.excluded_collections);
                                            }}
                                        >
                                        [ +Add ]
                                        </Button>
                                        {
                                            excluded_collections.length > 0?
                                            <span>
                                                <Button size="slim" plain
                                                    disabled={!exclude_collections_checkbox}
                                                    onClick={()=>{
                                                        this.setState({
                                                            update_ex_collections__modal_open:true
                                                        });
                                                        store('excludedCollections',this.state.excluded_collections);
                                                    }}
                                                >
                                                [ Edit <label style={{borderBottom:'1px dotted'}}>{excluded_collections.length}</label> ]
                                                </Button>
                                            </span>
                                            :null
                                        }
                                    </span>
                                }
                            />
                            <Checkbox
                                checked={exclude_products_checkbox}
                                onChange={(value)=>{
                                    this.setState({
                                        exclude_products_checkbox: value,
                                        exclude_product_modal_open: value
                                    })
                                }}
                                label={
                                    <span>
                                        Exclude products
                                        <Button size="slim" plain
                                            // disabled={!exclude_products_checkbox}
                                            onClick={()=>{
                                                this.setState({
                                                    exclude_product_modal_open: true
                                                });
                                                store('excludedProducts',this.state.excluded_products);
                                            }}
                                        >
                                        [ +Add ]
                                        </Button>
                                        {
                                            excluded_products.length > 0?
                                            <span>
                                                <Button size="slim" plain
                                                    disabled={!exclude_products_checkbox}
                                                    onClick={()=>{
                                                        this.setState({
                                                            update_ex_products__modal_open:true
                                                        });
                                                        store('excludedProducts',this.state.excluded_products);
                                                    }}
                                                >
                                                [ Edit <label style={{borderBottom:'1px dotted'}}>{excluded_products.length}</label> ]
                                                </Button>
                                            </span>
                                            :null
                                        }
                                    </span>
                                }
                            />
                       </Stack>
                    }
                    {/* For collections */}
                    {
                        value === 'specific_collections' &&
                        <ButtonGroup>
                            <Button
                                primary
                                onClick= { () => {
                                    this.setState({collection_modal_open:true});
                                    store('collections',this.state.collections_array);
                                }}
                            > + Add Collections </Button>
                            {
                                this.state.collections.length > 0 &&
                                <Button
                                        onClick= {()=> {
                                            this.setState({update_collections__modal_open: true});
                                            store('collections',this.state.collections_array);
                                        }}
                                        plain={true}
                                    >
                                    &nbsp; [ Edit ({this.state.collections.length}) ]
                                </Button>
                            }
                        </ButtonGroup>
                    }
                    {
                        value === 'specific_collections' && this.state.collections.length > 0?
                        <ButtonGroup>
                            <Checkbox
                                checked={exclude_products_checkbox}
                                onChange={(value)=>{
                                    this.setState({
                                        exclude_products_checkbox: value,
                                        exclude_product_modal_open: value
                                    })
                                }}
                                label={
                                    <span>
                                        Exclude products
                                        <Button size="slim" plain
                                            // disabled={!exclude_products_checkbox}
                                            onClick={()=>{
                                                this.setState({
                                                    exclude_product_modal_open:true
                                                });
                                                store('excludedProducts',this.state.excluded_products);
                                            }}
                                        >[ +Add ]
                                        </Button>
                                        {
                                            excluded_products.length > 0?
                                            <span>
                                                <Button size="slim" plain
                                                    disabled={!exclude_products_checkbox}
                                                    onClick={()=>{
                                                        this.setState({
                                                            update_ex_products__modal_open:true
                                                        });
                                                        store('excludedProducts',this.state.excluded_products);
                                                    }}
                                                >
                                                [ Edit <label style={{borderBottom:'1px dotted'}}>{excluded_products.length}</label> ]
                                                </Button>
                                            </span>
                                            :null
                                        }
                                    </span>
                                }
                            ></Checkbox>
                        </ButtonGroup>
                        :null
                    }
                    {/* For Products */}
                    {
                        value === 'specific_products' &&
                        <ButtonGroup>
                            <Button
                                primary
                                onClick={ () => {
                                    this.setState({product_modal_open: true});
                                    store('products', this.state.products_array);
                                }}
                            > + Add Products </Button>
                            {
                                this.state.products.length > 0 &&
                                <Button
                                    onClick= {()=> {
                                        this.setState({update_products__modal_open: true});
                                        store('products', this.state.products_array);
                                    }}
                                    plain={true}
                                >
                                &nbsp;[ Edit <label style={{borderBottom:'1px dotted'}}>{this.state.products.length}</label> ]
                                </Button>
                            }
                        </ButtonGroup>
                    }
                    {/* For variants */}
                    {
                        value === 'specific_variants'?
                        this.props.user_current_plan.plan_permissions?
                            !this.props.user_current_plan.plan_permissions[0].variants?
                            <Banner status="warning" title="Action required!"
                                action={{content: "Upgrade plan to premium", onAction: this.upgrade }}
                            >
                            </Banner>
                                :value === 'specific_variants'?
                                    <ButtonGroup>
                                        <Button
                                            primary
                                            onClick = { () => {
                                                this.setState({product_modal_for_variant_open:true});
                                                store('variants', this.state.variants_array);
                                            }}
                                        > + Add Variants </Button>
                                        {
                                            this.state.variants.length > 0 &&
                                            <Button
                                                onClick= {()=> {
                                                    this.setState({update_variants__modal_open: true});
                                                    store('variants', this.state.variants_array);
                                                }}
                                                    plain={true}
                                                >
                                                &nbsp;[ Edit <label style={{borderBottom:'1px dotted'}}>{this.state.variants.length}</label> ]
                                            </Button>
                                        }
                                    </ButtonGroup>
                                :null
                            :null
                        :null
                    }
                    {/* Documentations */}
                </Stack>
            </Card>
        </div>;
        
        let step_four = <div>
            <Card sectioned title="Step 5: Schedule ( Optional )"
                actions={[{content:"Read tutorial", url:"https://digitalcoo.com/wholesale-all-in-one/", external: true}]}
            >
                <Stack vertical spacing="tight">
                    <div>
                        <TextContainer>
                            <TextStyle variation="subdued">You can set the time frame for the Regular Discount activation and expiration</TextStyle>
                            <br></br>
                            <TextStyle variation="subdued">Please note that the START DATE allows you to set the activation date and time and the SET END DATE allows you to set the expiration date and time. </TextStyle>
                        </TextContainer>
                    </div>
                    <Checkbox
                        checked={schedule_checked}
                        label="Schedule this discount rule"
                        onChange={(value)=>{
                            this.setState({schedule_checked:value})
                        }}
                    />
                    {
                        schedule_checked && <FormLayout>
                            <FormLayout.Group>
                                    <Popover
                                        active={start_date_popup}
                                        activator={start_date_activator}
                                        onClose={(value)=>{ this.setState({start_date_popup:false})}}
                                        preferredPosition="above"
                                        sectioned
                                    >
                                    {start_date_piker}
                                    </Popover>
                                    <Select
                                        label={'Start time (EST)'}
                                        placeholder="Select time"
                                        options={time_options}
                                        onChange={(value)=>{this.setState({start_time:value})}}
                                        value={start_time}
                                    >
                                    </Select>
                            </FormLayout.Group>
                        </FormLayout>
                    }
                    {
                        schedule_checked && <Checkbox
                            checked={schedule_end_date}
                            label="Set end date"
                            onChange={(value)=>{
                                this.setState({schedule_end_date:value})
                            }}
                        />
                    }
                    {
                        (schedule_checked && schedule_end_date) && 
                        <FormLayout>
                            <FormLayout.Group>
                                <Popover
                                    active={end_date_popup}
                                    activator={end_date_activator}
                                    onClose={(value)=>{this.setState({end_date_popup:false})}}
                                    preferredPosition="above"
                                    sectioned
                                >
                                {end_date_piker}
                                </Popover>
                                <Select
                                    label={'End time (EST)'}
                                    placeholder="Select time"
                                    options={time_options}
                                    onChange={(value)=>{this.setState({end_time:value})}}
                                    value={end_time}
                                    error={end_date_time_error?true:false}
                                />
                            </FormLayout.Group>
                        </FormLayout>
                    }
                </Stack>
            </Card>
        </div>;

        let resource_pikers_And_models = <span>
            {
                this.state.product_modal_open && 
                <ResourcePicker
                    open={this.state.product_modal_open}
                    onSelection={(resources) => {
                            let productsArray = [];
                            let productsTitleArray = [];
                            for(var j = 0; j < resources.selection.length; j++){
                                if(this.state.products.includes(resources.selection[j].id) === false){
                                    productsArray.push(resources.selection[j].id);
                                    productsTitleArray.push({
                                        id: resources.selection[j].id,
                                        title: resources.selection[j].title,
                                        variants: resources.selection[j].variants,
                                        handle: resources.selection[j].handle,
                                        tags: resources.selection[j].tags,
                                        vendor: resources.selection[j].vendor,
                                        image: resources.selection[j].image,
                                    });
                                }
                            }
                            let prod = productsArray.concat(this.state.products);
                            let prod_title = productsTitleArray.concat(this.state.products_array);
                            this.setState({
                                products: prod,
                                products_array: prod_title,
                                product_modal_open: false
                            });
                        }
                    }
                    onCancel={() => this.setState({product_modal_open: false})}
                />
            }
            {
                this.state.collection_modal_open && 
                <ResourcePicker
                    resourceType="Collection"
                    open={this.state.collection_modal_open}
                    onSelection={(resources) => {
                            let collectionArray = [];
                            let collectionsTitleArray = [];
                            for(var i=0;i<resources.selection.length;i++){
                                if(this.state.collections.includes(resources.selection[i].id) === false){
                                    collectionArray.push(resources.selection[i].id);
                                    collectionsTitleArray.push({
                                        id: resources.selection[i].id,
                                        title: resources.selection[i].title,
                                        image: resources.selection[i].image,
                                        handle: resources.selection[i].handle
                                    });
                                }
                            }
                            let collect = collectionArray.concat(this.state.collections);
                            let collect_title = collectionsTitleArray.concat(this.state.collections_array);
                            this.setState({
                                collections: collect,
                                collections_array:collect_title,
                                collection_modal_open: false
                            });
                        }
                    }
                    onCancel={() => this.setState({collection_modal_open: false})}
                />
            }
            {
                this.state.product_modal_for_variant_open && 
                <ResourcePicker
                    open={this.state.product_modal_for_variant_open}
                    onSelection={ resources => {
                            this.setState({
                                selected_products_variant_picker_open: true
                            });
                            store('selected_products', resources.selection);
                        }
                    }
                    onCancel={() => this.setState({product_modal_for_variant_open: false})}
                />
            }
            {
                this.state.variant_modal_open && 
                <Modal
                size="Medium"
                src={'/variant-picker'}
                open={this.state.variant_modal_open}
                onClose={() => this.setState({variant_modal_open: false})}
                title={'Select variants'}
                primaryAction={{
                    content: 'Select variants',
                    onAction: this.handleSubmitSelectedVariants,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: () => { this.setState({variant_modal_open: false})},
                    },
                ]}
                sectioned={false}
            >
            </Modal>
            }
            
            {
                this.state.selected_products_variant_picker_open && 
                <Modal
                size="Medium"
                src={'/selected-products-variant-picker'}
                open={this.state.selected_products_variant_picker_open}
                onClose={() => this.setState({
                    selected_products_variant_picker_open: false,product_modal_for_variant_open: false
                })}
                title={'Select variants'}
                primaryAction={{
                    content: 'Select variants',
                    onAction: this.handleSubmitSelectedVariants,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: () => { this.setState({
                            selected_products_variant_picker_open: false,product_modal_for_variant_open: false
                        })},
                    },
                ]}
                sectioned={false}
            >
            </Modal>
            }
            
            {
                this.state.update_products__modal_open && 
                <Modal
                size="Medium"
                src={'/update-products-modal'}
                open={this.state.update_products__modal_open}
                onClose={() => this.setState({update_products__modal_open: false})}
                title={'Products'}
                primaryAction={{
                    content: 'Update',
                    onAction: this.handleSubmitUpdateProducts,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: () => {this.setState({update_products__modal_open: false})},
                    }
                ]}
                sectioned={false}
            >
            </Modal>
            }
            {
                this.state.update_collections__modal_open && 
                <Modal
                size="Medium"
                src={'/update-collections-modal'}
                open={this.state.update_collections__modal_open}
                onClose={() => this.setState({update_collections__modal_open: false})}
                title={'Collections'}
                primaryAction={{
                    content: 'Update',
                    onAction: this.handleSubmitUpdateCollections,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: () => {this.setState({update_collections__modal_open: false})},
                    }
                ]}
                sectioned={false}
            >
            </Modal>
            }
            {
                this.state.update_variants__modal_open && 
                <Modal
                size="Medium"
                src={'/update-variants-modal'}
                open={this.state.update_variants__modal_open}
                onClose={() => this.setState({update_variants__modal_open: false})}
                title={'Variants'}
                primaryAction={{
                    content: 'Update',
                    onAction: this.handleSubmitUpdateVariants,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: () => {this.setState({update_variants__modal_open: false})},
                    }
                ]}
                sectioned={false}
            >
            </Modal>
            }
            
        </span>;
        
        let exclude_resource_pikers = <span>
            {
                this.state.exclude_product_modal_open && 
                <ResourcePicker
                    open={this.state.exclude_product_modal_open}
                    onSelection={(resources) => {
                        if(resources.selection.length > 0){
                            let tem_array = [];
                            resources.selection.forEach(product =>{
                                if(excluded_products.length > 0){
                                    if(excluded_products.findIndex(x=>x.id === product.id) === -1){
                                        tem_array.push({
                                            id: product.id,
                                            title: product.title,
                                            variants: product.variants,
                                            handle: product.handle,
                                            tags: product.tags,
                                            vendor: product.vendor,
                                            image: product.image,
                                        });
                                    }
                                }
                                else{
                                    tem_array.push({
                                        id: product.id,
                                        title: product.title,
                                        variants: product.variants,
                                        handle: product.handle,
                                        tags: product.tags,
                                        vendor: product.vendor,
                                        image: product.image,
                                    });
                                }
                            });
                            this.setState({
                                excluded_products: excluded_products.concat(tem_array),
                                exclude_product_modal_open: false,
                                update_ex_products__modal_open: false,
                                exclude_products_checkbox: tem_array.length>0?true:false
                            })
                        }
                    }}
                    onCancel={() => this.setState({exclude_product_modal_open: false})}
                />
            }
            {
                this.state.exclude_collection_modal_open && 
                <ResourcePicker
                    resourceType="Collection"
                    open={this.state.exclude_collection_modal_open}
                    onSelection={(resources) => {
                        if(resources.selection.length > 0){
                            let tem_array = [];
                            resources.selection.forEach(collection =>{
                                if(excluded_collections.length > 0){
                                    if(excluded_collections.findIndex(x=>x.id === collection.id) === -1){
                                        tem_array.push({
                                            id: collection.id,
                                            title: collection.title,
                                            handle: collection.handle,
                                            image: collection.image,
                                        });
                                    }
                                }
                                else{
                                    tem_array.push({
                                        id: collection.id,
                                        title: collection.title,
                                        handle: collection.handle,
                                        image: collection.image,
                                    });
                                }
                            });
                            this.setState({
                                excluded_collections: excluded_collections.concat(tem_array),
                                exclude_collection_modal_open: false,
                                update_ex_collections__modal_open: false,
                                exclude_collections_checkbox: tem_array.length>0?true:false
                            })
                        }
                    }}
                    onCancel={() => this.setState({exclude_collection_modal_open: false})}
                />
            }
            {
                this.state.update_ex_products__modal_open && 
                <Modal
                size="Medium"
                src={'/update-excluded-products'}
                open={this.state.update_ex_products__modal_open}
                onClose={() => this.setState({update_ex_products__modal_open: false})}
                title={'Excluded products'}
                primaryAction={{
                    content: 'Update',
                    onAction: this.handleSubmitUpdateExcProducts,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: () => {this.setState({update_ex_products__modal_open: false})},
                    },
                ]}
                sectioned={false}
            >
            </Modal>
            }
            {
                this.state.update_ex_collections__modal_open && 
                <Modal
                size="Medium"
                src={'/update-excluded-collections'}
                open={this.state.update_ex_collections__modal_open}
                onClose={() => this.setState({update_ex_collections__modal_open: false})}
                title={'Excluded collections'}
                primaryAction={{
                    content: 'Update',
                    onAction: this.handleSubmitUpdateExcCollections,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: () => {this.setState({update_ex_collections__modal_open: false})},
                    },
                ]}
                sectioned={false}
            >
            </Modal>
            }
            
        </span>;
        
        let create_discount_button = <PageActions
            primaryAction={{
            content: 'Save Regular Discount',
            disabled: 
                this.state.min_quantity_requirment?
                this.state.discount_type === 'fixed_price'? 
                this.state.settings.discount_method === 'line_items'?
                true:false:false:false
            ,
            loading: this.state.save_discount_rule,
            onAction: () =>{ this.handleSubmit(); }
            }}
            secondaryActions={[
            {
                content: "Cancel",
                target: "APP",
                url: "/general-discount-groups"
            }
            ]}
        ></PageActions>;

        var toast = this.state.showToast? (
            <ToastMessage
              onDismiss={this.dissmissToast}
              message={this.state.toastMsg}
              error={this.state.toastError}
            />
          ): (null);
          

        return(
            <div>
                <PageTitleBar
                    title={this.props.show === "edit"?"Edit":"Create"}
                    primaryAction={{
                        content:"Save Regular Discount", onAction:()=>{this.handleSubmit();},
                        disabled:
                            this.state.__minq !== '' && parseInt(this.state.__minq) > parseInt(this.state.__dq) ? true :
                            this.state.__minq !== '' && parseInt(this.state.__minq) % parseInt(this.state.__qi) !== 0 ? true : this.state.save_discount_rule
                        ,
                    }}
                    secondaryActions={[
                        { content: 'Back', url: '/pricing-groups', target: "APP" },
                    ]}
                />
                {toast}
                <div>
                    {
                        errors.length > 0?
                        <div style={{margin: "2rem 0rem"}}>
                            <Banner status="critical" title={<span>There {errors.length > 1 ? "are" : "is"} {errors.length} {errors.length > 1 ? "errors" : "error"} in discount rule</span>}>
                                <List type="number">
                                    {
                                        errors.map((error, key) => {
                                            return <List.Item key={key}>{error}</List.Item>;
                                        })
                                    }
                                </List>
                            </Banner>
                        </div>
                        :null
                    }
                    {resource_pikers_And_models}
                    {exclude_resource_pikers}
                    <Layout>
                        <Layout.Section>{internal_name_section}</Layout.Section>
                        <Layout.Section>{step_one}</Layout.Section>
                        <Layout.Section>{step_two}</Layout.Section>
                        <Layout.Section>{step_three_new}</Layout.Section>
                        <Layout.Section>{step_four}</Layout.Section>
                        <Layout.Section>{create_discount_button}</Layout.Section>
                    </Layout>
                    <div style={{marginBottom:'8px'}}></div>
                    <FooterHelpLinks help={this.props.help}/>
                </div>
            </div>
        )
    }
}

function getValue(value){
	if(value){
		try {
            value = value.toString();
        } catch (e) { }
    }
    return value;
}