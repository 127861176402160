import React from 'react';
import { Card,Layout,ResourceItem,TextStyle,ResourceList,Stack,Icon } from '@shopify/polaris';
import { CircleTickOutlineMinor } from '@shopify/polaris-icons';
import GeneralSettings from './GeneralSettings';

export default class GeneralSettingsDashboard extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            selected_id:'general'
        }
    }

    render(){
        const tabs = [
            {
              id: 'general',
              content: 'General',
              url:'#',
              icon:CircleTickOutlineMinor,
            },
            {
              id: 'discount_method',
              content: 'Discount Method',
              url:'#',
              icon:CircleTickOutlineMinor,
            },
            {
              id: 'sale_clock',
              content: 'Sale Clock',
              url:'#',
              icon:CircleTickOutlineMinor,
            }
          ];

        return(
            <div className="admin_area_waio">
                <div>
                    <Layout>
                        <Layout.Section>
                            <Card>
                            <Card.Header
                                title="Settings"
                            />
                            <div className="admin_panel_settings">
                                <div className="admin_leftSide">
                                    <Card.Section>
                                        <ResourceList
                                            items={tabs}
                                            renderItem={(item) => {
                                            const {id, content,url,icon} = item;
                                            return (
                                                <div className={"grid_items gs-tabs-2021-02"}>
                                                    <ResourceItem
                                                    id={(this.state.selected_id===id ? "selected_tab":id)}
                                                    onClick={()=>{
                                                        this.setState({selected_id:id});
                                                    }}
                                                    >
                                                    <Stack alignment="center" spacing="tight">
                                                        <Icon source={icon} color={(this.state.selected_id===id ?("green"):("black"))} />
                                                        <TextStyle variation="strong">{content}</TextStyle>
                                                    </Stack>
                                                    </ResourceItem>
                                                </div>
                                            );
                                            }}
                                        />
                                    </Card.Section>
                                </div>
                                <div id="admin_righSide">
                                    <Card.Section>
                                    {this.state.selected_id === "general" &&
                                        <div className="grid_tabs">
                                            <GeneralSettings selected_tab={0} snippet_version={this.props.snippet_version} shop={this.props.shop} host_name={this.props.host_name} shop_features={this.props.shop_features} shop_id={this.props.shop_id} change_setting={this.props.change_setting} help={this.props.help} />
                                        </div>
                                    }
                                    {this.state.selected_id === "discount_method" &&
                                        <div className="grid_tabs">
                                            <GeneralSettings selected_tab={1} snippet_version={this.props.snippet_version} shop={this.props.shop} host_name={this.props.host_name} shop_features={this.props.shop_features} shop_id={this.props.shop_id} change_setting={this.props.change_setting} help={this.props.help} />
                                        </div>
                                    }
                                    {this.state.selected_id === "sale_clock" &&
                                        <div className="grid_tabs">
                                            <GeneralSettings selected_tab={2} snippet_version={this.props.snippet_version} shop={this.props.shop} host_name={this.props.host_name} shop_features={this.props.shop_features} shop_id={this.props.shop_id} change_setting={this.props.change_setting} help={this.props.help} />
                                        </div>
                                    }
                                    </Card.Section>
                                </div>
                            </div>
                            </Card>
                        </Layout.Section>
                    </Layout>
                </div>
            </div>
        );
    }
}