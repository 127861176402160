import React, { Component } from 'react';
import axios from 'axios';
import { Card, Stack, Badge, Link, TextStyle, Banner } from '@shopify/polaris';
import SkeletonPageComp from './SkeletonPageComp';

export default class TrackOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      host_name: this.props.host_name,
      shop: this.props.shop,
      page_loaded: false,
      orders: []
    };
  }
  componentDidMount() {
    if (this.props.shop) {
      axios.get(this.state.host_name + '/api/tracking/additional/' + this.props.shop)
        .then(response => {
          if (response.data.metafields.length > 0) {
            if (response.data.metafields[0].key === "additional_discounts") {
              var orders = JSON.parse(response.data.metafields[0].value);
              var new_orders = [];
              orders.forEach((data, i) => {
                if (new_orders[data.code] === void 0) { new_orders[data.code] = []; }
                new_orders[data.code].push({
                  "order_id": data.order_id,
                  "order_revenue": parseFloat(data.order_revenue)
                })
              });
              this.setState({ orders: new_orders, page_loaded: true });
            } else {
              this.setState({ page_loaded: true });
            }
          } else {
            this.setState({ page_loaded: true });
          }

        })
        .catch(error => {
          // console.log("Error");
          this.setState({ page_loaded: true });
        });
    }
  }
  render() {

    var _orderLen = Object.keys(this.state.orders).length;
    this.items = <Card title="Additional Discounts">{Object.keys(this.state.orders).map((d, i) => {
      var _e = this.state.orders[d];
      var _r = _e.map((_rv, _ri) => _rv.order_revenue / 100);
      const _add = (a, b) => a + b;
      // use reduce to sum our array
      const _revenue = _r.reduce(_add)
      var _t = <Stack>
        <TextStyle variation="strong">{d}&nbsp;
        <Badge size="small" status="attention">{_e.length}</Badge>
          <Badge size="small" status="success">{_revenue}</Badge>
        </TextStyle>
      </Stack>
      return <Card.Section subdued title="" key={i}>
        {_t}
        <br />
        <Stack>

          {

            _e.map((f, j) => <Badge key={j}>
              <Link
                url={window.location.protocol + "//" + this.state.shop + "/admin/orders/" + f.order_id}
                external={true}>
                {f.order_id}
              </Link>
            </Badge>
            )

          }

        </Stack>
      </Card.Section>

    })
    }
    </Card>
    if (_orderLen <= 0) {
      this.items = <Banner title="No additional discounts found" status="critical">
      </Banner>
    }
    if (this.state.page_loaded) {
      return (
        <div>
          {this.items}
        </div>
      )
    } else {
      return (
        <SkeletonPageComp />
      )
    }
  }
}