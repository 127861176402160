import React, { Component } from 'react';
import axios from 'axios';
import {
  Card,ResourceList,TextStyle,Button,Layout,EmptyState,Badge,Tooltip,ButtonGroup,Pagination, ResourceItem
} from '@shopify/polaris';
import FooterHelpLinks from '../../FooterHelpLinks';
import ToastMessage from '../../../Common/ToastMessage';
import PageTitleBar from '../../../Common/TitleBar';
import SkeletonPageComp from '../../SkeletonPageComp';
import {Modal} from '@shopify/app-bridge-react';
import Common from '../../../Helpers/Common';
import waio from '../../../Helpers/functions';
import { DeleteMinor, ExportMinor } from '@shopify/polaris-icons';

var interval = 0;
export default class RegularDiscountList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      qb_data: [],
      filter_qb_data: [],
      host_name: this.props.host_name,
      shop_id: this.props.shop_id,
      searchValue: '',
      appliedFilters: [
        // {
        //   key: 'accountStatusFilter',
        //   value: 'Account enabled',
        // },
      ],
      shop: this.props.shop,
      page_loaded: false,
      est_time: undefined,
      selected_quantity_breaks: [],
      selected_quantity_breaks_keys: [],
      enable_btn_loading: false,
      disable_btn_loading: false,
      delete_btn_loading: false,
      loading: false,
      import_qb_modal: false,
      sort_by: 'DATE_CREATED_DESC',
      pagination_has_next: false,
      pagination_has_previous: false,
      totalPages: 1,
      pageNumber: 1,
      showToast: false,
      toastError: false,
      toastMsg: ""
    };
    axios.defaults.headers.common['X-Ws-Shop-Domain'] = "https://"+this.props.shop;
    this.handleSortChange = this.handleSortChange.bind(this);
  }
  showToastMessage(msg, error){
    this.setState({
      showToast: true,
      toastMsg: msg,
      toastError: error||false,
    });
  }
  
  dissmissToast = () =>{
    this.setState({
      showToast: false,
    });
  }
  handleSortChange(sort_by) {
    this.setState({ sort_by });
    this.fetch_all_qb(sort_by);
  }
  handleSelectionChange = (selectedItems) => {
    var temp_items = [];
    var temp_keys = [];
    if(selectedItems.length > 0){
      selectedItems.forEach(x=>{
        temp_items.push(this.state.qb_data[x]._id);
        let keys = this.state.qb_data[x].meta_keys;
        try{
          keys = keys.split(",");
        }catch(e){console.log(e)}
        temp_keys = temp_keys.concat(keys);
      })
    }
    this.setState({
      selectedItems:selectedItems,
      selected_quantity_breaks: temp_items,
      selected_quantity_breaks_keys: temp_keys
    });
    this.fetch_est_time();
  };
  renderItem = (item,id) => {
    const{_id,title,customer_group,tags,discount_type,discount_value,applies_to,products,collections,state,individual_product}=item;
    var url = '/regular-discount/edit/'+_id;
    var actionButtons = [
      {content: 'Edit', target:'APP' ,url: '/regular-discount/edit/'+_id}
    ];
    if(state === 'published'){
      actionButtons.push({
        content: 'Unpublish', onAction: () =>{ this.change_quantity_break(item,"unpublished");}
      });
    }
    else{
      actionButtons.push({
        content: 'Publish', onAction: () =>{ this.change_quantity_break(item,"published"); }
      });
    }

    if(item.individual_product === true && item.applies_to === "products"){
      actionButtons.push({
        icon: ExportMinor,
        onAction: () => {
          window.top.location.href = "https://wholesaleheavyload.digitalcoo.com/ie/export/csv/regular-discount?shop="+this.props.shop+"&type=bulk&id="+item._id;
        }
      })
    }

    if(item.applies_to === "collections"){
      actionButtons.push({
        icon: ExportMinor,
        onAction: () => {
          window.top.location.href = "https://wholesaleheavyload.digitalcoo.com/ie/export/csv/regular-discount/export-collections?shop="+this.props.shop+"&type=bulk&id="+item._id; 
        }
      })
    }
    actionButtons.push({
      icon: DeleteMinor, onAction: ()=>{ this.delete_qb(item)}
    })

    const shortcutActions = applies_to ?actionButtons:null;

    const mainTitleSection = (
      <div className="CustomerListItem__Profile ">
        <h3 className="CustomerListItem__Title">
        <TextStyle variation="strong">{
            <span>
              { title || "No title" } { item.channel === "import" && <Badge>via Import</Badge> }
            </span>
          }</TextStyle>
        </h3>
        <div className="CustomerListItem__Location">
            <TextStyle variation="subdued">
              { this.getAppliesToSection(item) }
            </TextStyle>
        </div>
        <div className="CustomerListItem__Location">
          <span>
            <TextStyle variation="subdued">
              {
                ((customer_group === "has_tag" && item.individual_product) || customer_group === "has_tag") ?
                (tags.length > 1?"Customer tags: ":"Customer tag: "):
                customer_group === "all"?"All customers":
                customer_group === "has_account"?"Logged in customers":
                customer_group === "no_account"?"Guest customers":
                " "
              }
              {
                ((customer_group === "has_tag" && item.individual_product) || customer_group === "has_tag") && <span>{ tags.toString() }</span>
              }
            </TextStyle>
          </span>
        </div>
      </div>
    );

    const contentSection = (
      <div className="CustomerListItem__Status">
        <div className="CustomerListItem__StatusBadge">
          {
            item.schedule_active ?
            this.schedule_ends_in(item,id)
            :item.state === 'published'?
            <Badge status="success"> Published </Badge>:
            item.state==='unpublished'?<Badge> Unpublished </Badge>
            :null
          }
        </div>
      </div>
    );

    return (
      <ResourceItem 
        id={id}
        url={url}
        shortcutActions={shortcutActions}
      >
        <div className="CustomerListItem__Main">
          {mainTitleSection}
          {contentSection}
        </div>
      </ResourceItem>
    );

  };

  getAppliesToSection(item){
    var _html = '';
    if(item){
      if(item.applies_to === "products"){
        if(item.individual_product === true){
          _html += (item.products.length > 1?"Individual products":"Individual product");
        }
        else{
          _html += (item.products.length > 1?"Products":"Product");
        }
        _html += " ("+(item.products.length)+")";
      }
      else if(item.applies_to === "collections"){
        _html += (item.collections.length > 1?"Collections":"Collection");
        _html += " ("+(item.collections.length)+")";
      }
      else if(item.applies_to === "entire_store"){
        _html = "Entire store";
      }
      if(item.individual_product === false){
        if(item.discount_type === "percentage"){
          try {
            _html += " | "+(parseInt(item.discount_value))+"% Off";
          } catch (e) { }
        }
        else if(item.discount_type === "price_discount"){
          _html += " | "+(waio.Currency.format_money(Number(item.discount_value).toFixed(2)))+" Off";
        }
        else if(item.discount_type === "fixed_price"){
          _html += " | New Price: "+(waio.Currency.format_money(Number(item.discount_value).toFixed(2)));
        }
      }
      // waio.Currency.format_money
      //price_discount
      //fixed_price
    }
    return _html;
  }

  componentDidMount() {
    this.clearAllInterval(interval);
    this.fetch_all_qb(this.state.sort_by);
  }

  componentWillUnmount() {
    this.clearAllInterval(interval);
  }

  componentDidCatch(){
    this.clearAllInterval(interval);
  }

  clearAllInterval = (interval) =>{
    for (var i = 1; i <= interval; i++){
      clearInterval(i);
    }
  };

  fetch_all_qb(sort_by, page_request){
    this.clearAllInterval(interval);
    let pageNumber = this.state.pageNumber || 1;
    if(page_request === "prev"){
      pageNumber = Number(pageNumber)-1;
      this.setState({loading: true});
    }
    else if(page_request === "next"){
      pageNumber = Number(pageNumber)+1;
      this.setState({loading: true});
    }
    axios.get(this.state.host_name+'/app/regular-discounts?shop='+this.props.shop+'&page='+pageNumber+"&sort_by="+sort_by)
    .then(response => {
      var data = response.data.vd;
      var count = response.data.count;
      var totalPages = Math.ceil(Number(count)/20);
      var pagination_has_next = false;
      var pagination_has_previous = false;
      if( Number(Number(pageNumber)*20) < Number(count)){
        pagination_has_next = true;
      }
      if(Number(pageNumber) > 1){
        pagination_has_previous = true;
      }
      this.setState({
          pageNumber: pageNumber,
          totalPages: totalPages,
          pagination_has_next: pagination_has_next,
          pagination_has_previous: pagination_has_previous,
          page_loaded: true,
          qb_data: data,
          loading: false,
          filter_qb_data: data,
          selectedItems: []
      });
      this.fetch_est_time();
    })
    .catch(error => {
      this.setState({loading:false});
      this.showToastMessage("Error", true);
      this.fetch_est_time();
    });
  }

  fetch_est_time(){
    this.clearAllInterval(interval);
    axios.get(this.state.host_name+'/est-time-now/v2')
    .then(time=>{
      this.clearAllInterval(interval);
      this.setState({est_time:time.data.current_est_time});
    }).catch(err=>{
    });
  }

  delete_qb(item){
    if(window.confirm("Delete Wholesale Pricing?")){
      const newData = this.state.qb_data.filter(i => i._id !== item._id);
      this.setState({qb_data:newData,loading:false});
      this.setState({loading:true});
      this.fetch_est_time();
      if(item.metafield_ids){
        axios.delete(this.state.host_name+'/app/regular-discount?id='+item._id+'&shop='+this.props.shop+'&metafield_ids='+item.metafield_ids)
        .then( response => {
            if(response.data.status === 1){
              this.showToastMessage(response.data.message);
              this.fetch_all_qb(this.state.sort_by);
            }
            else{
              this.setState({loading:false});
              this.showToastMessage(response.data.message, true);
              this.fetch_est_time();
            }
            this.setState({selectedItems: []});
          }
        ).catch(err => {
          this.setState({loading:true});
          this.fetch_est_time();
        });
      }
      else{
      axios.delete(this.state.host_name+'/app/regular-discount?id='+item._id+'&shop='+this.props.shop+'&keys='+item.meta_keys)
      .then( response => {
          if(response.data.status === 1){
            this.showToastMessage(response.data.message);
            this.fetch_all_qb(this.state.sort_by);
          }
          else{
            this.setState({loading:false});
            this.showToastMessage(response.data.message, true);
            this.fetch_est_time();
          }
          this.setState({selectedItems: []});
        }
      ).catch(err => {
        this.setState({loading:true});
        this.fetch_est_time();
      });
    }
  }
}

  change_quantity_break(item, state){
      this.setState({loading:true});
      this.fetch_est_time();
      try{
        item["state"] = state || "published";
      }catch(e){}
      axios.put(this.state.host_name+'/app/regular-discount?id='+item._id,item)
      .then( response => {
          if(response.data.status === 1){
            this.showToastMessage(response.data.message);
            this.fetch_all_qb(this.state.sort_by);
          }else{
            this.setState({loading:false});
            this.fetch_est_time();
            this.showToastMessage(response.data.message, true);
          }
          this.setState({selectedItems: []});
        }
      ).catch(err => {
        this.setState({loading:false});
        this.fetch_est_time();
        this.showToastMessage("Error", true);
      });
  }

  getTimezoneOffset() {
    try{
      function z(n){return (n<10? '0' : '') + n}
      var offset = new Date().getTimezoneOffset();
      var sign = offset < 0? '+' : '-';
      offset = Math.abs(offset);
      return sign + z(offset/60 | 0) + z(offset%60);
    }catch(err){}
  }

  get_est_dateTime(date,time){
    try{
      let dateTimeValue = new Date(date);
      if(typeof dateTimeValue === 'object'){
        dateTimeValue = dateTimeValue.toString();
        if(typeof dateTimeValue === 'string'){
          dateTimeValue = dateTimeValue.replace(this.getTimezoneOffset(), '+0000');
        }
      }
      dateTimeValue = new Date(dateTimeValue).toISOString();
      dateTimeValue = dateTimeValue.split('T')[0] + " " +time;
      return dateTimeValue;
    }catch(err){}
  }

  schedule_ends_in(item,counter){
    let clockId = 'id'+counter;
    let _html = null;
    // <span id={'id'+id} className="custom-badge"></span>
    if(item.schedule){
      var _schedule = Common.getSchedule(item.schedule);
      if(
        Number(_schedule.ends_in.years) <= 0 && 
        Number(_schedule.ends_in.months) <= 0 && 
        Number(_schedule.ends_in.days) <= 0 && 
        Number(_schedule.ends_in.hours) <= 0 && 
        Number(_schedule.ends_in.minutes) <= 0 && 
        Number(_schedule.ends_in.seconds) <= 0 
      ){
        _html = <Badge> Expired </Badge>
      }
      else if(
        Number(_schedule.started.years) >= 0 && 
        Number(_schedule.started.months) >= 0 && 
        Number(_schedule.started.days) >= 0 && 
        Number(_schedule.started.hours) >= 0 && 
        Number(_schedule.started.minutes) >= 0 && 
        Number(_schedule.started.seconds) >= 0 
      ){
        _html = <Badge status="info"> Scheduled </Badge>
      }
      else{
        if(item.state === 'published'){
          _html = <Badge status="success"> Published </Badge>;
        }
        else if(item.state==='unpublished'){
          _html = <Badge> Unpublished </Badge>;
        }
      }
    }
    return _html;
  }

  countDown(end,clockId){
    let result;
    let est_time = this.state.est_time;
    let start = new Date(est_time.year,est_time.month,est_time.date,est_time.hours,est_time.minutes,est_time.seconds,est_time.milliseconds);
    try{
      if(typeof start !== 'object' || typeof end !== 'object'){return undefined; }
      start = start.getTime();
      end = end.getTime();
      var timeSpan = end - start;
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(timeSpan / (1000 * 60 * 60 * 24));
      var hours = Math.floor((timeSpan % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((timeSpan % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((timeSpan % (1000 * 60)) / 1000);
      result = {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds
      };
      if(result.seconds < 0){
        result.minutes--;
        result.seconds += 60;
      }
      if(result.minutes < 0){
        result.hours--;
        result.minutes += 60;
      }
      if(result.hours < 0){
        result.days--;
        result.hours += 24;
      }
      if(result.days > 0 || result.hours > 0 || result.minutes > 0 || result.seconds > 0){
        interval = setInterval(startCountDown,1000);
      }
      function startCountDown(){
        result.seconds--;
        if(result.seconds < 0){
          result.minutes--;
          result.seconds += 60;
        }
        if(result.minutes < 0){
          result.hours--;
          result.minutes += 60;
        }
        if(result.hours < 0){
          result.days--;
          result.hours += 24;
        }
        if(result.days < 0 || result.hours < 0 || result.minutes < 0 || result.seconds < -1){
          clearInterval(interval);
          if(clockId !== null){
            try{
              let CLOCKID = document.getElementById(clockId);
              if(CLOCKID !== null){
                CLOCKID.innerHTML = '';
              }
            }catch(e){}
          }
        
        }
        else{
          let ends_message = '';
          if(result.days > 0){
            ends_message += result.days+'d ';
          }
          if(result.hours > 0){
            ends_message += result.hours+'h ';
          }
          if(result.minutes > 0){
            let minutes = result.minutes;
            if(result.minutes < 10){
              minutes = '0'+minutes;
            }
            ends_message += minutes+'m ';
          }
          if(result.seconds >= 0){
            let seconds = result.seconds;
            if(result.seconds < 10){
              seconds = '0'+seconds;
            }
            ends_message += seconds+'s ';
          }
          if(ends_message !== ''){
            if(clockId !== null){
              try{
                let CLOCKID = document.getElementById(clockId);
                if(CLOCKID !== null){
                  CLOCKID.innerHTML = ends_message;
                }
              }catch(e){
                clearInterval(interval);
              }
            }
          }
        }
        window.onbeforeunload = function(){
          clearInterval(interval);
        };
      }
      return result;
    }
    catch(e){
      clearInterval(interval);
    }
  }

  doNotUpdate = (e) => {
    e.preventDefault();
  }

  upgrade = () => {
    this.props.history.push('/pricing-plans');
  }

  bulk_delete = (selected_items) => {
    if(window.confirm("Delete all selected rules permanently?")){
      this.setState({loading:true});
      this.fetch_est_time();
      axios.put(this.state.host_name+'/app/bulk-delete-regular-discount?shop='+this.props.shop,{
        selected_items: selected_items,
        shop: this.props.shop,
        keys: (this.state.selected_quantity_breaks_keys).toString()
      })
      .then( response => {
          if(response.data.status === 1){
            this.showToastMessage(response.data.message);
            this.fetch_all_qb(this.state.sort_by);
          }
          else{
            this.showToastMessage(response.data.message, true);
            this.setState({loading:false});
            this.fetch_est_time();
          }
          this.setState({selectedItems: []});
        }
      ).catch(err => {
        this.showToastMessage("Error", true);
        this.setState({loading:false});
        this.fetch_est_time();
      });
    }
  }

  handleSearchChange = (searchValue) => {
    // console.log(this.state.qb_data);
    var _data = this.state.qb_data;
    _data = _data.filter((x)=>x.title.toLowerCase().indexOf(searchValue.toLowerCase())!==-1);
    // console.log(_data);
    this.setState({
      filter_qb_data: _data,
      searchValue:searchValue
    });
  };

  handleFiltersChange = (appliedFilters) => {
    // this.setState({appliedFilters});
    var _data = this.state.qb_data;
    
    _data = _data.filter((x)=> appliedFilters.findIndex((y)=>y.key==="tagBased" && y.value.toLowerCase().indexOf(x.customer_tag.toLowerCase()!==-1))!==-1);
    // console.log(_data);
    this.setState({
      filter_qb_data: _data,
      appliedFilters:appliedFilters
    });
  };

  render() {
    const {sort_by} = this.state;
    this.clearAllInterval(interval);
    const add_qb_buttons = <div style={{margin:"1rem 0rem"}}>
      <ButtonGroup>
        <Button primary size="slim" url="/regular-discount/create" target="APP">Create Wholesale Pricing</Button>
        {
          this.props.shop_features && this.props.shop_features.findIndex(x=>x === "import_export") > -1 &&
          <Button outline size="slim" onClick={()=>{
            this.setState({import_qb_modal:true});
            this.fetch_est_time();
          }}
          >
          Import
          </Button>
        }
      </ButtonGroup>
    </div>
    const resourceName = {
      singular: 'Wholesale Pricing Rule',
      plural: 'Wholesale Pricing Rules',
    };
    const sortOptions = [
      { label: 'Newest', value: 'DATE_CREATED_DESC' },
      { label: 'Oldest', value: 'DATE_CREATED_ASC' },
      { label: 'Most used', value: 'MOST_USED_DESC' },
      { label: 'Least used', value: 'MOST_USED_ASC' }
    ];
    const promotedBulkActions = [
      {
        content: 'Delete selected',
        onAction: () => {
          this.bulk_delete(this.state.selected_quantity_breaks);
        }
      }
    ];
    const importModalSection = <div>
      <Modal
        size="Medium"
        src={'/import-regular-discount'}
        open={this.state.import_qb_modal}
        onClose={() => {
          this.setState({import_qb_modal: false, loading: true});
          this.clearAllInterval(interval);
          this.fetch_all_qb(this.state.sort_by);
          this.fetch_est_time();
        }}
        secondaryActions={[
          {
            content: "Close",
            onAction: () => {
              this.setState({import_qb_modal: false, loading: true});
              this.clearAllInterval(interval);
              this.fetch_all_qb(this.state.sort_by);
              this.fetch_est_time();
            }
          }
        ]}
        title={'Import Wholesale Pricing by CSV file'}
      >
      </Modal>
    </div>;
    var emptySecondaryAction = null;
    if(this.props.shop_features){
      if(this.props.shop_features.findIndex(x=>x === "import_export") > -1){
        emptySecondaryAction = {content: 'Import Wholesale Pricing',onClick: ()=>{
            this.setState({import_qb_modal:true});
            this.fetch_est_time();
          }
        };
      }
    }

    var toast = this.state.showToast? (
      <ToastMessage
      onDismiss={this.dissmissToast}
      message={this.state.toastMsg}
      error={this.state.toastError}
      />
    ): (null);

    if(this.state.page_loaded === false){
      return(
        <div>
          <PageTitleBar
              title="Wholesale Pricing"
            />
          <SkeletonPageComp />
        </div>
      );
    }
    else{
      if(this.state.qb_data.length < 1){
        return(
          <div>
            {importModalSection}
            {toast}
            <Layout>
            <EmptyState
              heading="Create Wholesale Pricing"
              action={{content: 'Create Wholesale Pricing', url: '/regular-discount/create', target: "APP"}}
              secondaryAction={emptySecondaryAction}
              image="https://cdn.shopifycloud.com/web/assets/6abcecfa5637ef0dced77481e9c7069e.svg"
            >
            <p>Offer multiple Wholesale Pricing to specific varaints, products, collections or your entire store for all customers or tag based customer groups</p>
            </EmptyState>
            </Layout>
            <FooterHelpLinks help={this.props.help}/>
          </div>
        );
      }
      else if(this.state.filter_qb_data && this.state.filter_qb_data.length){
        return (
          <div>
            {importModalSection}
            {add_qb_buttons}
            {toast}
            <PageTitleBar
              title="Wholesale Pricing"
            />
            <Layout>
              <Layout.Section>
                <Card>
                  <ResourceList
                    showHeader={true}
                    resourceName={resourceName}
                    items={this.state.filter_qb_data}
                    renderItem={this.renderItem} 
                    // selectedItems={this.state.selectedItems}
                    // onSelectionChange={this.handleSelectionChange}
                    // promotedBulkActions={promotedBulkActions}
                    persistActions
                    loading={this.state.loading}
                    // sortOptions={sortOptions}
                    // sortValue={sort_by}
                    // onSortChange={this.handleSortChange}
                  />
                </Card>
              </Layout.Section>
              {
                (this.state.pagination_has_previous || this.state.pagination_has_next) && 
                <Layout.Section>
                  <Pagination
                    hasPrevious={this.state.pagination_has_previous}
                      onPrevious={() => {
                        this.fetch_all_qb(this.state.sort_by, "prev");
                      }}
                      hasNext={this.state.pagination_has_next}
                      onNext={() => {
                        this.fetch_all_qb(this.state.sort_by,"next");
                      }}
                  />
                </Layout.Section>
              }
              <Layout.Section><FooterHelpLinks help={this.props.help}/></Layout.Section>
            </Layout>
          </div>
        );
      }
      else{
        return (
          <div>
            <PageTitleBar
              title="Wholesale Pricing"
            />
            {importModalSection}
            {add_qb_buttons}
            {toast}
            <FooterHelpLinks help={this.props.help}/>
          </div>
        );
      }
    }
  }

}