import React, { Component } from 'react';
import { Link } from '@shopify/polaris';
import axios from 'axios';
import {
  Card,ResourceList,TextStyle,Button,Layout,EmptyState,Badge,Tooltip,ButtonGroup, ResourceItem
} from '@shopify/polaris';
import FooterHelpLinks from '../FooterHelpLinks';
import SkeletonPageComp from '../SkeletonPageComp';
import PageTitleBar from '../../Common/TitleBar';
import ToastMessage from '../../Common/ToastMessage';
import {Modal} from '@shopify/app-bridge-react';

var interval = 0;

export default class GeneralDiscountGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      discount_groups: [],
      host_name: this.props.host_name,
      shop_id: this.props.shop_id,
      shop: this.props.shop,
      page_loaded: false,
      est_time: undefined,
      add_discountRule_popover: false,
      enable_btn_loading: false,
      disable_btn_loading: false,
      delete_btn_loading: false,
      loading: false,
      selected_discount_groups: [],
      import_discount_groups_modal: false,
      sortValue: 'DATE_CREATED_DESC',
      showToast: false,
      toastMsg: '',
      toastError: false
      
    };
    this.handleSortChange = this.handleSortChange.bind(this);
  }
  showToastMessage(msg, error){
    this.setState({
      showToast: true,
      toastMsg: msg,
      toastError: error||false,
    });
  }
  
  dissmissToast = () =>{
    this.setState({
      showToast: false,
    });
  }

  handleSelectionChange = (selectedItems) => {
    var temp_items = [];
    if (selectedItems.length > 0) {
      selectedItems.forEach(x => {
        temp_items.push(this.state.discount_groups[x]._id);
      })
    }
    this.setState({
      selectedItems: selectedItems,
      selected_discount_groups: temp_items
    });
    this.fetch_est_time();
  };
  renderItem = (item, id) => {
    const { _id, applies_to, internal_name, collections, customers_group, products, status, tags, type, value, variants } = item;
    const shortcutActions = applies_to
      ? [
        { content: 'Edit', target: "APP", url: '/edit-discount-group/' + _id, disabled: this.status_button_en_dis(item) },
        {
          content: status === 'published' ? 'Disable' : 'Enable', disabled: this.status_button_en_dis(item), onAction: () => {
            status === 'published' ? this.disable_discount_group(item) : this.enable_discount_group(item)
          }
        },
        {content: 'Delete', onAction: ()=>{ this.delete(item)}}
      ]
      : null;
    const mainTitleSection = (
      <div className="CustomerListItem__Profile">
        <h3 className="CustomerListItem__Title">
          <TextStyle variation="strong">{
            <span>
              {internal_name === '' ? 'No name' : internal_name} {customers_group.tag_base_customers ? tags ?
                <span className="custom-tag-style">{tags}</span>
                : null : null}
            </span>
          }</TextStyle>
        </h3>
        <div className="CustomerListItem__Location">
          {
            type === 'price_discount' || type === "fixed_price" ? '$' + value : value + '%'} - {customers_group.customers === "all" ? "All customers" :
              customers_group.tag_base_customers ? "Tag based customers" :
                "Only logged in customers"} - {applies_to.replace(/_/g, ' ').replace('specific', 'selected')
          }
          {
            applies_to === 'specific_products' ? ' (' + products.length + ')' :
              applies_to === 'specific_collections' ? ' (' + collections.length + ')' :
                applies_to === 'specific_variants' ? ' (' + variants.length + ')' : null
          }
        </div>
      </div>
    );
    const contentSection = (
      <div className="CustomerListItem__Status">
        <div className="CustomerListItem__StatusBadge">
          {
            this.status_button_en_dis(item) ?
              <Badge status='warning' progress="incomplete">
                <Link className="no_link_style" to={'/pricing-plans'}>Upgrade plan</Link>
              </Badge>
              : item.status === 'published' ?
                <Badge status="success" progress="complete">Active</Badge> :
                item.status === 'unpublished' ? <Badge>Expired</Badge>
                  : item.status === 'expired' ?
                    <Badge>Expired</Badge>
                    : item.schedule_active && item.status !== 'expired' ?
                      <Tooltip content={
                        <span>
                          <div>Start:
                  {
                              new Date(this.get_est_dateTime(item.schedule.start_date, item.schedule.start_time_est)).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })
                            }
                          </div>
                          <div>
                            End:
                    {
                              item.schedule.end_date ?
                                new Date(this.get_est_dateTime(item.schedule.end_date, item.schedule.end_time_est)).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })
                                : "Never expires"
                            }
                          </div>
                        </span>
                      }>
                        <Badge status="attention">
                          <Link onClick={this.doNotUpdate} className="help_and_info_text" style={{ color: '#595130' }} to='#'>Scheduled</Link>
                        </Badge>
                      </Tooltip>
                      : <Badge>Expired</Badge>
          }
          {
            item.schedule_active && item.status !== 'expired' && item.status !== 'schedule' && item.schedule.end_date ?
              <span id={'id' + id} className="custom-badge">
                {
                  this.schedule_ends_in(item, id)
                }
              </span>
              : null
          }
        </div>
      </div>
    );
    return (
      <div className="CustomerListItem">
        <ResourceItem
          id={id}
          shortcutActions={shortcutActions}
        >
          <div className="CustomerListItem__Main">
            {mainTitleSection}
            {contentSection}
          </div>
        </ResourceItem>
      </div>
    );
  };
  componentDidMount() {
    this.clearAllInterval(interval);
    this.fetch_all_discount_groups(this.state.sortValue);
  }
  componentWillUnmount() {
    this.clearAllInterval(interval);
  }
  componentDidCatch() {
    this.clearAllInterval(interval);
  }
  clearAllInterval = (interval) => {
    for (var i = 1; i <= interval; i++) {
      clearInterval(i);
    }
  };
  fetch_all_discount_groups(sortBy) {
    this.clearAllInterval(interval);
    axios.get(this.state.host_name + '/get-pricing-group/' + this.props.shop + '/sortby/' + sortBy)
      .then(response => {
        this.setState({
          page_loaded: true,
          discount_groups: response.data,
          loading: false
        });
        this.fetch_est_time();
      })
      .catch(error => {
        
      });
  }
  fetch_est_time() {
    axios.get(this.state.host_name + '/est-time-now/v2')
      .then(time => {
        this.clearAllInterval(interval);
        this.setState({ est_time: time.data.current_est_time });
      });
  }
  delete(item) {
    if (window.confirm("Delete discount group permanently?")) {
      this.setState({ loading: true });
      this.fetch_est_time();
      axios.delete(this.state.host_name + '/delete-pricing-group/' + item._id + '/' + this.props.shop)
        .then(response => {
          if (response.data.status === 1) {
            this.showToastMessage(response.data.message);
            const newData = this.state.discount_groups.filter(i => i._id !== item._id);
            this.setState({ discount_groups: newData, loading: false });
          }
          else {
            this.showToastMessage(response.data.message, true);
            this.setState({ loading: false });
          }
          this.fetch_est_time();
        }
        ).catch(err => {
          this.fetch_est_time();
        });
    }
  }
  disable_discount_group(item) {
    this.setState({ loading: true });
    this.fetch_est_time();
    axios.put(this.state.host_name + '/disable-pricing-group/' + this.props.shop, item)
      .then(response => {
        if (response.data.status === 1) {
          this.showToastMessage(response.data.message);
          this.fetch_all_discount_groups(this.state.sortValue);
        }
        else {
          this.showToastMessage(response.data.message, true);
          this.setState({ loading: false });
          this.fetch_est_time();
        }
      }
      ).catch(err => {
        
        this.setState({ loading: false });
        this.fetch_est_time();
      });
  }
  enable_discount_group(item) {
    this.setState({ loading: true });
    this.fetch_est_time();
    axios.put(this.state.host_name + '/enable-pricing-group/' + this.props.shop, item)
      .then(response => {
        if (response.data.status === 1) {
          this.showToastMessage(response.data.message);
          this.fetch_all_discount_groups(this.state.sortValue);
        } else {
          this.showToastMessage(response.data.message, true)
          this.setState({ loading: false });
        }
      }
      ).catch(err => {
        
        this.setState({ loading: false });
      });
  }
  getTimezoneOffset() {
    try {
      function z(n) { return (n < 10 ? '0' : '') + n }
      var offset = new Date().getTimezoneOffset();
      var sign = offset < 0 ? '+' : '-';
      offset = Math.abs(offset);
      return sign + z(offset / 60 | 0) + z(offset % 60);
    } catch (err) { }
  }
  get_est_dateTime(date, time) {
    try {
      let dateTimeValue = new Date(date);
      if (typeof dateTimeValue === 'object') {
        dateTimeValue = dateTimeValue.toString();
        if (typeof dateTimeValue === 'string') {
          dateTimeValue = dateTimeValue.replace(this.getTimezoneOffset(), '+0000');
        }
      }
      dateTimeValue = new Date(dateTimeValue).toISOString();
      dateTimeValue = dateTimeValue.split('T')[0] + " " + time;
      return dateTimeValue;
    } catch (err) { }
  }
  schedule_ends_in(item, counter) {
    let clockId = 'id' + counter;
    try {
      this.countDown(new Date((item.schedule.end_date).split('T')[0] + " " + item.schedule.end_time_est), clockId);
    } catch (error) { }
  }
  countDown(end, clockId) {
    let result;
    let est_time = this.state.est_time;
    let start = new Date(est_time.year, est_time.month, est_time.date, est_time.hours, est_time.minutes, est_time.seconds, est_time.milliseconds);
    try {
      if (typeof start !== 'object' || typeof end !== 'object') { return undefined; }
      start = start.getTime();
      end = end.getTime();
      var timeSpan = end - start;
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(timeSpan / (1000 * 60 * 60 * 24));
      var hours = Math.floor((timeSpan % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((timeSpan % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((timeSpan % (1000 * 60)) / 1000);
      result = {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds
      };
      if (result.seconds < 0) {
        result.minutes--;
        result.seconds += 60;
      }
      if (result.minutes < 0) {
        result.hours--;
        result.minutes += 60;
      }
      if (result.hours < 0) {
        result.days--;
        result.hours += 24;
      }
      if (result.days > 0 || result.hours > 0 || result.minutes > 0 || result.seconds > 0) {
        interval = setInterval(startCountDown, 1000);
      }
      function startCountDown() {
        result.seconds--;
        if (result.seconds < 0) {
          result.minutes--;
          result.seconds += 60;
        }
        if (result.minutes < 0) {
          result.hours--;
          result.minutes += 60;
        }
        if (result.hours < 0) {
          result.days--;
          result.hours += 24;
        }
        if (result.days < 0 || result.hours < 0 || result.minutes < 0 || result.seconds < -1) {
          clearInterval(interval);
          if (clockId !== null) {
            try {
              let CLOCKID = document.getElementById(clockId);
              if (CLOCKID !== null) {
                CLOCKID.innerHTML = '';
              }
            } catch (e) { }
          }
          try {
            window.location.reload(true);
          } catch (e) { }
        }
        else {
          let ends_message = '';
          if (result.days > 0) {
            ends_message += result.days + 'd ';
          }
          if (result.hours > 0) {
            ends_message += result.hours + 'h ';
          }
          if (result.minutes > 0) {
            let minutes = result.minutes;
            if (result.minutes < 10) {
              minutes = '0' + minutes;
            }
            ends_message += minutes + 'm ';
          }
          if (result.seconds >= 0) {
            let seconds = result.seconds;
            if (result.seconds < 10) {
              seconds = '0' + seconds;
            }
            ends_message += seconds + 's ';
          }
          if (ends_message !== '') {
            if (clockId !== null) {
              try {
                let CLOCKID = document.getElementById(clockId);
                if (CLOCKID !== null) {
                  CLOCKID.innerHTML = ends_message;
                }
              } catch (e) {
                clearInterval(interval);
              }
            }
          }
        }
        window.onbeforeunload = function () {
          clearInterval(interval);
        };
      }
      return result;
    }
    catch (e) {
      clearInterval(interval);
    }
  }
  doNotUpdate = (e) => {
    e.preventDefault();
  }
  upgrade = () => {
    this.props.history.push('/pricing-plans');
  }
  status_button_en_dis = (item) => {
    let plan = this.props.user_current_plan;
    if (plan.plan_permissions) {
      if (!plan.plan_permissions[0].volume_discount && item.min_quantity_requirment) {
        return true;
      }
      else if (!plan.plan_permissions[0].variants && item.applies_to === 'specific_variants') {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  };
  bulk_delete = (selected_items) => {
    if (window.confirm("Delete all selected rules permanently?")) {
      this.setState({ loading: true });
      axios.put(this.state.host_name + '/bulk-delete-pricing-group/' + this.props.shop, {
        selected_items: selected_items,
        shop: this.props.shop
      })
        .then(response => {
          if (response.data.status === 1) {
            this.showToastMessage(response.data.message);
            this.fetch_all_discount_groups(this.state.sortValue);
          } else {
            this.showToastMessage(response.data.message, true)
            this.setState({ loading: false });
            this.fetch_est_time();
          }
        }
        ).catch(err => {
          
          this.setState({ loading: false });
          this.fetch_est_time();
        });
    }
  }
  handleSortChange(sortValue) {
    this.setState({ sortValue });
    this.fetch_all_discount_groups(sortValue);
  }
  handleSelectionChange(selectedItems) {
    this.setState({ selectedItems });
    this.fetch_est_time();
  }
  render() {
    const { sortValue } = this.state;
    const importModalSection = <div>
      <Modal
        size="Medium"
        src={'/import-discount-groups'}
        open={this.state.import_discount_groups_modal}
        onClose={() => {
          this.setState({ import_discount_groups_modal: false });
        }}
        title={'Import discount rule by CSV file'}
      >
      </Modal>
    </div>;
    const header_buttons = <div style={{ margin: "1rem 0rem" }}>
      <ButtonGroup>
        <Button primary size="slim" url="/add-general-discount" target="APP">Create Automatic Discount</Button>
        <Button outline size="slim" onClick={() => {
          this.setState({ import_discount_groups_modal: true });
          this.fetch_est_time();
        }}
        >Import</Button>
        <Button outline size="slim" onClick={() => {
          this.fetch_est_time();
          window.top.location.href = this.props.host_name + '/admin/export/csv/discount-groups/' + this.props.shop;
        }}
        >Export All</Button>
      </ButtonGroup>
    </div>;
    const resourceName = {
      singular: 'Discount group',
      plural: 'Discount groups',
    };
    const promotedBulkActions = [
      {
        content: 'Export selected',
        onAction: () => {
          var selected_ids = JSON.stringify(this.state.selected_discount_groups);
          window.top.location.href = this.props.host_name + '/admin/bulk-export/csv/discount-group/' + this.props.shop + '/' + selected_ids;
        }
      },
      {
        content: 'Delete Selected',
        onAction: () => {
          this.bulk_delete(this.state.selected_discount_groups);
        },
      }
    ];
    const sortOptions = [
      { label: 'Newest', value: 'DATE_CREATED_DESC' },
      { label: 'Oldest', value: 'DATE_CREATED_ASC' },
      { label: 'Most used', value: 'MOST_USED_DESC' },
      { label: 'Least used', value: 'MOST_USED_ASC' },
      { label: 'Highest price', value: 'VALUE_DESC' },
      { label: 'Lowest price', value: 'VALUE_ASC' },
    ];

    var toast = this.state.showToast? (
      <ToastMessage
      onDismiss={this.dissmissToast}
      message={this.state.toastMsg}
      error={this.state.toastError}
      />
    ): (null);
    

    if (this.state.page_loaded) {
      if (this.state.discount_groups.length < 1) {
        return (
          <div>
            {toast}
            {importModalSection}
            <Layout>
              <EmptyState
                heading="Create an automatic discount "
                action={{ content: 'Create Automatic Discount', url: '/add-general-discount', target: "APP" }}
                secondaryAction={{
                  content: 'Import discount group', onClick: () => {
                    this.setState({ import_discount_groups_modal: true })
                    this.fetch_est_time();
                  }
                }}
                image="https://cdn.shopifycloud.com/web/assets/6abcecfa5637ef0dced77481e9c7069e.svg"
              >
                <p>Offer your customers automatic discounts</p>
              </EmptyState>
            </Layout>
            <div className="youtube-video-player">
            </div>
            <FooterHelpLinks help={this.props.help} />
          </div>
        );
      }
      else {
        return (
          <div>
            <PageTitleBar
              title="General Discounts"
            />
            {toast}
            {importModalSection}
            {header_buttons}
            <Card>
              <ResourceList
                showHeader={true}
                resourceName={resourceName}
                items={this.state.discount_groups}
                renderItem={this.renderItem}
                selectedItems={this.state.selectedItems}
                onSelectionChange={this.handleSelectionChange}
                promotedBulkActions={promotedBulkActions}
                persistActions
                loading={this.state.loading}
                sortOptions={sortOptions}
                sortValue={sortValue}
                onSortChange={this.handleSortChange}
              />
            </Card>
            <FooterHelpLinks help={this.props.help} />
          </div>
        );
      }
    }
    else {
      return (
        <div>
          <SkeletonPageComp />
        </div>
      );
    }
  }
}