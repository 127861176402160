import React from 'react';
import { Layout, RadioButton, Modal, Stack, Button, TextStyle, Heading, Link } from '@shopify/polaris';
import { InfoMinor } from "@shopify/polaris-icons";
import axios from 'axios';
import ToastMessage from '../Common/ToastMessage';

export default class AppStatusBanner extends React.Component{

    constructor(props){
        super(props);
        this.state = {
          app_mode: this.props.app_mode || "test",
          expanded: this.props.app_mode === "live"? false: true,
          modal: false
        }
    }

    showToastMessage(msg, error){
      this.setState({
        showToast: true,
        toastMsg: msg,
        toastError: error||false,
      });
    }

    dissmissToast = () =>{
      this.setState({
        showToast: false,
      });
    }

    handleEnableApp = (id, value) => {
      var app_mode = value;
      this.setState({app_mode: value});
      const settingsData = {
        app_mode: app_mode,
        shop: this.props.shop
      };
      axios.put(this.props.host_name+'/app/app-mode', settingsData)
      .then( response => {
          if(response.data.status === 1){
            this.showToastMessage(response.data.message);
          }
          else{
            this.showToastMessage(response.data.message, true);
          }
      })
      .catch(error => {
        this.showToastMessage("Server error", true);
      });
    };

    render(){
        const {app_mode } = this.state;

        var toast = this.state.showToast? (
          <ToastMessage
            onDismiss={this.dissmissToast}
            message={this.state.toastMsg}
            error={this.state.toastError}
          />
        ): (null);

        const infoModal = <Modal
        open={this.state.modal}
        onClose={()=>{this.setState({modal:false})}}
        title="Test Mode & Live Mode options"
      >
        <Modal.Section>
          <Stack distribution="fillEvenly">
              <Stack.Item>
                <Heading>Test Mode</Heading>
                <p>
                    Use to test the wholesale app working before making it live to the customers.
                </p>
              </Stack.Item>
              <Stack.Item>
                <Heading>Live Mode</Heading>
                <p>
                    The wholesale app features will appear direclty to the live customers.
                    <br></br>
                    <Link external={true} url="https://support.digitalcoo.com/hc/en-us/articles/360042800512">Learn More</Link>
                </p>
              </Stack.Item>
          </Stack>
        </Modal.Section>
      </Modal>;

        return(
            <div>
              {toast}
                <Layout>
                    {infoModal}
                    <Layout.Section>
                        <Stack>
                            <Stack.Item fill>
                            </Stack.Item>
                            <Stack.Item>
                                <Button
                                    plain
                                    disclosure={this.state.expanded ? "up" : "down"}
                                    onClick={() => {
                                        this.setState({ expanded: !this.state.expanded});
                                    }}
                                >
                                    {this.state.app_mode === "live"
                                    ? <span><img src="https://img.icons8.com/material/12/008000/filled-circle.png" style={{verticalAlign:"middle"}} /><span style={{verticalAlign:"middle"}}> Live Mode Enabled</span></span>
                                    : <span><img src="https://img.icons8.com/material/12/ffbc00/filled-circle.png" style={{verticalAlign:"middle"}} /><span style={{verticalAlign:"middle"}}> Test Mode Enabled</span></span> 
                                    }
                                </Button>
                            </Stack.Item>
                        </Stack>
                    </Layout.Section>
                    {this.state.expanded &&
                    <Layout.Section>
                        <div className={"Polaris-Banner Polaris-Banner--withinPage " + (this.state.app_mode === "live" ? " Polaris-Banner--statusSuccess ":" Polaris-Banner--statusWarning " ) } tabindex="0" role="status" aria-live="polite" aria-describedby="PolarisBanner1Content">
                            <div className="Polaris-Banner__ContentWrapper">
                                <div className="Polaris-Banner__Content" id="PolarisBanner1Content">
                                    <Stack spacing="tight" alignment="center">
                                        <Stack.Item>
                                            <TextStyle variation="strong">Select the application mode:</TextStyle>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <RadioButton
                                                label="Test Mode"
                                                id="test"
                                                name="mode"
                                                checked={app_mode === 'test'}
                                                onChange={this.handleEnableApp}
                                            />
                                        </Stack.Item>
                                        <Stack.Item fill>
                                            <RadioButton
                                                label="Live Mode"
                                                checked={app_mode === 'live'}
                                                id="live"
                                                name="mode"
                                                onChange={this.handleEnableApp}
                                            />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Button plain icon={InfoMinor} onClick={()=> {
                                                this.setState({modal:true})
                                            }}>
                                                Quick info
                                            </Button>
                                        </Stack.Item>
                                    </Stack>
                                </div>
                            </div>
                        </div>
                    </Layout.Section>
                    }
                </Layout>
            </div>
        );
    }

}