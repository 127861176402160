import React from "react";
import { Layout, Card, SkeletonBodyText, TextContainer, SkeletonDisplayText } from "@shopify/polaris";

export default function skeletonPageComp(){
    return (
        <Layout>
            <Layout.Section>
                <Card sectioned>
                    <SkeletonBodyText />
                </Card>
                <Card sectioned>
                    <TextContainer>
                        <SkeletonDisplayText size="small" />
                        <SkeletonBodyText />
                    </TextContainer>
                </Card>
                <Card sectioned>
                    <TextContainer>
                        <SkeletonDisplayText size="small" />
                        <SkeletonBodyText />
                    </TextContainer>
                </Card>
            </Layout.Section>
        </Layout>
    );
}
