import React, { Component } from 'react';
import axios from 'axios';
import * as PropTypes from 'prop-types';
import queryString from 'qs';
import { Layout, FooterHelp, Link, Spinner } from '@shopify/polaris';
import SkeletonPageComp from '../Components/SkeletonPageComp';
import PageTitleBar from '../Common/TitleBar';

const parsed = queryString.parse(window.location.search);

export default class PricingPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pricing_plans: [],
      host_name: this.props.host_name,
      shop: this.props.shop,
      shop_id: this.props.shop_id,
      charge_id: parsed.charge_id,
      user_current_plan: '',
      shop_payment: this.props.shop_payment,
      plan_btn_disable: false,
      page_load: false,
      button_was_clicked : false
    }
  }
  static contextTypes = {
    easdk: PropTypes.object,
  };
  componentDidMount() {
    if (parsed.charge_id && parsed.plan) {
      axios.get(this.props.host_name + '/admin/recuring-charge/callback/' + parsed.charge_id + '/' + this.props.shop + '/' + this.props.shop_id + '/' + parsed.plan)
        .then(response => {
          window.top.location.href = "https://"+this.props.shop+"/admin/apps/"+process.env.REACT_APP_APPLICATION_HANDLE+"/pricing-plans";
        })
        .catch(error => {
          
        })
    }
    axios.get(this.props.host_name + '/admin/get-plans/' + this.props.shop)
      .then(plans => {
        if (plans.data.length > 0) {
          this.setState({
            pricing_plans: plans.data,
            page_load: true
          });
        }
        else{
          this.setState({
            page_load: true
          });
        }
      })
      .catch(error => {
        this.setState({
          page_load: true
        });
      });

    axios.get(this.props.host_name + '/admin/get-payments/' + this.props.shop)
      .then(response => {
        if (response.data.status === "Pending") {
          // this.context.easdk.showFlashNotice("You did not subsctibe any plan.");
        }
        else if (response.data.status === "active") {
          this.setState({
            user_current_plan: response.data.plan_name
          });
        }
      })
      .catch(error => {
        
      });
  }

  UI_Included(included) {
    let inc = included.split(',');
    let res = '';
    if (inc.length > 0) {
      res = inc.map((el, i) => {
        return <li className="list-included" key={i}>{el} </li>
      });
    }
    return res;
  };
  UI_Not_included(not_included) {
    let not_inc = not_included.split(',');
    let res = '';
    if (not_inc.length > 0) {
      res = not_inc.map((el, i) => {
        return <li className="list-not-included" key={i}>{el} </li>
      });
    }
    return res;
  };
  subscribePlan(plan) {
    this.setState({ plan_btn_disable: true, button_was_clicked: true });
    let data = {
      _id: plan._id,
      price: plan.price,
      trial_days: plan.trial_days,
      plan_name: plan.plan_name,
      shop: plan.shop,
      shop_id: plan.shop_id
    };
    var headers = {
      'Access-Control-Allow-Origin': '*'
    };
    axios.post(this.props.host_name + '/admin/plan-payment-process', data, headers)
      .then(response => {
        // console.log(response.data);
        window.top.location.href = response.data.confirmation_url;
      })
      .catch(error => {
        this.setState({button_was_clicked: false, plan_btn_disable: false})
        return false;
      });
  }
  render() {
    let PricingUI = '';
    if(this.state.button_was_clicked){
      return (
        <div>
          <div className="spinner-container" style={{textAlign:"center", height:"50vh", padding: "2rem 0rem"}}>
            <span style={{marginBottom: "2rem"}}><Spinner color="teal" size="large" /></span>
            <h1>Generating Invoice. Please wait...</h1>
          </div>
        </div>
      )
    }
    else if(this.state.page_load){
      if (this.state.pricing_plans.length > 0) {
        PricingUI = this.state.pricing_plans.map((pp, i) => {
          return <div key={i} className={this.state.user_current_plan.toLocaleLowerCase() === pp.plan_name.toLowerCase() ? 'columns plan-selected' : 'columns'}>
            <ul className="price">
              <li className="header">{pp.plan_name} Plan</li>
              <li className="grey">${pp.price} / {pp.payment_cycle.toLowerCase()}
                {/* <div className="free-trial-text">{pp.trial_days} days free trial</div> */}
              </li>
              {this.UI_Included(pp.included)}
              {pp.not_included !== '' ? this.UI_Not_included(pp.not_included) : ''}
              <li className="grey">
                {this.state.user_current_plan.toLocaleLowerCase() === pp.plan_name.toLowerCase() ? <button className='button btn-active' disabled> Active plan </button> : <button className='button' onClick={this.subscribePlan.bind(this, pp)} disabled={this.state.plan_btn_disable}> {pp.btn_text}</button>}
              </li>
            </ul>
          </div>;
        });
        return (
          <div>
            <PageTitleBar
              title="Plans"
            />
            <Layout sectioned>
              <Layout.Section>
                <div className="clearfix"></div>
                <FooterHelp>
                Which plan is best fit for me? <Link url="https://digitalcoo.com/wholesale-all-in-one/#pricing" external={true}> Click Here</Link>
                </FooterHelp>
              </Layout.Section>
              <Layout.Section>
                {PricingUI}
              </Layout.Section>
            </Layout>
          </div>
        );
      }
      else {
        return (
          <div>
          No plan found
          </div>
        )
      }
    }
    else{
      return (
        <div>
          <SkeletonPageComp />
        </div>
      )
    }
  }
}