import store from 'store2';

import _Modules from '../modules.json';

var _module = {};

function isPositiveInteger(r){return/^\d+$/.test(r)}

_module.checkVersion = function(r,t){var e=r.split("."),n=t.split(".");function i(r){for(var t=0;t<r.length;++t)if(!isPositiveInteger(r[t]))return!1;return!0}if(!i(e)||!i(n))return NaN;for(var u=0;u<e.length;++u){if(n.length===u)return 1;if(e[u]!==n[u])return e[u]>n[u]?1:-1}return e.length!==n.length?-1:0};

_module.getShop = function() {
    const parsed = new URLSearchParams(window.location.search);
    var shop = parsed.get('shop');
    if(!shop)shop = store('shop');
    else{
        store('shop', shop);
    }

    return shop;
    // return parsed.get('shop') || store('shop');
}

_module.getHost = function() {

    /* Get host from URL */
    const parsed = new URLSearchParams(window.location.search);
    var host = parsed.get('host');
    if(!host || host === '')host = store('host') || '';
    else{
        /* Update host in store() */
        store('host', host);
    }

    if(!host || host === ''){
        /* Still not found? generate the host programically */
        var shopifyDomain = _module.getShop();
        if(shopifyDomain && shopifyDomain !== ''){
            host = window.btoa(shopifyDomain + '/admin');                                                                                                      
        }
    }

    return host || '';

}


/* Generate base Url for links*/
_module.getShopBaseUrl = function(isAdminView = false) {
    let url = '';
    if(isAdminView){
        // Get host provides by shopify and decode it
        let host = _module.getHost();
        if(host && host !== ""){
            url = "https://"+window.atob(host);
        }
    }
    else{
        // Get shop public domain, if not found, use primary domain
        if (store("_wsinit")) {
            let shopData = store("_wsinit");
            try {
              shopData = JSON.parse(shopData);
            } catch (e) { shopData = null}
            if(typeof shopData !== "undefined" && shopData && typeof shopData.domain !== "undefined"){
                url = "https://"+shopData.domain;
            }
        }

    }

    if(url == '' && store("shop")){
        // append shop primary domain
        url = "https://"+store("shop");
    }

    return url;
}

_module.getHostName = function() {
    return process.env.REACT_APP_SERVER_URL;
}

_module.getAppHandle = function() {
    return process.env.REACT_APP_APPLICATION_HANDLE;
}

_module.getSnippetVersion = function() {
    return store('snippet_version') || process.env.REACT_APP_SNIPPET_VERSION || "2.3.8";
}

_module.isAdmin = function(){
    const parsed = new URLSearchParams(window.location.search);
    var isAdmin = parsed.get('wsadmin') || store("wsAdmin")?store("wsAdmin"):!1;
    if(parsed.get('wsadmin')){ store("wsAdmin", parsed.get('wsadmin')); }
    if(isAdmin && isAdmin.trim().toLowerCase().toString() === "yes"){
        isAdmin = true;
    }
    else{
        isAdmin = !1;
    }
    return isAdmin?!0:!1;
}

_module.getVersionComponent = function(params, callback){
    var versions = params.versions,
    default_version = params.default_version,
    snippet_version = _module.getSnippetVersion() || '2.3.8',
    counter = 0;
    function findVersion(_version, callback) {
      if (_version) {
        var index = !1;
        try {
          index = _module.checkVersion(snippet_version, _Modules.find(x => x._id === _version.id).snippet_version) > -1;
        } catch (e) {}
        if (index) {
          if (typeof callback === 'function') {
            callback(_version);
          }
        } else {
          counter++;
          findVersion(versions[counter], callback);
        }
      } else {
        if (typeof callback === 'function') {
          callback(default_version);
        }
      }
    }
    findVersion(versions[counter], callback);
}

_module.getId = function(id){
  try {
    if(id.indexOf("/")>-1){
      id = id.split("/")[id.split("/").length-1];
    }
  } catch (e) { }
  return id;
}

_module.wsId = function(length) {
  var text = "";var result = "";length = length || 6;
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for(var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  for (var j=0; j < text.length; j++) {
      result += text.charCodeAt(j).toString(16);
  }
  return result;
}

_module.checkLength = function(a){
  if(typeof a === "object"){
      return JSON.stringify(a).length
  }
  return a.length
}

_module.getSchedule = function(schedule){
  function est_time_now() {
      var tN = new Date();
      tN.setTime(tN.getTime() + tN.getTimezoneOffset() * 60 * 1000);
      var offset = -300, estDate = new Date(tN.getTime() + offset * 60 * 1000);
      return estDate.getTime();
  }
  function getTimezoneOffset() {
      function z(n) { return (n < 10 ? '0' : '') + n }
      var offset = new Date().getTimezoneOffset();
      var sign = offset < 0 ? '+' : '-';
      offset = Math.abs(offset);
      return sign + z(offset / 60 | 0) + z(offset % 60);
  }
  function date_time_value(date, time) {
      var dateTimeValue = new Date(date);
      if (typeof dateTimeValue === 'object') {
          try {
              dateTimeValue = dateTimeValue.toString();
              if (typeof dateTimeValue === 'string') {
                  dateTimeValue = dateTimeValue.replace(getTimezoneOffset(), '+0000');
              }
          } catch (e) { }
      }
      try {
          dateTimeValue = new Date(dateTimeValue).toISOString();
      } catch (e) { }
      try {
          dateTimeValue = dateTimeValue.split('T')[0] + " " + time;
      } catch (e) { }
      return dateTimeValue;
  }
  function diff_in_schedule(date1, date2) {
      date1 = new Date(date1);
      date2 = new Date(date2);
      if (date1 === 'Invalid Date' || date2 === 'Invalid Date') {
          return 0;
      }
      if (!date1 || !date2) return 0;
      if (typeof date1 !== 'object' || typeof date2 !== 'object') {
          return 0;
      }
      if (date1 > date2) {
          var result = diff_in_schedule(date2, date1);
          result.years = -result.years;
          result.months = -result.months;
          result.days = -result.days;
          result.hours = -result.hours;
          result.minutes = -result.minutes;
          result.seconds = -result.seconds;
          return result;
      }
      result = {
          years: date2.getYear() - date1.getYear(),
          months: date2.getMonth() - date1.getMonth(),
          days: date2.getDate() - date1.getDate(),
          hours: date2.getHours() - date1.getHours(),
          minutes: date2.getMinutes() - date1.getMinutes(),
          seconds: date2.getSeconds() - date1.getSeconds()
      };
      if (result.seconds < 0) {
          result.minutes--;
          result.seconds += 60;
      }
      if (result.minutes < 0) {
          result.hours--;
          result.minutes += 60;
      }
      if (result.hours < 0) {
          result.days--;
          result.hours += 24;
      }
      if (result.days < 0) {
          result.months--;
          var copy1 = new Date(date1.getTime());
          copy1.setDate(32);
          result.days = 32 - date1.getDate() - copy1.getDate() + date2.getDate();
      }
      if (result.months < 0) {
          result.years--;
          result.months += 12;
      }
      return result;
  }
  var end_schedule_difference = {
      "years": 1,
      "months": 1,
      "days": 1,
      "hours": 1,
      "minutes": 1,
      "seconds": 1
  },
  start_schedule_difference = {
      "years": -1,
      "months": -1,
      "days": -1,
      "hours": -1,
      "minutes": -1,
      "seconds": -1
  },
  _est = est_time_now();
  if(schedule){
      if(schedule.start_date && schedule.start_time_est){
          var schedule_started = date_time_value(schedule.start_date, schedule.start_time_est);
          start_schedule_difference = diff_in_schedule(_est, schedule_started);
      }
      if(schedule.end_date && schedule.end_time_est){
          var schedule_started = date_time_value(schedule.end_date, schedule.end_time_est);
          end_schedule_difference = diff_in_schedule(_est, schedule_started);
      }
  }
  return {
    ends_in: end_schedule_difference,
    started: start_schedule_difference,
    est_time_now: _est
  }
}

_module.getPlanID = function(plans_permissions) {
    if (_module.isAdmin()) {
        return 3;
    }
    var uid = store('_wsuuid') || null;
    var _id = 1;
    if (uid === "374d30586f55") {
        _id = 1;
    }
    if (uid === "366c51353677") {
        _id = 2;
    }
    if (uid === "30776d463454") {
        _id = 3;
    }
    return _id;
}

_module.mergeQueryParams = (url) => {

    let queryParams = new URLSearchParams(window.location.search);

    // merge previous query params
    try{
        if(url.indexOf('?') > 0){
            const oldUrlParams = new URLSearchParams(url.split('?')[1]);
            oldUrlParams.forEach((value, key) => !queryParams.get(key) ? queryParams.set(key, value) : null);
        }
    } catch(e) {  }


    // If shop still now found, try to fetch it from somewhere
    // 1) from ancestorOrigins, our app is embedded, so we will have the origin host
    // 2) from localStorage, it may be not accurate
    if(!queryParams.get('shop')){
        // Get shop from frame ancestor
        let shop = null;
        if(window.location.ancestorOrigins.length > 0){
            try{
                let topHost = window.location.ancestorOrigins[0]
                .replace("https://", "")
                .replace("http://", "");
                // if not admin domain
                if(topHost !== "admin.shopify.com"){
                    shop = topHost;
                }
            } catch(e) {  }
        }

        if(!shop){
            shop = store('shop') || null;
        }

        if(shop) queryParams.set('shop', shop);
    }

    // Check the possibility if origin host not match with local storage
    const localShop = store('shop') || null;
    if(localShop && localShop !== queryParams.get('shop')){
        // clear the cache to load most recent data
        queryParams.set('cache', 'clear');
    }

    //override url with previous query param
    if(url.indexOf('?') > 0){
        url = url.split('?')[0];
    }
    return url + "?" + queryParams.toString();
}

export default _module;