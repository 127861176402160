import React, { Component } from 'react';
import {Card, TextStyle, Stack, TextField, Button,Layout, Heading} from '@shopify/polaris';
import axios from 'axios';
import CustomersFAQ from '../2020-04/CustomersFAQ';
import PageTitleBar from '../../../Common/TitleBar';
import ToastMessage from '../../../Common/ToastMessage';

export default class ManageCustomerDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_current_plan: this.props.user_current_plan,
            shop: this.props.shop,
            all_customers: [],
            selectedCustomers: [],
            loading: true,
            appliedFilters: [],
            searchValue: "",
            filtered_all_customers: [],
            page_loaded: false,
            selected_tab: 0,
            customer_status: "pending",
            wholesale_form_exists: false,
            wholesale_form_fetched: false,
            pending_customer_count: 0,
            rejected_customer_count: 0,
            showToast: false,
        	toastMsg: '',
        	toastError: false
        }
        axios.defaults.headers.common['X-Ws-Shop-Domain'] = "https://"+this.props.shop;
    }

    componentDidMount(){
        this.getCount("pending");
        this.getCount("rejected");
    }

    showToastMessage(msg, error){
    	this.setState({
    	  showToast: true,
    	  toastMsg: msg,
    	  toastError: error||false,
    	});
    }

    dissmissToast = () =>{
    	this.setState({
    	  showToast: false,
    	});
    }

    flashMessage(msg,isError) {
        this.showToastMessage(msg,isError);
    }

    getCount(status){
        axios.get(this.props.host_name + '/customer/api/335/admin/count?shop='+this.props.shop+"&status="+status).then(response => {
            if(response && response.data && typeof response.data.count !== "undefined"){
                if(status === "pending"){
                    this.setState({pending_customer_count: response.data.count});
                }
                else if(status === "rejected"){
                    this.setState({rejected_customer_count: response.data.count});
                }
            }
        }).catch(e => {this.flashMessage("Server error",true)});
    }

    redirectWSForm(section){
        window.location.href = "/wholesale-forms/"+section+"?shop="+this.props.shop;
    }

    redirectWSCustomerList(status){
        window.location.href = "/customers-list/"+status+"?shop="+this.props.shop;
    }

    redirectShopifyCustomerList(query){
        window.open("https://"+this.props.shop+"/admin/customers"+query)
    }
    
    render() {

        const section1 = <div>
            <Card title="Embed Customer Registration form to your website" actions={[{content:"Read Tutorial", url:"https://support.digitalcoo.com/hc/en-us/articles/360035051292", external:true}]}>
                <Card.Section>
                    <Stack>
                        <Stack.Item fill>
                            <TextField
                                label="Embed code"
                                labelHidden
                                value={`<div class="wholesale-all-in-one-registration-form"></div>`}
                                // helpText="Copy the HTML code from the bellow box and paste the code into your page or website HTML."
                                id="form-embed-code"
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Button
                                onClick={() => {
                                    var copyText = document.getElementById("form-embed-code");
                                    copyText.select();
                                    document.execCommand("copy");
                                    this.flashMessage("Copied to clipboard", false);
                                }}
                            >Copy</Button>
                        </Stack.Item>
                    </Stack>
                </Card.Section>
            </Card>
            <Card>
                <Card.Section>
                    <Stack>
                        <Stack.Item fill>
                            <Heading>
                                Customers Registraton > General Settings
                            </Heading>
                            <div>
                                <TextStyle variation="subdued">
                                    Manage General preferences for Customer Registration form
                                </TextStyle>
                            </div>
                        </Stack.Item>
                        <Stack.Item>
                            <Button onClick={() => {
                                this.redirectWSForm(0);
                            }}>Manage</Button>
                        </Stack.Item>
                    </Stack>
                </Card.Section>
                <Card.Section>
                    <Stack>
                        <Stack.Item fill>
                            <Heading>
                            Customers Registraton > Form Options
                            </Heading>
                            <div>
                                <TextStyle variation="subdued">
                                Customize Customer Registration form according to your business requirements
                                </TextStyle>
                            </div>
                        </Stack.Item>
                        <Stack.Item>
                            <Button onClick={() => {
                                this.redirectWSForm(6);
                            }}>Manage</Button>
                        </Stack.Item>
                    </Stack>
                </Card.Section>
                <Card.Section>
                    <Stack>
                        <Stack.Item fill>
                            <Heading>
                            Email Notifications & Registration Alerts
                            </Heading>
                            <div>
                                <TextStyle variation="subdued">
                                    Customize email notifications and acknowledgement alerts on form submit
                                </TextStyle>
                            </div>
                        </Stack.Item>
                        <Stack.Item>
                            <Button onClick={() => {
                                this.redirectWSForm(1);
                            }}>Manage</Button>
                        </Stack.Item>
                    </Stack>
                </Card.Section>
            </Card>
        </div>;
        
        const section2_right = <div>
            <Card>
                <Card.Section>
                    <Stack vertical spacing="extraTight">
                        <div>
                            <Stack>
                                <Stack.Item fill>
                                    <Heading>Waiting for approval ({this.state.pending_customer_count})</Heading>
                                </Stack.Item>
                                <Stack.Item>
                                    <Button
                                    onClick={() => {
                                        this.redirectWSCustomerList("pending")
                                    }}
                                    size="slim">View</Button>
                                </Stack.Item>
                            </Stack>
                        </div>
                        <div>
                            <TextStyle variation="subdued">
                            See list of customers who are waiting for account approval
                            </TextStyle>
                        </div>
                    </Stack>
                </Card.Section>
                <Card.Section>
                    <Stack vertical spacing="extraTight">
                        <div>
                            <Stack>
                                <Stack.Item fill>
                                    <Heading>
                                    Rejected customers ({this.state.rejected_customer_count})
                                    </Heading>
                                </Stack.Item>
                                <Stack.Item>
                                    <Button
                                    onClick={() => {
                                        this.redirectWSCustomerList("rejected")
                                    }}
                                    size="slim">View</Button>
                                </Stack.Item>
                            </Stack>
                        </div>
                        <div>
                            <TextStyle variation="subdued">
                            See rejected customers list
                            </TextStyle>
                        </div>
                    </Stack>
                </Card.Section>
                <Card.Section>
                    <Stack vertical spacing="extraTight">
                        <div>
                            <Stack>
                                <Stack.Item fill>
                                    <Heading>Approved Customers</Heading>
                                </Stack.Item>
                                <Stack.Item>
                                    <Button size="slim"
                                    onClick={() => {
                                        this.redirectShopifyCustomerList("?tag=VerifiedByWholesaleAllInOne");
                                    }}
                                    >View</Button></Stack.Item>
                            </Stack>
                        </div>
                        <div>
                            <TextStyle variation="subdued">
                                List of all approved customer through Wholesale All In One application
                            </TextStyle>
                        </div>
                    </Stack>
                </Card.Section>
            </Card>
        </div>;

        var toast = this.state.showToast? (
        <ToastMessage
          onDismiss={this.dissmissToast}
          message={this.state.toastMsg}
          error={this.state.toastError}
        />
        ): (null);


        return (
            <div>
                <PageTitleBar
                    title="Manage customers"
                    breadcrumbs={[{content: 'Dashboard', url: '/dashboard', target: "APP"}]}
                    secondaryActions={[{content: 'Back', url: '/dashboard', target: "APP"}]}
                />
                {toast}
                <Layout>
                    <Layout.Section>
                        {section1}
                    </Layout.Section>
                    <Layout.Section secondary>
                        {section2_right}
                    </Layout.Section>
                    <Layout.Section>
                        <CustomersFAQ/>
                    </Layout.Section>
                </Layout>
            </div>
        );

    }
}