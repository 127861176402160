import React, { Component } from 'react';
import axios from 'axios';
import * as PropTypes from 'prop-types';
import {Card, Checkbox, ChoiceList, Tag,TextField, Stack,  Button, SkeletonPage, Layout, SkeletonBodyText, TextContainer, SkeletonDisplayText } from '@shopify/polaris';
import FooterHelpLinks from './FooterHelpLinks';
import PageTitleBar from '../Common/TitleBar';
import ToastMessage from '../Common/ToastMessage';

export default class AccessControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      host_name: this.props.host_name,
      shop: this.props.shop,
      page_loaded: false,
      access_data: [],
      isEnabled: false,
      exclude_homepage: false,
      exclude_customer_area: true,
      choice_selected: ["only_loggedin"],
      textFieldValue: '',
      save_lock: false,
      choice_tags: [],
      msg: "",
      showToast: false,
      toastMsg: '',
      toastError: false
    };
  }

  static contextTypes = {
    easdk: PropTypes.object,
  };

  componentDidMount(){ 
    if(this.props.shop){
      axios.get(this.state.host_name+'/access-control/get/'+this.props.shop)
      .then(response => {
          var _data = response.data;
          if(_data.status === 1){
            this.setState({
                isEnabled: _data.response.enable,
                exclude_homepage: _data.response.exclude_homepage,
                exclude_customer_area: _data.response.exclude_customer_area,
                choice_selected: [_data.response.key_type],
                choice_tags: _data.response.tags,
                msg: _data.response.msg,
                page_loaded: true
            });
          }
          else{
            this.setState({page_loaded: true});
          }
      })
      .catch(error => {
        this.setState({page_loaded: true});
      });
    }
  }

  showToastMessage(msg, error){
    this.setState({
      showToast: true,
      toastMsg: msg,
      toastError: error||false,
    });
  }
  
  dissmissToast = () =>{
    this.setState({
      showToast: false,
    });
  }

  render() {
      var notvalidPlan = false;
      if(this.props.user_current_plan){
          var plan_id = this.props.user_current_plan?this.props.user_current_plan.plan_id:null;
          var valid = false;
          if(plan_id){
              valid = plan_id.indexOf("basic") > -1;
          }
          if(valid){
            notvalidPlan = true;
          }
      }
      if(this.props.is_basic_plan){
        notvalidPlan = true
      }
    
    const skeletonPage = <SkeletonPage>
    <Layout>
      <Layout.Section>
        <Card sectioned>
          <SkeletonBodyText />
        </Card>
        <Card sectioned>
          <TextContainer>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText />
          </TextContainer>
        </Card>
        <Card sectioned>
          <TextContainer>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText />
          </TextContainer>
        </Card>
      </Layout.Section>
    </Layout>
  </SkeletonPage>;

    this.items =  
    <Layout>
        <Layout.Section>
            <Card title="Manage Locks" sectioned actions={[{content:"Read tutorial", url:"https://digitalcoo.com/wholesale-all-in-one/", external: true}]}>
                <Checkbox
                    checked={this.state.isEnabled}
                    label="Enable lock"
                    helpText="Your entire storefront will not be accessible when this is enabled."
                    onChange={(isEnabled)=>this.setState({isEnabled})}
                    disabled={notvalidPlan}
                />
                {
                  this.state.isEnabled && <div>
                <Checkbox
                    checked={this.state.exclude_homepage}
                    label="Exclude homepage"
                    helpText="Makes sure everyone can access the home page, even when this lock is enabled."
                    onChange={(exclude_homepage)=>this.setState({exclude_homepage})} 
                />

                <Checkbox
                    checked={this.state.exclude_customer_area}
                    label="Exclude customer area"
                    helpText="Makes sure everyone can access customer login, registration, and account information, even when this lock is enabled."
                    onChange={(exclude_customer_area)=>this.setState({exclude_customer_area})} 
                />
                <TextField
                    label="Guest message content"
                    value={this.state.msg}
                    onChange={(msg)=>{this.setState({msg})}}
                    multiline
                    helpText="Shown with your login form to visitors who are not yet signed in."
                    placeholder="You can add HTML too."
                />
                </div>
                }
            </Card>

            {this.state.isEnabled && (
            <Card sectioned>
                <div style={{height: '150px'}}>
                    <ChoiceList
                    title={'Choose who will access'}
                    choices={[
                        {label: 'Only Loggedin Customers', value: 'only_loggedin'},
                        {
                          label: 'Tag based Customers',
                          value: 'tag_based',
                          renderChildren: (isSelected) => {
                              return (
                              isSelected && (
                                  <div>
                                      <TextField
                                      label="Tag"
                                      labelHidden
                                      placeholder="Wholesale, Guest, Gold"
                                      onChange={(textFieldValue)=>{
                                          var val = textFieldValue;
                                          var isComma = val.substr(val.length - 1);
                                          if(isComma === ','){
                                              var _tag =textFieldValue.substring(0, textFieldValue.length - 1)
                                              if(_tag!==''){
                                                  this.state.choice_tags.push(_tag)
                                                  this.setState({textFieldValue:''})
                                              }
                                              else{
                                                  this.setState({textFieldValue}) 
                                              }
                                          } 
                                          else{
                                              this.setState({textFieldValue})
                                          }
                                      }}
                                      onBlur={() => {
                                        var v = this.state.textFieldValue;
                                        v = v.replace(",","");
                                        v = v.trim();
                                        if(v === "") return false;
                                        var choice_tags = this.state.choice_tags;
                                        if(choice_tags.indexOf(v) > -1){
                                          return false;
                                        }
                                        choice_tags.push(v);
                                        this.setState({ choice_tags, textFieldValue: "" });
                                      }}
                                      value={this.state.textFieldValue}
                                      />
                                      <div style={{padding: '10px 0'}}>
                                          <TextContainer>
                                              <Stack spacing="extraTight">{this.renderTags()}</Stack>
                                          </TextContainer>
                                      </div>
                                  </div>
                              )
                              );
                          },
                        },
                    ]}
                    selected={this.state.choice_selected}
                    onChange={(choice_selected)=>this.setState({choice_selected})}
                    />
                </div>
            </Card>
            )}
        </Layout.Section>
    </Layout>

    let create_lock_button = <div><div className="bottom-line"></div>
      <div className="bottom-save-buttons">
          {
              this.props.is_basic_plan && <img style={{color:"#ffffff"}} width="36px" height="30px" src='/icons/lock.svg' alt="lock-icon" />
          }
          <Button primary
              disabled = { notvalidPlan }
              loading = { this.state.save_lock }
              onClick = { () => { this.handleSubmit() }}
          >Save</Button>
      </div>
    <div className="clearfix"></div></div>;

var toast = this.state.showToast? (
  <ToastMessage
	onDismiss={this.dissmissToast}
	message={this.state.toastMsg}
	error={this.state.toastError}
  />
): (null);


    if(this.state.page_loaded){
      return(
        <div>
          <PageTitleBar
            title="Manage Locks"
            secondaryActions={[{content: 'Back', url: '/dashboard', target: "APP"}]}
          />
          {toast}
          {this.items}
          {create_lock_button}
          <FooterHelpLinks help={this.props.help} />
        </div>
      )
    }else{
      return(
      <div>{skeletonPage}</div>
      )
    }
  }
  removeTag = (tag) => {
    const {choice_tags: newSelected} = this.state;
    newSelected.splice(newSelected.indexOf(tag), 1);
    this.setState({choice_tags: newSelected});
  };
  renderTags = () => {
    return this.state.choice_tags.map((tag) => {
      let tagLabel = '';
      tagLabel = titleCase(tag);
      return (
        <Tag key={'tag' + tag} onRemove={() => this.removeTag(tag)}>
          {tagLabel}
        </Tag>
      );
    });
  };


  handleSubmit = (event) => {
    this.setState({ save_lock: true });
    const formData = {
        enable: this.state.isEnabled,
        exclude_homepage: this.state.exclude_homepage,
        exclude_customer_area: this.state.exclude_customer_area,
        key_type:this.state.choice_selected[0],
        tags: this.state.choice_tags,
        shop: this.state.shop,
        msg: this.state.msg
    }
    axios.post(this.state.host_name + '/access-control/add', formData)
    .then(response => {
        this.setState({ save_lock: false });
        this.showToastMessage("Setting saved!");
    })
    .catch(error => { });
  }
}

function titleCase(string) {
    string = string
      .toLowerCase()
      .split(' ')
      .map(function(word) {
        return word.replace(word[0], word[0].toUpperCase());
      });
    return string.join(' ');
  }