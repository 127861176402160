import React, { Component } from 'react';
import axios from 'axios';
import {
  Card,Tabs,RadioButton,Stack,Checkbox, TextStyle, Banner, Select, TextField ,ColorPicker ,Popover, RangeSlider,SettingToggle,PageActions, Badge
} from '@shopify/polaris';
import * as PropTypes from 'prop-types';
import FooterHelpLinks from '../FooterHelpLinks';
import _Modules from '../../modules.json';
import waio from '../../Helpers/functions';
import SkeletonPageComp from '../SkeletonPageComp';
import Translations from '../version201/Translations';
import ToastMessage from '../../Common/ToastMessage';

var interval = 0;

export default class Settings extends Component {
    constructor(props){
      super(props);
      this.state = {
        selected_tab: 0,
        discount_method_value: '',
        host_name: this.props.host_name,
        shop_id: this.props.shop_id,
        shop: this.props.shop,
        isSetDiscountMethod: '',
        compareAtPrice: false,
        ShowAsCompareAtPrice: false,
        hide_cents:false,
        isProcessing_Hide_cents: false,
        isProcessing_ShowAsCompareAtPrice: false,
        isProcessing_compareAtPrice: false,
        sale_clock_type: '1',
        sale_clock_text1: 'Sale ends in',
        sale_clock_text2: '',
        page_loaded: false,
        clockSaveChanges: false,
        show_saleClock: true,
        bg_color: {
          hue:0,
          saturation:0,
          brightness:0
        },
        fg_color: {
          hue:0,
          saturation:0,
          brightness:1
        },
        bg_color_popup: false,
        fg_color_popup: false,
        saleclock_text_align: 'left',
        saleClockFontSize: '26',
        hex_bg_color: '#000000',
        hex_fg_color: '#ffffff',
        saleClockBorderRadius: '0',
        enable_app: true,
        default_table_style: false,
        table_bg_clr: {
          hue:0,
          saturation:0,
          brightness:1
        },
        table_fg_clr: {
          hue:0,
          saturation:0,
          brightness:0
        },
        table_bg_clr_popup: false,
        table_fg_clr_popup: false,
        table_hex_bg_clr: '#ffffff',
        table_hex_fg_clr: '#000000',
        table_template_class:'wholesale-table-striped wholesale-table-hover',
        thead_bg_clr: {
          hue:0,
          saturation:0,
          brightness:1
        },
        thead_fg_clr: {
          hue:0,
          saturation:0,
          brightness:0
        },
        thead_bg_clr_popup: false,
        thead_fg_clr_popup: false,
        thead_hex_bg_clr: '#ffffff',
        thead_hex_fg_clr: '#000000',
        table_text_align: 'left',
        table_border_hex_clr: '#ddd',
        table_border_clr:{
          hue:0,
          saturation:0,
          brightness:0.86
        },
        table_border_clr_popup: false,
        tableWidgetSaveChanges: false,
        ppdt_prefix:'This product qualifies for ',
        ppdt_suffix:' discount!',
        ppdt_additionalStyle: false,
        ppdtSaveChanges: false,
        ppdtFontSize: "14",
        ppdtTextAlign: "left",
        ppdt_hex_fg_color:"#000000",
        ppdt_fg_color_popup: false,
        ppdt_hex_bg_color: "#eeeeee",
        ppdt_bg_color_popup: false,
        ppdt_fg_color: {
          hue:0,
          saturation:0,
          brightness:0
        },
        ppdt_bg_color: {
          hue:0,
          saturation:0,
          brightness:0.93
        },
        cart_total_price_html: '<span class="wholesale-cart-total"><del class="wholesale-crossout-price">[[strikethrough_price]]</del>[[wholesale_price]]</span>',
        cart_single_price_html: '<span class="wholesale-item-price"><del class="wholesale-crossout-price">[[strikethrough_price]]</del>[[wholesale_price]]</span>',
        cart_subtotal_price_html: '<span class="wholesale-subtotal"><del class="wholesale-crossout-price">[[strikethrough_price]]</del>[[wholesale_price]]</span>',
        product_price_html: '<span class="wholesale-item-price"><del class="wholesale-crossout-price">[[strikethrough_price]]</del>[[wholesale_price]]</span>',
        product_price_coll_html: '<span class="wholesale-item-price"><del class="wholesale-crossout-price">[[strikethrough_price]]</del>[[wholesale_price]]</span>',
        enable_additional_coupon_code: false,
        preDiscount_cart_total_price_html: '[[wholesale_price]]',
        preDiscount_cart_single_price_html: '[[wholesale_price]]',
        preDiscount_cart_subtotal_price_html: '[[wholesale_price]]',
        preDiscount_product_price_html: '[[wholesale_price]]',
        preDiscount_product_price_coll_html: '[[wholesale_price]]',
        showToast: false,
        toastMsg: '',
        toastError: false
      }
      if(!this.props.shop){
        this.props.history.push('/install');
      }
    }
    handleTabChange = (selectedTabIndex) => {
      this.setState({selected_tab: selectedTabIndex});
    };
    static contextTypes = {
      easdk: PropTypes.object,
    };
    showToastMessage(msg, error){
    	this.setState({
    	  showToast: true,
    	  toastMsg: msg,
    	  toastError: error||false,
    	});
    }

    dissmissToast = () =>{
    	this.setState({
    	  showToast: false,
    	});
    }

    
    componentDidMount(){
      axios.get(this.state.host_name+'/get-settings/'+this.props.shop)
      .then(response => {
        if(response.data.length > 0){
            var data = response.data[0];
            var tws = data.table_widget_style;
            var pp_discount_text = [];
            if(data.pp_discount_text && data.pp_discount_text.length > 0){
              pp_discount_text = data.pp_discount_text[0];
            }

            var price_design_config = data.price_design_config ? data.price_design_config[0]?data.price_design_config[0]:{}:{};
            var cart_total_price_html = price_design_config.cart_total_price_html || this.state.cart_total_price_html;
            var cart_single_price_html = price_design_config.cart_single_price_html || this.state.cart_single_price_html;
            var cart_subtotal_price_html = price_design_config.cart_subtotal_price_html || this.state.cart_subtotal_price_html;
            var product_price_html = price_design_config.product_price_html || this.state.product_price_html;
            var product_price_coll_html = price_design_config.product_price_coll_html || this.state.product_price_coll_html;
            cart_total_price_html = cart_total_price_html.replace(/(?:\\)/g,''); 
            cart_single_price_html = cart_single_price_html.replace(/(?:\\)/g,''); 
            cart_subtotal_price_html = cart_subtotal_price_html.replace(/(?:\\)/g,''); 
            product_price_html = product_price_html.replace(/(?:\\)/g,''); 
            product_price_coll_html = product_price_coll_html.replace(/(?:\\)/g,'');
            var preDiscount_cart_total_price_html = price_design_config.preDiscount_cart_total_price_html || this.state.preDiscount_cart_total_price_html;
            var preDiscount_cart_single_price_html = price_design_config.preDiscount_cart_single_price_html || this.state.preDiscount_cart_single_price_html;
            var preDiscount_cart_subtotal_price_html = price_design_config.preDiscount_cart_subtotal_price_html || this.state.preDiscount_cart_subtotal_price_html;
            var preDiscount_product_price_html = price_design_config.preDiscount_product_price_html || this.state.preDiscount_product_price_html;
            var preDiscount_product_price_coll_html = price_design_config.preDiscount_product_price_coll_html || this.state.preDiscount_product_price_coll_html;
            preDiscount_cart_total_price_html = preDiscount_cart_total_price_html.replace(/(?:\\)/g,''); 
            preDiscount_cart_single_price_html = preDiscount_cart_single_price_html.replace(/(?:\\)/g,''); 
            preDiscount_cart_subtotal_price_html = preDiscount_cart_subtotal_price_html.replace(/(?:\\)/g,''); 
            preDiscount_product_price_html = preDiscount_product_price_html.replace(/(?:\\)/g,''); 
            preDiscount_product_price_coll_html = preDiscount_product_price_coll_html.replace(/(?:\\)/g,''); 
            try{
              this.setState({
                page_loaded: true,
                discount_method_value: data.discount_method,
                compareAtPrice: data.compare_at_price,
                ShowAsCompareAtPrice: data.ShowAsCompareAtPrice,
                hide_cents:data.hide_cents,
                sale_clock_type: data.sale_clock.sale_clock_type,
                sale_clock_text1: data.sale_clock.sale_clock_text1,
                sale_clock_text2: data.sale_clock.sale_clock_text2,
                show_saleClock: data.sale_clock.show_sale_clock,
                bg_color: data.sale_clock.style.bg_color[0],
                fg_color: data.sale_clock.style.fg_color[0],
                saleClockFontSize: data.sale_clock.style.font_size,
                saleclock_text_align: data.sale_clock.style.text_align,
                hex_bg_color: data.sale_clock.style.hex_bg_color,
                hex_fg_color: data.sale_clock.style.hex_fg_color,
                saleClockBorderRadius: data.sale_clock.style.border_radius,
                enable_app: data.enable_app,
                default_table_style: tws.length > 0?tws[0].default_table_style:this.state.default_table_style,
                table_bg_clr: tws.length>0?tws[0].table_bg_clr:this.state.table_bg_clr,
                table_fg_clr: tws.length>0?tws[0].table_fg_clr:this.state.table_fg_clr,
                table_hex_bg_clr: tws.length>0?tws[0].table_hex_bg_clr:this.state.table_hex_bg_clr,
                table_hex_fg_clr: tws.length>0?tws[0].table_hex_fg_clr:this.state.table_hex_fg_clr,
                table_template_class: tws.length>0?tws[0].table_template_class:this.state.table_template_class,
                thead_bg_clr: tws.length>0?tws[0].thead_bg_clr:this.state.thead_bg_clr,
                thead_fg_clr: tws.length>0?tws[0].thead_fg_clr:this.state.thead_fg_clr,
                thead_hex_bg_clr: tws.length>0?tws[0].thead_hex_bg_clr:this.state.thead_hex_bg_clr,
                thead_hex_fg_clr: tws.length>0?tws[0].thead_hex_fg_clr:this.state.thead_hex_fg_clr,
                table_text_align: tws.length>0?tws[0].table_text_align:this.state.table_text_align,
                table_border_hex_clr: tws.length>0?tws[0].table_border_hex_clr:this.state.table_border_hex_clr,
                table_border_clr: tws.length>0?tws[0].table_border_clr:this.state.table_border_clr,
                isSetDiscountMethod: true,
                ppdt_suffix: pp_discount_text.text?pp_discount_text.text.suffix:this.state.ppdt_suffix,
                ppdt_prefix: pp_discount_text.text?pp_discount_text.text.prefix: this.state.ppdt_prefix,
                ppdt_additionalStyle: pp_discount_text.style?pp_discount_text.style.ppdt_additionalStyle: this.state.ppdt_additionalStyle,
                ppdtFontSize: pp_discount_text.style?pp_discount_text.style.ppdtFontSize: this.state.ppdtFontSize,
                ppdtTextAlign: pp_discount_text.style?pp_discount_text.style.ppdtTextAlign: this.state.ppdtTextAlign,
                ppdt_fg_color: pp_discount_text.style?pp_discount_text.style.ppdt_fg_color: this.state.ppdt_fg_color,
                ppdt_bg_color: pp_discount_text.style?pp_discount_text.style.ppdt_bg_color: this.state.ppdt_bg_color,
                ppdt_hex_fg_color: pp_discount_text.style?pp_discount_text.style.ppdt_hex_fg_color: this.state.ppdt_hex_fg_color,
                ppdt_hex_bg_color: pp_discount_text.style?pp_discount_text.style.ppdt_hex_bg_color: this.state.ppdt_hex_bg_color,
                cart_total_price_html,
                cart_subtotal_price_html,
                product_price_html,
                product_price_coll_html,
                cart_single_price_html,
                enable_additional_coupon_code: data.enable_additional_coupon_code?data.enable_additional_coupon_code: this.state.enable_additional_coupon_code,
                preDiscount_cart_total_price_html,
                preDiscount_cart_single_price_html,
                preDiscount_cart_subtotal_price_html,
                preDiscount_product_price_html,
                preDiscount_product_price_coll_html
              });
            }catch(e){
              // console.log("ERROR:",e);
            }
          }else{
            this.setState({isSetDiscountMethod: false});
            this.showToastMessage("Select a discount method.", true)
          }
      })
      .catch(error => {
        
      })
    }
    handleDiscountMethodChange = (checked, newValue) => {
      this.setState({ discount_method_value: newValue });
      const settingsData = {
          discount_method: newValue,
          shop: this.state.shop,
          shop_id: this.state.shop_id,
      };
      axios.put(this.state.host_name+'/update-discount-method', settingsData)
      .then( response => {
        if(response.data.status === 1){
          this.showToastMessage(response.data.message);
        }else{
          this.showToastMessage(response.data.message, true);
        }
      }).catch(error => {
       
      });
    };
    handleHide_cents = (value) => {
      this.setState({hide_cents: value, isProcessing_Hide_cents: true});
      
      const settingsData = {
        hide_cents: value,
        shop: this.state.shop
      };
      axios.put(this.state.host_name+'/update-hide-cents', settingsData)
      .then( response => {
        if(response.data.status === 1){
          this.showToastMessage(response.data.message);
        }else{
          this.showToastMessage(response.data.message, true);
        }
        this.setState({isProcessing_Hide_cents: false});
      })
      .catch(error => {
         
      });
    };
    handleShowAsCompareAtPrice = (value) => {
      this.setState({ShowAsCompareAtPrice: value, isProcessing_ShowAsCompareAtPrice: true});
      const settingsData = {
        show_compare_at_price: value,
        shop: this.state.shop
      };
      axios.put(this.state.host_name+'/update-show-compare-at-price', settingsData)
      .then( response => {
        if(response.data.status === 1){
          this.showToastMessage(response.data.message);
        }else{
          this.showToastMessage(response.data.message, true);
        }
        this.setState({isProcessing_ShowAsCompareAtPrice: false});
      })
      .catch(error => {
         
      });
    };
    handleCompareAtPrice = (value) => {
      this.setState({compareAtPrice: value,isProcessing_compareAtPrice: true});
      const settingsData = {
        compare_at_price: value,
        shop: this.state.shop,
        shop_id: this.state.shop_id
      };
      axios.put(this.state.host_name+'/update-compare-at-price', settingsData)
      .then( response => {
        if(response.data.status === 1){
          this.showToastMessage(response.data.message);
        }else{
          this.showToastMessage(response.data.message, true);
        }
        this.setState({isProcessing_compareAtPrice: false});
      })
      .catch(error => {
         
      });
    };
    saveClockType(){
      let text1 = this.state.sale_clock_text1 === ''?'Sale ends in':this.state.sale_clock_text1;
      let text2 = this.state.sale_clock_text2;
      let clockType = this.state.sale_clock_type;
      let show_sale_clock = this.state.show_saleClock;
      const data = {
        shop: this.state.shop,
        shop_id: this.state.shop_id,
        sale_clock: {
          sale_clock_text1: text1,
          sale_clock_text2: text2,
          sale_clock_type: clockType,
          show_sale_clock: show_sale_clock,
          style:{
            font_size: this.state.saleClockFontSize,
            bg_color: this.state.bg_color,
            fg_color: this.state.fg_color,
            text_align: this.state.saleclock_text_align,
            padding: '0.5rem 1rem',
            border_radius: this.state.saleClockBorderRadius,
            hex_bg_color: this.state.hex_bg_color,
            hex_fg_color: this.state.hex_fg_color
          }
        }
      };
      axios.put(this.state.host_name+'/update-sale-clock', data)
      .then( response => {
        if(response.data.status === 1){
          this.setState({clockSaveChanges:false});
          this.showToastMessage(response.data.message);
        }else{
          this.showToastMessage(response.data.message, true);
        } 
      })
      .catch(error => {
         
      });
    }
    saveTableWidget(){
      const tableWidgetData = {
        shop: this.state.shop,
        shop_id: this.state.shop_id,
        table_widget_style: {
          default_table_style: this.state.default_table_style,
          table_bg_clr: this.state.table_bg_clr,
          table_fg_clr: this.state.table_fg_clr,
          table_hex_bg_clr: this.state.table_hex_bg_clr,
          table_hex_fg_clr: this.state.table_hex_fg_clr,
          table_template_class: this.state.table_template_class,
          thead_bg_clr: this.state.thead_bg_clr,
          thead_fg_clr: this.state.thead_fg_clr,
          thead_hex_bg_clr: this.state.thead_hex_bg_clr,
          thead_hex_fg_clr: this.state.thead_hex_fg_clr,
          table_text_align: this.state.table_text_align,
          table_border_hex_clr: this.state.table_border_hex_clr,
          table_border_clr: this.state.table_border_clr	
        }
      };
      axios.put(this.state.host_name+'/update-table-widget', tableWidgetData)
      .then( response => {
        if(response.data.status === 1){
          this.setState({tableWidgetSaveChanges:false});
          this.showToastMessage(response.data.message);
        }else{
          this.showToastMessage(response.data.message, true);
        }
      })
      .catch(error => {
         
      });
    }
    savePPDT(){
      const text_and_style = {
        shop: this.state.shop,
        shop_id: this.state.shop_id,
        pp_discount_text: {
          "text":{
            prefix: this.state.ppdt_prefix,
            suffix: this.state.ppdt_suffix
          },
          "style":{
            ppdt_additionalStyle: this.state.ppdt_additionalStyle,
            ppdtFontSize: this.state.ppdtFontSize,
            ppdtTextAlign: this.state.ppdtTextAlign,
            ppdt_fg_color: this.state.ppdt_fg_color,
            ppdt_bg_color: this.state.ppdt_bg_color,
            ppdt_hex_fg_color: this.state.ppdt_hex_fg_color,
            ppdt_hex_bg_color: this.state.ppdt_hex_bg_color
          }
        }
      };
      axios.put(this.state.host_name+'/update-ppdt', text_and_style)
      .then( response => {
        if(response.data.status === 1){
          this.setState({ppdtSaveChanges:false});
          this.showToastMessage(response.data.message);
        }else{
          this.showToastMessage(response.data.message, true);
        }
      })
      .catch(error => {
         
      });
  
    }
    ppdtResetToDefault(){
      this.setState({
        ppdt_prefix:'This product qualifies for ',
        ppdt_suffix:' discount!',
        ppdt_additionalStyle: false,
        ppdtSaveChanges: true,
        ppdtFontSize: "14",
        ppdtTextAlign: "left",
        ppdt_hex_fg_color:"#000000",
        ppdt_fg_color_popup: false,
        ppdt_hex_bg_color: "#eeeeee",
        ppdt_bg_color_popup: false,
        ppdt_fg_color: {
          hue:0,
          saturation:0,
          brightness:0
        },
        ppdt_bg_color: {
          hue:0,
          saturation:0,
          brightness:0.93
        }
      });
    }
    hueToRgb(t1, t2, hue) {
      if (hue < 0) hue += 6;
      if (hue >= 6) hue -= 6;
      if (hue < 1) return (t2 - t1) * hue + t1;
      else if(hue < 3) return t2;
      else if(hue < 4) return (t2 - t1) * (4 - hue) + t1;
      else return t1;
    }
    rgbToHex (red,green,blue) {
      var r = this.toHex(red);
      var g = this.toHex(green);
      var b = this.toHex(blue);
      return "#" +  r + g + b;
    }
    toHex(n) {
      var hex = n.toString(16);
      while (hex.length < 2) {hex = "0" + hex; }
      return hex;
    }
    hslToRgb(hue, sat, light) {
      var t1, t2, r, g, b;
      hue = hue / 60;
      if ( light <= 0.5 ) {
        t2 = light * (sat + 1);
      } else {
        t2 = light + sat - (light * sat);
      }
      t1 = light * 2 - t2;
      r = this.hueToRgb(t1, t2, hue + 2) * 255;
      g = this.hueToRgb(t1, t2, hue) * 255;
      b = this.hueToRgb(t1, t2, hue - 2) * 255;
      r = Number(r.toFixed(0));
      g = Number(g.toFixed(0));
      b = Number(b.toFixed(0));
      return {r:r,g:g,b:b};
    }
    handleEnableApp = () => {
      this.setState(({enable_app}) => {
        return {enable_app: !enable_app};
      });
      const settingsData = {
        enable_app: !this.state.enable_app,
        shop: this.state.shop,
        shop_id: this.state.shop_id
      };
      axios.put(this.state.host_name+'/update-control-app', settingsData)
      .then( response => {
          if(response.data.status === 1){
            this.showToastMessage(response.data.message);
          }else{
            this.showToastMessage(response.data.message, true);
          }
      })
      .catch(error => {
         
      });
  
    };
    setSaleClockToDefault(){
      this.setState({
        sale_clock_type: '1',
        sale_clock_text1: 'Sale ends in',
        sale_clock_text2: '',
        clockSaveChanges: true,
        show_saleClock: true,
        bg_color: {
          hue:0,
          saturation:0,
          brightness:0
        },
        fg_color: {
          hue:0,
          saturation:0,
          brightness:1
        },
        bg_color_popup: false,
        fg_color_popup: false,
        saleclock_text_align: 'left',
        saleClockFontSize: '26',
        hex_bg_color: '#000000',
        hex_fg_color: '#ffffff',
        saleClockBorderRadius: '0',
      });
    }
    setTableWidgetToDefault(){
      this.setState({
        default_table_style: false,
        table_bg_clr: {
          hue:0,
          saturation:0,
          brightness:1
        },
        table_fg_clr: {
          hue:0,
          saturation:0,
          brightness:0
        },
        table_bg_clr_popup: false,
        table_fg_clr_popup: false,
        table_hex_bg_clr: '#ffffff',
        table_hex_fg_clr: '#000000',
        table_template_class:'wholesale-table-striped wholesale-table-hover',
        thead_bg_clr: {
          hue:0,
          saturation:0,
          brightness:1
        },
        thead_fg_clr: {
          hue:0,
          saturation:0,
          brightness:0
        },
        thead_bg_clr_popup: false,
        thead_fg_clr_popup: false,
        thead_hex_bg_clr: '#ffffff',
        thead_hex_fg_clr: '#000000',
        table_text_align: 'left',
        table_border_hex_clr: '#ddd',
        table_border_clr:{
          hue:0,
          saturation:0,
          brightness:0.86
        },
        table_border_clr_popup: false,
        tableWidgetSaveChanges: true
      });
    }
    countDownClock(end,clockId){
      try{
        this.setCountDownClock(this.state.sale_clock_type,end,clockId);
      }
      catch(e){}
    }
    clearAllInterval = (interval) => {
      for (var i = 1; i <= interval; i++){
        clearInterval(i);
      }
    };
    setCountDownClock(sale_clock_type,end,clockId){
      clearInterval(interval);
      let result;
      let start = new Date('2019-04-20 12:00:55');
      try{
        if(typeof start !== 'object' || typeof end !== 'object'){return undefined; }
        start = start.getTime();
        end = end.getTime();
        var timeSpan = end - start;
        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(timeSpan / (1000 * 60 * 60 * 24));
        var hours = Math.floor((timeSpan % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((timeSpan % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((timeSpan % (1000 * 60)) / 1000);
        result = {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds
        };
        if(result.seconds < 0){
          result.minutes--;
          result.seconds += 60;
        }
        if(result.minutes < 0){
          result.hours--;
          result.minutes += 60;
        }
        if(result.hours < 0){
          result.days--;
          result.hours += 24;
        }
        if(result.days > 0 || result.hours > 0 || result.minutes > 0 || result.seconds > 0){
          interval = setInterval(startCountDown,1000);
        }
        function startCountDown(){
          result.seconds--;
          if(result.seconds < 0){
            result.minutes--;
            result.seconds += 60;
          }
          if(result.minutes < 0){
            result.hours--;
            result.minutes += 60;
          }
          if(result.hours < 0){
            result.days--;
            result.hours += 24;
          }
          if(result.days < 0 || result.hours < 0 || result.minutes < 0 || result.seconds < -1){
            clearInterval(interval);
            if(clockId !== null){
              try{
                let CLOCKID = document.getElementById(clockId);
                if(CLOCKID !== null){
                  CLOCKID.innerHTML = '';
                }
              }catch(e){}
            }
            try{
              window.location.reload(true);
            }catch(e){}
          }
          else{
            let ends_message = '';
            if(result.days > 0){
              if(sale_clock_type === 1){
                ends_message += result.days+'days, ';
              }
              else if(sale_clock_type === 9){
                ends_message += result.days+'days ';
              }
              else{
                ends_message += result.days+'d: ';
              }
            }
            if(result.hours > 0){
              if(sale_clock_type === 1){
                ends_message += result.hours+'hours, ';
              }
              else if(sale_clock_type === 9){
                ends_message += result.hours+'hours ';
              }
              else{
                ends_message += result.hours+'h: ';
              }
            }
            if(result.minutes > 0){
              let minutes = result.minutes;
              if(result.minutes < 10){
                minutes = '0'+minutes;
              }
              if(sale_clock_type === 1){
                ends_message += minutes+'minutes, ';
              }
              else if(sale_clock_type === 9){
                ends_message += minutes+'minutes ';
              }
              else{
                ends_message += minutes+'m: ';
              }
            }
            if(result.seconds >= 0){
              let seconds = result.seconds;
              if(result.seconds < 10){
                seconds = '0'+seconds;
              }
              if(sale_clock_type === 1){
                ends_message += seconds+'seconds';
              }
              else if(sale_clock_type === 9){
                ends_message += seconds+'seconds';
              }
              else{
                ends_message += seconds+'s';
              }
            }
            if(ends_message !== ''){
              if(clockId !== null){
                try{
                  let CLOCKID = document.getElementById(clockId);
                  if(CLOCKID !== null){
                    CLOCKID.innerHTML = ends_message;
                  }
                }catch(e){
                  clearInterval(interval);
                }
              }
            }
          }
          window.onbeforeunload = function(){
            clearInterval(interval);
          };
        }
        return result;
      }
      catch(e){
        clearInterval(interval);
      }
    }
    savePriceDesign(){
      var {
        cart_total_price_html,
        cart_single_price_html,
        cart_subtotal_price_html,
        product_price_html,
        product_price_coll_html,
        preDiscount_cart_total_price_html,
        preDiscount_cart_single_price_html,
        preDiscount_cart_subtotal_price_html,
        preDiscount_product_price_html,
        preDiscount_product_price_coll_html,
        shop
      } = this.state;
      if(
        !cart_total_price_html ||
        !cart_single_price_html ||
        !cart_subtotal_price_html ||
        !product_price_html ||
        !product_price_coll_html ||
        !preDiscount_cart_total_price_html ||
        !preDiscount_cart_single_price_html ||
        !preDiscount_cart_subtotal_price_html ||
        !preDiscount_product_price_html ||
        !preDiscount_product_price_coll_html ||
        cart_total_price_html.trim() === "" ||
        cart_single_price_html.trim() === "" ||
        cart_subtotal_price_html.trim() === "" ||
        product_price_html.trim() === "" ||
        product_price_coll_html.trim() === "" || 
        preDiscount_cart_total_price_html.trim() === "" ||
        preDiscount_cart_single_price_html.trim() === "" ||
        preDiscount_cart_subtotal_price_html.trim() === "" ||
        preDiscount_product_price_html.trim() === "" ||
        preDiscount_product_price_coll_html.trim() === "" 
      ){
        this.showToastMessage("All fields are required", true);
        return false;
      }
// 1
      if(
        cart_total_price_html.indexOf("[[wholesale_price]]") === -1 &&
        cart_total_price_html.indexOf("[[strikethrough_price]]") === -1 
      ){
        this.showToastMessage("cart total price fields missing price config", true);
        return false;
      }
// 2
      if(
        cart_single_price_html.indexOf("[[wholesale_price]]") === -1 &&
        cart_single_price_html.indexOf("[[strikethrough_price]]") === -1 
      ){
        this.showToastMessage("Cart single fields missing price config", true);
        return false;
      }
// 3
      if(
        cart_subtotal_price_html.indexOf("[[wholesale_price]]") === -1 &&
        cart_subtotal_price_html.indexOf("[[strikethrough_price]]") === -1 
      ){
        this.showToastMessage("Subtotal price fields missing price config", true);
        return false;
      }
// 4
      if(
        product_price_html.indexOf("[[wholesale_price]]") === -1 &&
        product_price_html.indexOf("[[strikethrough_price]]") === -1 
      ){
        this.showToastMessage("Product price fields missing price config", true);
        return false;
      }
// 5
      if(
        product_price_coll_html.indexOf("[[wholesale_price]]") === -1 &&
        product_price_coll_html.indexOf("[[strikethrough_price]]") === -1 
      ){
        this.showToastMessage("Product price for coll fields missing price config", true);
        return false;
      }

      cart_total_price_html = cart_total_price_html.replace(/(?:")/g,'\\"')
      .replace(/(?:\r\n|\r|\n)/g, '<br>');
      cart_single_price_html = cart_single_price_html.replace(/(?:")/g,'\\"')
      .replace(/(?:\r\n|\r|\n)/g, '<br>');
      cart_subtotal_price_html = cart_subtotal_price_html.replace(/(?:")/g,'\\"')
      .replace(/(?:\r\n|\r|\n)/g, '<br>');
      product_price_html = product_price_html.replace(/(?:")/g,'\\"')
      .replace(/(?:\r\n|\r|\n)/g, '<br>');
      product_price_coll_html = product_price_coll_html.replace(/(?:")/g,'\\"')
      .replace(/(?:\r\n|\r|\n)/g, '<br>');

      preDiscount_cart_total_price_html = preDiscount_cart_total_price_html.replace(/(?:")/g,'\\"')
      .replace(/(?:\r\n|\r|\n)/g, '<br>');
      preDiscount_cart_single_price_html = preDiscount_cart_single_price_html.replace(/(?:")/g,'\\"')
      .replace(/(?:\r\n|\r|\n)/g, '<br>');
      preDiscount_cart_subtotal_price_html = preDiscount_cart_subtotal_price_html.replace(/(?:")/g,'\\"')
      .replace(/(?:\r\n|\r|\n)/g, '<br>');
      preDiscount_product_price_html = preDiscount_product_price_html.replace(/(?:")/g,'\\"')
      .replace(/(?:\r\n|\r|\n)/g, '<br>');
      preDiscount_product_price_coll_html = preDiscount_product_price_coll_html.replace(/(?:")/g,'\\"')
      .replace(/(?:\r\n|\r|\n)/g, '<br>');


      var priceDesignData = {
        shop,
        price_design_config: {
          cart_total_price_html,
          cart_single_price_html,
          cart_subtotal_price_html,
          product_price_html,
          product_price_coll_html,
          preDiscount_cart_total_price_html,
          preDiscount_cart_single_price_html,
          preDiscount_cart_subtotal_price_html,
          preDiscount_product_price_html,
          preDiscount_product_price_coll_html
        }
      };
      axios.put(this.state.host_name+'/update-price-design-settings', priceDesignData)
      .then( response => {
        if(response.data.status === 1){
          this.showToastMessage(response.data.message);
        }
        else{
          this.showToastMessage(response.data.message, true);
        }
      })
      .catch(error => {
          this.showToastMessage("Server error", true);
      });
    }
    resetToDefaultPriceDesign(){
      this.setState({
        cart_total_price_html: '<span class="wholesale-cart-total"><del class="wholesale-crossout-price">[[strikethrough_price]]</del>[[wholesale_price]]</span>',
        cart_single_price_html: '<span class="wholesale-item-price"><del class="wholesale-crossout-price">[[strikethrough_price]]</del>[[wholesale_price]]</span>',
        cart_subtotal_price_html: '<span class="wholesale-subtotal"><del class="wholesale-crossout-price">[[strikethrough_price]]</del>[[wholesale_price]]</span>',
        product_price_html: '<span class="wholesale-item-price"><del class="wholesale-crossout-price">[[strikethrough_price]]</del>[[wholesale_price]]</span>',
        product_price_coll_html: '<span class="wholesale-item-price"><del class="wholesale-crossout-price">[[strikethrough_price]]</del>[[wholesale_price]]</span>',
        preDiscount_cart_total_price_html: '[[wholesale_price]]',
        preDiscount_cart_single_price_html: '[[wholesale_price]]',
        preDiscount_cart_subtotal_price_html: '[[wholesale_price]]',
        preDiscount_product_price_html: '[[wholesale_price]]',
        preDiscount_product_price_coll_html: '[[wholesale_price]]',
      });
    }

    saveAdditionalDiscountCodeField(value){
      var data = {
        shop: this.state.shop,
        enable_additional_coupon_code: value
      };
      axios.put(this.state.host_name+'/update-additional-coupon-code-field-settings', data)
      .then( response => {
        if(response.data.status === 1){
          this.showToastMessage(response.data.message);
        }
        else{
          this.showToastMessage(response.data.message, true);
        }
      })
      .catch(error => {
          this.showToastMessage("Server error", true);
      });
    }
    
    render() {
      const {
        discount_method_value,compareAtPrice,isProcessing_compareAtPrice,isProcessing_Hide_cents,hide_cents, ShowAsCompareAtPrice,isProcessing_ShowAsCompareAtPrice,sale_clock_type,sale_clock_text2,sale_clock_text1,clockSaveChanges,show_saleClock,bg_color,fg_color,bg_color_popup,fg_color_popup,saleclock_text_align,saleClockFontSize,hex_bg_color,hex_fg_color,saleClockBorderRadius,enable_app,default_table_style,table_bg_clr,table_fg_clr,table_bg_clr_popup,table_fg_clr_popup,table_hex_bg_clr,table_hex_fg_clr,table_template_class,thead_hex_fg_clr,thead_hex_bg_clr,thead_fg_clr_popup,thead_bg_clr_popup,thead_fg_clr,thead_bg_clr,table_text_align,table_border_clr,table_border_hex_clr,table_border_clr_popup,tableWidgetSaveChanges,cart_total_price_html,cart_single_price_html,cart_subtotal_price_html,product_price_html,product_price_coll_html,preDiscount_cart_total_price_html,
        preDiscount_cart_single_price_html,
        preDiscount_cart_subtotal_price_html,
        preDiscount_product_price_html,
        preDiscount_product_price_coll_html
      } = this.state;

          
    const contentStatus = enable_app ? 'Disable app' : 'Enable app';
    const textStatus = enable_app ? 'enabled' : 'disabled';

    let saleClockOptions = [
      {
        label:'2days, 1hour, 59minutes, 43seconds (Count down clock)',value: '1'
      },
      {
        label:'2days 1hour 59minutes 42seconds (Count down clock)',value: '9'
      },
      {
        label:'2d:1h:45m:42s (Count down clock)',value:'8'
      },
      {
        label:'Friday, June 16, 2019, 5:12:26 PM',value:'3'
      },
      {
        label:'Jun 16, 2019, 5:12:55 PM',value:'6'
      },
      {
        label:'Friday, June 16, 2019',value:'4'
      },
      {
        label:'June 16, 2019',value:'5'
      },
      {
        label:'Jun 16, 2019',value:'7'
      },
      {
        label:'2 days',value:'2'
      }
    ];

    const bg_color_activator = (
      <TextField
        readOnly={false}
        label="Background color"
        value={hex_bg_color}
        autoComplete={false}
        onChange={(value)=>{
          this.setState({hex_bg_color:value,bg_color_popup:false,clockSaveChanges:true});
        }}
        onFocus={()=>{
          this.setState({bg_color_popup:true});
        }}
      />
    );

    const fg_color_activator = (
      <TextField
        readOnly={false}
        label="Foreground color"
        value={hex_fg_color}
        autoComplete={false}
        onChange={(value)=>{
          this.setState({hex_fg_color:value,fg_color_popup:false,clockSaveChanges:true});
        }}
        onFocus={()=>{
          this.setState({fg_color_popup:true});
        }}
      />
    );

    const table_bg_clr_activator = (
      <TextField
        readOnly={false}
        label="Table background color"
        value={table_hex_bg_clr}
        autoComplete={false}
        onChange={(value)=>{
          this.setState({table_hex_bg_clr:value,table_bg_clr_popup:false,clockSaveChanges:true});
        }}
        onFocus={()=>{
          this.setState({table_bg_clr_popup:true});
        }}
      />
    );

    const table_fg_clr_activator = (
      <TextField
        readOnly={false}
        label="Table foreground color"
        value={table_hex_fg_clr}
        autoComplete={false}
        onChange={(value)=>{
          this.setState({table_hex_fg_clr:value,table_fg_clr_popup:false,clockSaveChanges:true});
        }}
        onFocus={()=>{
          this.setState({table_fg_clr_popup:true});
        }}
      />
    );

    const thead_bg_clr_activator = (
      <TextField
        readOnly={false}
        label="Table header background color"
        value={thead_hex_bg_clr}
        autoComplete={false}
        onChange={(value)=>{
          this.setState({thead_hex_bg_clr:value,thead_bg_clr_popup:false,clockSaveChanges:true});
        }}
        onFocus={()=>{
          this.setState({thead_bg_clr_popup:true});
        }}
      />
    );

    const thead_fg_clr_activator = (
      <TextField
        readOnly={false}
        label="Table header foreground color"
        value={thead_hex_fg_clr}
        autoComplete={false}
        onChange={(value)=>{
          this.setState({thead_hex_fg_clr:value,thead_fg_clr_popup:false,clockSaveChanges:true});
        }}
        onFocus={()=>{
          this.setState({thead_fg_clr_popup:true});
        }}
      />
    );
    
    const table_border_clr_activator = (
      <TextField
        readOnly={false}
        label="Table border color"
        value={table_border_hex_clr}
        autoComplete={false}
        onChange={(value)=>{
          this.setState({table_border_hex_clr:value,table_border_clr_popup:false,clockSaveChanges:true});
        }}
        onFocus={()=>{
          this.setState({table_border_clr_popup:true});
        }}
      />
    );

    const previewStyle = {
      background:hex_bg_color?hex_bg_color:'hsla('+bg_color.hue+','+bg_color.saturation*100+'%,'+bg_color.brightness*100+'%,1)',
      color:hex_fg_color?hex_fg_color:'hsla('+fg_color.hue+','+fg_color.saturation*100+'%,'+fg_color.brightness*100+'%,1)',textAlign:saleclock_text_align,
      fontSize:saleClockFontSize+'px',
      padding:'1rem 1rem',
      borderRadius:saleClockBorderRadius+'px',
      lineHeight: 'initial'
    };

    var tableStyle = {
      background: table_hex_bg_clr,
      color: table_hex_fg_clr,
      width: '100%',
      textAlign: table_text_align
    };

    var tableBorderColor = {
      borderColor: table_border_hex_clr
    }

    var tableStyleThead = {
      background: thead_hex_bg_clr,
      color: thead_hex_fg_clr,
      textAlign: table_text_align
    };
    
    if(!default_table_style){
      tableStyle = {};
      tableStyleThead = {};
      tableBorderColor = {};
    }

    let section_appEnableDisable = <div style={{marginBottom:'2rem'}}>
      <SettingToggle
        action={{
          content: contentStatus,
          onAction: this.handleEnableApp,
        }}
        enabled={enable_app}
      >
        Application is <strong>{textStatus}</strong>
      </SettingToggle>
    </div>;
    
    let section_discountMethod = <div>
        <Stack vertical>
          <RadioButton
            label="Coupon code"
            helpText="Discount will apply on entire order through coupon code."
            id="coupon_code"
            name="discount_method"
            checked={discount_method_value === 'coupon_code'}
            onChange={this.handleDiscountMethodChange}
          />
          <RadioButton
            label="Line items"
            helpText={<span>Discount price will appear separately for each added item in the cart.<br></br>Shipping Charges, Only apply for line item method.</span>}
            checked={discount_method_value === 'line_items'}
            id="line_items"
            name="discount_method"
            onChange={this.handleDiscountMethodChange}
            disabled={!this.props.change_setting}
          />
          {
            !this.props.change_setting?
              <Banner status="critical">
                <p>
                  Fix amount base Volume discount does doesn't support "Line items" method.
                </p>
                <p>
                  <strong>Tips:</strong>Tips: To enable "Line Items" method, Please first remove the 'fixed amount volume discount' rule under the "Discount" rules. 
                </p>
              </Banner>
              :''
          }
        </Stack>
      </div>
    
    let section_priceControl = <div>
      <Stack vertical>
        <Checkbox
          checked={compareAtPrice}
          label="Show crossout price"
          onChange={this.handleCompareAtPrice}
          disabled={isProcessing_compareAtPrice}
        /> 
        { 
          this.props.snippet_version !== 0 && this.props.snippet_version !== void 0 && waio.compareVersionNumbers(this.props.snippet_version, _Modules.find(x => x._id===1).snippet_version) !== -1?
          <Stack vertical>
            <Checkbox checked={ShowAsCompareAtPrice}
              label="Show campare at price as crossout price"
              onChange={this.handleShowAsCompareAtPrice}
              disabled={isProcessing_ShowAsCompareAtPrice}
            />
            <Checkbox
              checked={hide_cents}
              label="Hide decimal points when decimal value is equal to .00"
              onChange={this.handleHide_cents}
              disabled={isProcessing_Hide_cents}
            />
          </Stack>: null
      }
      </Stack>
    </div>;
    
    let section_salesClock = <div>
      <Stack vertical>
        <Checkbox
          label="Show sale clock on product page"
          checked={show_saleClock}
          onChange={(value)=>{this.setState({show_saleClock:value,clockSaveChanges:true})}}
        ></Checkbox>
        {
          show_saleClock?
          <div>
            <Stack wrap={false} alignment="leading" spacing="tight">
              <Stack.Item fill>
                <Stack distribution="fill" spacing="tight">
                  <TextField
                    value={sale_clock_text1}
                    label="Prefix"
                    labelHidden={true}
                    onChange={(text)=>{this.setState({sale_clock_text1:text,clockSaveChanges:true})}}
                    error={sale_clock_text1 === ''?true:false}
                  ></TextField>
                  <Select
                    label={sale_clock_text1 || 'Sale ends in'}
                    labelInline={true}
                    placeholder={'Select sale clock'}
                    options={saleClockOptions}
                    value={sale_clock_type}
                    onChange={(value)=>{ this.setState({sale_clock_type:value,clockSaveChanges: true})}}
                  ></Select>
                  <TextField
                    label="Suffix text"
                    placeholder="Custom text"
                    labelHidden={true}
                    value={sale_clock_text2}
                    onChange={(text)=>{this.setState({sale_clock_text2:text,clockSaveChanges:true})}}
                  ></TextField>
                </Stack>
              </Stack.Item>
            </Stack>
            <div style={{marginBottom:'1.5rem',marginTop:'1.5rem'}}>
              <Stack wrap={false} alignment="leading" spacing="tight">
                <Stack.Item fill>
                  <Stack distribution="fill" spacing="tight">
                    <Popover
                      active={bg_color_popup}
                      activator={bg_color_activator}
                      onClose={(value)=>{ this.setState({bg_color_popup:false})}}
                      preferredPosition="mostSpace"
                      sectioned
                    >
                    <ColorPicker
                      onChange={(value)=>{
                        let rgb  = this.hslToRgb(value.hue,value.saturation,value.brightness);
                        let hex = this.rgbToHex(rgb.r,rgb.g,rgb.b);
                        this.setState({bg_color:value,hex_bg_color:hex,clockSaveChanges:true});
                      }}
                      color={bg_color}
                    />
                    </Popover>
                    <Popover
                      active={fg_color_popup}
                      activator={fg_color_activator}
                      onClose={(value)=>{ this.setState({fg_color_popup:false})}}
                      preferredPosition="mostSpace"
                      sectioned
                    >
                    <ColorPicker
                      onChange={(value)=>{
                        let rgb  = this.hslToRgb(value.hue,value.saturation,value.brightness);
                        let hex = this.rgbToHex(rgb.r,rgb.g,rgb.b);
                        this.setState({fg_color:value,hex_fg_color:hex,clockSaveChanges:true});
                      }}
                      color={fg_color}
                    />
                    </Popover>
                    <Select
                      options={[
                        {value:'left',label:'Left'},
                        {value:'center',label:'Center'},
                        {value:'right',label:'Right'},
                      ]}
                      label="Text align"
                      onChange={(value) => this.setState({saleclock_text_align:value,clockSaveChanges:true})}
                      value={saleclock_text_align}
                    >
                    </Select>
                  </Stack>
                </Stack.Item>
              </Stack>
              <br></br>
              <Stack wrap={false} alignment="leading" spacing="tight">
                <Stack.Item fill>
                  <Stack distribution="fill" spacing="tight">
                    <RangeSlider
                      label="Font size"
                      labelHidden={true}
                      value={saleClockFontSize}
                      onChange={(value)=>{this.setState({saleClockFontSize:value,clockSaveChanges:true})}}
                      min='8'
                      max='36'
                      step='2'
                      output={true}
                      prefix="Font size"
                    ></RangeSlider>
                    <RangeSlider
                      label="Border radius"
                      value={saleClockBorderRadius}
                      labelHidden={true}
                      onChange={(value)=>{this.setState({saleClockBorderRadius:value,clockSaveChanges:true})}}
                      min='0'
                      max='50'
                      step='1'
                      output={true}
                      prefix="Border radius"
                    ></RangeSlider>
                  </Stack>
                </Stack.Item>
              </Stack>
            </div>
            <h2>Preview:</h2>
            <div style={previewStyle}>
              {
                sale_clock_text1 || 'Sale ends in'
              }
              &nbsp;
              {
                sale_clock_type === 1 || sale_clock_type === 8 || sale_clock_type === 9 ?
                <span id="count_down_clock">
                {this.countDownClock(new Date('2019-04-27 12:00:00'),'count_down_clock')}
                </span>
                :null
              }
              {
                saleClockOptions.map((option,i) => {
                  return <span key={i}>
                  {
                    sale_clock_type === 1 || sale_clock_type === 8 || sale_clock_type === 9 ?null:
                    option.value === sale_clock_type ?
                    (option.label).replace("(Count down clock)","")
                    :''
                  }
                  </span>;
                })
              }
              {
                sale_clock_text2
              }
            </div>
          </div>
          :null
        }
        </Stack>
        <PageActions
          secondaryActions={{content:"Reset to default",onAction:()=>{this.setSaleClockToDefault()}}}
          primaryAction={{content: 'Save', disabled:!clockSaveChanges, onAction: ()=>{this.saveClockType()}}}
        ></PageActions>
      </div>;

    let section_tableStyleWidget = <div>
        <Stack vertical>
        <Select
          options={[
            {label:"Simple table",value:"wholesale-table-simple"},
            {label:"Simple and hoverable rows",value:"wholesale-table-simple wholesale-table-hover"},
            {label:"Bordered table",value:"wholesale-table-bordered"},
            {label:"Bordered and hoverable rows",value:"wholesale-table-bordered wholesale-table-hover"},
            {label:"No bordered table",value:"wholesale-table-no-border"},
            {label:"No bordered and hoverable rows",value:"wholesale-table-no-border wholesale-table-hover"},
            {label:"Striped rows",value:"wholesale-table-striped"},
            {label:"Striped and hoverable rows",value:"wholesale-table-striped wholesale-table-hover"}
          ]}
          value={table_template_class}
          label="Select table template"
          onChange={(value)=>{ this.setState({table_template_class:value,tableWidgetSaveChanges:true})}}
        >
        </Select>
        <Checkbox
          label="Apply additional styles"
          checked={default_table_style}
          onChange={(value)=>{this.setState({default_table_style:value,tableWidgetSaveChanges:true})}}
        />
        {
          default_table_style?
          <div>
            <Stack vertical>
              <Stack wrap={false} alignment="leading" spacing="tight">
                <Stack.Item fill>
                    <Stack distribution="fill" spacing="tight">
                      <Popover
                        active={table_bg_clr_popup}
                        activator={table_bg_clr_activator}
                        onClose={(value)=>{ this.setState({table_bg_clr_popup:false})}}
                        preferredPosition="mostSpace"
                        sectioned
                      >
                      <ColorPicker
                        onChange={(value)=>{
                          let rgb  = this.hslToRgb(value.hue,value.saturation,value.brightness);
                          let hex = this.rgbToHex(rgb.r,rgb.g,rgb.b);
                          this.setState({table_bg_clr:value,table_hex_bg_clr:hex,tableWidgetSaveChanges:true});
                        }}
                        color={table_bg_clr}
                      />
                      </Popover>
                      <Popover
                        active={table_fg_clr_popup}
                        activator={table_fg_clr_activator}
                        onClose={(value)=>{ this.setState({table_fg_clr_popup:false})}}
                        preferredPosition="mostSpace"
                        sectioned
                      >
                      <ColorPicker
                        onChange={(value)=>{
                          let rgb  = this.hslToRgb(value.hue,value.saturation,value.brightness);
                          let hex = this.rgbToHex(rgb.r,rgb.g,rgb.b);
                          this.setState({table_fg_clr:value,table_hex_fg_clr:hex,tableWidgetSaveChanges:true});
                        }}
                        color={table_fg_clr}
                      />
                      </Popover>
                    </Stack>
                  </Stack.Item>
              </Stack>
              <Stack wrap={false} alignment="leading" spacing="tight">
                <Stack.Item fill>
                    <Stack distribution="fill" spacing="tight">
                      <Popover
                        active={thead_bg_clr_popup}
                        activator={thead_bg_clr_activator}
                        onClose={(value)=>{ this.setState({thead_bg_clr_popup:false})}}
                        preferredPosition="mostSpace"
                        sectioned
                      >
                      <ColorPicker
                        onChange={(value)=>{
                          let rgb  = this.hslToRgb(value.hue,value.saturation,value.brightness);
                          let hex = this.rgbToHex(rgb.r,rgb.g,rgb.b);
                          this.setState({thead_bg_clr:value,thead_hex_bg_clr:hex,tableWidgetSaveChanges:true});
                        }}
                        color={thead_bg_clr}
                      />
                      </Popover>
                      <Popover
                        active={thead_fg_clr_popup}
                        activator={thead_fg_clr_activator}
                        onClose={(value)=>{ this.setState({thead_fg_clr_popup:false})}}
                        preferredPosition="mostSpace"
                        sectioned
                      >
                      <ColorPicker
                        onChange={(value)=>{
                          let rgb  = this.hslToRgb(value.hue,value.saturation,value.brightness);
                          let hex = this.rgbToHex(rgb.r,rgb.g,rgb.b);
                          this.setState({thead_fg_clr:value,thead_hex_fg_clr:hex,tableWidgetSaveChanges:true});
                        }}
                        color={thead_fg_clr}
                      />
                      </Popover>
                    </Stack>
                  </Stack.Item>
              </Stack>
              <Stack wrap={false} alignment="leading" spacing="tight">
                <Stack.Item fill>
                    <Stack distribution="fill" spacing="tight">
                      <Popover
                        active={table_border_clr_popup}
                        activator={table_border_clr_activator}
                        onClose={(value)=>{ this.setState({table_border_clr_popup:false})}}
                        preferredPosition="mostSpace"
                        sectioned
                      >
                      <ColorPicker
                        onChange={(value)=>{
                          let rgb  = this.hslToRgb(value.hue,value.saturation,value.brightness);
                          let hex = this.rgbToHex(rgb.r,rgb.g,rgb.b);
                          this.setState({table_border_clr:value,table_border_hex_clr:hex,tableWidgetSaveChanges:true});
                        }}
                        color={table_border_clr}
                      />
                      </Popover>
                      <Select
                      label="Table text align"
                        value={table_text_align}
                        options={[
                          {label:"Left",value:"left"},
                          {label:"Center",value:"center"},
                          {label:"Right",value:"right"},
                        ]}
                        onChange={(value)=>{
                          this.setState({table_text_align:value,tableWidgetSaveChanges:true});
                        }}
                      ></Select>
                    </Stack>
                  </Stack.Item>
              </Stack>
            </Stack>
          </div>
          :null
        }

        <div>
          <TextStyle variation="strong">Preview: </TextStyle>
          <table style={tableStyle} className={'wholesale-table '+table_template_class}>
          <thead style={tableStyleThead}>
            <tr>
              <th style={tableBorderColor}>Heading 1</th>
              <th style={tableBorderColor}>Heading 2</th>
              <th style={tableBorderColor}>Heading 3</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={tableBorderColor}>Dummy data</td>
              <td style={tableBorderColor}>Dummy data</td>
              <td style={tableBorderColor}>Dummy data</td>
            </tr>
            <tr>
              <td style={tableBorderColor}>Dummy data</td>
              <td style={tableBorderColor}>Dummy data</td>
              <td style={tableBorderColor}>Dummy data</td>
            </tr>
            <tr>
              <td style={tableBorderColor}>Dummy data</td>
              <td style={tableBorderColor}>Dummy data</td>
              <td style={tableBorderColor}>Dummy data</td>
            </tr>
          </tbody>
        </table>
        </div>
        </Stack>
        <PageActions
          secondaryActions={{content:"Reset to default",onAction:()=>{this.setTableWidgetToDefault()}}}
          primaryAction={{content: 'Save', disabled:!tableWidgetSaveChanges, onAction: ()=>{this.saveTableWidget()}}}
        ></PageActions>
    </div>;

    let display_settings = <div>
      <Stack>
        <Checkbox
          label={`Enable additional coupon code field at cart page`}
          value={this.state.enable_additional_coupon_code}
          checked={this.state.enable_additional_coupon_code}
          onChange={(value) => {
            this.saveAdditionalDiscountCodeField(value);
            this.setState({
              enable_additional_coupon_code: value
            });
          }}
          id="enable-additional-coupon"
          name="enable_additional_coupon_code"
        ></Checkbox>
      </Stack>
    </div>;

    const cart_price__section = <div>
      <Card.Section fullWidth>
        <Stack vertical distribution="fill" spacing="loose">
        <TextField
          label="Cart item total price *"
          helpText={<span>Use html to configure price design and this value must contains <TextStyle variation="code">[[wholesale_price]]</TextStyle> and <TextStyle variation="code">[[strikethrough_price]]</TextStyle></span>}
          multiline
          value={cart_total_price_html}
          error={cart_total_price_html ?false:"This field can't be blank"}
          onChange={v=>{this.setState({cart_total_price_html:v})}}
        ></TextField>

        <TextField
          label={<span>Pre discount Cart item total price *<Badge>Pre discount</Badge></span>}
          helpText={<span>Use html to configure price design and this value must contains <TextStyle variation="code">[[wholesale_price]]</TextStyle> and <TextStyle variation="code">[[strikethrough_price]]</TextStyle></span>}
          multiline
          value={preDiscount_cart_total_price_html}
          error={preDiscount_cart_total_price_html ?false:"This field can't be blank"}
          onChange={v=>{this.setState({preDiscount_cart_total_price_html:v})}}
        ></TextField>

        <TextField
          label="Cart item signle price *"
          helpText={<span>Use html to configure price design and this value must contains <TextStyle variation="code">[[wholesale_price]]</TextStyle> and <TextStyle variation="code">[[strikethrough_price]]</TextStyle></span>}
          multiline
          value={cart_single_price_html}
          error={cart_single_price_html ?false:"This field can't be blank"}
          onChange={v=>{this.setState({cart_single_price_html:v})}}
          ></TextField>

        <TextField
          label={<span>Cart item signle price *<Badge>Pre discount</Badge></span>}
          helpText={<span>Use html to configure price design and this value must contains <TextStyle variation="code">[[wholesale_price]]</TextStyle> and <TextStyle variation="code">[[strikethrough_price]]</TextStyle></span>}
          multiline
          value={preDiscount_cart_single_price_html}
          error={preDiscount_cart_single_price_html ?false:"This field can't be blank"}
          onChange={v=>{this.setState({preDiscount_cart_single_price_html:v})}}
          ></TextField>


        <TextField
          label="Cart subtotal price *"
          helpText={<span>Use html to configure price design and this value must contains <TextStyle variation="code">[[wholesale_price]]</TextStyle> and <TextStyle variation="code">[[strikethrough_price]]</TextStyle></span>}
          multiline
          value={cart_subtotal_price_html}
          error={cart_subtotal_price_html ?false:"This field can't be blank"}
          onChange={v=>{this.setState({cart_subtotal_price_html:v})}}
          ></TextField>

        <TextField
          label={<span>Cart subtotal price *<Badge>Pre discount</Badge></span>}
          helpText={<span>Use html to configure price design and this value must contains <TextStyle variation="code">[[wholesale_price]]</TextStyle> and <TextStyle variation="code">[[strikethrough_price]]</TextStyle></span>}
          multiline
          value={preDiscount_cart_subtotal_price_html}
          error={preDiscount_cart_subtotal_price_html ?false:"This field can't be blank"}
          onChange={v=>{this.setState({preDiscount_cart_subtotal_price_html:v})}}
          ></TextField>
          </Stack>
      </Card.Section>
      <div className="border-top-line" style={{marginBottom:'1rem',marginTop:'1rem'}}></div>
      <Card.Section fullWidth>
        <TextField
          label="Product price (product template) *"
          helpText={<span>Use html to configure price design and this value must contains <TextStyle variation="code">[[wholesale_price]]</TextStyle> and <TextStyle variation="code">[[strikethrough_price]]</TextStyle></span>}
          multiline
          value={product_price_html}
          error={product_price_html ?false:"This field can't be blank"}
          onChange={v=>{this.setState({product_price_html:v})}}
          ></TextField>
        <TextField
          label={<span>Product price (product template) *<Badge>Pre discount</Badge></span>}
          helpText={<span>Use html to configure price design and this value must contains <TextStyle variation="code">[[wholesale_price]]</TextStyle> and <TextStyle variation="code">[[strikethrough_price]]</TextStyle></span>}
          multiline
          value={preDiscount_product_price_html}
          error={preDiscount_product_price_html ?false:"This field can't be blank"}
          onChange={v=>{this.setState({preDiscount_product_price_html:v})}}
          ></TextField>
      </Card.Section>
      <div className="border-top-line" style={{marginBottom:'1rem',marginTop:'1rem',}}></div>
      <Card.Section fullWidth>
        <TextField
          label="Product price (home, collections, related products and search templates) *"
          helpText={<span>Use html to configure price design and this value must contains <TextStyle variation="code">[[wholesale_price]]</TextStyle> and <TextStyle variation="code">[[strikethrough_price]]</TextStyle></span>}
          multiline
          value={product_price_coll_html}
          error={product_price_coll_html ?false:"This field can't be blank"}
          onChange={v=>{this.setState({product_price_coll_html:v})}}
          ></TextField>
          
        <TextField
          label={<span>Product price (home, collections, related products and search templates) *<Badge>Pre discount</Badge></span>}
          helpText={<span>Use html to configure price design and this value must contains <TextStyle variation="code">[[wholesale_price]]</TextStyle> and <TextStyle variation="code">[[strikethrough_price]]</TextStyle></span>}
          multiline
          value={preDiscount_product_price_coll_html}
          error={preDiscount_product_price_coll_html ?false:"This field can't be blank"}
          onChange={v=>{this.setState({preDiscount_product_price_coll_html:v})}}
          ></TextField>


      </Card.Section>
      <div style={{marginBottom:'2rem'}}></div>
      <PageActions
        primaryAction={{content: 'Save', onAction:() => this.savePriceDesign()}}
        secondaryActions={{content: 'Reset to default', onAction:()=> this.resetToDefaultPriceDesign()}}
      >
      </PageActions>
    </div>;

      const {selected_tab} = this.state;
      var tabs = [
        {
          id: 'all',
          content: 'General',
          accessibilityLabel: 'General',
          panelID: 'all-content',
        },
        {
          id: 'discount-method',
          content: 'Discount method',
          panelID: 'discount-content',
        },
        {
          id: 'price-control',
          content: 'Price Control',
          panelID: 'price-control-content',
        },
        {
          id: 'sale-clock',
          content: 'Sale clock',
          panelID: 'sale-clock-content',
        },
        {
          id: 'product-page-table',
          content: 'Quantity Break Table',
          panelID: 'product-page-table-content',
        }
      ];

      if(this.props.snippet_version && waio.compareVersionNumbers(this.props.snippet_version, _Modules.find(x => x._id === 201).snippet_version) > -1){
        tabs.push({
          id: 'dev-tool-html',
          content: 'Price design',
          panelID: 'dev-tool-html-content',
        });
        tabs.push({
          id: 'translations-html',
          content: 'Translations',
          panelID: 'translations-content',
        });
      }

      var render_section = <div>{section_appEnableDisable}</div>;
      switch (tabs[selected_tab].id) {
          case 'all':
            render_section = <div>{display_settings}</div>;
          break;
          case 'discount-method':
            render_section = <div>{section_discountMethod}</div>;
          break;
          case 'price-control':
            render_section = <div>{section_priceControl}</div>;
          break;
          case 'sale-clock':
            render_section = <div>{section_salesClock}</div>;
          break;
          case 'product-page-table':
            render_section = <div>{section_tableStyleWidget}</div>;
          break;
          case 'dev-tool-html':
              render_section = <div>{cart_price__section}</div>;
            break;
          case 'translations-html':
            render_section = <Translations host_name={this.props.host_name} shop={this.props.shop} />;
            break;
        default:
          break;
      }

      var toast = this.state.showToast? (
        <ToastMessage
        onDismiss={this.dissmissToast}
        message={this.state.toastMsg}
        error={this.state.toastError}
        />
      ): (null);

      if(!this.state.page_loaded){
        return (
          <SkeletonPageComp />
        );
      }
      else{
        return (
          <div>
            {toast}
            {section_appEnableDisable}
            <Card sectioned>
              <Tabs tabs={tabs} selected={selected_tab} onSelect={this.handleTabChange}>
                <Card.Section title={tabs[selected_tab].content}>
                {render_section}
                </Card.Section>
              </Tabs>
            </Card>
            <div className="clearfix"></div>
            <FooterHelpLinks help={this.props.help}/>
          </div>
        );
      }
    }
}