// RoutePropagator.tsx
import React from 'react';

import {RouterProps, withRouter } from 'react-router';
import {RoutePropagator as AppBridgeRoutePropagator} from '@shopify/app-bridge-react';

function RoutePropagator(props: RouterProps) {
  const {location} = props;
  return <AppBridgeRoutePropagator location={location} />;
};

export default withRouter(RoutePropagator);