import React, { Component } from 'react';
import axios from 'axios';
import { Card, Stack, FormLayout, TextField, Checkbox, Tag, Select, Button, RadioButton, TextStyle, Badge, Banner, Link, Icon} from '@shopify/polaris';
import store from 'store2';
import _Modules from '../../modules.json';
import waio from '../../Helpers/functions';
import ToastMessage from '../../Common/ToastMessage';
import TitleBar from '../../Common/TitleBar';
import { DeleteMinor } from '@shopify/polaris-icons';
var snippet_version = store('snippet_version') || "2.0.9";

var store_currency = store('store_currency') || null;
export default class CreateMinOrderControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
            shop: this.props.shop,
            edit_rule_id: this.props.match.params.id,
            rules: [{ "column": "cart_original_total_price", "relation": "greater_than", "condition": "" }],
            rules_operator: "and",
            message: "",
            internal_name: "Order control",
            status: "active",
            customer_group: "all",
            except_logged_in: false,
            except_tags: false,
            excepted_tags: [],
            temp_tag_value: "",
            customer_tags: [],
            temp_customer_tags_value: "",
            saveButtonLoading: false,
            allOrderControls: [],
            page_loaded: false,
            showToast: false,
            toastMsg: '',
            toastError: false
        };
    }

    column_cart_options = [
        { label: "Cart total amount (subtotal)", value: "cart_original_total_price", disabled: false },
        { label: "Cart total items (quantity)", value: "cart_item_count", disabled: false },
        { label: `Cart total weight (grams)`, value: "cart_total_weight", disabled: false }
    ];

    relation_options = [
        { label: "is greater than", value: "greater_than", disabled: false },
        { label: "is less than", value: "less_than", disabled: false },
        { label: "is equal to", value: "equal", disabled: false },
        { label: "is not equal to", value: "not_equal", disabled: false }
    ];

    needToUpgrade = false;
    
    componentDidMount(){
        this.getAllOrderControls();
        if(this.state.show === "edit"){
            this.getARuleForEdit();
        }
        
        if (snippet_version && waio.compareVersionNumbers(snippet_version, _Modules.find(x => x._id === 209).snippet_version) === -1) {
            this.needToUpgrade = true;
        }
    }

    showToastMessage(msg, error){
        this.setState({
          showToast: true,
          toastMsg: msg,
          toastError: error||false,
        });
    }
    
    dissmissToast = () =>{
        this.setState({
          showToast: false,
        });
    }    

    getARuleForEdit(){
        axios.get(this.props.host_name + '/app/get-order-control/'+this.state.edit_rule_id)
        .then(r => {
            if (r && r.data) {
                var {
                    rules,
                    rules_operator,
                    message,
                    internal_name,
                    status,
                    customer_group,
                    except_logged_in,
                    except_tags,
                    excepted_tags,
                    customer_tags,
                } = r.data;

                this.setState({
                    rules,
                    rules_operator,
                    message,
                    internal_name,
                    status,
                    customer_group,
                    except_logged_in,
                    except_tags,
                    excepted_tags,
                    customer_tags,
                });
            }
            else {
                this.showToastMessage("Server error", true);
            }
        })
        .catch(error => {
            this.showToastMessage(error, true);
        });
    }

    getAllOrderControls(){
        axios.get(this.props.host_name + '/app/all-order-controls/'+this.props.shop )
        .then(r => {
            if (r && r.data) {
                this.setState({allOrderControls:r.data,page_loaded:true});
            }
            else {
                this.showToastMessage("Server error", true);
            }
        })
        .catch(error => {
            this.showToastMessage("Server error", true);
        });
    }

    addCondition() {
        var rules = this.state.rules;
        rules.push(
            { "column": "cart_original_total_price", "relation": "greater_than", "condition": "" }
        );
        this.setState({rules});
    }

    removeCondition(i) {
        var rules = this.state.rules;
        rules.splice(i, 1);
        this.setState({ rules });
    }

    changeConditionValue(value, id, i) {
        var rules = this.state.rules;
        rules[i] = { ...rules[i], [id]: value };
        this.setState({ rules });
    }

    renderExceptedTags(){
        var excepted_tags = this.state.excepted_tags;
        // console.log("excepted_tags", excepted_tags);
        return excepted_tags.map((tag,i) => {
            return <span key={i} style={{margin:"4px"}}>
                <Tag
                    onRemove={this.removeTag.bind(this,tag,i)}
                >{tag}</Tag>
            </span>
        });
    }

    removeTag(tag,i,el){
        var excepted_tags = this.state.excepted_tags;
        excepted_tags.splice([i],1);
        this.setState({ excepted_tags });
    }

    saveOrderRule(){
        if(this.validation() === true){
            this.setState({saveButtonLoading: true});
            const data = {
                shop: this.props.shop,
                rules: this.state.rules,
                rules_operator: this.state.rules_operator,
                message: this.state.message,
                internal_name: this.state.internal_name,
                status: this.state.status,
                customer_group: this.state.customer_group,
                customer_tags: this.state.customer_tags,
                except_logged_in: this.state.except_logged_in,
                except_tags: this.state.except_tags,
                excepted_tags: this.state.excepted_tags,
            };
            if(this.state.show === "edit"){
                // /update-order-control
                axios.put(this.props.host_name + '/app/update-order-control/'+this.state.edit_rule_id, data)
                    .then(r => {
                        if (r && r.data && r.data.message) {
                            if(r.data.status === 1){
                                this.showToastMessage(r.data.message);
                                this.props.history.push('/min-order-control');
                                this.getAllOrderControls();
                                this.setState({show:"list"});
                            }
                            else{
                                this.showToastMessage(r.data.message, true);
                            }
                        }
                        else {
                            this.showToastMessage("Server error", true);
                        }
                        this.setState({saveButtonLoading: false})
                    })
                    .catch(error => {
                        this.showToastMessage("Server error", true);
                        this.setState({saveButtonLoading: false});
                    });
            }
            else{
                axios.post(this.props.host_name + '/app/add-order-control', data)
                    .then(r => {
                        if (r && r.data && r.data.message) {
                            if(r.data.status === 1){
                                this.showToastMessage(r.data.message);
                                this.props.history.push('/min-order-control');
                                this.getAllOrderControls();
                                this.setState({show:"list"});
                            }
                            else{
                                this.showToastMessage(r.data.message, true);
                            }
                        }
                        else {
                            this.showToastMessage("Server error", true);
                        }
                        this.setState({saveButtonLoading: false})
                    })
                    .catch(error => {
                        this.showToastMessage("Server error", true);
                        this.setState({saveButtonLoading: false});
                    });
            }
        }
    }

    disableSaveButton(){
        return !this.validation();
    }

    validation(){
        var valid = true;
        var {excepted_tags,except_tags,rules,customer_group,customer_tags} = this.state;
        if(except_tags && excepted_tags.length < 1) valid = false;
        if(customer_group === "tag_based" && customer_tags.length < 1) valid = false;
        for(var rule of rules){
            if(!rule.condition){ valid = false; break; }
            else if(rule.condition.trim() === "") { valid = false; break; }
        }
        return valid;
    }

    renderCustomerTags(){
        var customer_tags = this.state.customer_tags;
        // console.log("customer_tags", customer_tags);
        return customer_tags.map((tag,i) => {
            return <span key={i} style={{margin:"4px"}}>
                <Tag
                    onRemove={this.removeCustomerTag.bind(this,tag,i)}
                >{tag}</Tag>
            </span>
        });
    }

    removeCustomerTag(tag,i,el){
        var customer_tags = this.state.customer_tags;
        customer_tags.splice([i],1);
        this.setState({ customer_tags });
    }

    deleteRule(item){
        if(window.confirm(`Delete ${item.internal_name}?`)){
            axios.delete(this.props.host_name + '/app/delete-order-control/'+item._id+'/'+ this.props.shop)
            .then(r => {
                if (r && r.data && r.data.message) {
                    if(r.data.status === 1){
                        this.showToastMessage(r.data.message);
                        this.getAllOrderControls();
                    }
                    else{
                        this.showToastMessage(r.data.message, true);
                    }
                }
                else {
                    this.showToastMessage("Server error", true);
                }
            })
            .catch(error => {
                this.showToastMessage("Server error", true);
            });
        }
    }

    showTag(item){
        return item.customer_tags.map((el,i)=>{
            return i < 1 ? <span key={i}><Badge>{el}</Badge></span>:null
        });
    }

    disableRule(item){
        if(window.confirm(`Disable ${item.internal_name}?`)){
            var data = {
                shop: this.props.shop,
                _id: item._id,
                status: "disabled"
            };
            axios.put(this.props.host_name + '/app/update-status-moc/'+item._id+'/', data)
            .then(r => {
                if (r && r.data && r.data.message) {
                    if(r.data.status === 1){
                        this.showToastMessage(r.data.message);
                        this.getAllOrderControls();
                    }
                    else{
                        this.showToastMessage(r.data.message, true);
                    }
                }
                else {
                    this.showToastMessage("Server error", true);
                }
            })
            .catch(error => {
                this.showToastMessage("Server error", true);
            });
        }
    }

    enableRule(item){
        var data = {
            shop: this.props.shop,
            _id: item._id,
            status: "active"
        };
        axios.put(this.props.host_name + '/app/update-status-moc/'+item._id+'/', data)
        .then(r => {
            if (r && r.data && r.data.message) {
                if(r.data.status === 1){
                    this.showToastMessage(r.data.message);
                    this.getAllOrderControls();
                }
                else{
                    this.showToastMessage(r.data.message, true);
                }
            }
            else {
                this.showToastMessage("Server error", true);
            }
        })
        .catch(error => {
            this.showToastMessage("Server error", true);
        });
    }

    render() {
        const {
            rules,rules_operator,message,internal_name,customer_group,except_logged_in,except_tags,excepted_tags, temp_tag_value, temp_customer_tags_value 
        } = this.state;

        var UpgradeBanner = null;
        if(this.needToUpgrade){
            UpgradeBanner = <div style={{margin:"1rem 0rem"}}>
                <Banner status="warning" title="Upgrade your script!">
                Order Limit feature requires script upgrade. Please email us at <TextStyle variation="strong">support@digitalcoo.com</TextStyle> We will update the relevant script.
                </Banner>
            </div>;
        }

        var planUpgradeBanner = <div style={{margin:"1rem 0rem"}}><Banner status="critical" title="Upgrade your plan!">
            <p>
            Minimum Order Limit feature is not included in Basic Plan. <Link url="/pricing-plans" target="APP">CLICK HERE</Link> to upgrade to Standard Plan.
            </p>
        </Banner></div>;

        const customerTagsInputField = <TextField
            label="Customer tags"
            labelHidden
            autoFocus
            value={temp_customer_tags_value}
            placeholder="Enter comma seperated tags"
            onChange={(v)=>{
                if(v && v.indexOf(",") >-1 ){
                    v= v.replace(",","");
                    v = v.trim();
                    if(v === "") return false;
                    var customer_tags = this.state.customer_tags;
                    if(customer_tags.indexOf(v) > -1){
                        this.showToastMessage("Tag already exists", true);
                        return false;
                    }
                    customer_tags.push(v);
                    this.setState({ customer_tags, temp_customer_tags_value: "" });
                }
                else{
                    this.setState({temp_customer_tags_value:v});
                }
            }}
            onBlur={()=>{
                var v = this.state.temp_customer_tags_value;
                v = v.replace(",","");
                v = v.trim();
                if(v === "") return false;
                var customer_tags = this.state.customer_tags;
                if(customer_tags.indexOf(v) > -1){
                    return false;
                }
                customer_tags.push(v);
                this.setState({ customer_tags, temp_customer_tags_value: "" });
            }}
        />;

        const tags_input = <TextField
            label="Tags"
            labelHidden
            autoFocus
            placeholder="Enter comma separated tags"
            value={temp_tag_value}
            onChange={(v)=>{
                if(v && v.indexOf(",") >-1 ){
                    v= v.replace(",","");
                    v = v.trim();
                    if(v === "") return false;
                    var excepted_tags = this.state.excepted_tags;
                    if(excepted_tags.indexOf(v) > -1){
                        this.showToastMessage("Tag already exists", true);
                        return false;
                    }
                    excepted_tags.push(v);
                    this.setState({ excepted_tags, temp_tag_value: "" });
                }
                else{
                    this.setState({temp_tag_value:v});
                }
            }}
            onBlur={()=>{
                var v = this.state.temp_tag_value;
                v = v.replace(",","");
                v = v.trim();
                if(v === "") return false;
                var excepted_tags = this.state.excepted_tags;
                if(excepted_tags.indexOf(v) > -1){
                    return false;
                }
                excepted_tags.push(v);
                this.setState({ excepted_tags, temp_tag_value: "" });
            }}
        ></TextField>;

        const except_logged_in_section = <div>
            <Checkbox
                label="Except logged in"
                id="except_logged_in"
                value={except_logged_in}
                checked={except_logged_in}
                onChange={(checked, value) => {
                    this.setState({ except_logged_in: checked });
                }}
            />
        </div>;

        const except_taged_section = <Stack vertical spacing="tight">
            <Checkbox
                label={<span>Except tags</span>}
                id="except_tags"
                value={except_tags}
                checked={except_tags}
                onChange={(checked, value) => {
                    this.setState({ except_tags: checked });
                }}
            />
            {except_tags?tags_input: null}
            {
                except_tags && excepted_tags.length > 0?
                <Stack>
                    {this.renderExceptedTags()}
                </Stack>
                : null
            }
        </Stack>;

        const Customer_section = <div>
            <FormLayout>
                <RadioButton
                    label="All customers"
                    id="all"
                    name="customers_group"
                    checked={customer_group === "all"}
                    onChange={(checked, value) => {
                        this.setState({ customer_group: value, except_tags: false, except_logged_in: false  });
                    }}
                />
                {
                    customer_group === "all" ?
                        <div style={{marginLeft:"20px"}}>
                            {except_logged_in_section}
                            {except_taged_section}
                        </div>
                    :null
                }
                <RadioButton
                    label="Only logged in customers"
                    id="logged_in"
                    name="customers_group"
                    checked={customer_group === "logged_in"}
                    onChange={(checked, value) => {
                        this.setState({ customer_group: value,except_tags: false, except_logged_in: false });
                    }}
                />
                {
                    customer_group === "logged_in" ?
                        <div style={{marginLeft:"20px"}}>
                            {except_taged_section}
                        </div>
                    :null
                }

                <RadioButton
                    label={<span>Only tag based logged in customers </span>}
                    id="tag_based"
                    name="customers_group"
                    checked={customer_group === "tag_based"}
                    onChange={(checked, value) => {
                        this.setState({ customer_group: value, except_tags: false, except_logged_in: false  });
                    }}
                />
                {customer_group === "tag_based"?customerTagsInputField:null}
                {
                    customer_group === "tag_based" ?
                        <div style={{marginLeft:"20px"}}>
                            <Stack spacing="tight">
                                {this.renderCustomerTags()}
                            </Stack>
                        </div>
                    :null
                }
            </FormLayout>
        </div>;

        const conditions_section = rules.map((el, i) => {
            return <FormLayout.Group condensed key={i} fullWidth>
                <Select
                    label="Column"
                    labelHidden={true}
                    id="column"
                    name="column"
                    value={el.column}
                    options={this.column_cart_options}
                    onChange={(selected, id) => { this.changeConditionValue(selected, id, i) }}
                ></Select>
                <Select
                    label="Relation"
                    labelHidden={true}
                    id="relation"
                    name="relation"
                    value={el.relation}
                    options={this.relation_options}
                    onChange={(selected, id) => { this.changeConditionValue(selected, id, i) }}
                ></Select>
                <TextField
                    prefix={
                        el.column === "cart_original_total_price"?(store_currency)
                        :el.column === "cart_item_count"?("Qty")
                        :(null)
                    }
                    suffix={
                        el.column === "cart_total_weight"?("Grams"):(null)
                    }
                    type="number"
                    label="Condition"
                    labelHidden={true}
                    id="condition"
                    name="condition"
                    value={el.condition || ""}
                    onChange={(selected, id) => { this.changeConditionValue(selected, id, i) }}
                    error={el.condition === "" ? "This field can't be blank" : false}
                ></TextField>
                <Button
                    onClick={this.removeCondition.bind(this, i)}
                    disabled={rules.length === 1}
                ><Icon source={DeleteMinor}/></Button>
            </FormLayout.Group>;
        });

        const add_or_edit_section = <div>
            <Card title="Order limit" actions={[{content:"Read tutorial", url:"https://support.digitalcoo.com/hc/en-us/articles/360041737692", external: true}]}>
                <Card.Section>
                    <FormLayout fullWidth>
                        <FormLayout.Group>
                            <TextField
                                label="write title"
                                value={internal_name || ""}
                                onChange={(value) => {
                                    this.setState({ internal_name: value });
                                }}
                                helpText="This will not appear to the customers."
                                maxLength={255}
                                showCharacterCount={true}
                                clearButton={true}
                                autoFocus={true}
                                onClearButtonClick={() => { 
                                    this.setState({ internal_name: "" });
                                }}
                                error={internal_name === "" ? "This field can't be blank" : false}
                            />
                            <TextField
                                label="Minimum purchase limit message"
                                multiline
                                value={message}
                                onChange={(v) => {
                                    this.setState({ message: v });
                                }}
                                helpText="This will appear to the customers at cart page next to cart subtotal price"
                            />
                        </FormLayout.Group>
                    </FormLayout>
                </Card.Section>
                <Card.Section>
                    <FormLayout fullWidth>
                        {Customer_section}
                    </FormLayout>
                </Card.Section>
                <Card.Section>
                    <FormLayout fullWidth>
                        <FormLayout.Group>
                            <div>
                                Must match to place order:&nbsp;&nbsp;&nbsp;
                                <RadioButton
                                    name="condition_operator"
                                    checked={rules_operator === "and"}
                                    id="and"
                                    label="All conditions"
                                    onChange={(checked, value) => {
                                        this.setState({ rules_operator: value });
                                    }}
                                />
                                &nbsp;&nbsp;&nbsp;
                                <RadioButton
                                    name="condition_operator"
                                    checked={rules_operator === "or"}
                                    id="or"
                                    label="Any condition"
                                    onChange={(checked, value) => {
                                        this.setState({ rules_operator: value });
                                    }}
                                />
                            </div>
                        </FormLayout.Group>
                        {conditions_section}
                        <FormLayout.Group fullWidth>
                            <Button
                                disabled={rules.length >= 3 ? true : false}
                                onClick={this.addCondition.bind(this)}
                            >
                                Add another condition
                            </Button>
                        </FormLayout.Group>
                    </FormLayout>
                </Card.Section>
            </Card>
            <div className="bottom-line"></div>
            <div className="bottom-save-buttons">
                {
                    this.props.is_basic_plan && <img style={{color:"#ffffff"}} width="36px" height="30px" src='/icons/lock.svg' alt="lock-icon" />
                }
                <Button primary
                    onClick = { () => this.saveOrderRule() }
                    disabled = { this.props.is_basic_plan?true:this.needToUpgrade?true:this.disableSaveButton()}
                    loading = { this.state.saveButtonLoading }
                >Save</Button>
            </div>
            <div className="clearfix"></div>
        </div>;

        var toast = this.state.showToast? (
            <ToastMessage
              onDismiss={this.dissmissToast}
              message={this.state.toastMsg}
              error={this.state.toastError}
            />
        ): (null);

        return (
            <div>
                <TitleBar
                    title={this.state.show === "add"?"Add":"Edit"}
                    breadcrumbs={[{content: 'Order controls', url: '/min-order-control', target: 'APP'}]}
                    primaryAction={{content:" Save ", onAction: () => this.saveOrderRule(), disabled: this.props.is_basic_plan?true:this.needToUpgrade? true :this.disableSaveButton()? true: this.state.saveButtonLoading }}
                    secondaryActions={[{content: 'Back', url: "/min-order-control" , target: 'APP'}]}
                />
                {toast}
                {
                    this.props.is_basic_plan ? planUpgradeBanner
                    : this.needToUpgrade ? UpgradeBanner : null
                }
                {add_or_edit_section}
            </div>
        );
    }
}
