import React, { Component } from 'react';
import {Card, TextStyle, TextContainer, Checkbox, Stack, TextField, Button, ButtonGroup, Avatar, Heading, Layout, Tag, PageActions, DataTable, RadioButton} from '@shopify/polaris';
import axios from 'axios';
import * as PropTypes from 'prop-types';
import PageTitleBar from '../../../Common/TitleBar';
import ToastMessage from '../../../Common/ToastMessage';
import SkeletonPageComp from '../../SkeletonPageComp';

export default class CustomerDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_current_plan: this.props.user_current_plan,
            shop: this.props.shop,
            customer_id: this.props.match.params.id,
            page_loaded: false,
            customer: {},
            status: "",
            customer_tags: [],
            temp_customer_tags_value: "",
            approveBtnLoading: false,
            denyBtnLoading: false,
            deleteBtnLoading: false,
            created_at: null,
            last_updated_at: null,
            last_order_name: null,
            reason_for_decline: "Fill the valid data",
            req_action_status: "approve",
            resubmission_allowed: true,
            notify_customer: true,
            showToast: false,
            toastMsg: '',
            toastError: false
        }
        axios.defaults.headers.common['X-Ws-Shop-Domain'] = "https://"+this.props.shop;
    }

    static contextTypes = {
        easdk: PropTypes.object,
    };

    rebumitText = "Fill the valid data";

    blockText = "Violation of terms and privacy policy";

    showToastMessage(msg, error){
    	this.setState({
    	  showToast: true,
    	  toastMsg: msg,
    	  toastError: error||false,
    	});
    }

    dissmissToast = () =>{
    	this.setState({
    	  showToast: false,
    	});
    }

    componentDidMount() {
        if(this.props.shop){
            this.getCustomerDetails();
        }
        else{
            this.setState({page_loaded: true});
        }
    }

    getCustomerDetails(){
        if(this.props.getFromShopify){
            axios.get(this.props.host_name + '/customer/from-shopify/detail/'+ this.state.customer_id +'/'+ this.props.shop).then(r=>{
                if(r && r.data && r.data.length !== 0){
                    var { customer, status, tags, auto_approve,created_at,last_updated_at,last_order_name} = r.data;
                    var customer_tags = [];
                    if(tags && tags.length > 0){
                        customer_tags = tags.replace(new RegExp(", ", "g"), ",").split(",");
                    }
                    if(customer){
                        this.setState({
                            customer_tags, customer,status,tags,auto_approve,page_loaded:true,created_at,last_updated_at,last_order_name
                        });
                    }
                }
                else{
                    this.flashMessage("You already approved or rejected the account approval request.", true);
                    this.props.history.push('/customers/wholesale_approval');
                }
                this.setState({page_loaded: true});
            }).catch(e=>{
                this.flashMessage("Server error", true);
                this.setState({page_loaded: true});
                this.props.history.push('/customers/wholesale_approval');
            })
        }
        else{
            axios.get(this.props.host_name + "/customer/api/335/admin/get?shop="+this.props.shop+"&customer_id="+this.props.match.params.id).then(r=>{
                if(r && r.data && r.data.length !== 0){
                    var { customer, status, tags, auto_approve,created_at,last_updated_at } = r.data;
                    if(customer){
                        this.setState({
                            customer,status,tags,auto_approve,page_loaded:true,created_at,last_updated_at
                        });
                    }
                }
                else{
                    this.flashMessage("You already approved or rejected the account approval request.", true);
                    this.props.history.push('/customers/wholesale_approval');
                }
                this.setState({page_loaded: true});
            }).catch(e=>{
                this.flashMessage("Server error", true);
                this.setState({page_loaded: true});
                this.props.history.push('/customers/wholesale_approval');
            })
        }
    }

    flashMessage(message, error) {
        this.showToastMessage(message,error );
    }

    renderTags(){
        var customer_tags = this.state.customer_tags;
        return customer_tags.map((tag,i) => {
            return <span key={i} style={{margin:"4px"}}>
                <Tag
                    onRemove={this.removeTag.bind(this,tag,i)}
                >{tag}</Tag>
            </span>
        });
    }

    removeTag(tag,i,el){
        var customer_tags = this.state.customer_tags;
        customer_tags.splice([i],1);
        this.setState({ customer_tags });
    }

    deleteAccount(){
        if(window.confirm(`Delete ${this.state.customer.first_name}?`)){
            this.setState({deleteBtnLoading: true});
            axios.delete(this.props.host_name + '/customer/api/335/admin/delete?customer_id='+ this.state.customer_id +'&shop='+ this.props.shop).then(r=>{
                if(r && r.data){
                    if( r.data.status === 1){
                        this.flashMessage(r.data.message, false);
                        this.props.history.push('/customers/wholesale_approval');
                    }
                    else{
                        this.flashMessage("Server error. Rrefresh page and try again", true);
                    }
                }
                else{
                    this.flashMessage("Server error. Rrefresh page and try again", true);
                }
                this.setState({deleteBtnLoading: false});
            }).catch(e=>{
                this.flashMessage("Server error", true);
                this.setState({deleteBtnLoading: false});
            })
        }
    }
    
    approveCustomer(){
        this.setState({approveBtnLoading: true});
        var item = {
            _id: this.props.match.params.id,
            shop: this.props.shop,
            tags: this.state.customer_tags.toString(),
            tax_exempt: this.state.customer.tax_exempt
        };
        axios.post(this.props.host_name + '/customer/api/335/admin/approve', item).then(response => {
            if(response && response.data &&  response.data.code === 1){
                if(response.data.message){
                    this.flashMessage(response.data.message);
                }
                this.props.history.push('/customers/wholesale_approval');
            }
            else if(response && response.data &&  response.data.code === 0){
                this.flashMessage(response.data.message, true);  
            }
            this.setState({approveBtnLoading: false});
        }).catch(e => {
            this.flashMessage("Server error",true);
            this.setState({approveBtnLoading: false});
        });
    }

    denyCustomer(){
        this.setState({denyBtnLoading: true});
        var item = {
            _id: this.props.match.params.id,
            shop: this.props.shop,
            reason_for_decline: this.state.reason_for_decline,
            resubmission_allowed: this.state.resubmission_allowed,
            notify_customer: this.state.notify_customer
        };
        axios.post(this.props.host_name + '/customer/api/335/admin/reject', item).then(response => {
            if(response && response.data && response.data.code === 1){
                this.flashMessage(response.data.success.message);
                this.props.history.push('/customers/wholesale_approval');
            }
            else{
                this.flashMessage(response.data.error.message,true);
            }
            this.setState({denyBtnLoading: true});
        }).catch(e => {
            this.flashMessage("Server error",true);
            this.setState({denyBtnLoading: true});
        });
    }

    isPremiumPlan(){
        var isPremium = false;
        if(this.props.user_current_plan){
            var plan_id = this.props.user_current_plan?this.props.user_current_plan.plan_id:null;
            isPremium = false;
            if(!plan_id){
                isPremium = false;
            }
            else{
                isPremium = plan_id.indexOf("premium") > -1;
            }
        }
        return isPremium;
    }
    
    renderUploadedFiles(el, uploadedKeys){
        var html = null;
        var isIndex = uploadedKeys.findIndex(x => x.key === el.key);
        if(-1 < isIndex){
            var values = uploadedKeys[isIndex].values;
            if(values && values.length > 0){
                try{
                    html = values.map((v,i) => {
                        return <span key={'ke'+i}><a className="ws--download-file" target="_new" href={v}>Download file</a>{((i+1)%3 === 0) && <br></br>}</span>
                    });
                }catch(e){}
            }
        }
        return html;
    }

    renderDataTime(msg, d){
        return <div><TextStyle variation="strong">{msg}</TextStyle>{d}</div>
    }

    bannerTitle(){
        var status = this.state.status;
        var customer = this.state.customer;
        if("request_an_account" === status.toLowerCase()){
            status = " request for a wholesale account";
        }
        else if("account_created" === status.toLowerCase()){
            status = "'s account was created";
        }
        else if("account_rejected" === status.toLowerCase()){
            status = "'s request was declined alread";
        }
        else if("rejected" === status.toLowerCase()){
            status = "'s request was declined";
        }
        else{
            status = " "+status;
        }
        if(customer){
            return customer.first_name +""+ status;
        }else{
            return "";
        }
    }

    render() {

        const { req_action_status, customer, status, customer_tags, temp_customer_tags_value, created_at, last_updated_at } = this.state;

        var extra_info_rows = [];

        var extFArray = [];
        var uploadedFiles = [];
        if(customer){
            for (let [key, value] of Object.entries(customer)) {
                if("first_name" === key || "last_name" === key || "email" === key || "password" === key || "password_confirmation" === key ) continue;
                if("first_name" === key || "last_name" === key || "email" === key || "password" === key || "password_confirmation" === key ) continue;
                if("note" === key) continue;
                if(key){
                    try{
                        if(key.toLowerCase() === "password"){ continue;}
                        if(key.toLowerCase() === "password_confirmation"){ continue;}
                        if(key.toLowerCase().indexOf("password")> -1 ){ continue;}
                    }catch(e){
                    }
                }
                if(key === "addresses"){
                    if(value[0]){
                        for (let [k, v] of Object.entries(value[0])) {
                            extFArray.push({
                                "key" : k,
                                "value": v
                            });
                        }
                    }
                }
                else{
                    var isFileUploaded = -1;
                    try{
                        if(value.toString().indexOf("https://digitalcoo.sfo2.digitaloceanspaces.com") > -1){
                            isFileUploaded = 1;
                        }
                    }catch(e){}
                    if(isFileUploaded > -1){
                        extFArray.push({
                            "key" : key,
                            "value": value
                        });
                        try{
                            uploadedFiles.push({
                                values: value.split(","),
                                key: key
                            });
                        }catch(e){}
                    }
                    else{
                        extFArray.push({
                            "key" : key,
                            "value": value==="true"?"Yes":value==="false"?"No":value===true?"Yes":value===false?"No":value
                        });
                    }
                } 
            }
        }

        if(extFArray.length > 0){
            extra_info_rows = extFArray.map((el,i) => [
                <div className="text-capitalize">{el.key.replace(new RegExp("_","g"), " ")}</div>,
                <div className="">
                    {
                        (el.value && el.value.toString().indexOf("https://digitalcoo.sfo2.digitaloceanspaces.com") > -1) ?
                        <span>
                            {this.renderUploadedFiles(el,uploadedFiles)}
                            <span className="ws--download-file-note">Note, download link is valid for next 30 days</span>
                        </span>
                        :el.value
                    }
                </div>,
            ])
        }
        var cusAction = null;
        var cusTitle = null;
        if(this.state.customer_id && this.props.getFromShopify){
            cusAction = [{content: "View more details", external:true, url: `https://${this.props.shop}/admin/customers/${this.state.customer_id}`}];
            cusTitle = "Customer information";
        }
        const customerDetailsSection = <Card title={cusTitle}
            actions={cusAction}
        >
            <Card.Section>
                <Stack spacing="tight">
                    <Avatar customer name={customer.first_name + " " + customer.last_name } />
                    <div>
                        <Heading>
                            {customer.first_name + " " + customer.last_name }
                        </Heading>
                        <TextContainer>
                            <span className="text-capitalize">{
                                status && status.replace(/_/g," ")
                            }</span>
                        </TextContainer>
                    </div>
                </Stack>
                {
                    this.props.getFromShopify && <div>
                        <div style={{marginTop: "2rem"}}></div>
                        <Stack vertical>
                            <TextField
                                label="Customer Note"
                                placeholder="Add a note"
                                rows="12"
                                multiline
                                value={customer.note}
                                onChange={(v) => {
                                    var customer = this.state.customer;
                                    customer.note = v;
                                    this.setState({customer})
                                }}
                            />
                        </Stack>
                    </div>
                }
            </Card.Section>
            {
                extFArray.length > 0 ?
                <Card.Section>
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                        ]}
                        headings={[
                            'Label',
                            'Value',
                        ]}
                        rows={extra_info_rows}
                        verticalAlign="middle"
                    ></DataTable>
                </Card.Section>
                :null
            }
            <Card.Section>
                <Stack distribution="fill" alignment="center">
                    <div className="text-center">
                        <TextContainer spacing="tight">
                            <div>
                                <TextStyle variation="subdued">
                                    Last order
                                </TextStyle>
                            </div>
                            <div>
                                <TextStyle variation="strong">
                                    {
                                        this.state.last_order_name?
                                        this.state.last_order_name
                                        :"N/A"
                                    }
                                </TextStyle>
                            </div>
                        </TextContainer>
                    </div>

                    <div className="text-center">
                        <TextContainer spacing="tight">
                            <div>
                                <TextStyle variation="subdued">
                                    Last login
                                </TextStyle>
                            </div>
                            <div>
                                <TextStyle variation="strong">
                                N/A
                                </TextStyle>
                            </div>
                        </TextContainer>
                    </div>

                    <div className="text-center">
                        <TextContainer spacing="tight">
                            <div>
                                <TextStyle variation="subdued">
                                    Last 30 days
                                </TextStyle>
                            </div>
                            <div>
                                <TextStyle variation="strong">
                                    $0.00
                                </TextStyle>
                            </div>
                        </TextContainer>
                    </div>
                    
                </Stack>
            </Card.Section>
        </Card>;

        var approveSection = <div>
            <Stack vertical>
                <TextField
                    id="customer-tag-field"
                    label="Enter tag to approve the customer"
                    placeholder="e.g wholesale"
                    value={temp_customer_tags_value}
                    onChange={ (v) => {
                        if(v && v.indexOf(",") >-1 ){
                            v= v.replace(",","");
                            v = v.trim();
                            if(v === "") return false;
                            var customer_tags = this.state.customer_tags;
                            if(customer_tags.indexOf(v) > -1){
                                this.flashMessage("Tag already exists",true);
                                return false;
                            }
                            customer_tags.push(v);
                            this.setState({ customer_tags, temp_customer_tags_value: "" });
                        }
                        else{
                            this.setState({temp_customer_tags_value:v});
                        }
                    }}
                    onBlur={()=>{
                        var v = this.state.temp_customer_tags_value;
                        v = v.replace(",","");
                        v = v.trim();
                        if(v === "") return false;
                        var customer_tags = this.state.customer_tags;
                        if(customer_tags.indexOf(v) > -1){
                            return false;
                        }
                        customer_tags.push(v);
                        this.setState({ customer_tags, temp_customer_tags_value: "" });
                    }}
                />
                {
                    customer_tags && customer_tags.length > 0 ?
                    <Stack spacing="tight">
                    {this.renderTags()}
                    </Stack>
                    :null
                }
                <div>
                    <Checkbox
                        label="Don't collect tax from the customer"
                        checked={customer.tax_exempt === "true"}
                        onChange={(v) => {
                            var customer = this.state.customer;
                            customer.tax_exempt = v?"true":"false";
                            this.setState({customer});
                        }}
                    ></Checkbox>
                </div>
                <ButtonGroup>
                    <Button
                        primary
                        onClick={() => {this.approveCustomer();}}
                        disabled= {this.state.customer_tags.length<1?temp_customer_tags_value.length>0?false:true:false}
                        loading={this.state.approveBtnLoading}
                    >
                        {
                            this.props.getFromShopify? "Add/Update tags":"Approve customer"
                        }
                    </Button>
                </ButtonGroup>
            </Stack>
        </div>;

        var declineSection = <div>
            <Stack vertical spacing="tight">
                <RadioButton
                    label="Allow this customer to resubmit the wholesale account request"
                    helpText="When this is checked, customer will be able to resubmit the request"
                    checked={this.state.resubmission_allowed}
                    onChange={(v) => {
                        var reason_for_decline = this.rebumitText;
                        this.setState({resubmission_allowed:true,reason_for_decline})
                    }}
                />
                <RadioButton
                    label="Parmanent block this customer"
                    helpText="When this is checked, customer will not able to create a wholesale account"
                    checked={!this.state.resubmission_allowed}
                    onChange={(v) => {
                        var reason_for_decline = this.blockText;
                        this.setState({resubmission_allowed:false,reason_for_decline})
                    }}
                />
                <Checkbox
                    label="Notify this customer that your account denied for the specific reason"
                    helpText="When this is checked, customer will receive an email"
                    checked={this.state.notify_customer}
                    onChange={(v) => {
                        this.setState({notify_customer:v})
                    }}
                />
                {
                    (this.state.notify_customer) && <TextField
                        label={"Reason for "+(this.state.resubmission_allowed ? "decline" : "block")}
                        multiline
                        rows={3}
                        value={this.state.reason_for_decline}
                        onChange={(v) => { this.setState({reason_for_decline:v}) }}
                    />
                }
                <Button
                    destructive
                    disabled={this.state.reason_for_decline.trim() === ""}
                    onClick={() => {
                        this.denyCustomer();
                    }}
                    loading={this.state.denyBtnLoading}
                >
                    {
                        this.state.resubmission_allowed ? "Decline" : "Block"
                    }
                </Button>
            </Stack>
        </div>;

        var toast = this.state.showToast? (
            <ToastMessage
              onDismiss={this.dissmissToast}
              message={this.state.toastMsg}
              error={this.state.toastError}
            />
          ): (null);


        if(this.state.page_loaded){
            return (
                <div>
                    <PageTitleBar
                        title="Customer Detail"
                        breadcrumbs={[{content: 'Customers', url: '/customers/wholesale_approval', target: 'APP'}]}
                        secondaryActions={[{content: 'Back', url: '/customers/wholesale_approval', target: 'APP'}]}
                    />
                    {toast}
                    <Layout>
                        <Layout.Section>
                            <Card>
                                <Card.Section>
                                    <Stack>
                                        <RadioButton
                                            label="Approve this request"
                                            checked={"approve" === req_action_status}
                                            id="approve"
                                            onChange={(v,id) => { this.setState({req_action_status:"approve"}) }}
                                        />
                                        {
                                            (!this.props.getFromShopify) && 
                                            <RadioButton
                                                label="Decline this request"
                                                checked={"decline" === req_action_status}
                                                id="decline"
                                                onChange={(v,id) => { this.setState({req_action_status:"decline"}) }}
                                            />
                                        }
                                    </Stack>
                                </Card.Section>
                                <Card.Section>
                                {
                                    (req_action_status === "approve") && approveSection
                                }
                                {
                                    (req_action_status === "decline") && declineSection
                                }
                                </Card.Section>
                            </Card>
                        </Layout.Section>
                        <Layout.Section>
                            {customerDetailsSection}
                        </Layout.Section>
                        <Layout.Section secondary>
                            <Card title="Customer overview" sectioned>
                                <Stack vertical>
                                    <div>{customer.email}</div>
                                    {(customer.accepts_marketing === "true" || customer.accepts_marketing === true) && <div>Accepts email marketing</div>}
                                    <div>
                                        {
                                            "request_an_account" === status ?"Pending for approval"
                                            :"account_created" === status ?"This account has been approved"
                                            :"account_rejected" === status ?"This account has been rejected"
                                            :null
                                        }
                                    </div>
                                        {(last_updated_at) && this.renderDataTime("Last updated at: ",last_updated_at)}
                                        {(created_at) && this.renderDataTime("Created at: ",created_at)}
                                </Stack>
                            </Card>
                        </Layout.Section>
                    </Layout>
                    <div style={{marginTop: "2rem"}}></div>
                    {
                        !this.props.getFromShopify && <PageActions
                            primaryAction={null}
                            secondaryActions={[
                                {
                                    content: 'Delete customer',
                                    destructive: true,
                                    onAction: () => { this.deleteAccount(); },
                                    disabled: this.state.deleteBtnLoading
                                },
                            ]}
                        />
                    }
                </div>
            );
        }
        else{
            return (
                <div>
                    <PageTitleBar
                        title="Customer Detail"
                        breadcrumbs={[{content: 'Customers', url: '/customers/wholesale_approval', target: 'APP'}]}
                        secondaryActions={[{content: 'Back', url: '/customers/wholesale_approval', target: 'APP'}]}
                    />
                    {toast}
                    <SkeletonPageComp />
                </div>
            );
        }
    }
}