import React, { Component } from 'react';
import axios from 'axios';
import { Switch, Route } from 'react-router';
import { Banner,Spinner, Modal } from '@shopify/polaris';
import * as PropTypes from 'prop-types';
import Install from './Components/Install';
import Home from './Components/Home';
// import CreatePricingGroup from './Components/CreatePricingGroup';
// import DiscountGroup from './Components/DiscountGroupV2';
import EditPricingGroup from './Components/EditPricingGroup';
import Settings from './Components/version206/Settings';
// import Settings from './Components/Settings';
import VariantPicker from './Components/VariantPicker';
import querystring from 'querystring';
import UpdateProductsModal from './Components/UpdateProductsModal';
import UpdateCollectionsModal from './Components/UpdateCollectionsModal';
import UpdateVariantsModal from './Components/UpdateVariantsModal';
import './index.css';
import PricingPlan from './Components/PricingPlan';
import Configuration from './Components/version202/Configuration';
import Help from './Components/Help';
import VariantPickerForSelectedProducts from './Components/VariantPickerForSelectedProducts';
import UpdateExcludedCollections from './Components/UpdateExcludedCollections';
import UpdateExcludedProducts from './Components/UpdateExcludedProducts';
import store from 'store2';
import FAQ from './Components/FAQ';
import FreeInstallationSupport from './Components/FreeInstallationSupport';
import ImportDiscountGroup from './Components/ImportDiscountGroup';
import ImportQuantityBreak from './Components/ImportQuantityBreak';
import TrackOrders from './Components/TrackOrders';
import _Modules from './modules.json';
import InstallationStatus from './Components/InstallationStatus';
// import Shipping from './Components/Shipping';
import AccessControl from './Components/AccessControl';
import SkeletonPageComp from './Components/SkeletonPageComp';
import waio from './Helpers/functions';
import DeveloperTools from './Components/version201/DeveloperTools';
import ManualDraftOrder from './Components/version201/ManualDraftOrder';
import CreateGeneralDiscount from './Components/version202/CreateGeneralDiscount';
import EditGeneralDiscount from './Components/version202/EditGeneralDiscount';
import GeneralDiscountGroups from './Components/version202/GeneralDiscountGroups202';
import Dashboard from './Components/version206/Dashboard';
import CreateQuantityBreak from './Components/version204/qb/CreateQuantityBreak';
import EditQuantityBreak from './Components/version204/qb/EditQuantityBreak';
import QuantityBreaks from './Components/QuantityBreaksV2';
import UpdateCollectionsModalQB from './Components/version204/qb/UpdateCollectionsModalQB';
import MinOrderControl from './Components/version207/MinOrderControl';
import CreateMinOrderControl from './Components/version207/CreateMinOrderControl';
import Unlock from './Components/version208/Unlock';
import ManageShipping from './Components/version209/ManageShipping';
import WholesaleForms from './Components/upgraded/2019-09/WholesaleForms';
import CustomerList from './Components/upgraded/2019-09/CustomersList';
import CustomerDetail from './Components/upgraded/2019-09/CustomerDetail';
import UpgradeAppRequired from './UpgradeAppRequired';

const parsed = querystring.parse(window.location.search);
const help_links = {
  link1: process.env.REACT_APP_ABOUT_LINK,
  link2: process.env.REACT_APP_DOC_LINK
};

const host_name = process.env.REACT_APP_SERVER_URL;
var snippet_version = store('snippet_version') || "2.0.9";
var isAdmin = parsed.wsadmin || store("wsAdmin")?store("wsAdmin"):undefined;
if(parsed.wsadmin){ store("wsAdmin", parsed.wsadmin); }if(isAdmin && isAdmin.trim().toLowerCase().toString() === "yes"){ isAdmin = true; }else{ isAdmin = undefined; }

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      host_name: host_name,
      shop_features: [],
      help_links: help_links,
      shop_id: store('shop_id') || '',
      shop: parsed.shop || store('shop'),
      server_error: false,
      page_loaded: false,
      pricing_plans: [],
      shop_payment: [],
      payment_status: 'active',
      user_current_plan: [],
      app_configured: true,
      new_installation: false,
      total_products: 0,
      change_setting: false,
      installation_page: false,
      app_enabled: true,
      new_client: false,
      enable_btn_loading: false,
      free_installation_support_popup: false,
      install_status: "completed",
      disabled_all_nav: false,
      weight_unit: null,
      currency: null,
    };
  }

  componentDidMount() {
    if (window.location.pathname === '/') {
      this.setState({ new_installation: true });
    }
    else if (window.location.pathname === '/install') {
      this.setState({ installation_page: true });
    }
    else {
      let shop = this.state.shop;

      if (!shop) {
        shop = parsed.shop;
        if (shop) {
          this.setState({
            page_loaded: true
          })
        }
      }
      else if (shop) {
        axios.get(host_name + '/get-shop-data/' + shop)
          .then(response => {
            if(response.data.snippet_version){
              var ws_snippet_version = response.data.snippet_version;
              store('snippet_version', ws_snippet_version);
              snippet_version = ws_snippet_version;
            }
            store('shop_id', response.data._id);
            store('shop', response.data.shop);
            store('owner_email', response.data.email);
            store('store_currency', response.data.currency);
            this.setState({
              shop_features: response.data.shop_features,
              shop: response.data.shop,
              shop_id: response.data._id,
              page_loaded: true,
              shop_payment: response.data.shop_payment,
              payment_status: response.data.shop_payment.status,
              app_configured: response.data.app_configured,
              total_products: response.data.total_products,
              change_setting: response.data.change_setting,
              app_enabled: response.data.app_enabled,
              new_client: response.data.new_client,
              install_status: response.data.install_status,
              disabled_all_nav: response.data.shop_payment.status !== "active" ? true : false,
              weight_unit: response.data.weight_unit,
              currency: response.data.currency
            });
          })
          .catch(error => {
            this.setState({ server_error: true });
            
          });
        axios.get(host_name + '/admin/get-plans-and-privileges/' + shop)
          .then(response => {
            if (response.data) {
              if (response.data.plans) {
                this.setState({
                  user_current_plan: response.data.plans
                });
              }
            }
          })
          .catch(error => {
            
          });
      }
    }
  }

  static contextTypes = {
    easdk: PropTypes.object,
  };

  isBasicPlan(){
    var basic = false;
    if(this.state.user_current_plan){
      var plan_id = this.state.user_current_plan?this.state.user_current_plan.plan_id:null;
      var valid = false;
      if(plan_id){
        valid = plan_id.indexOf("basic") > -1;
        if(valid){
            this.basicPlan = true;
            basic = true;
        }
      }
    }
    return basic;
  }

  render() {
    // const primary_action = { content: 'Ask Question', disabled: this.state.disabled_all_nav, onAction: () => { this.setState({ free_installation_support_popup: true })}};
    const primary_action = null;
    var secondary_actions = [
      { content: 'Automatic Discounts', url: '/pricing-groups', target: 'app', disabled: this.state.disabled_all_nav },
      { content: 'Quantity Breaks', url: '/quantity-breaks', target: 'app', disabled: this.state.disabled_all_nav },
      { content: 'Settings', url: '/settings', target: 'app', disabled: this.state.disabled_all_nav },
    ];
    const actionGroups = [
      {
        title: 'More',
        actions: [
          { content: 'User Guide', url: help_links.link2, target: 'remote', disabled: this.state.disabled_all_nav },
          { content: 'Subscription', url: '/pricing-plans', target: 'app' },
          { content: 'Installation / Uninstalaltion', url: '/home', target: 'app', disabled: this.state.disabled_all_nav },
          { content: 'Ask Question', disabled: this.state.disabled_all_nav, onAction: () => { this.setState({ free_installation_support_popup: true }) } }
        ],
      }
    ];

    if (this.state.shop === 'necklet-jewelry.myshopify.com' || this.state.shop === 'solution-win-testing.myshopify.com') {
      actionGroups[0].actions.push({ content: 'Track Orders', url: '/track-orders', target: 'app', disabled: this.state.disabled_all_nav });
    }

    if (snippet_version && waio.compareVersionNumbers(snippet_version, _Modules.find(x => x._id === 126).snippet_version) > -1) {
      secondary_actions.push({ content: 'Shipping', url: '/shipping', target: 'app', disabled: this.state.disabled_all_nav });
      secondary_actions.push({ content: 'Access Control', url: '/access-control', target: 'app', disabled: this.state.disabled_all_nav });
    }
    if (snippet_version && waio.compareVersionNumbers(snippet_version, _Modules.find(x => x._id === 201).snippet_version) > -1) {
      actionGroups[0].actions.push({ content: 'Developer tools', url: '/dev-tools', target: 'app', disabled: this.state.disabled_all_nav });
      actionGroups[0].actions.push({ content: 'Minimum order control', url: '/min-order-control', target: 'app', disabled: this.state.disabled_all_nav });
    }

    if (snippet_version && waio.compareVersionNumbers(snippet_version, _Modules.find(x => x._id === 202).snippet_version) > -1) {
      secondary_actions[0].url = "/general-discount-groups";
    }

    const pageLoading = <div className="snipper-container">
      <center><Spinner color="teal" size="large" /></center>
    </div>;

    const appDisabledBanner = <div><Banner
      title="Application is disabled"
      status="critical"
      action={{ content: 'Enable app', url: '/settings', target: "app" }}
    >
    </Banner>
      <br></br>
    </div>;

    const reactRouter = <div>
      <Switch>
        <Route
          exact
          path="/"
          render={props => <Home {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} />}
        />
        <Route
          exact
          path="/dashboard"
          render={props => <Dashboard {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} user_current_plan={this.state.user_current_plan} />}
        />
        <Route
          exact
          path="/help"
          render={props => <Help {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} help={help_links} />}
        />
        
        <Route
          exact
          path="/create-pricing-group"
          render={props => <CreateGeneralDiscount {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />
        <Route
          exact
          path="/create-quantity-break"
          render={props => <CreateQuantityBreak {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />
        <Route
          exact
          path="/quantity-breaks"
          render={props => <QuantityBreaks {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />
        <Route
          exact
          path="/edit-quantity-breaks/:id"
          render={props => <EditQuantityBreak {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />
        <Route
          exact
          path="/home"
          render={props => <Configuration {...props} snippet_version={snippet_version} shop={this.state.shop} shop_features={this.state.shop_features} host_name={host_name} help={help_links} />}
        />
        <Route
          exact
          path="/config"
          render={props => <Configuration {...props} snippet_version={snippet_version} shop={this.state.shop} shop_features={this.state.shop_features} host_name={host_name} help={help_links} />}
        />
        <Route
          exact
          path="/pricing-groups"
          render={props => <GeneralDiscountGroups {...props} snippet_version={snippet_version} shop={this.state.shop} shop_features={this.state.shop_features} host_name={host_name} shop_id={this.state.shop_id} user_current_plan={this.state.user_current_plan} help={help_links} primaryAction={primary_action} secondaryActions={secondary_actions} actionGroups={actionGroups} />}
        />
        <Route
          exact
          path="/edit-pricing-group/:id"
          render={props => <EditPricingGroup {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />
        <Route
          exact
          path="/install"
          render={props => <Install {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} help={help_links} secondaryActions={secondary_actions} primaryAction={primary_action} actionGroups={actionGroups} />}
        />
        <Route
          exact
          path="/variant-picker"
          render={props => <VariantPicker {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} total_products={this.state.total_products} />}
        />
        <Route
          exact
          path="/selected-products-variant-picker"
          render={props => <VariantPickerForSelectedProducts {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} total_products={this.state.total_products} />}
        />
        <Route
          exact
          path="/update-products-modal"
          render={props => <UpdateProductsModal {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} />}
        />
        <Route
          exact
          path="/update-collections-modal"
          render={props => <UpdateCollectionsModal {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} />}
        />
        <Route
          exact
          path="/update-variants-modal"
          render={props => <UpdateVariantsModal {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} />}
        />
        <Route
          exact
          path="/update-excluded-products"
          render={props => <UpdateExcludedProducts {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} />}
        />
        <Route
          exact
          path="/update-excluded-collections"
          render={props => <UpdateExcludedCollections {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} />}
        />
        <Route
          exact
          path="/pricing-plans"
          render={props => <PricingPlan {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} help={help_links} />}
        />
        <Route
          exact
          path="/admin/recuring-charge/callback"
          render={props => <PricingPlan {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} help={help_links} />}
        />
        <Route
          exact
          path="/install-snippet"
          render={props => <Configuration {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} help={help_links} />}
        />
        <Route
          exact
          path="/dashboard"
          render={props => <Home {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} help={help_links} user_current_plan={this.state.user_current_plan} />}
        />
        <Route
          exact
          path="/faq"
          render={props => <FAQ {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} help={help_links} />}
        />
        <Route
          exact
          path="/free-installation-support"
          render={props => <FreeInstallationSupport {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} />}
        />
        <Route
          exact
          path="/import-discount-groups"
          render={props => <ImportDiscountGroup {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} />}
        />
        <Route
          exact
          path="/import-quantity-breaks"
          render={props => <ImportQuantityBreak {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} />}
        />
        <Route
          exact
          path="/track-orders"
          render={props => <TrackOrders {...props} snippet_version={snippet_version} shop={this.state.shop} shop_features={this.state.shop_features} host_name={host_name} shop_id={this.state.shop_id} />}
        />
        <Route
          exact
          path="/access-control"
          render={props => <AccessControl {...props} is_basic_plan={this.isBasicPlan()} user_current_plan={this.state.user_current_plan} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} help={help_links} />}
        />
        <Route
          exact
          path="/settings"
          render={props => <Settings {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_features={this.state.shop_features} shop_id={this.state.shop_id} change_setting={this.state.change_setting} help={help_links} />}
        />
        <Route
          exact
          path="/dev-tools"
          render={props => <DeveloperTools {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_features={this.state.shop_features} shop_id={this.state.shop_id} change_setting={this.state.change_setting} help={help_links} />}
        />
        <Route
          exact
          path="/min-order-control"
          render={props => <MinOrderControl {...props} is_basic_plan={this.isBasicPlan()} weight_unit={this.state.weight_unit} show="list" snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_features={this.state.shop_features} shop_id={this.state.shop_id} change_setting={this.state.change_setting} help={help_links} user_current_plan={this.state.user_current_plan}/>}
        />
        <Route
          exact
          path="/add-min-order-control"
          render={props => <CreateMinOrderControl {...props} is_basic_plan={this.isBasicPlan()} weight_unit={this.state.weight_unit} show="add" snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_features={this.state.shop_features} shop_id={this.state.shop_id} change_setting={this.state.change_setting} help={help_links} user_current_plan={this.state.user_current_plan}/>}
        />
        <Route
          exact
          path="/min-order-control/:id"
          render={props => <CreateMinOrderControl {...props} is_basic_plan={this.isBasicPlan()} weight_unit={this.state.weight_unit} show="edit" snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_features={this.state.shop_features} shop_id={this.state.shop_id} change_setting={this.state.change_setting} help={help_links} user_current_plan={this.state.user_current_plan}/>}
        />
        <Route
          exact
          path="/shipping"
          render={props => <ManageShipping {...props} is_basic_plan={this.isBasicPlan()} weight_unit={this.state.weight_unit} show="list" snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_features={this.state.shop_features} shop_id={this.state.shop_id} change_setting={this.state.change_setting} help={help_links} user_current_plan={this.state.user_current_plan} />}
        />
        <Route
          exact
          path="/manage-shipping"
          render={props => <ManageShipping {...props} is_basic_plan={this.isBasicPlan()} weight_unit={this.state.weight_unit} show="list" snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_features={this.state.shop_features} shop_id={this.state.shop_id} change_setting={this.state.change_setting} help={help_links} user_current_plan={this.state.user_current_plan} />}
        />
        <Route
          exact
          path="/add-manage-shipping"
          render={props => <ManageShipping {...props} is_basic_plan={this.isBasicPlan()} weight_unit={this.state.weight_unit} show="add" snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_features={this.state.shop_features} shop_id={this.state.shop_id} change_setting={this.state.change_setting} help={help_links} user_current_plan={this.state.user_current_plan} />}
        />
        <Route
          exact
          path="/manage-shipping/:id"
          render={props => <ManageShipping {...props} is_basic_plan={this.isBasicPlan()} weight_unit={this.state.weight_unit} show="edit" snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_features={this.state.shop_features} shop_id={this.state.shop_id} change_setting={this.state.change_setting} help={help_links} user_current_plan={this.state.user_current_plan}/>}
        />
        <Route
          exact
          path="/manual-draft-order"
          render={props => <ManualDraftOrder {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_features={this.state.shop_features} shop_id={this.state.shop_id} change_setting={this.state.change_setting} help={help_links} user_current_plan={this.state.user_current_plan}/>}
        />
        <Route
          exact
          path="/add-general-discount"
          render={props => <CreateGeneralDiscount {...props} snippet_version={snippet_version} shop={this.state.shop} shop_features={this.state.shop_features} host_name={host_name} shop_id={this.state.shop_id} user_current_plan={this.state.user_current_plan} help={help_links} primaryAction={primary_action} secondaryActions={secondary_actions} actionGroups={actionGroups} />}
        />  
        <Route
          exact
          path="/general-discount-groups"
          render={props => <GeneralDiscountGroups {...props} snippet_version={snippet_version} shop={this.state.shop} shop_features={this.state.shop_features} host_name={host_name} shop_id={this.state.shop_id} user_current_plan={this.state.user_current_plan} help={help_links} primaryAction={primary_action} secondaryActions={secondary_actions} actionGroups={actionGroups} />}
        />
        <Route
          exact
          path="/edit-discount-group/:id"
          render={props => <EditGeneralDiscount {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />
        
        <Route
          exact
          path="/update-collections-modal-qb"
          render={props => <UpdateCollectionsModalQB {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />
        
        <Route
          exact
          path="/unlock"
          render={props => <Unlock {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />
        
        <Route
          exact
          path="/wholesale-forms"
          render={props => <WholesaleForms {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />

        <Route
          exact
          path="/customers"
          render={props => <CustomerList {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />

        <Route
          exact
          path="/customer/:id"
          render={props => <CustomerDetail {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />



        {!isAdmin ?
          <Route
            exact
            path="/status"
            render={props => <InstallationStatus {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} />}
          />
          : ''
        }

        

      <Route
        exact
        path="*"
        render={props => <Dashboard {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
      />

      </Switch>
    </div>;

    const modals = <Modal
      src={'/free-installation-support'}
      open={this.state.free_installation_support_popup}
      onClose={() => this.setState({
        free_installation_support_popup: false,
      })}
      title="Free installation support"
      secondaryActions={[
        {
          content: 'Close',
          onAction: () => {
            this.setState({
              free_installation_support_popup: false,
            })
          },
        },
      ]}
    >
    </Modal>;
    if (this.state.new_installation) {
      return (
        <div>
          {modals}
          <Home shop={this.state.shop} actionGroups={actionGroups} primary_action={primary_action} secondary_actions={secondary_actions} host_name={host_name} shop_id={this.state.shop_id} help={help_links} />
        </div>
      );
    }
    else if (this.state.installation_page) {
      return (
        <div>
          {modals}
          <Install shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} help={help_links} ></Install>
        </div>
      );
    }
    else if (this.state.payment_status !== "active") {
      return (
        <div>
          {modals}
          {
            window.location.pathname === '/free-installation-support' ?
              <FreeInstallationSupport shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} />
              : <PricingPlan shop_payment={this.state.shop_payment} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} help={help_links} />
          }
        </div>
      );
    }
    else if ((this.state.install_status !== "completed" || !this.state.app_configured) && !isAdmin) {
      return (
        <div>
          <InstallationStatus app_configured={this.state.app_configured} install_status={this.state.install_status} shop={this.state.shop} host_name={host_name} help={help_links} />
        </div>
      );
    }
    else if (this.state.server_error) {
      return (
        <div>
          {modals}
          <Banner
            title="Wholesale - All in one is down for maintenance"
            status="warning"
          >
            <p>
              We expect to be back in a couple hours.
            </p>
          </Banner>
        </div>
      );
    }
    else if (this.state.page_loaded) {
      return (
        <div>
          <div style={{marginBottom: "2rem", marginTop: "2rem"}}>
            <UpgradeAppRequired />
          </div>
          {modals}
          {
            window.location.pathname === '/import-quantity-breaks' ||
              window.location.pathname === '/import-discount-groups' ||
              window.location.pathname === '/free-installation-support' ||
              window.location.pathname === '/update-excluded-collections' ||
              window.location.pathname === '/update-excluded-products' ||
              window.location.pathname === '/update-variants-modal' ||
              window.location.pathname === '/update-collections-modal' ||
              window.location.pathname === '/update-products-modal' ||
              window.location.pathname === '/selected-products-variant-picker' ||
              window.location.pathname === '/variant-picker'||
              window.location.pathname === '/update-collections-modal-qb' || 
              window.location.pathname === '/settings' ? null :
              this.state.app_enabled ?
              null : appDisabledBanner
          }
          {reactRouter}
        </div>
      );
    }
    else {
      return (
        <div>
          <div style={{marginBottom: "2rem", marginTop: "2rem"}}>
            <UpgradeAppRequired />
          </div>
          {modals}
          {
            window.location.pathname === '/import-quantity-breaks' ||
            window.location.pathname === '/import-discount-groups' ||
            window.location.pathname === '/free-installation-support' ||
            window.location.pathname === '/update-excluded-collections' ||
            window.location.pathname === '/update-excluded-products' ||
            window.location.pathname === '/update-variants-modal' ||
            window.location.pathname === '/update-collections-modal' ||
            window.location.pathname === '/update-products-modal' ||
            window.location.pathname === '/selected-products-variant-picker' ||
            window.location.pathname === '/update-collections-modal-qb' || 
            window.location.pathname === '/variant-picker' ? pageLoading :
            <SkeletonPageComp />
          }
        </div>
      )
    }
  }
}

export default App;
