import React from 'react';
import {Card,FormLayout,Layout, ResourceItem, TextField, RadioButton, ResourceList,Button,Stack,Checkbox,Select,Heading,Modal, Autocomplete, Link } from '@shopify/polaris';
import { PlusMinor, CancelSmallMinor } from '@shopify/polaris-icons';
import PageTitleBar from '../../../Common/TitleBar';
import CommonHelper from '../../../Helpers/Common';
import ResourcePicker from '../../../Common/ResourcePicker';
import axios from 'axios';
import SkeletonPageComp from '../../SkeletonPageComp';

class CreateLock extends React.Component{
  constructor(props){
      super(props);
      this.state = {
        "edit_id": this.props.match.params.id || null,
        "manage_lock":{
            "id": CommonHelper.wsId(7),
            "title": "",
            "applies_to": "selected_customers",
            "conditions":[[{"title":"tag_with","value":"","condition":"OR"}]],
            "access_permissions":"",
            "content_name":"",
            "state": "published",
            "collections":{
                "selections":[],
                "hide_from_collections":true,
                "hide_product_listing":true,
            },
            "products":{
                "selections":[],
                "hide_product_listing": true
            },
            "content_custom_url":[],
            "redirect_to":{
              "login_url":"",
              "custom_url_link":"",
              "message":""
            }
        },
        "state": "published",
        "active":false,
        "add_url":"",
        "select_field":"",
        "savingData": false,
        "collection_selection_open": false,
        "products_selection_open": false,
        "page_loaded": false
      }

      this.save_settings = this.save_settings.bind(this);
  }

  componentDidMount(){
    if(this.props.match.params.id){
      axios.get(this.props.host_name +"/app/manage-lock?shop="+this.props.shop+"&id="+this.props.match.params.id,).then(response => {
        if(response && response.data){
          this.setState({manage_lock: response.data.manage_lock, page_loaded: true});
        }
      })
      .catch(error => {
        this.setState({page_loaded: true});
      });
    }
    else{
        this.setState({page_loaded: true});
    }
  }

  save_settings(){
      this.setState({savingData: true});
      var data = {
        shop: this.props.shop,
        manage_lock : this.state.manage_lock,
        state: this.state.state
      }
      if(this.props.match.params.id){
        axios.put(this.props.host_name +"/app/manage-lock/"+this.props.match.params.id, data ).then(response => {
          this.setState({savingData: false});
          this.props.history.push("/manage-locks");
        })
        .catch(error => {
          this.setState({savingData: false});
        });
      }
      else{
        axios.post(this.props.host_name +"/app/manage-lock", data ).then(response => {
          this.setState({savingData: false});
          this.props.history.push("/manage-locks");
        })
        .catch(error => {
          this.setState({savingData: false});
        });
      }
  }

  render(){
      const { title,applies_to,conditions,access_permissions,content_name,collections,products,content_custom_url,redirect_to }=this.state.manage_lock;
      const options = [
          {label: 'is tagged with', value: 'equal'},
          {label: 'is not tagged with', value: 'not_equal'}
      ];
      var popup_modal = <div>
        {
          this.state.collection_selection_open && 
          <ResourcePicker
            resourceType="Collection"
            onSelection={(resources) => {
              var manage_lock = this.state.manage_lock;
              manage_lock["collections"]["selections"] = manage_lock["collections"]["selections"] || [];
              resources.selection.forEach(item => {
                if(manage_lock["collections"]["selections"].findIndex(x => x.handle === item.handle) === -1){
                    manage_lock["collections"]["selections"].push({
                      id: item.id,
                      handle: item.handle,
                      title: item.title,
                      wsid: item.wsid
                    });
                }
              });
              this.setState({manage_lock:manage_lock, collection_selection_open: false});
            }}
            onCancel={() => { this.setState({collection_selection_open: false}) }}
          />
        }
        {
          this.state.products_selection_open && 
          <ResourcePicker
            onSelection={(resources) => {
              var manage_lock = this.state.manage_lock;
              manage_lock["products"]["selections"] = manage_lock["products"]["selections"] || [];
              resources.selection.forEach(item => {
                if(manage_lock["products"]["selections"].findIndex(x => x.handle === item.handle) === -1){
                    manage_lock["products"]["selections"].push({
                      id: item.id,
                      handle: item.handle,
                      title: item.title,
                      wsid: item.wsid,
                      products_count: item.productsCount
                    });
                }
              });
              this.setState({manage_lock:manage_lock, products_selection_open: false});
            }}
            onCancel={() => { this.setState({products_selection_open: false}) }}
          />
        }
        <Modal
          open={this.state.active}
          onClose={()=> {this.setState({active:false})}}
          title="Products"
          primaryAction={{
            content: 'Add',
            onAction: ()=>{},
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: ()=>{this.setState({active:false})},
            },
          ]}
        >
          <Modal.Section>
              <p>
                Use Instagram posts to share your products with millions of
                people. Let shoppers buy from your store without leaving
                Instagram.
              </p>
          </Modal.Section>
        </Modal>
      </div>;

      const textField = (
          <Autocomplete.TextField
          onChange={(value)=>{this.setState({select_field:value})}}
          label="Pages"
          labelHidden
          value={this.state.select_field}
          placeholder="Cart, Search"
          />
      );
      const page_content = <Layout>
      <Layout.Section>
          <Card sectioned title="Step 1: Lock Name">
              <TextField label="lock name"
              labelHidden 
              value={title} 
              placeholder="e.g Lock for wholesale customers"
              helpText="Write the title of your Lock Rule. This will not appear to the customers."
              onChange={(value)=> {
                  var manage_lock = this.state.manage_lock;
                  manage_lock.title = value;
                  this.setState({manage_lock})
              }}
              />
          </Card>
          <Card sectioned title="Step 2: Customers/Visitors lock applies to">
              <Stack vertical spacing="extraTight">
                  <RadioButton
                      label="Selected customers"
                      checked={applies_to === 'selected_customers'}
                      id="selected_customers"
                      name="applies_to"
                      onChange={(id,value) => {
                          var manage_lock = this.state.manage_lock;
                          manage_lock.applies_to = value;
                          this.setState({manage_lock});
                      }}
                  />
                  <RadioButton
                      label="All login customers"
                      id="loggedin_customers"
                      name="applies_to"
                      checked={applies_to === 'loggedin_customers'}
                      onChange={(id,value) => {
                          var manage_lock = this.state.manage_lock;
                          manage_lock.applies_to = value;
                          this.setState({manage_lock});
                      }}
                  />
                  <RadioButton
                      label="All Visitors"
                      checked={applies_to === 'all_customers'}
                      id="all_customers"
                      name="applies_to"
                      onChange={(id,value) => {
                          var manage_lock = this.state.manage_lock;
                          manage_lock.applies_to = value;
                          this.setState({manage_lock});
                      }}
                  />
              </Stack>
              <br/>
              { applies_to === "selected_customers"&& 
                  <Card sectioned>
                  <div className="all_tags_data">
                      {   conditions.map((data,index) =>
                          <div className="tags_data" key={index} length={data.length} >
                          {data.length>0 && data.map((data2,index2) =>
                              <div key={(index2+1)} length={data2.length} >
                              <Stack>
                                  <Stack.Item>
                                      <div className="tag_select">
                                          <Select
                                              label="lock conditions"
                                              labelHidden
                                              options={options}
                                              value={data2.title}
                                              onChange={(value)=>{
                                                  var manage_lock = this.state.manage_lock;
                                                  manage_lock.conditions[index][index2].title = value;
                                                  this.setState({manage_lock})
                                              }}
                                          />
                                      </div>
                                  </Stack.Item>
                                  <Stack.Item fill>
                                      <div className="tag_info">
                                          <TextField label="lock name"
                                          labelHidden 
                                          value={data2.value} 
                                          onChange={(value)=> {
                                              var manage_lock = this.state.manage_lock;
                                              manage_lock.conditions[index][index2].value = value;
                                              this.setState({manage_lock})
                                          }} 
                                          />
                                      </div>
                                  </Stack.Item>
                                  <Stack.Item>
                                  <div className="crs_btn">
                                      <Button size="slim" icon={CancelSmallMinor} plain onClick={()=>{
                                          var manage_lock = this.state.manage_lock;
                                          if(manage_lock.conditions[index].length <= 1)
                                          {
                                              manage_lock.conditions.splice(index,1);
                                          }else
                                          {
                                              manage_lock.conditions[index].splice(index2,1);
                                          }
                                          this.setState({manage_lock});
                                      }} ></Button>
                                  </div>
                              </Stack.Item>
                              </Stack>
                              </div>
                          )
                          }
                              <div style={{textAlign:"right"}}>
                              <Button size="slim" plain icon={PlusMinor} onClick={()=>{
                                  var new_obj = {"title":"tag_with","value":"","condition":"OR"};
                                  var manage_lock = this.state.manage_lock;
                                  manage_lock.conditions[index] = [...manage_lock.conditions[index],new_obj];
                                  this.setState({manage_lock});
                              }} ></Button></div>
                          </div>
                          )
                      }
                  </div>
                  <div className="add_btn">
                      <Button primary onClick={()=>{
                          var new_obj = [{"title":"tag_with","value":"","condition":"AND"}];
                          if(conditions.length===0){
                              new_obj = [{"title":"tag_with","value":"","condition":"OR"}];
                          }
                          var manage_lock = this.state.manage_lock;
                          manage_lock.conditions = [...manage_lock.conditions,new_obj];
                          this.setState({manage_lock});
                      }}>AND</Button>
                  </div>
              </Card>
              }
          </Card>

          <Card sectioned title="Step 3: Access permissions">
              <Stack vertical spacing="extraTight">
                  <RadioButton
                      label="May Access"
                      checked={access_permissions === 'access'}
                      id="access"
                      name="access_permissions"
                      onChange={(id,value) => {
                          var manage_lock = this.state.manage_lock;
                          manage_lock.access_permissions = value;
                          this.setState({manage_lock});
                      }}
                  />
                  <RadioButton
                      label="Cannot Access"
                      id="deny"
                      name="access_permissions"
                      checked={access_permissions === 'deny'}
                      onChange={(id,value) => {
                          var manage_lock = this.state.manage_lock;
                          manage_lock.access_permissions = value;
                          this.setState({manage_lock});
                      }}
                  />
              </Stack>
          </Card>
          <Card sectioned title="Step 4: Lock content">
              <Stack>
                  <RadioButton
                      label="Products"
                      checked={content_name === 'products'}
                      id="products"
                      name="content_name"
                      onChange={(id,value) => {
                          var manage_lock = this.state.manage_lock;
                          manage_lock.content_name = value;
                          this.setState({manage_lock});
                      }}
                  />
                  <RadioButton
                      label="Collections"
                      checked={content_name === 'collections'}
                      id="collections"
                      name="content_name"
                      onChange={(id,value) => {
                          var manage_lock = this.state.manage_lock;
                          manage_lock.content_name = value;
                          this.setState({manage_lock});
                      }}
                  />
                  <RadioButton
                      label="Whole Store"
                      checked={content_name === 'entire_store'}
                      id="entire_store"
                      name="content_name"
                      onChange={(id,value) => {
                          var manage_lock = this.state.manage_lock;
                          manage_lock.content_name = value;
                          this.setState({manage_lock});
                      }}
                  />
                  <RadioButton
                      label="URL"
                      id="url"
                      name="content_name"
                      checked={content_name === 'url'}
                      onChange={(id,value) => {
                          var manage_lock = this.state.manage_lock;
                          manage_lock.content_name = value;
                          this.setState({manage_lock});
                      }}
                  />
              </Stack>
              <br/>
              {content_name === "products" &&
                  <div className="products">
                      <Card>
                          <Card.Section>
                          <Stack distribution="equalSpacing">
                              <Button icon={PlusMinor} primary onClick={()=>{this.setState({products_selection_open:true})}}>Select Products</Button>
                              {
                                  products.selections.length && 
                                  <Button destructive
                                    onClick={() => {
                                        if(window.confirm("Are you sure?")){
                                            var manage_lock = this.state.manage_lock;
                                            manage_lock.products.selections = [];
                                            this.setState({manage_lock:manage_lock})
                                        }
                                    }}
                                  >Remove All</Button>
                              }
                          </Stack>
                          <br/>
                          {/* {products.products.arr.length>0 && */}
                              <div className="product_data"> 
                              <Heading>{products.selections.length} products selected</Heading>
                              <ResourceList
                                  items={products.selections}
                                  renderItem={(item) => {
                                  const {id, title, handle } = item;
                                  return (
                                      <ResourceItem
                                      id={id}
                                      >
                                      <Stack distribution="equalSpacing">
                                      <Link url={'https://'+this.props.shop+'/products/'+handle} external={true}>{title}</Link>
                                          <Button outline destructive
                                            onClick={() => {
                                                var manage_lock = this.state.manage_lock;
                                                manage_lock.products.selections = manage_lock.products.selections.filter(x => x.id!==item.id);
                                                this.setState({manage_lock:manage_lock});
                                            }}
                                          >Remove</Button>
                                      </Stack>
                                      </ResourceItem>
                                  );
                                  }}
                              />
                              </div>
                          {/* } */}
                          </Card.Section>
                          {access_permissions !== 'access'&&
                          <Card.Section>
                          <FormLayout>
                                  <Checkbox
                                      label="Hide locked products from Product Listing sections (Collection pages, related products and search page etc.)"
                                      checked={products.hide_product_listing}
                                      onChange={(value)=>{
                                          var manage_lock = this.state.manage_lock;
                                          manage_lock.products.hide_product_listing = value;
                                          this.setState({manage_lock});
                                      }}
                                  />
                              </FormLayout>
                          </Card.Section>
                          }
                      </Card>
                  </div>
              }
              {content_name === "collections" &&
                  <div className="collections">
                      <Card>
                          <Card.Section>
                          <Stack distribution="equalSpacing">
                              <Button
                                icon={PlusMinor}
                                primary onClick={()=>{this.setState({collection_selection_open:true})}}
                              >Select Collection</Button>
                              {
                                  collections.selections.length && 
                                      <Button destructive 
                                      onClick={() => {
                                        if(window.confirm("Are you sure?")){
                                            var manage_lock = this.state.manage_lock;
                                            manage_lock.collections.selections = [];
                                            this.setState({manage_lock:manage_lock})
                                        }
                                    }}
                                    >
                                    Remove All
                                    </Button>
                              }
                              
                          </Stack>
                          <br/>
                          {/* {collections.selections.length>0 && */}
                              <div className="coll_data"> 
                              <Heading>Total Collections Selected - {collections.selections.length}</Heading>
                              <ResourceList
                                  items={collections.selections}
                                  renderItem={(item) => {
                                  const {id, title,handle } = item;
                                  return (
                                      <ResourceItem
                                      id={id}
                                      >
                                      <Stack distribution="equalSpacing">
                                          <Link url={'https://'+this.props.shop+'/collections/'+handle} external={true}>{title}</Link>
                                          <Button outline destructive
                                            onClick={() => {
                                                var manage_lock = this.state.manage_lock;
                                                manage_lock.collections.selections = manage_lock.collections.selections.filter(x => x.id!==item.id);
                                                this.setState({manage_lock:manage_lock});
                                            }}
                                          >Remove</Button>
                                      </Stack>
                                      </ResourceItem>
                                  );
                                  }}
                              />
                              </div>
                          {/* } */}
                          </Card.Section>
                          {access_permissions !== 'access'&&
                          <Card.Section>
                              <FormLayout>
                                  <Checkbox
                                      label={"Hide locked collections from Collection Listing page [URL: https://"+this.props.shop+"/collections]"}
                                      checked={collections.hide_from_collections}
                                      onChange={(value)=>{
                                          var manage_lock = this.state.manage_lock;
                                          manage_lock.collections.hide_from_collections = value;
                                          this.setState({manage_lock});
                                      }}
                                  />
                                  <Checkbox
                                      label="Hide locked products from Product Listing sections (Collection pages, related products and search page etc.)"
                                      checked={collections.hide_product_listing}
                                      onChange={(value)=>{
                                          var manage_lock = this.state.manage_lock;
                                          manage_lock.collections.hide_product_listing = value;
                                          this.setState({manage_lock});
                                      }}
                                  />
                              </FormLayout>
                          </Card.Section>}
                      </Card>
                  </div>
              }
              {content_name === "url" &&
                  <>
                  <TextField label="lock name"
                      labelHidden 
                      prefix={"https://"+this.props.shop+'/'}
                      value={this.state.add_url} 
                      onChange={(value)=> {
                          this.setState({add_url:value});
                      }}
                      connectedRight={<Button primary onClick={()=>{
                          var manage_lock = this.state.manage_lock;
                          if(content_custom_url.findIndex(x=>x.url === this.state.add_url)>-1)
                          {alert("URL Already Exist")}else
                          {
                              manage_lock.content_custom_url.push({"url":this.state.add_url});
                              this.setState({manage_lock});
                          }
                          this.setState({add_url:''})
                      }}>ADD</Button>} 
                  />
                  <br/>
                  <div className="coll_data"> 
                      <Heading>Total Selected Pages - {content_custom_url.length}</Heading>
                      <ResourceList
                          items={content_custom_url}
                          renderItem={(item,id,index) => {
                          const {url} = item;
                          return (
                              <ResourceItem
                              id={index}
                              >
                              <Stack distribution="equalSpacing">
                              <Link url={"https://"+this.props.shop+'/'+url} external={true}>https://{this.props.shop}/{url}</Link>
                                  <Button size="slim" outline destructive onClick={()=>{
                                      var manage_lock = this.state.manage_lock;
                                      manage_lock.content_custom_url.splice(index,1);
                                      this.setState({manage_lock})
                                  }}>Remove</Button>
                              </Stack>
                              </ResourceItem>
                          );
                          }}
                      />
                  </div>
                  </>
              }
          </Card>
          <Card sectioned title="Step 5: Redirect rules">
              <Stack vertical spacing="extraTight">
                  <RadioButton
                      label="Login Page"
                      checked={redirect_to.login_url === 'login_page'}
                      name="redirect_to"
                      id="login_page"
                      onChange={(id,value) => {
                          var manage_lock = this.state.manage_lock;
                          manage_lock.redirect_to.login_url = value;
                          manage_lock.redirect_to.custom_url_link = '/account/login';
                          this.setState({manage_lock});
                      }}
                  />
                  <RadioButton
                      label="Custom URL"
                      id="specific_url"
                      name="redirect_to"
                      checked={redirect_to.login_url === 'specific_url'}
                      onChange={(id,value) => {
                          var manage_lock = this.state.manage_lock;
                          manage_lock.redirect_to.custom_url_link = '';
                          manage_lock.redirect_to.login_url = value;
                          this.setState({manage_lock});
                      }}
                  />
                   { redirect_to.login_url === "specific_url" &&
                      <TextField label="lock name"
                          labelHidden 
                          value={redirect_to.custom_url_link}
                          onChange={(value)=> {
                              var manage_lock = this.state.manage_lock;
                              manage_lock.redirect_to.custom_url_link = value;
                              this.setState({manage_lock})
                          }} 
                      />
                  }
                  <RadioButton
                      label="Show Custom Message"
                      id="custom_message"
                      name="redirect_to"
                      checked={redirect_to.login_url === 'custom_message'}
                      onChange={(id,value) => {
                          var manage_lock = this.state.manage_lock;
                          manage_lock.redirect_to.custom_url_link = '';
                          manage_lock.redirect_to.login_url = value;
                          this.setState({manage_lock});
                      }}
                  />
                  { 
                    redirect_to.login_url === 'custom_message' &&
                      <TextField
                          multiline={4}
                          labelHidden={true}
                          label="Custom Message"
                          value={redirect_to.message}
                          name="redirect_to_Message"
                          id="redirect_to_Message"
                          onChange={(value) => {
                              var manage_lock = this.state.manage_lock;
                              manage_lock.redirect_to.message = value;
                              this.setState({manage_lock});
                          }}
                      />
                    }
              </Stack>
          </Card>
      </Layout.Section>
      <Layout.Section>
          <Stack>
              <Stack.Item fill></Stack.Item>
              <Stack.Item><Button url="/manage-locks" target="APP">Cancel</Button></Stack.Item>
              <Stack.Item><Button primary loading={this.state.savingData} onClick={this.save_settings}>Save</Button></Stack.Item>
          </Stack>
      </Layout.Section>
    </Layout>;
      if(this.state.page_loaded){
        return(
            <div>
              <PageTitleBar
                title={this.props.show === "add"?"Create Lock":"Edit Lock"}
                breadcrumbs={[{content: 'Manage Locks', url: '/manage-locks'}]}
                primaryAction={{
                  disabled: this.state.savingData,
                  content: "Save",
                  onAction: this.save_settings
                }}
                secondaryActions={[
                  {
                    target: "APP",
                    content: "Cancel",
                    url: "/manage-locks"
                  }
                ]}
              />
              {page_content}
              {popup_modal}
            </div>
        );
      }
      else{
        return(
            <SkeletonPageComp></SkeletonPageComp>
        );
      }
  }
}

export default CreateLock;