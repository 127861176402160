import React, { Component } from 'react';
import {
  Layout,
  Card,
  TextStyle,
  Link
} from '@shopify/polaris';
import * as PropTypes from 'prop-types';
import axios from 'axios';
import ToastMessage from '../Common/ToastMessage';

export default class ImportQuantityBreak extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      host_name: this.props.host_name,
      shop: this.props.shop,
      shop_id: this.props.shop_id,
      upload_file_btn_disable: true,
      showToast: false,
      toastMsg: '',
      toastError: false
    }
  }
  static contextTypes = {
    easdk: PropTypes.object,
  };
  handleFileSelect = (e) => {
    this.setState({upload_file_btn_disable: false});
  }

  showToastMessage(msg, error){
    this.setState({
      showToast: true,
      toastMsg: msg,
      toastError: error||false,
    });
  }
  
  dissmissToast = () =>{
    this.setState({
      showToast: false,
    });
  }
  
  handleFormSubmit = (e) => {
    e.preventDefault();
    // HTML file input, chosen by user
    this.setState({upload_file_btn_disable: true});
    var $form = document.getElementById("form-upload-files");
    var data = new FormData($form);
    axios.post(this.state.host_name+'/import/qb/'+this.state.shop+'/'+this.state.shop_id, data)
      .then( response => {
          if(response.data.status === 1){
            this.showToastMessage(response.data.message);
          }
          else{
            this.showToastMessage(response.data.message, true);
            return false;
          }
      })
      .catch(error => {
          this.setState({upload_file_btn_disable: false});
      });
  }

  render() {
    var toast = this.state.showToast? (
      <ToastMessage
      onDismiss={this.dissmissToast}
      message={this.state.toastMsg}
      error={this.state.toastError}
      />
    ): (null);

    return (
      <div>
        {toast}
        <Layout>
          <Layout.Section>
            <Card sectioned
              primaryFooterAction={{content: 'Upload file',onAction: this.handleFormSubmit, disabled: this.state.upload_file_btn_disable, }}
            >
              <div>
                <form id="form-upload-files" encType="multipart/form-data" action={this.props.host_name+"/import/dg/"+this.props.shop} method="post" onSubmit={this.handleFormSubmit}>
                  <input type="file" name="file" accept=".csv" onChange={this.handleFileSelect} className="choose--csv-file"></input>
                </form>
              </div>
              <div style={{marginTop:'2rem'}}>
                <TextStyle variation="subdued">
                  Download a <Link url="https://solutionwin.s3.eu-west-2.amazonaws.com/task_manage/comments/Export_all_quantity_breaks_1556206152.csv" external={true}>sample CSV template</Link> to see an example of the format required.
                </TextStyle>
              </div>
            </Card>
            <div style={{marginTop:'2rem'}}>
            <TextStyle variation="strong"><i>Your shop info for csv file:</i></TextStyle>
              <p><TextStyle variation="code">Shop: {this.props.shop}</TextStyle></p>
              <p><TextStyle variation="code">Shop_id: {this.props.shop_id}</TextStyle></p>
            </div>
          </Layout.Section>
        </Layout>
      </div>
    );
  }
}