import React, { Component } from 'react';
import axios from 'axios';
import { Switch, Route } from 'react-router';
import { Banner,Spinner, Modal, Layout, SettingToggle, TextStyle, Link } from '@shopify/polaris';
import * as PropTypes from 'prop-types';
import Install from './Components/Install';
import Home from './Components/Home';
import VariantPicker from './Components/VariantPicker';
import UpdateProductsModal from './Components/UpdateProductsModal';
import UpdateCollectionsModal from './Components/UpdateCollectionsModal';
import UpdateVariantsModal from './Components/UpdateVariantsModal';
import './index.css';
import PricingPlan from './Components/PricingPlan';
import Help from './Components/Help';
import VariantPickerForSelectedProducts from './Components/VariantPickerForSelectedProducts';
import UpdateExcludedCollections from './Components/UpdateExcludedCollections';
import UpdateExcludedProducts from './Components/UpdateExcludedProducts';
import store from 'store2';
import FAQ from './Components/FAQ';
import FreeInstallationSupport from './Components/FreeInstallationSupport';
// import ImportDiscountGroup from './Components/ImportDiscountGroup';
import TrackOrders from './Components/TrackOrders';
import _Modules from './modules.json';
import InstallationStatus from './Components/InstallationStatus';
import AccessControl from './Components/AccessControl';
import SkeletonPageComp from './Components/SkeletonPageComp';
import waio from './Helpers/functions';
import DeveloperTools from './Components/version201/DeveloperTools';
// import UpdateCollectionsModalQB from './Components/version204/qb/UpdateCollectionsModalQB';
import MinOrderControl from './Components/version207/MinOrderControl';
import CreateMinOrderControl from './Components/version207/CreateMinOrderControl';
import Unlock from './Components/version208/Unlock';
// import CreateQuantityBreak from './Components/upgraded/2019-12/CreateQuantityBreak';
// import QuantityBreaks from './Components/QuantityBreaksV3';
// import GeneralDiscountGroups from './Components/upgraded/2019-12/GeneralDiscountGroups';
import QuickOrderForm  from './Components/upgraded/2019-12/QuickOrderForm';
import CreateQuickOrderForm  from './Components/upgraded/2019-12/CreateQuickOrderForm';
import ShopInformation from './Components/upgraded/2019-12/ShopInformation';
import CustomerDetail from './Components/upgraded/2020-04/CustomerDetail';

import trialBannerJSON from './trialBanner.json';
import upgradePlanBanner from './upgradePlanBanner.json';
import Configuration from './Components/upgraded/2020-04/Configuration';

import NetTerms from './Components/upgraded/2020-04/NetTerms';
import ManualDraftOrder from './Components/upgraded/2020-04/ManualDraftOrder';
import CommonHelper from './Helpers/Common';
import ToastMessage from './Common/ToastMessage';

// version 2.3.7- changes 
import Dashboard from './Components/upgraded/2020-07/Dashboard';
import VolumeDiscountList from './Components/upgraded/2020-07/VolumeDiscountList';
import RegularDiscountList from './Components/upgraded/2020-07/RegularDiscountList';
import QuantityControls from './Components/upgraded/2020-07/QuantityControls';
import ManageCustomerDashboard from './Components/upgraded/2020-07/ManageCustomerDashboard';
import WholesaleForms from './Components/upgraded/2020-04/WholesaleForms';
import CustomerList from './Components/upgraded/2020-04/CustomersList';
import Settings from './Components/upgraded/2020-07/GeneralSettingsDashboard';
import ManageShipping from './Components/upgraded/2020-07/ManageShipping';
import CreateManageShipping from './Components/upgraded/2020-07/CreateManageShipping';
import ShippingCountries from './Components/upgraded/2020-07/ShippingCountries';
import ManageLocks from './Components/upgraded/2020-07/ManageLocks';
import CreateLock from './Components/upgraded/2020-07/CreateLock';
import CreateVolumeDiscount from './Components/upgraded/2020-07/CreateVolumeDiscount';
import CreateRegularDiscount from './Components/upgraded/2020-07/CreateRegularDiscount';
import ImportVolumeDiscount from './Components/upgraded/2020-07/ImportVolumeDiscount';
import ImportRegularDiscount from './Components/upgraded/2020-07/ImportRegularDiscount';
import AppStatusBanner from './Components/AppStatusBanner';

const help_links = {
  link1: process.env.REACT_APP_ABOUT_LINK,
  link2: process.env.REACT_APP_DOC_LINK
};

var isAdmin = CommonHelper.isAdmin();
var snippet_version = CommonHelper.getSnippetVersion();
const host_name = CommonHelper.getHostName();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      host_name: host_name,
      shop_features: [],
      help_links: help_links,
      shop_id: store('shop_id') || '',
      shop: CommonHelper.getShop() || store('shop'),
      server_error: false,
      page_loaded: false,
      pricing_plans: [],
      shop_payment: [],
      payment_status: 'active',
      user_current_plan: [],
      original_user_current_plan: {},
      app_configured: true,
      new_installation: false,
      total_products: 0,
      change_setting: false,
      installation_page: false,
      app_enabled: true,
      new_client: false,
      enable_btn_loading: false,
      free_installation_support_popup: false,
      install_status: "completed",
      disabled_all_nav: false,
      weight_unit: null,
      currency: null,
      current_shop_info: [],
      shop_trial: {},
      app_mode: null,
      showToast: false,
      toastMsg: "",
      toastError: false
    };
  }

  componentDidMount() {
    if (window.location.pathname === '/') {
      this.setState({ new_installation: true });
    }
    else if (window.location.pathname === '/install') {
      this.setState({ installation_page: true });
    }
    else {
      let shop = this.state.shop;
      if (!shop) {
        shop = CommonHelper.getShop();
        if (shop) {
          this.setState({
            page_loaded: true
          })
        }
      }
      else if (shop) {
        axios.get(host_name + '/new/get-shop-data/' + shop)
          .then(response => {
            if(store('ignore_live_file') === true){
            }
            else{
              store('snippet_version', response.data.snippet_version);
              snippet_version = response.data.snippet_version;
            }
            store('shop_id', response.data._id);
            store('shop', response.data.shop);
            store('owner_email', response.data.email);
            store('store_currency', response.data.currency);
            this.setState({
              shop_features: response.data.shop_features,
              shop: response.data.shop,
              shop_id: response.data._id,
              page_loaded: true,
              shop_payment: response.data.shop_payment,
              payment_status: response.data.shop_payment.status,
              app_configured: response.data.app_configured,
              total_products: response.data.total_products,
              change_setting: response.data.change_setting,
              app_enabled: response.data.app_enabled,
              new_client: response.data.new_client,
              install_status: response.data.install_status,
              disabled_all_nav: response.data.shop_payment.status !== "active" ? true : false,
              weight_unit: response.data.weight_unit,
              currency: response.data.currency
            });
          })
          .catch(error => {
            this.setState({ server_error: true });
            this.showToastMessage(error, true);
          });
        axios.get(host_name + '/admin/get-plan-and-permissions/' + shop)
          .then(response => {
            if (response.data) {
              if (response.data.plans) {
                this.setState({
                  user_current_plan: response.data.plans
                });
              }
            }
          })
          .catch(error => {
            this.showToastMessage(error, true);
          });
          axios.get(host_name + '/admin/get-plans-and-privileges/' + shop)
          .then(response => {
            if (response.data) {
              if (response.data.plans) {
                this.setState({
                  original_user_current_plan: response.data.plans
                });
              }
            }
          })
          .catch(error => {
            this.showToastMessage(error, true);
          });

          axios.get(host_name + '/static/shop-detail/'+ shop).then(response => {
            if(response && response.data){
                this.setState({current_shop_info: response.data});
                if(typeof response.data.app_mode !== "undefined"){
                  if(response.data.app_mode === "test"){
                    this.setState({app_mode: "test"});
                  }
                  else if(response.data.app_mode === "live"){
                    this.setState({app_mode: "live"});
                  }
                }
            }
          }).catch(e=>{
            this.showToastMessage("Server error", true);
          });

          axios.get(host_name + '/static/shop-trial/'+ shop).then(response => {
            if(response && response.data){
                this.setState({shop_trial: response.data});
            }
          }).catch(e=>{
            this.showToastMessage("Server error", true);
          });

      }
    }
  }

  static contextTypes = {
    easdk: PropTypes.object,
  };

  showToastMessage(msg, error){
    this.setState({
      showToast: true,
      toastMsg: msg,
      toastError: error||false,
    });
  }

  dissmissToast = () =>{
    this.setState({
      showToast: false,
    });
  }

  isBasicPlan(){
    var basic = false;
    if(this.state.user_current_plan){
      var plan_id = this.state.user_current_plan?this.state.user_current_plan.plan_id:null;
      var valid = false;
      if(plan_id){
        valid = plan_id.indexOf("basic") > -1;
        if(valid){
            this.basicPlan = true;
            basic = true;
        }
      }
    }
    return basic;
  }

  planName(){
    var plan_name = "";
    if(this.state.original_user_current_plan){
      try{
        plan_name = this.state.original_user_current_plan.plan_name;
      }catch(e){}
    }
    return plan_name;
  }
  

  showTrialBanner(){
    var plan_name = this.planName();
    var shop_trial =  this.state.shop_trial;
    try{
      plan_name = plan_name.toLowerCase();
    }catch(e){}
    var title = trialBannerJSON.title;
    var descripton = trialBannerJSON.descripton;
    try{
      title = title.split("[[trial_days]]").join(shop_trial.trial_days);
    }catch(e){}
    var show = false;
    var pathname = window.location.pathname;
    for (var i = 0; i < trialBannerJSON.settings.length; i++) {
      var settings = trialBannerJSON.settings[i];
      if(pathname.indexOf(settings.url) > -1){
        if(settings.show_on_plans.indexOf(plan_name) > -1){
          show = true;//show the banner;
          try{
            descripton = descripton.split("[[plan_name]]").join(settings.upgrade_to)
            .split("[[title]]").join(settings.title);
          }catch(e){}
          try{
            descripton = descripton.split("Click Here").join("");
          }catch(e){}
        }
      }
    }//endfor

    var html = shop_trial.trial_days > 0?
    <div style={{margin : "2rem 0rem"}}>
      <Banner
        status={trialBannerJSON.type}
        title={title}
      >
      <p>{descripton}{ <Link url='/pricing-plans'> Click Here</Link>}</p>
    </Banner>
    </div>
    :null;

    if(!show){
      html = null;
    }
    return html;

  }

  showUpgradePlanBanner(){
    var plan_name = this.planName();
    var shop_trial =  this.state.shop_trial;
    try{
      plan_name = plan_name.toLowerCase();
    }catch(e){}
    var title = upgradePlanBanner.title;
    var descripton = upgradePlanBanner.descripton;
    try{
      if(title){
        title = title.split("[[trial_days]]").join(shop_trial.trial_days);
      }
    }catch(e){}
    var show = false;
    var pathname = window.location.pathname;
    for (var i = 0; i < upgradePlanBanner.settings.length; i++) {
      var settings = upgradePlanBanner.settings[i];
      if(pathname.indexOf(settings.url) > -1){
        if(settings.show_on_plans.indexOf(plan_name) > -1){
          show = true;//show the banner;
          try{
            descripton = descripton.split("[[plan_name]]").join(settings.upgrade_to)
            .split("[[title]]").join(settings.title);
          }catch(e){}
          try{
            descripton = descripton.split("Click Here").join("");
          }catch(e){}
        }
      }
    }//endfor

    var html = shop_trial.trial_days <= 0? <div style={{margin : "2rem 0rem"}}>
      <Banner
        status={upgradePlanBanner.type}
        title={title}
      >
     <p>{descripton}{ <Link url='/pricing-plans'> Click Here</Link>}</p>
      </Banner>
    </div>:null;

    if(!show){
      html = null;
    }
    return html;

  }

  handleEnableApp = () => {
    var app_mode = this.state.app_mode;
    if(this.state.app_mode === "live"){
      this.setState({app_mode: "test"});
      app_mode = "test";
    }
    else{
      this.setState({app_mode: "live"});
      app_mode = "live";
    }
    const settingsData = {
      app_mode: app_mode,
      shop: this.state.shop
    };
    axios.put(this.state.host_name+'/app/app-mode', settingsData)
    .then( response => {
        if(response.data.status === 1){
          this.showToastMessage(response.data.message);
        }
        else{
          this.showToastMessage(response.data.message, true);
        }
    })
    .catch(error => {
      this.showToastMessage("Server error", true);
    });
  };

  render() {
    const {
      current_shop_info, shop_trial
    } = this.state;
    // const primary_action = { content: 'Ask Question', disabled: this.state.disabled_all_nav, onAction: () => { this.setState({ free_installation_support_popup: true })}};
    const primary_action = null;
    var secondary_actions = [
      { content: 'Automatic Discounts', url: '/pricing-groups', target: 'app', disabled: this.state.disabled_all_nav },
      { content: 'Quantity Breaks', url: '/quantity-breaks', target: 'app', disabled: this.state.disabled_all_nav },
      { content: 'Settings', url: '/settings', target: 'app', disabled: this.state.disabled_all_nav },
    ];
    const actionGroups = [
      {
        title: 'More',
        actions: [
          { content: 'User Guide', url: help_links.link2, target: 'remote', disabled: this.state.disabled_all_nav },
          { content: 'Subscription', url: '/pricing-plans', target: 'app' },
          { content: 'Installation / Uninstalaltion', url: '/home', target: 'app', disabled: this.state.disabled_all_nav },
          { content: 'Ask Question', disabled: this.state.disabled_all_nav, onAction: () => { this.setState({ free_installation_support_popup: true }) } }
        ],
      }
    ];

    if (this.state.shop === 'necklet-jewelry.myshopify.com' || this.state.shop === 'solution-win-testing.myshopify.com') {
      actionGroups[0].actions.push({ content: 'Track Orders', url: '/track-orders', target: 'app', disabled: this.state.disabled_all_nav });
    }

    if (snippet_version && waio.compareVersionNumbers(snippet_version, _Modules.find(x => x._id === 126).snippet_version) > -1) {
      secondary_actions.push({ content: 'Shipping', url: '/shipping', target: 'app', disabled: this.state.disabled_all_nav });
      secondary_actions.push({ content: 'Access Control', url: '/access-control', target: 'app', disabled: this.state.disabled_all_nav });
    }
    if (snippet_version && waio.compareVersionNumbers(snippet_version, _Modules.find(x => x._id === 201).snippet_version) > -1) {
      actionGroups[0].actions.push({ content: 'Developer tools', url: '/dev-tools', target: 'app', disabled: this.state.disabled_all_nav });
      actionGroups[0].actions.push({ content: 'Minimum order control', url: '/min-order-control', target: 'app', disabled: this.state.disabled_all_nav });
    }

    if (snippet_version && waio.compareVersionNumbers(snippet_version, _Modules.find(x => x._id === 202).snippet_version) > -1) {
      secondary_actions[0].url = "/general-discount-groups";
    }

    const pageLoading = <div className="snipper-container">
      <center><Spinner color="teal" size="large" /></center>
    </div>;

    const appDisabledBanner = <div><Banner
      title="Application is disabled"
      status="critical"
      action={{ content: 'Enable app', url: '/settings', target: "app" }}
    >
    </Banner>
      <br></br>
    </div>;

    const reactRouter = <div>
      <Switch>
        <Route
          exact
          path="/"
          render={props => <Home {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} />}
        />
        <Route
          exact
          path="/dashboard"
          render={props => <Dashboard {...props} shop_trial={shop_trial} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} user_current_plan={this.state.user_current_plan} shop_features={this.state.shop_features}/>}
        />
        <Route
          exact
          path="/help"
          render={props => <Help {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} help={help_links} />}
        />
        
        <Route
          exact
          path="/home"
          render={props => <Configuration {...props} snippet_version={snippet_version} shop={this.state.shop} shop_features={this.state.shop_features} host_name={host_name} help={help_links} />}
        />
        <Route
          exact
          path="/config"
          render={props => <Configuration {...props} snippet_version={snippet_version} shop={this.state.shop} shop_features={this.state.shop_features} host_name={host_name} help={help_links} />}
        />
        <Route
          exact
          path="/install"
          render={props => <Install {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} help={help_links} secondaryActions={secondary_actions} primaryAction={primary_action} actionGroups={actionGroups} />}
        />
        <Route
          exact
          path="/pricing-plans"
          render={props => <PricingPlan {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} help={help_links} />}
        />
        <Route
          exact
          path="/admin/recuring-charge/callback"
          render={props => <PricingPlan {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} help={help_links} />}
        />
        <Route
          exact
          path="/install-snippet"
          render={props => <Configuration {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} help={help_links} />}
        />
        <Route
          exact
          path="/faq"
          render={props => <FAQ {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} help={help_links} />}
        />
        <Route
          exact
          path="/free-installation-support"
          render={props => <FreeInstallationSupport {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} />}
        />
        <Route
          exact
          path="/update-excluded-products"
          render={props => <UpdateExcludedProducts {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} />}
        />
        <Route
          exact
          path="/update-excluded-collections"
          render={props => <UpdateExcludedCollections {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} />}
        />
        <Route
          exact
          path="/variant-picker"
          render={props => <VariantPicker {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} total_products={this.state.total_products} />}
        />
        <Route
          exact
          path="/selected-products-variant-picker"
          render={props => <VariantPickerForSelectedProducts {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} total_products={this.state.total_products} />}
        />
        
        <Route
          exact
          path="/update-products-modal"
          render={props => <UpdateProductsModal {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} />}
        />
        <Route
          exact
          path="/update-collections-modal"
          render={props => <UpdateCollectionsModal {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} />}
        />
        <Route
          exact
          path="/update-variants-modal"
          render={props => <UpdateVariantsModal {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} />}
        />

        <Route
          exact
          path="/track-orders"
          render={props => <TrackOrders {...props} snippet_version={snippet_version} shop={this.state.shop} shop_features={this.state.shop_features} host_name={host_name} shop_id={this.state.shop_id} />}
        />
        <Route
          exact
          path="/access-control"
          render={props => <AccessControl {...props} is_basic_plan={this.isBasicPlan()} user_current_plan={this.state.user_current_plan} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} help={help_links} />}
        />
        <Route
          exact
          path="/settings"
          render={props => <Settings {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_features={this.state.shop_features} shop_id={this.state.shop_id} change_setting={this.state.change_setting} help={help_links} />}
        />
        <Route
          exact
          path="/dev-tools"
          render={props => <DeveloperTools {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_features={this.state.shop_features} shop_id={this.state.shop_id} change_setting={this.state.change_setting} help={help_links} />}
        />
        <Route
          exact
          path="/min-order-control"
          render={props => <MinOrderControl {...props} is_basic_plan={this.isBasicPlan()} weight_unit={this.state.weight_unit} show="list" snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_features={this.state.shop_features} shop_id={this.state.shop_id} change_setting={this.state.change_setting} help={help_links} user_current_plan={this.state.user_current_plan}/>}
        />
        <Route
          exact
          path="/add-min-order-control"
          render={props => <CreateMinOrderControl {...props} is_basic_plan={this.isBasicPlan()} weight_unit={this.state.weight_unit} show="add" snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_features={this.state.shop_features} shop_id={this.state.shop_id} change_setting={this.state.change_setting} help={help_links} user_current_plan={this.state.user_current_plan}/>}
        />
        <Route
          exact
          path="/min-order-control/:id"
          render={props => <CreateMinOrderControl {...props} is_basic_plan={this.isBasicPlan()} weight_unit={this.state.weight_unit} show="edit" snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_features={this.state.shop_features} shop_id={this.state.shop_id} change_setting={this.state.change_setting} help={help_links} user_current_plan={this.state.user_current_plan}/>}
        />
        <Route
          exact
          path="/shipping"
          render={props => <ManageShipping {...props} is_basic_plan={this.isBasicPlan()} weight_unit={this.state.weight_unit} show="list" snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_features={this.state.shop_features} shop_id={this.state.shop_id} change_setting={this.state.change_setting} help={help_links} user_current_plan={this.state.user_current_plan} />}
        />
        <Route
          exact
          path="/manage-shipping"
          render={props => <ManageShipping {...props} is_basic_plan={this.isBasicPlan()} weight_unit={this.state.weight_unit} show="list" snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_features={this.state.shop_features} shop_id={this.state.shop_id} change_setting={this.state.change_setting} help={help_links} user_current_plan={this.state.user_current_plan} />}
        />
        <Route
          exact
          path="/add-manage-shipping"
          render={props => <CreateManageShipping {...props} is_basic_plan={this.isBasicPlan()} weight_unit={this.state.weight_unit} show="add" snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_features={this.state.shop_features} shop_id={this.state.shop_id} change_setting={this.state.change_setting} help={help_links} user_current_plan={this.state.user_current_plan} />}
        />
        <Route
          exact
          path="/manage-shipping/:id"
          render={props => <CreateManageShipping {...props} is_basic_plan={this.isBasicPlan()} weight_unit={this.state.weight_unit} show="edit" snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_features={this.state.shop_features} shop_id={this.state.shop_id} change_setting={this.state.change_setting} help={help_links} user_current_plan={this.state.user_current_plan}/>}
        />
        <Route
          exact
          path="/select-shipping-countries"
          render={props => <ShippingCountries {...props} />}
        />

        <Route
          exact
          path="/manual-draft-order"
          render={props => <ManualDraftOrder {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_features={this.state.shop_features} shop_id={this.state.shop_id} change_setting={this.state.change_setting} help={help_links} user_current_plan={this.state.user_current_plan}/>}
        />
       
        <Route
          exact
          path="/unlock"
          render={props => <Unlock {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />
        
        <Route
          exact
          path="/quantity-controller"
          render={props => <QuantityControls {...props} show="list" snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />

        <Route
          exact
          path="/add-quantity-controller"
          render={props => <QuantityControls {...props} show="add" snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />

        <Route
          exact
          path="/edit-quantity-controller/:id"
          render={props => <QuantityControls {...props} show="edit" snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />

        <Route
          exact
          path="/wholesale-forms/:section"
          render={props => <WholesaleForms {...props} current_shop_info={current_shop_info} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />

        <Route
          exact
          path="/customers-list/:status"
          render={props => <CustomerList {...props} current_shop_info={current_shop_info} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />
        
        <Route
          exact
          path="/customers"
          render={props => <ManageCustomerDashboard {...props} current_shop_info={current_shop_info} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />
        
        <Route
          exact
          path="/customers/:id"
          render={props => <ManageCustomerDashboard {...props} current_shop_info={current_shop_info} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />
        
        <Route
          exact
          path="/customer/:id"
          render={props => <CustomerDetail {...props} getFromShopify={false} current_shop_info={current_shop_info} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />
        
        <Route
          exact
          path="/customer/shopify/:id"
          render={props => <CustomerDetail {...props} getFromShopify={true} current_shop_info={current_shop_info} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />

        <Route
          exact
          path="/quick-order-forms"
          render={props => <QuickOrderForm {...props} current_shop_info={current_shop_info} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />
        
        <Route
          exact
          path="/quick-order-form/edit/:id"
          render={props => <CreateQuickOrderForm {...props} show="edit" current_shop_info={current_shop_info} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />
        
        <Route
          exact
          path="/quick-order-form/add"
          render={props => <CreateQuickOrderForm {...props} show="add" current_shop_info={current_shop_info} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />
        
        <Route
          exact
          path="/net-terms"
          render={props => <NetTerms {...props} current_shop_info={current_shop_info} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />

        <Route
          exact
          path="/net-terms/settings"
          render={props => <NetTerms {...props} current_shop_info={current_shop_info} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />

        <Route
          exact
          path="/shop"
          render={props => <ShopInformation {...props} current_shop_info={current_shop_info} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />
      
     
        <Route
          exact
          path="/regular-discount/edit/:id"
          render={props => <CreateRegularDiscount {...props} show="edit" snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />
        <Route
          exact
          path="/regular-discount/create"
          render={props => <CreateRegularDiscount {...props} show="create" snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />
        <Route
          exact
          path="/regular-discount/list"
          render={props => <RegularDiscountList {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />
        <Route
          exact
          path="/pricing-groups"
          render={props => <RegularDiscountList {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />
        <Route
          exact
          path="/general-discount-groups"
          render={props => <RegularDiscountList {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />
        <Route
          exact
          path="/import-volume-discount"
          render={props => <ImportVolumeDiscount {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} shop_features={this.state.shop_features} />}
        />
        <Route
          exact
          path="/import-regular-discount"
          render={props => <ImportRegularDiscount {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} shop_features={this.state.shop_features} />}
        />
        <Route
          exact
          path="/volume-discount/edit/:id"
          render={props => <CreateVolumeDiscount {...props} show="edit" snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />
        <Route
          exact
          path="/volume-discount/create"
          render={props => <CreateVolumeDiscount {...props} show="create" snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />
        <Route
          exact
          path="/volume-discount/list"
          render={props => <VolumeDiscountList {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />
        <Route
          exact
          path="/quantity-breaks"
          render={props => <VolumeDiscountList {...props} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
        />
        <Route
          exact
          path="/import-volume-discount"
          render={props => <ImportVolumeDiscount {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} shop_features={this.state.shop_features} />}
        />
        
        <Route
          exact
          path="/manage-locks"
          render={props => <ManageLocks {...props} is_basic_plan={this.isBasicPlan()} user_current_plan={this.state.user_current_plan} shop={this.state.shop} host_name={host_name} />}
        />
        
        <Route
          exact
          path="/manage-locks/create"
          render={props => <CreateLock {...props} show={"add"} is_basic_plan={this.isBasicPlan()} user_current_plan={this.state.user_current_plan} shop={this.state.shop} host_name={host_name} />}
        />
        
        <Route
          exact
          path="/manage-locks/edit/:id"
          render={props => <CreateLock {...props} show={"edit"} is_basic_plan={this.isBasicPlan()} user_current_plan={this.state.user_current_plan} shop={this.state.shop} host_name={host_name} />}
        />

        {
          !isAdmin ?
            <Route
              exact
              path="/status"
              render={props => <InstallationStatus {...props} snippet_version={snippet_version} shop={this.state.shop} host_name={host_name} />}
            />
            : ''
        }

      <Route
        exact
        path="*"
        render={props => <Dashboard {...props} shop_trial={shop_trial} snippet_version={snippet_version} shop_payment={this.state.shop_payment} shop_features={this.state.shop_features} shop={this.state.shop} host_name={host_name} user_current_plan={this.state.user_current_plan} total_products={this.state.total_products} help={help_links} />}
      />

      </Switch>
    </div>;

    const modals = <Modal
      src={'/free-installation-support'}
      open={this.state.free_installation_support_popup}
      onClose={() => this.setState({
        free_installation_support_popup: false,
      })}
      title="Free installation support"
      secondaryActions={[
        {
          content: 'Close',
          onAction: () => {
            this.setState({
              free_installation_support_popup: false,
            })
          },
        },
      ]}
    >
    </Modal>;

    var app_mode_section = this.state.app_mode !== null ?
    <div style={{marginBottom:"2rem"}}>
      <AppStatusBanner app_mode={this.state.app_mode} shop={this.state.shop} host_name={this.state.host_name}></AppStatusBanner>
    </div>
  :null;
    
    var toast = this.state.showToast? (
      <ToastMessage
        onDismiss={this.dissmissToast}
        message={this.state.toastMsg}
        error={this.state.toastError}
      />
    ): (null);

    if(
      window.location.pathname === '/import-quantity-breaks' ||
      window.location.pathname === '/import-discount-groups' ||
      window.location.pathname === '/free-installation-support' ||
      window.location.pathname === '/update-excluded-collections' ||
      window.location.pathname === '/update-excluded-products' ||
      window.location.pathname === '/update-variants-modal' ||
      window.location.pathname === '/update-collections-modal' ||
      window.location.pathname === '/update-products-modal' ||
      window.location.pathname === '/selected-products-variant-picker' ||
      window.location.pathname === '/variant-picker'||
      window.location.pathname === '/update-collections-modal-qb' || 
      window.location.pathname === '/import-volume-discount' || 
      window.location.pathname === '/import-regular-discount' ||
      window.location.pathname === '/select-shipping-countries'
    ){
      app_mode_section = null;
    }

    if (this.state.new_installation) {
      return (
        <div>
          {modals}
          {toast}
          {app_mode_section}
          <Home shop={this.state.shop} actionGroups={actionGroups} primary_action={primary_action} secondary_actions={secondary_actions} host_name={host_name} shop_id={this.state.shop_id} help={help_links} />
        </div>
      );
    }
    else if (this.state.installation_page) {
      return (
        <div>
          {modals}
          {toast}
          <Install shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} help={help_links} ></Install>
        </div>
      );
    }
    else if (this.state.payment_status !== "active") {
      return (
        <div>
          {modals}
          {toast}
          {
            window.location.pathname === '/free-installation-support' ?
              <FreeInstallationSupport shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} />
              : <PricingPlan shop_payment={this.state.shop_payment} shop={this.state.shop} host_name={host_name} shop_id={this.state.shop_id} help={help_links} />
          }
        </div>
      );
    }
    // else if ((this.state.install_status !== "completed" || !this.state.app_configured) && !isAdmin) {
    //   return (
    //     <div>
    //       {toast}
    //       <InstallationStatus app_configured={this.state.app_configured} install_status={this.state.install_status} shop={this.state.shop} host_name={host_name} help={help_links} />
    //     </div>
    //   );
    // }
    else if (this.state.server_error) {
      return (
        <div>
          {modals}
          {toast}
          <Banner
            title="Wholesale - All in one is down for maintenance"
            status="warning"
          >
            <p>
              We expect to be back in a couple hours.
            </p>
          </Banner>
        </div>
      );
    }
    else if (this.state.page_loaded) {
      return (
        <div>
          {modals}
          {toast}
          {app_mode_section}
          {
              window.location.pathname === '/import-quantity-breaks' ||
              window.location.pathname === '/import-discount-groups' ||
              window.location.pathname === '/free-installation-support' ||
              window.location.pathname === '/update-excluded-collections' ||
              window.location.pathname === '/update-excluded-products' ||
              window.location.pathname === '/update-variants-modal' ||
              window.location.pathname === '/update-collections-modal' ||
              window.location.pathname === '/update-products-modal' ||
              window.location.pathname === '/selected-products-variant-picker' ||
              window.location.pathname === '/variant-picker'||
              window.location.pathname === '/update-collections-modal-qb' || 
              window.location.pathname === '/import-volume-discount' || 
              window.location.pathname === '/import-regular-discount' ||
              window.location.pathname === '/select-shipping-countries' ||
              window.location.pathname === '/settings' ? null :
              <span>
                {
                  this.state.app_enabled ?
                  null : appDisabledBanner
                }
                {this.showTrialBanner()}
                {this.showUpgradePlanBanner()}
              </span>
          }
          {reactRouter}
        </div>
      );
    }
    else {
      return (
        <div>
          {modals}
          {
            window.location.pathname === '/import-quantity-breaks' ||
            window.location.pathname === '/import-discount-groups' ||
            window.location.pathname === '/free-installation-support' ||
            window.location.pathname === '/update-excluded-collections' ||
            window.location.pathname === '/update-excluded-products' ||
            window.location.pathname === '/update-variants-modal' ||
            window.location.pathname === '/update-collections-modal' ||
            window.location.pathname === '/update-products-modal' ||
            window.location.pathname === '/selected-products-variant-picker' ||
            window.location.pathname === '/update-collections-modal-qb' ||  
            window.location.pathname === '/import-volume-discount' ||  
            window.location.pathname === '/import-regular-discount' ||
            window.location.pathname === '/select-shipping-countries' ||
            window.location.pathname === '/variant-picker' ? pageLoading :
            <SkeletonPageComp />
          }
        </div>
      )
    }
  }
}

export default App;
