import React, { Component } from 'react';
import axios from 'axios';
import { Spinner, Card, Banner, TextContainer } from '@shopify/polaris';
import * as PropTypes from 'prop-types';
import querystring from 'querystring';
import Common from '../../../Helpers/Common';

const parsed_query = querystring.parse(window.location.search);
const serverUrl = process.env.REACT_APP_SERVER_URL;

export default class ManualDraftOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            host_name: this.props.host_name,
            shop: this.props.shop,
            manual_redirect_message: null,
            isError: false,
            msg_for_user: "Applying discount to draft order v238. Please wait..."
        };
    }

    static contextTypes = {
        easdk: PropTypes.object,
    };

    fetch_data() {
        if(parsed_query && parsed_query.id){
            axios.get(serverUrl+'/draft-order/236/' + this.props.shop + '/' + parsed_query.id)
            .then(response => {
                if(typeof response.data !== "undefined" && response.data && typeof response.data.status !== "undefined" && response.data.status == 0){
                    // cannot apply discount, show error
                    this.setState({
                        isError: true,
                        msg_for_user: "",
                    });
                }
                else{
                    this.setState({
                        isError: false,
                        msg_for_user: "Draft order successfully updated ",
                    });
                    window.top.location.href = Common.getShopBaseUrl(true) + '/draft_orders/'+parsed_query.id;
                }
            })
            .catch(error => {
                window.top.location.href = Common.getShopBaseUrl(true) + '/draft_orders/'+parsed_query.id;
            });
        }
        else{
            this.setState({
                msg_for_user: "Invalid request",
            })
            window.top.location.href = Common.getShopBaseUrl(true) + '/draft_orders';
        }
    }

    componentDidMount() {
        this.fetch_data();
    }
    
    render() {
        const {msg_for_user,manual_redirect_message} = this.state;
        const  pageLoading = <div className="snipper-container">
            <center><Spinner color="teal" size="large" /></center>
        </div>;

        return (
            <Card sectioned>
                {this.state.isError ?
                    <Banner 
                        status="warning" 
                        title="Cannot apply discount on Draft Order"
                        action={{
                            content: 'Go Back',
                            onAction:() => {
                                window.top.location.href = 'https://'+this.props.shop+'/admin/draft_orders/'+parsed_query.id;
                            }   
                        }}
                    >
                       <TextContainer>
                            <p>
                                Draft Order is already completed, discount can be applied only on pending orders
                            </p>
                       </TextContainer>
                    </Banner>
                :
                <>
                    {pageLoading}
                    <div>{msg_for_user}</div>
                    {manual_redirect_message}
                </>
                }
                
            </Card>
        );
    }
}