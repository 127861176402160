import React, { Component } from 'react';
import { Card, Link, Collapsible, Button} from '@shopify/polaris';
import nav_list from '../../nav.json';
import nav_list_2 from '../../nav2.json';
import store from 'store2';
import FAQ from '../version206/FAQ';
import axios from 'axios';
import PageTitleBar from '../../Common/TitleBar';
import LinkOverride from '../../Common/LinkOverride';

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_current_plan: this.props.user_current_plan,
      extra_featured_collapsed: false,
      extra_features: [
        {
          title: "ADVANCED MINIMUMS",
          description: "Set minimum quantity requirements per variant, product, collection or entore store.",
          link_url: "https://digitalcoo.com/wholesale-all-in-one-documnetation/custom-wholesale-features",
          link_label: "Read more"
        },
        {
          title: "NET TERMS",
          description: "Allow customers to create an unpaid order directly from their cart.",
          link_url: "https://digitalcoo.com/wholesale-all-in-one-documnetation/custom-wholesale-features",
          link_label: "Read more"
        },
        {
          title: "QUICK ORDER FORM",
          description: "Provide frequent purchasers with a simple, single-page order form.",
          link_url: "https://digitalcoo.com/wholesale-all-in-one-documnetation/custom-wholesale-features",
          link_label: "Read more"
        },
        {
          title: "LOGIN TO VIEW PRICES",
          description: "Hide price from those customers who are not logged in.",
          link_url: "https://digitalcoo.com/wholesale-all-in-one-documnetation/custom-wholesale-features",
          link_label: "Read more"
        }
      ]
    }
  }

  componentDidMount(){
    axios.get(this.props.host_name + '/admin/get-plans-and-privileges/' + this.props.shop)
      .then(response => {
        if (response.data) {
          if (response.data.plans) {
            this.setState({
              user_current_plan: response.data.plans
            });
          }
        }
      })
      .catch(error => {
        
      });
  }
  
  basicPlan = false;
  redirectToPath(x){
    if(x.exactURL){
      window.location.href = x.exactURL;
    }
    if(x.pathToUpgrade){
      window.location.href = '/unlock';
    }
    return;
  }

  isBasicPlan(){
    var basic = false;
    if(this.state.user_current_plan){
      var plan_id = this.state.user_current_plan?this.state.user_current_plan.plan_id:null;
      var valid = false;
      if(plan_id){
        valid = plan_id.indexOf("basic") > -1;
        if(valid){
            this.basicPlan = true;
            basic = true;
        }
      }
    }
    return basic;
  }

  render() {
    var list = null;
    if(nav_list && nav_list.length){
      list = nav_list.map((x,k)=>{
          var lockImage = false;
          var isDisabled = x.disabled;
          var external = "";
          if(x.external){
            external = '_blank';
          }
          var addStyleClass = "";
          if(x.url !== "/pricing-groups" && x.url !== "/quantity-breaks" ){
            addStyleClass = "custom-nav-font-color";
          }
          if(x.url === "/pricing-groups" || x.url === "/quantity-breaks" ){
            x.exactURL = x.url;
            x.url = "#";
          }
          if(this.isBasicPlan()){
            lockImage = true;
          }
          if(
            x.url !== "/min-order-control" &&
            x.url !== "/access-control"  &&
            x.url !== "/shipping"
          ){
            lockImage = false;
          }
          var subscribed_plan = null;
          if(x.url === "/pricing-plans"){
            subscribed_plan = this.state.user_current_plan? this.state.user_current_plan.plan_name: null;
          }
          return <li key={k} className={"area-settings-nav__item"}>
          <LinkOverride target={external} className={`area-settings-nav__action`} onClick={()=>{this.redirectToPath(x)}} href={x.url} aria-disabled={isDisabled} >
            <div className="area-settings-nav__media">
              <img src={x.img_src} alt={x.title}></img>
            </div>
            <div>
              <div className="main-nav-title">
                <p className={`area-settings-nav__title ${addStyleClass}`}>
                    {x.title} <span className="subscribed-plan-name">{subscribed_plan?`(${subscribed_plan})`:null}</span>
                </p>
                {
                  lockImage? 
                  <span>
                    {/* <img className="icon-lock" src="/icons/lock-img.PNG" alt="lock"></img> */}
                  <img className="icon-lock" width="25px" height="18px" src='/icons/lock.svg' alt="lock-icon"></img>
                  </span>
                  :null
                }
              </div>
              <p className="area-settings-nav__description">
                {x.description}
              </p>
            </div>
          </LinkOverride>
        </li>;
      });
    }


    var qc_enabled = false;
    var netTerm_enabled = false;
    var quick_order_form = false;

    if(this.props.shop_features){
      if(this.props.shop_features.findIndex(x=>x === "quantity_controller") > -1){
        qc_enabled=true;
      }
      if(this.props.shop_features.findIndex(x=>x === "net_term") > -1){
        netTerm_enabled=true;
      }
      if(this.props.shop_features.findIndex(x=>x === "quick_order_form") > -1){
        quick_order_form=true;
      }
      
    }

    var list_2 = [];
    var show_adOns = false;
    if(nav_list_2 && nav_list_2.length){
      list_2 = nav_list_2.map((x,k)=>{
          var lockImage = false;
          var isDisabled = x.disabled;
          if( (x.url === "/quantity-controller") && !(qc_enabled)){
            return false;
          }
          if((x.url === "/net-terms") && !(netTerm_enabled)){
            return false;
          }
          if((x.url === "/quick-order-forms") && !(quick_order_form)){
            return false;
          }
          
          var external = "";
          if(x.external){
            external = '_blank';
          }
          var addStyleClass = "";
          if(x.url !== "/pricing-groups" && x.url !== "/quantity-breaks" ){
            addStyleClass = "custom-nav-font-color";
          }
          if(x.url === "/pricing-groups" || x.url === "/quantity-breaks" ){
            x.exactURL = x.url;
            x.url = "#";
          }
          if(this.isBasicPlan()){
            lockImage = true;
          }
          if(
            x.url !== "/min-order-control" &&
            x.url !== "/access-control"  &&
            x.url !== "/shipping"
          ){
            lockImage = false;
          }
          var subscribed_plan = null;
          if(x.url === "/pricing-plans"){
            subscribed_plan = this.state.user_current_plan? this.state.user_current_plan.plan_name: null;
          }
          show_adOns=true;
          return <li key={k} className={"area-settings-nav__item border-bottom"}>
          <LinkOverride target={external} className={`area-settings-nav__action`} onClick={()=>{this.redirectToPath(x)}} href={x.url} aria-disabled={isDisabled} >
            <div className="area-settings-nav__media">
              <img src={x.img_src} alt={x.title}></img>
            </div>
            <div>
              <div className="main-nav-title">
                <p className={`area-settings-nav__title ${addStyleClass}`}>
                    {x.title} <span className="subscribed-plan-name">{subscribed_plan?`(${subscribed_plan})`:null}</span>
                </p>
                {
                  lockImage? 
                  <span>
                    {/* <img className="icon-lock" src="/icons/lock-img.PNG" alt="lock"></img> */}
                  <img className="icon-lock" width="25px" height="18px" src='/icons/lock.svg' alt="lock-icon"></img>
                  </span>
                  :null
                }
              </div>
              <p className="area-settings-nav__description">
                {x.description}
              </p>
            </div>
          </LinkOverride>
        </li>;
      });
    }
    var extraFeatureSection = this.state.extra_features.map((x,k) => {
      if(!x.title){ return false; }
      return <Card.Section title={x.title} key={'ex-fea-'+k}>
          {x.description} <Link external={true} url={x.link_url}>{x.link_label}</Link>
        </Card.Section>
    });
    return (
      <div>
        <PageTitleBar 
          title="Dashboard"
        />
        <Card sectioned>
            <ul className="area-settings-nav">
                {list}
            </ul>
        </Card>

        <Card title="Add-on Features">
        {
            (nav_list_2.length > 0) && show_adOns && (list_2) && (<div className="navbar-2">{list_2}</div>)
          }
          <div className="collapsible-item">
            <Card.Section>
              <div className="collapsible-item-header">
                  <Button plain id="text-left"
                    onClick={() => { this.setState({extra_featured_collapsed:!this.state.extra_featured_collapsed})}}
                    ariaExpanded={this.state.extra_featured_collapsed}
                  >
                  View list of all feature additions like Net Terms, Quick Order form, Advanced Minimums, Login to view price for a one-time installation fee. <b style={{textDecoration:"underline"}}>Click here</b>
                  </Button>
              </div>
            </Card.Section>
            <Collapsible open={this.state.extra_featured_collapsed} id="basic-collapsible">
                {extraFeatureSection}
              </Collapsible>
          </div>
          
        </Card>
        <div style={{margin:"2rem 0"}}>
          <FAQ />
        </div>
        <div className="app-version-link">
          {
            (this.props.snippet_version) && (
            <div>
              <span>
                App version: {this.props.snippet_version}
              </span>
              <span style={{marginLeft: "1rem"}}></span>|<span style={{marginLeft: "1rem"}}></span><Button plain onClick={()=> {
                  store.remove("snippet_version");
                  window.location.reload(true);
                }}> Clear cache </Button> 
            </div>)
          }
        </div>
      </div>
    );
  }
}