import  React, { Component } from 'react';
import {Layout, Card, TextField, Stack} from '@shopify/polaris';
import axios from 'axios';

export default class ShopInformation extends Component{
    constructor(props){
        super(props);
        this.state = {
            shop: this.props.shop,
            current_shop_info: []
        }
    }

    componentDidMount(){
        axios.get(this.props.host_name + '/static/shop-detail/'+ this.props.shop).then(response => {
            if(response && response.data){
                this.setState({current_shop_info: response.data});
            }
          }).catch(e=>{
            
          });
    }
    render(){
        return (
            <div>
                <Layout>
                    <Layout.Section>
                        <Card title="Profile">
                            <Card.Section>
                                <Stack vertical>
                                    <TextField
                                        label="Name"
                                        value={this.state.current_shop_info.name}
                                    ></TextField>
                                    <TextField
                                        label="Email"
                                        value={this.state.current_shop_info.email}
                                    ></TextField>
                                    <TextField
                                        label="Customer email"
                                        value={this.state.current_shop_info.customer_email}
                                    ></TextField>
                                    <TextField
                                        label="Shop URL"
                                        id="shop-link"
                                        labelAction={{content:"Copy", onAction: () => {
                                        var el = document.getElementById("shop-link");
                                                el.select();
                                                el.execCommand("copy");
                                            }
                                        }}
                                        value={"https://"+this.state.current_shop_info.shop}
                                    ></TextField>
                                </Stack>
                            </Card.Section>
                        </Card>
                    </Layout.Section>
                </Layout>
            </div>
        );
    }
}