import React, { Component } from 'react';
import axios from 'axios';
import { Popover, PageActions, DatePicker, Banner, Checkbox, Stack, Layout, Tabs, FormLayout, TextField, Select, Card, Button, RadioButton, ButtonGroup, List, TextStyle, Tag, TextContainer, Icon } from '@shopify/polaris';
import FooterHelpLinks from '../../FooterHelpLinks';
import store from 'store2';
import waio from '../../../Helpers/functions';
import _Modules from '../../../modules.json';
import PageTitleBar from '../../../Common/TitleBar';
import ResourcePicker from '../../../Common/ResourcePicker';
import ToastMessage from '../../../Common/ToastMessage';
import {Modal} from '@shopify/app-bridge-react';
import { DeleteMinor } from '@shopify/polaris-icons';

export default class CreateQuantityBreak extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab_selected: 0,
            __maxq: '1',
            __dq: '1',
            __enableQty: false,
            __minq: '1',
            __qi: '1',
            settings: [],
            customers_group: "all",
            qb_internal_name: '',
            customer_tag: '',
            qb_calculation_type: 'percent_discount',
            qb_type: 'per_item',
            volume_discount: [{ qty: '', value: '' }],
            qb_applies_to: 'selected_products',
            product_modal_open: false,
            update_products__modal_open: false,
            products: [],
            products_array: [],
            product_modal_for_variant_open: false,
            selected_products_variant_picker_open: false,
            variants: [],
            variants_array: [],
            variant_modal_open: false,
            update_variants__modal_open: false,
            qb_save_loading: false,
            schedule_active: false,
            schedule_end_date: false,
            start_month: new Date().getMonth(),
            start_year: new Date().getFullYear(),
            end_month: new Date().getMonth(),
            end_year: new Date().getFullYear(),
            start_date_selected: new Date(),
            end_date_selected: new Date(),
            start_date_popup: false,
            start_date: new Date().toISOString().split('T')[0],
            end_date_popup: false,
            end_date: new Date().toISOString().split('T')[0],
            start_time: '00:00:00',
            end_time: '23:30:00',
            end_date_time_error: false,
            saveBtnDisabled: false,
            errors: [],
            display_option: 'detailed_grid',
            custom_css: '',
            status: 'draft',
            collection_modal_open: false,
            collections_array: [],
            collections: [],
            update_collection__modal_open: false,
            except_tags: false,
            except_logged_in: false,
            excepted_tags: [],
            temp_tag_value: "",
            edit_id: this.props.match.params.id,
            highlighExecptedtags: false,
            showToast: false,
            toastMsg: '',
            toastError: false
        }
        store.remove('products');
        store.remove('variants');
        store.remove('collections');
    }

    showToastMessage(msg, error){
    	this.setState({
    	  showToast: true,
    	  toastMsg: msg,
    	  toastError: error||false,
    	});
    }

    dissmissToast = () =>{
    	this.setState({
    	  showToast: false,
    	});
    }

    get_est_date_only(date) {
        let date_only = new Date(date);
        if (typeof date_only === 'object') {
            date_only = date_only.toString();
            if (typeof date_only === 'string') {
                date_only = date_only.replace(this.getTimezoneOffset(), '+0000');
            }
        }
        date_only = new Date(date_only).toISOString();
        date_only = date_only.split('T')[0];
        return date_only;
    }
    componentDidMount() {
        if(this.props.show === "edit"){
            this.fetchForEdit();
        }
        axios.get(this.props.host_name + '/admin/settings/' + this.props.shop)
            .then(response => {
                if (response.data) {
                    this.setState({
                        settings: response.data.settings
                    });
                }
            })
            .catch(error => {
                
            });
    }
    fetchForEdit(){
        axios.get(this.props.host_name + '/qb/get/' + this.props.match.params.id)
        .then(response => {
            var data = response.data[0];
            this.setState({
                except_logged_in: data.except_logged_in || false,
                except_tags: data.except_tags || false,
                excepted_tags: data.excepted_tags || [],
                customers_group: data.customers_group,
                __maxq: data.qty_ctrl.max_qty,
                __dq: data.qty_ctrl.default_qty,
                __enableQty: data.qty_ctrl.enabled,
                __minq: data.qty_ctrl.min_qty,
                __qi: data.qty_ctrl.inc_qty,
                qb_internal_name: data.qb_internal_name,
                customer_tag: data.customer_tag,
                qb_calculation_type: data.qb_calculation_type,
                qb_type: data.qb_type,
                volume_discount: data.volume_discount,
                qb_applies_to: data.qb_applies_to,
                products_array: data.products,
                products: data.products,
                variants: data.variants,
                variants_array: data.variants,
                collections: data.collections,
                collections_array: data.collections,
                display_option: data.display_option,
                custom_css: data.custom_css,
                status: data.status,
                schedule: data.schedule,
                schedule_active: data.schedule_active,
                end_date: data.schedule.end_date ? (data.schedule.end_date).split('T')[0] : data.schedule.start_date ? this.get_est_date_only(data.schedule.start_date) : new Date().toISOString().split('T')[0],
                start_date: data.schedule.start_date ? this.get_est_date_only(data.schedule.start_date) : new Date().toISOString().split('T')[0],
                start_time: data.schedule.start_time_est ? data.schedule.start_time_est : this.state.start_time,
                end_time: data.schedule.end_time_est ? data.schedule.end_time_est : this.state.end_time,
                schedule_end_date: data.schedule.end_date ? true : false,
                page_loaded: true
            });
        })
        .catch(function (error) {
        })
    }
    volumeDiscountUI() {
        return this.state.volume_discount.map((el, i) => (
            <div key={i}>
                <Stack alignment="leading">
                    <Stack.Item fill>
                        <Stack spacing="extraTight">
                            <div className="QuantityValueField">
                                <div className="Polaris-TextField Polaris-TextField--hasValue">
                                    <input id="TextField1" className="Polaris-TextField__Input" aria-labelledby="TextField1Label" aria-invalid="false" min="1" type="number" name="qty" value={el.qty || ''} onChange={this.changeVDValue.bind(this, i)} placeholder="Quantity"></input>
                                    <div className="Polaris-TextField__Backdrop"></div>
                                </div>
                            </div>
                            <div className="DiscountValueField">
                                <div className="Polaris-TextField Polaris-TextField--hasValue">
                                    {/* {this.state.qb_calculation_type !== 'percent_discount' ? <div className="Polaris-TextField__Prefix" id="TextField2Prefix">$</div> : ''} */}
                                    <input id="TextField2" className="Polaris-TextField__Input" aria-labelledby="TextField2Label" aria-invalid="false" max={this.state.qb_calculation_type === 'percent_discount' ? '100' : '10000000000'} type="number" name="value" value={el.value || ''} onChange={this.changeVDValue.bind(this, i)} placeholder={this.state.qb_calculation_type === 'percent_discount'?"Value":this.state.qb_calculation_type === 'price_discount' ?"Discount":"Price Per Item"}></input>
                                    {this.state.qb_calculation_type === 'percent_discount' ? <div className="Polaris-TextField__Suffix"
                                        id="TextField2Suffix">%</div> : ''}
                                    <div className="Polaris-TextField__Backdrop"></div>
                                </div>
                            </div>
                            <Button
                                onClick={this.removeClick.bind(this, i)}
                                disabled={this.state.volume_discount.length < 2}
                            ><Icon source={DeleteMinor}/></Button>
                        </Stack></Stack.Item></Stack>
            </div>
        ))
    }
    addClick() {
        this.setState(prevState => ({
            volume_discount: [...prevState.volume_discount, { qty: '', value: '' }]
        }))
    }
    removeClick(i) {
        let volume_discount = [...this.state.volume_discount];
        volume_discount.splice(i, 1);
        this.setState({ volume_discount });
    }
    changeVDValue(i, e) {
        var { name, value } = e.target;
        value = Number(value);
        if (this.state.qb_calculation_type === 'percent_discount' && value > 100 && name === "value") {
            value = 100;
        }
        if (value !== '' && value < 0) {
            value = 1;
        }
        let volume_discount = [...this.state.volume_discount];
        volume_discount[i] = { ...volume_discount[i], [name]: value };
        this.setState({ volume_discount });
    }
    upgrade = () => {
        this.props.history.push('/pricing-plans');
    }
    handleSubmitSelectedVariants = (selected) => {
        let variants = store('variants') || [];
        if (variants.length < 1) {
            this.setState({
                variant_modal_open: false,
                selected_products_variant_picker_open: false,
                product_modal_for_variant_open: false
            });
        }
        else {
            this.setState({
                variants: variants,
                variants_array: variants,
                variant_modal_open: false,
                selected_products_variant_picker_open: false,
                product_modal_for_variant_open: false
            });
        }
    };
    handleSubmitUpdateProducts = (selected) => {
        let pro_selected = store('products') || [];
        let p_arr = [];
        if (pro_selected.length > 0) {
            pro_selected.forEach(x => {
                p_arr.push(x.id);
            });
        }
        this.setState({
            products_array: pro_selected,
            products: p_arr,
            update_products__modal_open: false
        })
    }
    handleSubmitUpdateVariants = (selected) => {
        let variants = store('variants') || [];
        this.setState({
            variants: variants,
            variants_array: variants,
            update_variants__modal_open: false,
        });
    }
    handleSubmitUpdateCollections = () =>{
        let pro_selected = store('collections') || [];
        this.setState({
            collections_array: pro_selected,
            collections: pro_selected,
            update_collection__modal_open: false
        });
    }
    getTimezoneOffset() {
        function z(n) { return (n < 10 ? '0' : '') + n }
        var offset = new Date().getTimezoneOffset();
        var sign = offset < 0 ? '+' : '-';
        offset = Math.abs(offset);
        return sign + z(offset / 60 | 0) + z(offset % 60);
    }
    diff_days(new_date, old_date) {
        var t1 = new Date(new_date);
        var t2 = new Date(old_date);
        let result = parseInt((t1 - t2) / (24 * 3600 * 1000), 10);
        if (isNaN(result)) result = 0;
        return result;
    }
    get_est_dateTime(date, time) {
        let dateTimeValue = new Date(date);
        if (typeof dateTimeValue === 'object') {
            dateTimeValue = dateTimeValue.toString();
            if (typeof dateTimeValue === 'string') {
                dateTimeValue = dateTimeValue.replace(this.getTimezoneOffset(), '+0000');
            }
        }
        dateTimeValue = new Date(dateTimeValue).toISOString();
        dateTimeValue = dateTimeValue.split('T')[0] + " " + time;
        return dateTimeValue;
    }

    saveQuantityBreak() {
        let start_date_time = this.get_est_dateTime(this.state.start_date, this.state.start_time);
        let start_time = this.state.start_time;
        let end_date_time = this.get_est_dateTime(this.state.end_date, this.state.end_time);
        let end_time = this.state.end_time;
        if (!this.state.schedule_active) {
            start_date_time = null;
            start_time = null;
            end_date_time = null;
            end_time = null;
        }
        if (!this.state.schedule_end_date) {
            end_date_time = null;
            end_time = null;
        }
        const qb_data = {
            except_logged_in: this.state.except_logged_in,
            except_tags: this.state.except_tags,
            excepted_tags: this.state.excepted_tags,
            shop_id: this.props.shop_id,
            shop: this.props.shop,
            customers_group: this.state.customers_group,
            qty_ctrl: {
                enabled: this.state.__enableQty,
                default_qty: this.state.__dq,
                min_qty: this.state.__minq,
                max_qty: this.state.__maxq,
                inc_qty: this.state.__qi
            },
            qb_internal_name: this.state.qb_internal_name,
            customer_tag: this.state.customer_tag,
            qb_calculation_type: this.state.qb_calculation_type,
            qb_type: this.state.qb_type,
            volume_discount: this.state.volume_discount,
            qb_applies_to: this.state.qb_applies_to,
            products: this.state.products_array,
            variants: this.state.variants_array,
            collections: this.state.collections_array,
            schedule_active: this.state.schedule_active,
            schedule: {
                start_date: start_date_time,
                end_date: end_date_time,
                start_time_est: start_time,
                end_time_est: end_time
            },
            display_option: this.state.display_option,
            custom_css: this.state.custom_css,
            status: this.state.status
        }
        var err = this.find_errors();
        if (err.length < 1) {
            this.setState({ qb_save_loading: true });
            if(this.props.show === "edit"){
                axios.put(this.props.host_name + '/qb/update/' + this.props.match.params.id, qb_data)
                .then(response => {
                    if (response.data.status === 1) {
                        this.showToastMessage(response.data.message);
                        this.props.history.push('/quantity-breaks');
                    }
                    else {
                        this.setState({ qb_save_loading: false })
                        this.showToastMessage(response.data.message, true);
                        return false;
                    }
                })
                .catch(error => { });
            }
            else{
                axios.post(this.props.host_name + '/qb/add', qb_data)
                .then(response => {
                    if (response.data.status === 1) {
                        this.showToastMessage(response.data.message);
                        this.props.history.push('/quantity-breaks');
                    } else {
                        this.setState({ qb_save_loading: false })
                        this.showToastMessage(response.data.message, true);
                        return false;
                    }
                })
                .catch(error => { });
            }
        }
        else {
            this.setState({ errors: err });
            // window.scroll(0, 0);
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    find_errors() {
        var errors = [];
        if (this.state.volume_discount.length > 0) {
            let error; let less_than_zero_price = false; let percent_greater100 = false;
            let qb_calculation_type = this.state.qb_calculation_type;
            this.state.volume_discount.forEach(function (item, idx) {
                if (item.value === '' || item.qty === '') {
                    error = true;
                }
                else if (item.value <= 0) {
                    less_than_zero_price = true;
                }
                if (qb_calculation_type === 'percent_discount' && item.value > 100) {
                    error = true;
                    percent_greater100 = true;
                }
            });
            if (less_than_zero_price) {
                errors.push("Discount value must be greater than zero");
            }
            if (percent_greater100) {
                errors.push("Percentage value can't exceed 100");
            }
            if (error) {
                errors.push("Volume Discount values can't be blank");
            }
        }
        if (this.state.customers_group === "tag_based") {
            if (this.state.customer_tag === "") {
                errors.push("Customer tag is missing");
            }
        }
        if (this.state.qb_applies_to === "selected_products") {
            if (this.state.products.length < 1) {
                errors.push("No product was selected");
            }
        }
        if (this.state.qb_applies_to === "selected_variants") {
            if (this.state.variants.length < 1) {
                errors.push("No variant was selected");
            }
        }
        if (this.state.qb_applies_to === "selected_collections") {
            if (this.state.collections.length < 1) {
                errors.push("No collection was selected");
            }
        }
        if (this.state.schedule_active && this.state.schedule_end_date) {
            if (this.diff_days(this.state.end_date, this.state.start_date) === 0) {
                if (this.state.start_time === this.state.end_time) {
                    this.setState({ end_date_time_error: true });
                    errors.push("Start date and end date can't be same");
                }
                else if (this.state.start_time > this.state.end_time) {
                    this.setState({ end_date_time_error: true });
                    errors.push("End date must be after the start date");
                }
            }
            else if (this.diff_days(this.state.end_date, this.state.start_date) < 0) {
                this.setState({ end_date_time_error: true });
                errors.push("Start date and end date can't be same");
            }
        }
        if(this.state.except_tags && this.state.excepted_tags.length === 0){
            this.setState({highlighExecptedtags: true});
            errors.push("Add atleast one excepted tags.");
        }
        return errors;
    }

    renderExceptedTags(){
        var excepted_tags = this.state.excepted_tags;
        return excepted_tags.map((tag,i) => {
            return <span key={i} style={{margin:"4px"}}>
                <Tag
                    onRemove={this.removeTag.bind(this,tag,i)}
                >{tag}</Tag>
            </span>
        });
    }
    removeTag(tag,i,el){
        var excepted_tags = this.state.excepted_tags;
        excepted_tags.splice([i],1);
        this.setState({ excepted_tags });
    }

    render() {
        const {except_tags,excepted_tags,temp_tag_value,except_logged_in, highlighExecptedtags} = this.state;

      Date.prototype.addHours = function (h) {
	this.setTime(this.getTime() + (h * 60 * 60 * 1000));
	return this;
}

        const {
            customers_group, qb_internal_name, customer_tag, qb_calculation_type, qb_type, volume_discount, qb_applies_to, product_modal_for_variant_open, qb_save_loading, schedule_active, schedule_end_date, end_month, end_year, start_month, start_year, start_date_selected, end_date_selected, start_date_popup, start_date, end_date_popup, end_date, start_time, end_time, end_date_time_error, errors, display_option
        } = this.state;

        let time_options = [
            { label: '12:00 am', value: '00:00:00' },
            { label: '12:30 am', value: '00:30:00' },
            { label: '01:00 am', value: '01:00:00' },
            { label: '01:30 am', value: '01:30:00' },
            { label: '02:00 am', value: '02:00:00' },
            { label: '02:30 am', value: '02:30:00' },
            { label: '03:00 am', value: '03:00:00' },
            { label: '03:30 am', value: '03:30:00' },
            { label: '04:00 am', value: '04:00:00' },
            { label: '04:30 am', value: '04:30:00' },
            { label: '05:00 am', value: '05:00:00' },
            { label: '05:30 am', value: '05:30:00' },
            { label: '06:00 am', value: '06:00:00' },
            { label: '06:30 am', value: '06:30:00' },
            { label: '07:00 am', value: '07:00:00' },
            { label: '07:30 am', value: '07:30:00' },
            { label: '08:00 am', value: '08:00:00' },
            { label: '08:30 am', value: '08:30:00' },
            { label: '09:00 am', value: '09:00:00' },
            { label: '09:30 am', value: '09:30:00' },
            { label: '10:00 am', value: '10:00:00' },
            { label: '10:30 am', value: '10:30:00' },
            { label: '11:00 am', value: '11:00:00' },
            { label: '11:30 am', value: '11:30:00' },
            { label: '12:00 pm', value: '12:00:00' },
            { label: '12:30 pm', value: '12:30:00' },
            { label: '01:00 pm', value: '13:00:00' },
            { label: '01:30 pm', value: '13:30:00' },
            { label: '02:00 pm', value: '14:00:00' },
            { label: '02:30 pm', value: '14:30:00' },
            { label: '03:00 pm', value: '15:00:00' },
            { label: '03:30 pm', value: '15:30:00' },
            { label: '04:00 pm', value: '16:00:00' },
            { label: '04:30 pm', value: '16:30:00' },
            { label: '05:00 pm', value: '17:00:00' },
            { label: '05:30 pm', value: '17:30:00' },
            { label: '06:00 pm', value: '18:00:00' },
            { label: '06:30 pm', value: '18:30:00' },
            { label: '07:00 pm', value: '19:00:00' },
            { label: '07:30 pm', value: '19:30:00' },
            { label: '08:00 pm', value: '20:00:00' },
            { label: '08:30 pm', value: '20:30:00' },
            { label: '09:00 pm', value: '21:00:00' },
            { label: '09:30 pm', value: '21:30:00' },
            { label: '10:00 pm', value: '22:00:00' },
            { label: '10:30 pm', value: '22:30:00' },
            { label: '11:00 pm', value: '23:00:00' },
            { label: '11:30 pm', value: '23:30:00' }
        ]

        let start_date_piker = <DatePicker
            month={start_month}
            year={start_year}
            disableDatesBefore={new Date().addHours(-24)}
            onChange={(value) => {
                let a = value.start;
                if (typeof a === 'object') {
                    a = a.toString();
                    if (typeof a === 'string') {
                        a = a.replace(this.getTimezoneOffset(), '+0000');
                    }
                }
                this.setState({
                    start_date_selected: value,
                    start_date: new Date(a).toISOString().split('T')[0],
                    end_date: this.diff_days(this.state.end_date, a) <= 0 ? new Date(a).toISOString().split('T')[0] : this.state.end_date,
                    start_date_popup: false
                })
            }}
            onMonthChange={(month, year) => {
                this.setState({
                    start_month: month,
                    start_year: year,
                });
            }}
            selected={start_date_selected}
        />;
        let end_date_piker = <DatePicker
            month={end_month}
            year={end_year}
            disableDatesBefore={start_date_selected.start ? start_date_selected.start : new Date().addHours(-24)}
            onChange={(value) => {
                let a = value.start;
                if (typeof a === 'object') {
                    a = a.toString();
                    if (typeof a === 'string') {
                        a = a.replace(this.getTimezoneOffset(), '+0000');
                    }
                }
                this.setState({
                    end_date_selected: value,
                    end_date: new Date(a).toISOString().split('T')[0],
                    end_date_popup: false
                })
            }}
            onMonthChange={(month, year) => {
                this.setState({
                    end_month: month,
                    end_year: year,
                });
            }}
            selected={end_date_selected}
        />;

        const start_date_activator = (
            <TextField
                readOnly={true}
                label="Start date"
                value={start_date}
                autoComplete={false}
                onChange={(value) => {
                    this.setState({ start_date: value, start_date_popup: false });
                }}
                onFocus={() => {
                    this.setState({ start_date_popup: true });
                }}
            />
        );
        const end_date_activator = (
            <TextField
                readOnly={true}
                label="End date"
                value={end_date}
                autoComplete={false}
                onChange={(value) => {
                    this.setState({ end_date: value, end_date_popup: false });
                }}
                onFocus={() => {
                    this.setState({ end_date_popup: true });
                }}
                error={end_date_time_error ? true : false}
            />
        );

        let volume_discount_fields = <FormLayout>
            {this.volumeDiscountUI()}
            <Button size="slim" onClick={this.addClick.bind(this)}>
                {volume_discount.length >= 1 ? 'Add more' : 'Add'}
            </Button>
        </FormLayout>;

        const tags_input = <TextField
            disabled={except_logged_in&&(customers_group === "all")}
            label="Tags"
            labelHidden
            autoFocus
            placeholder="Enter comma separated tags"
            value={temp_tag_value}
            onChange={(v)=>{
                this.setState({highlighExecptedtags:false})
                if(v && v.indexOf(",") >-1 ){
                    v= v.replace(",","");
                    v = v.trim();
                    if(v === "") return false;
                    var excepted_tags = this.state.excepted_tags;
                    if(excepted_tags.indexOf(v) > -1){
                        this.showToastMessage("Tag already exists", true);
                        return false;
                    }
                    excepted_tags.push(v);
                    this.setState({ excepted_tags, temp_tag_value: "" });
                }
                else{
                    this.setState({temp_tag_value:v});
                }
            }}
            onBlur={()=>{
                var v = this.state.temp_tag_value;
                v = v.replace(",","");
                v = v.trim();
                if(v === "") return false;
                var excepted_tags = this.state.excepted_tags;
                if(excepted_tags.indexOf(v) > -1){
                    return false;
                }
                excepted_tags.push(v);
                this.setState({ excepted_tags, temp_tag_value: "" });
            }}
            error={highlighExecptedtags}
            >
            </TextField>;

        const internal_name_section = <Layout.Section>
            <Card title="Step 1: Volume Discount Title"
                actions={[{content:"Read tutorial", url:"https://digitalcoo.com/wholesale-all-in-one/", external: true}]}
            >
                <Card.Section>
                    <FormLayout>
                        <FormLayout.Group>
                            <TextField
                                autoFocus
                                label="Title"
                                placeholder="e.g Discount for All customers"
                                helpText="Write the title of your Volume Discount. This will not appear to the customers."
                                labelHidden
                                value={qb_internal_name}
                                onChange={v => { this.setState({ qb_internal_name: v }) }}
                                type="text"
                            />
                        </FormLayout.Group>
                    </FormLayout>
                </Card.Section>
                <Card.Section>
                    <Stack vertical spacing="tight">
                        <RadioButton
                            label="Save as Test Mode"
                            helpText={<span>Test the discount rule before making it live to the customers. <a style={{textDecoration: "underline"}} href={`https://${this.props.shop}/?draftpreview=yes`} target="new">View Test Mode</a> </span>}
                            checked={"draft" === this.state.status}
                            id="draft"
                            onChange={(a,b) => {this.setState({status: "draft"});}}
                        />
                        <RadioButton
                            label="Save as Published"
                            helpText="Make discount rule available for the public to view."
                            checked={"published" === this.state.status}
                            id="published"
                            onChange={(a,b) => {this.setState({status: "published"});}}
                        />
                        <RadioButton
                            label="Save as Unpublished"
                            helpText="Make discount rule unavailable for the public to view."
                            checked={"published" !== this.state.status && "draft" !== this.state.status}
                            id="unpublished"
                            onChange={(a,b) => {this.setState({status: "unpublished"});}}
                        />
                    </Stack>
                </Card.Section>
            </Card>
        </Layout.Section>;

        const except_taged_section = <Stack vertical spacing="tight">
            <Checkbox
                disabled={except_logged_in&&(customers_group === "all")}
                label={<span>Except tags</span>}
                id="except_tags"
                value={except_tags}
                checked={except_tags}
                onChange={(checked, value) => {
                    this.setState({ except_tags: checked });
                }}
            />
            {except_tags?tags_input: null}
            {
                except_tags && excepted_tags.length > 0?
                <Stack>
                    {this.renderExceptedTags()}
                </Stack>
                : null
            }
        </Stack>;

        const customers_section = <Layout.Section>
            <Card title="Step 2: Customer Groups" sectioned
                actions={[{content:"Read tutorial", url:"https://digitalcoo.com/wholesale-all-in-one/", external: true}]}
            >
                <TextContainer>
                  <TextStyle variation="subdued">The Customer Groups section will allow you to select the target customers that will receive discount pricing.</TextStyle>
                  <br></br>
                  <TextStyle variation="subdued">Select the customer group that will receive the offer discount</TextStyle>
                </TextContainer>
                <Stack vertical spacing="extraTight">
                    <RadioButton
                        label={<span>All Customers </span>}
                        helpText="All website visitors will see the discount price whether or not they are logged in."
                        id="all"
                        name="customers_group"
                        checked={customers_group === 'all'}
                        onChange={(c, v) => {
                            this.setState({
                                customers_group: v,
                                except_tags: false,
                                except_logged_in: false
                            });
                        }}
                    />
                    {
                        customers_group === "all" ?
                            <div style={{marginLeft:"2.3rem"}}>
                                <Checkbox
                                    label="Except logged in"
                                    id="except_logged_in"
                                    value={except_logged_in}
                                    checked={except_logged_in}
                                    onChange={(checked, value) => {
                                        this.setState({ except_logged_in: checked });
                                    }}
                                />
                                {except_taged_section}
                            </div>
                        :null
                    }
                    <RadioButton
                        label="Only logged in customers "
                        helpText="Discount price will appear to only those customers who are logged in to their account. The customers who are not logged in will not receive the discount price. "
                        id="only_logged_in"
                        name="customers_group"
                        checked={customers_group === 'only_logged_in'}
                        onChange={(c, v) => {
                            this.setState({
                                customers_group: v,
                                except_tags: false,
                                except_logged_in: false
                            })
                    }}
                    />
                    {
                        customers_group === "only_logged_in" ?
                            <div style={{marginLeft:"2.3rem"}}>
                                {except_taged_section}
                            </div>
                        :null
                    }
                    <RadioButton
                        label="Only Tagged customers "
                        helpText={<span>Discount price will appear to only those customers who are logged in and have matching customer tag. <br></br><TextStyle variation="strong">Example:</TextStyle> Let’s assume we apply 10% discount for “wholesale” tag based customers, then all the customers having “wholesale” tag will receive the relevant discount. </span>}
                        id="tag_based"
                        name="customers_group"
                        checked={customers_group === 'tag_based'}
                        onChange={(c, v) => {
                            this.setState({
                                customers_group: v,
                                except_tags: false,
                                except_logged_in: false
                            })
                        }}
                    />
                    {
                        customers_group === 'tag_based' ?
                            <div style={{margin: "0rem 2.3rem"}}>
                                <TextField
                                    label="Enter a customer tag"
                                    placeholder="E.g. wholesale"
                                    helpText="Please write customer tag in lowercase. You can only enter one customer tag"
                                    value={customer_tag}
                                    onChange={(v) => { this.setState({ customer_tag: v }) }}
                                    autoComplete={false}
                                    error={customers_group === "tag_based" && customer_tag === "" ? true : false}
                                    onBlur={() => {
                                        var customer_tag = this.state.customer_tag;
                                        try{
                                            if(customer_tag){
                                                customer_tag = customer_tag.trim().toLowerCase().replace(new RegExp(",","g"),"");
                                                this.setState({customer_tag})
                                            }
                                        }catch(e){}
                                    }}
                                />
                                <div className="customer-tag-tag">
                                    {
                                        customer_tag && <Tag onRemove={() => { this.setState({customer_tag:""}) }}>{customer_tag}</Tag>
                                    }
                                </div>
                            </div>
                            : null
                    }
                </Stack>
            </Card>
        </Layout.Section>;

        const discount_type_section = <Layout.Section>
            <Card title="Step 3: Discount Types" sectioned
            actions={[{content:"Read tutorial", url:"https://digitalcoo.com/wholesale-all-in-one/", external: true}]}
            >
                <Stack vertical spacing="tight">
                    <RadioButton
                        label="Apply Volume Discount per Variant (Discount applies per individual item)"
                        helpText="Volume Discount will apply to specific variants (ie. Buy 5 of the same Large Red shirt to get price break)"
                        id="per_item"
                        name="qb_type"
                        checked={qb_type === 'per_item'}
                        onChange={(c, v) => { this.setState({ qb_type: v }) }}
                    />
                    <RadioButton
                        label="Apply Volume Discount across Group (Mix and match products for discount)"
                        helpText="Volume Discount will apply to a group of variants (ie. Buy ANY 5 shirts (any size, any colour) to receive price break)"
                        id="mix_and_match_products"
                        name="qb_type"
                        checked={qb_type === 'mix_and_match_products'}
                        onChange={(c, v) => { this.setState({ qb_type: v }) }}
                    />
                    {
                        (this.props.snippet_version) && (waio.compareVersionNumbers(this.props.snippet_version, _Modules.find(x => x._id === 233).snippet_version) > -1) && (
                        <RadioButton
                            label="Apply Volume Discount within each group of product"
                            helpText="Volume Discount will apply to the products of this group only (ie. Buy any 5 quantity from selected products (any variant) to receive price break)"
                            id="within_products"
                            name="qb_type"
                            checked={qb_type === 'within_products'}
                            onChange={(c, v) => { this.setState({ qb_type: v }) }}
                        />
                        )
                    }
                    <RadioButton
                        label="Apply Volume Discount across all Groups"
                        helpText="Volume Discounts will apply to all groups. Buy 5 products of group A and 5 products of group B and get the Volume Discount at 10 products with the price created in group B"
                        id="across_all_groups"
                        name="qb_type"
                        checked={qb_type === 'across_all_groups'}
                        onChange={(c, v) => { this.setState({ qb_type: v }) }}
                    />

                </Stack>
            </Card>
        </Layout.Section>;

        const price_level_section = <Layout.Section>
            <Card title=" Step 4: Discount Methods"
            actions={[{content:"Read tutorial", url:"https://digitalcoo.com/wholesale-all-in-one/", external: true}]}
            >
                <Card.Section>
                    <Stack vertical spacing="tight">
                        <RadioButton
                            label="Percent Off "
                            helpText={<span><TextStyle variation="strong">Percent Off</TextStyle> will set the wholesale price to a % off the current retail price of the product. (not the​ compare-at-price). If you change the price of the item in Shopify, the wholesale price will be adjusted as well.<br></br><TextStyle variation="strong">Example:</TextStyle> Let’s assume retail price of the item is $100 and you set 30% as “Percent Off” then customers will see $70 as final price. </span>}
                            id="percent_discount"
                            name="qb_calculation_type"
                            checked={qb_calculation_type === 'percent_discount'}
                            onChange={(c, v) => { this.setState({ qb_calculation_type: v }) }}
                        />
                        <RadioButton
                            label="Price Off"
                            helpText={<span><TextStyle variation="strong">Price Off</TextStyle> will set the wholesale price to a fixed amount off the current retail price of the product.​ (not the compare-at-price). If you change the price of the item in Shopify, the wholesale price will be adjusted as well. <br></br><TextStyle variation="strong">Example:</TextStyle> Let’s assume retail price of the item is $100 and you set $25 as “Price Off” then customers will see $75 as final price.</span>}
                            id="price_discount"
                            name="qb_calculation_type"
                            checked={qb_calculation_type === 'price_discount'}
                            onChange={(c, v) => { this.setState({ qb_calculation_type: v }) }}
                        />
                        <RadioButton
                            label="Fixed Price"
                            helpText={<span><TextStyle variation="strong">Fixed Price</TextStyle> will create a fixed wholesale price for the product. It won’t change if you change the retail price of the product in Shopify. <br></br><TextStyle variation="strong">Example:</TextStyle> Let’s assume retail price of the item is $100 and you set $70 as “Fixed Price” then customers will see $70 as final price. <br></br><TextStyle variation="strong">Note:</TextStyle> Price Per Item should be less than or equal to the retail price of the item.</span>}
                            id="fixed_price"
                            name="qb_calculation_type"
                            checked={qb_calculation_type === 'fixed_price'}
                            onChange={(c, v) => { this.setState({ qb_calculation_type: v }) }}
                        />
                        {/* <Banner status="info">
                        {
                            this.state.qb_calculation_type === 'percent_discount' ?
                            <span><strong>Percent Off</strong> will set the wholesale price to a % off the current retail price of the item. (not the compare-at-price). If you change the price of the item in Shopify, the wholesale price will be adjusted as well. Example: Let's assume retail price of the item is $100 and you set 30% as "Percent Off" then customers will see $70 as final price.</span>
                            :this.state.qb_calculation_type === 'price_discount' ?
                            <span><strong>Price Off</strong> will set the wholesale price to a fixed amount off the current retail price of the item. (not the compare-at-price). If you change the price of the item in Shopify, the wholesale price will be adjusted as well. Example: Let's assume retail price of the item is $100 and you set $25 as "Price Off" then customers will see $75 as final price.</span>
                            :<span><strong>Set Fix Price</strong> will create a fixed wholesale price for the item. It won't change if you change the retail price of the item in Shopify. Example: Let's assume retail price of the item is $100 and you set $70 as "Set Fix Price" then customers will see $70 as final price.</span>
                        }
                        </Banner> */}
                    </Stack>
                </Card.Section>
                <Card.Section>{volume_discount_fields}</Card.Section>
            </Card>
        </Layout.Section>;

        const products_selection_section = <Layout.Section>
            <Card sectioned title="Step 5: Apply Volume Discount To"
            actions={[{content:"Read tutorial", url:"https://digitalcoo.com/wholesale-all-in-one/", external: true}]}
            >
                <Stack vertical>
                    <Stack>
                        <RadioButton
                            label="Entire store"
                            helpText=""
                            id="entire_store"
                            name="qb_applies_to"
                            checked={qb_applies_to === 'entire_store'}
                            onChange={(c, v) => { this.setState({ qb_applies_to: v }) }}
                        />
                        <RadioButton
                            label="Specific Collections"
                            helpText=""
                            id="selected_collections"
                            name="qb_applies_to"
                            checked={qb_applies_to === 'selected_collections'}
                            onChange={(c, v) => { this.setState({ qb_applies_to: v }) }}
                        />
                        <RadioButton
                            label="Specific Products"
                            helpText=""
                            id="selected_products"
                            name="qb_applies_to"
                            checked={qb_applies_to === 'selected_products'}
                            onChange={(c, v) => { this.setState({ qb_applies_to: v }) }}
                        />
                        <RadioButton
                            label="Specific Variants"
                            helpText=""
                            id="selected_variants"
                            name="qb_applies_to"
                            checked={qb_applies_to === 'selected_variants'}
                            onChange={(c, v) => { this.setState({ qb_applies_to: v }) }}
                        />
                    </Stack>
                    <div>
                        <TextContainer>
                            <TextStyle variation="subdued">
                            {
                                qb_applies_to === 'entire_store' && 
                                <p>This will allow you to apply the Volume Discount to all products available in your Shopify store. <br></br><TextStyle variation="strong">Example:</TextStyle> You can apply a 10% discount for all products. ​</p>
                            }
                            {
                                qb_applies_to === 'selected_collections' && 
                                <p>This will allow you to apply the Volume Discount to specify one or more collections.  
                                <br></br><TextStyle variation="strong">Example:</TextStyle> You can apply a 10% discount to “shirts” products only.​ ​</p>
                            }
                            {
                                qb_applies_to === 'selected_products' && 
                                <p>You can use the “Specific Products” option to apply the Volume Discount for selected products of your choice. In this case the Volume Discount will apply to all variants of selected products.  ​</p>
                            }
                            {
                                qb_applies_to === 'selected_variants' && 
                                <p>You can use “Specific Variants” option to apply the Volume Discount at selective variant level. <br></br><TextStyle variation="strong">Example:</TextStyle> A T-Shirt has 4 variants: Red, Green, Black, Blue. You have an option to apply a discount to one or more variants of the product. 
                                Let’s assume we apply a 10% discount to only  Red & Green,  in this case the 10% discount will not apply to the Black and Blue shirts. 
                                ​</p>
                            }
                            </TextStyle>
                        </TextContainer>
                    </div>
                    {/* for collections */}
                    {
                        qb_applies_to === 'selected_collections' &&
                        <ButtonGroup>
                            <Button
                                primary
                                onClick={() => {
                                    this.setState({ collection_modal_open: true });
                                    store('collections', this.state.collections_array);
                                }}
                            > + Add Collections</Button>
                            {
                                this.state.collections.length > 0 &&
                                <Button
                                    onClick={() => {
                                        this.setState({ update_collection__modal_open: true });
                                        store('collections', this.state.collections_array);
                                    }}
                                    plain={true}
                                >
                                [ Edit <label style={{ borderBottom: '1px dotted' }}>{this.state.collections.length}</label> ]
                                </Button>
                            }
                        </ButtonGroup>
                    }
                    {/* for products */}
                    {
                        qb_applies_to === 'selected_products' && 
                        <ButtonGroup>
                            <Button
                                primary
                                onClick={() => {
                                    this.setState({ product_modal_open: true });
                                    store('products', this.state.products_array);
                                }}
                            > + Add Products </Button>
                            {
                                this.state.products.length > 0 &&
                                <Button
                                    onClick={() => {
                                    this.setState({ update_products__modal_open: true });
                                    store('products', this.state.products_array);
                                    }}
                                    plain={true}
                                >
                                [ Edit <label style={{ borderBottom: '1px dotted' }}>{this.state.products.length}</label> ]
                                </Button>
                            }
                        </ButtonGroup>
                    }
                    {/* for variants */}
                    {
                        qb_applies_to === 'selected_variants' ?
                        this.props.user_current_plan.plan_permissions?
                            !this.props.user_current_plan.plan_permissions[0].variants?
                            <Banner status="warning" title="Action required!"
                                action={{content: "Upgrade plan to premium", onAction: this.upgrade }}
                            >
                            </Banner>
                                :qb_applies_to === 'selected_variants' ?
                                    <ButtonGroup>
                                        <Button
                                            primary
                                            onClick={() => {
                                            this.setState({ product_modal_for_variant_open: true });
                                            store('variants', this.state.variants_array);
                                            }}
                                        > + Add Variants
                                        </Button>
                                        {
                                            this.state.variants.length > 0 ?
                                            <Button
                                                onClick={() => {
                                                    this.setState({ update_variants__modal_open: true });
                                                    store('variants', this.state.variants_array);
                                                }}
                                                plain={true}
                                                >
                                                [ Edit <label style={{ borderBottom: '1px dotted' }}>{this.state.variants.length}</label> ]
                                            </Button>
                                            : null
                                        }
                                    </ButtonGroup>
                                :null
                            :null
                        :null
                    }
                </Stack>
                <Stack vertical spacing="tight">
                    <div className="entire-store-only"></div>
                    <div className="selected-collections-only">
                        
                    </div>
                    <div className="selected-products-only">
                       
                    </div>
                    <div className="selected-variants-only">
                       
                    </div>
                </Stack>
            </Card>
        </Layout.Section>;

        let resource_pikers_and_models = <span>
            {
                this.state.collection_modal_open && 
                <ResourcePicker
                    resourceType="Collection"
                    open={this.state.collection_modal_open}
                    onSelection={(resources) => {
                        let productsTitleArray = [];
                        for (var j = 0; j < resources.selection.length; j++) {
                            if (this.state.collections.findIndex(x=>x.id === resources.selection[j].id) === -1) {
                                productsTitleArray.push({
                                    id: resources.selection[j].id,
                                    title: resources.selection[j].title,
                                    handle: resources.selection[j].handle
                                });
                            }
                        }
                        let prod_title = productsTitleArray.concat(this.state.collections);
                        this.setState({
                            collections: prod_title,
                            collections_array: prod_title,
                            collection_modal_open: false
                        });
                    }
                    }
                    onCancel={() => this.setState({ collection_modal_open: false })}
                    onClose={() => this.setState({ collection_modal_open: false })}
                />
            }
            {
                this.state.product_modal_open && 
                <ResourcePicker
                    open={this.state.product_modal_open}
                    onSelection={(resources) => {
                        let productsArray = [];
                        let productsTitleArray = [];
                        for (var j = 0; j < resources.selection.length; j++) {
                            if (this.state.products.findIndex(x=>x.id === resources.selection[j].id) === -1) {
                                productsArray.push(resources.selection[j].id);
                                productsTitleArray.push({
                                    id: resources.selection[j].id,
                                    title: resources.selection[j].title,
                                    variants: resources.selection[j].variants,
                                    handle: resources.selection[j].handle,
                                    tags: resources.selection[j].tags,
                                    vendor: resources.selection[j].vendor,
                                    image: resources.selection[j].image,
                                });
                            }
                        }
                        let prod = productsArray.concat(this.state.products);
                        let prod_title = productsTitleArray.concat(this.state.products_array);
                        this.setState({
                            products: prod,
                            products_array: prod_title,
                            product_modal_open: false
                        });
                    }
                    }
                    onCancel={() => this.setState({ product_modal_open: false })}
                    onClose={() => this.setState({ product_modal_open: false })}
                />
            }
            {
                product_modal_for_variant_open && 
                <ResourcePicker
                    open={product_modal_for_variant_open}
                    onSelection={resources => {
                        this.setState({
                            selected_products_variant_picker_open: true
                        });
                        store('selected_products', resources.selection);
                    }
                    }
                    onCancel={() => this.setState({ product_modal_for_variant_open: false })}
                    onClose={() => this.setState({ product_modal_for_variant_open: false })}
                />
            }
            {
                this.state.variant_modal_open && 
                <Modal
                size="Medium"
                src={'/variant-picker'}
                open={this.state.variant_modal_open}
                onClose={() => this.setState({ variant_modal_open: false })}
                title={'Select variants'}
                primaryAction={{
                    content: 'Select variants',
                    onAction: this.handleSubmitSelectedVariants,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: () => { this.setState({ variant_modal_open: false }) },
                    },
                ]}
                sectioned={false}
            >
            </Modal>
            }
            {
                this.state.selected_products_variant_picker_open && 
                <Modal
                size="Medium"
                src={'/selected-products-variant-picker'}
                open={this.state.selected_products_variant_picker_open}
                onClose={() => this.setState({
                    selected_products_variant_picker_open: false, product_modal_for_variant_open: false
                })}
                title={'Select variants'}
                primaryAction={{
                    content: 'Select variants',
                    onAction: this.handleSubmitSelectedVariants,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: () => {
                            this.setState({
                                selected_products_variant_picker_open: false, product_modal_for_variant_open: false
                            })
                        },
                    },
                ]}
                sectioned={false}
            >
            </Modal>
            }
            {
                this.state.update_products__modal_open && 
                 <Modal
                size="Medium"
                src={'/update-products-modal'}
                open={this.state.update_products__modal_open}
                onClose={() => this.setState({ update_products__modal_open: false })}
                title={'Products'}
                primaryAction={{
                    content: 'Update',
                    onAction: this.handleSubmitUpdateProducts,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: () => { this.setState({ update_products__modal_open: false }) },
                    }
                ]}
                sectioned={false}
            >
            </Modal>
            }
            {
                this.state.update_collection__modal_open && 
                <Modal
                size="Medium"
                src={'/update-collections-modal-qb'}
                open={this.state.update_collection__modal_open}
                onClose={() => this.setState({ update_collection__modal_open: false })}
                title={'Collections'}
                primaryAction={{
                    content: 'Update',
                    onAction: this.handleSubmitUpdateCollections,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: () => { this.setState({ update_collection__modal_open: false }) },
                    }
                ]}
                sectioned={false}
            >
            </Modal>
            }
            {
                this.state.update_variants__modal_open && 
                <Modal
                size="Medium"
                src={'/update-variants-modal'}
                open={this.state.update_variants__modal_open}
                onClose={() => this.setState({ update_variants__modal_open: false })}
                title={'Variants'}
                primaryAction={{
                    content: 'Update',
                    onAction: this.handleSubmitUpdateVariants,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: () => { this.setState({ update_variants__modal_open: false }) },
                    }
                ]}
                sectioned={false}
            >
            </Modal>
            }
        </span>;

        let create_qb_buttons = <PageActions
            primaryAction={{
                content: 'Save Volume Discount',
                loading: qb_save_loading,
                onAction: () => { this.saveQuantityBreak(); }
            }}
            secondaryActions={[
                {
                    content: "Cancel",
                    target: "APP",
                    url: "/quantity-breaks"
                }
            ]}
        ></PageActions>;

        let configure_display_section =
            <div className="display-configurations" style={{ padding: "10px 0" }}>
                <div className="main-div">
                    <div className="width-div">
                        <div className="custom-third">
                            <label className="" htmlFor="custom_id_1">
                                <RadioButton
                                    label="Detailed Grid"
                                    helpText=""
                                    id="detailed_grid"
                                    name="display_option"
                                    checked={display_option === 'detailed_grid'}
                                    onChange={(c, v) => { this.setState({ display_option: v }) }}
                                />
                            </label>
                            <table className="custom-table">
                                <thead>
                                    <tr>
                                        <th>Qty</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Buy 1</td>
                                        <td>$ 100.00 ea</td>
                                    </tr>
                                    <tr>
                                        <td>Buy 50</td>
                                        <td>$ 95.00 ea</td>
                                    </tr>
                                    <tr>
                                        <td>Buy 100</td>
                                        <td>$ 90.00 ea</td>
                                    </tr>
                                    <tr>
                                        <td>Buy 1000</td>
                                        <td>$ 85.00 ea</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="custom-third">
                            <label className="" htmlFor="custom_id_2">
                                <RadioButton
                                    label="Basic Grid"
                                    helpText=""
                                    id="basic_grid"
                                    name="display_option"
                                    checked={display_option === 'basic_grid'}
                                    onChange={(c, v) => { this.setState({ display_option: v }) }}
                                />
                            </label>
                            <table className="custom-table">
                                <thead>
                                    <tr>
                                        <th>Qty</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>$ 100.00</td>
                                    </tr>
                                    <tr>
                                        <td>50</td>
                                        <td>$ 95.00</td>
                                    </tr>
                                    <tr>
                                        <td>100</td>
                                        <td>$ 90.00</td>
                                    </tr>
                                    <tr>
                                        <td>1000</td>
                                        <td>$ 85.00</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="custom-third">
                            <label className="" htmlFor="custom_id_3">
                                <RadioButton
                                    label="Grid Range"
                                    helpText=""
                                    id="grid_range"
                                    name="display_option"
                                    checked={display_option === 'grid_range'}
                                    onChange={(c, v) => { this.setState({ display_option: v }) }}
                                />
                            </label>
                            <table className="custom-table">
                                <thead>
                                    <tr>
                                        <th>Minimum Qty</th>
                                        <th>Maximum Qty</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>49</td>
                                        <td>$ 100.00</td>
                                    </tr>
                                    <tr>
                                        <td>50</td>
                                        <td>99</td>
                                        <td>$ 95.00</td>
                                    </tr>
                                    <tr>
                                        <td>100</td>
                                        <td>999</td>
                                        <td>$ 90.00</td>
                                    </tr>
                                    <tr>
                                        <td>1000</td>
                                        <td>+</td>
                                        <td>$ 85.00</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="custom-third">
                            <label className="" htmlFor="custom_id_4">
                                <RadioButton
                                    label="Detailed Grid Percent"
                                    helpText=""
                                    id="detailed_grid_percent"
                                    name="display_option"
                                    checked={display_option === 'detailed_grid_percent'}
                                    onChange={(c, v) => { this.setState({ display_option: v }) }}
                                />
                            </label>
                            <table className="custom-table">
                                <thead>
                                    <tr>
                                        <th>Qty</th>
                                        <th>Price</th>
                                        <th>Discount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Buy 1</td>
                                        <td>$ 100.00 ea</td>
                                        <td>0% Off</td>
                                    </tr>
                                    <tr>
                                        <td>Buy 50</td>
                                        <td>$ 95.00 ea</td>
                                        <td>5% Off</td>
                                    </tr>
                                    <tr>
                                        <td>Buy 100</td>
                                        <td>$ 90.00 ea</td>
                                        <td>10% Off</td>
                                    </tr>
                                    <tr>
                                        <td>Buy 1000</td>
                                        <td>$ 85.00 ea</td>
                                        <td>15% Off</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="custom-third">
                            <label className="" htmlFor="custom_id_5">
                                <RadioButton
                                    label="Percent Grid"
                                    helpText=""
                                    id="percent_grid"
                                    name="display_option"
                                    checked={display_option === 'percent_grid'}
                                    onChange={(c, v) => { this.setState({ display_option: v }) }}
                                />
                            </label>
                            <table className="custom-table">
                                <thead>
                                    <tr>
                                        <th>Qty</th>
                                        <th>Discount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Buy 1</td>
                                        <td>0% Off</td>
                                    </tr>
                                    <tr>
                                        <td>Buy 50</td>
                                        <td>5% Off</td>
                                    </tr>     <tr>
                                        <td>Buy 100</td>
                                        <td>10% Off</td>
                                    </tr>
                                    <tr>
                                        <td>Buy 1000</td>
                                        <td>15% Off</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="custom-third">
                            <label className="" htmlFor="custom_id_6">
                                <RadioButton
                                    label="Grid Range Alternate"
                                    helpText=""
                                    id="grid_range_alternate"
                                    name="display_option"
                                    checked={display_option === 'grid_range_alternate'}
                                    onChange={(c, v) => { this.setState({ display_option: v }) }}
                                />
                            </label>
                            <table className="custom-table">
                                <thead>
                                    <tr>
                                        <th>Qty</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1 - 49</td>
                                        <td>$ 100.00</td>
                                    </tr>
                                    <tr>
                                        <td>50 - 99</td>
                                        <td>$ 95.00</td>
                                    </tr>
                                    <tr>
                                        <td>100 - 999</td>
                                        <td>$ 90.00</td>
                                    </tr>
                                    <tr>
                                        <td>1000 - +</td>
                                        <td>$ 85.00</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        let schedule_section = <div style={{ padding: "10px 0" }}>
            <Stack vertical spacing="tight">
                <div>
                    <TextContainer>
                        <TextStyle variation="subdued">You can set the time frame for the Volume Discount activation and expiration</TextStyle>
                        <br></br>
                        <TextStyle variation="subdued">Please note that the START DATE allows you to set the activation date and time and the SET END DATE allows you to set the expiration date and time. </TextStyle>
                    </TextContainer>
                </div>
                <Checkbox
                    checked={schedule_active}
                    label="Schedule this volume discount"
                    onChange={(value) => {
                        this.setState({ schedule_active: value })
                    }}
                    disabled={false}
                />
                {
                    schedule_active && <FormLayout>
                        <FormLayout.Group>
                            <Popover
                                active={start_date_popup}
                                activator={start_date_activator}
                                onClose={(value) => { this.setState({ start_date_popup: false }) }}
                                preferredPosition="above"
                                sectioned
                            >
                                {start_date_piker}
                            </Popover>
                            <Select
                                label={'Start time (EST)'}
                                placeholder="Select time"
                                options={time_options}
                                onChange={(value) => { this.setState({ start_time: value }) }}
                                value={start_time}
                            >
                            </Select>
                        </FormLayout.Group>
                    </FormLayout>
                }
                {
                    schedule_active && <Checkbox
                        checked={schedule_end_date}
                        label="Set end date"
                        onChange={(value) => {
                            this.setState({ schedule_end_date: value })
                        }}
                    />
                }
                {
                    (schedule_active && schedule_end_date) && <FormLayout>
                    <FormLayout.Group>
                            <Popover
                                active={end_date_popup}
                                activator={end_date_activator}
                                onClose={(value) => { this.setState({ end_date_popup: false }) }}
                                preferredPosition="above"
                                sectioned
                            >
                                {end_date_piker}
                            </Popover>
                            <Select
                                label={'End time (EST)'}
                                placeholder="Select time"
                                options={time_options}
                                onChange={(value) => { this.setState({ end_time: value }) }}
                                value={end_time}
                                error={end_date_time_error ? true : false}
                            ></Select>
                        </FormLayout.Group>
                    </FormLayout>
                }
            </Stack>
        </div>

        let errors_section = <Layout.Section>
            <Banner status="critical" title={<span>There {errors.length > 1 ? "are" : "is"} {errors.length} {errors.length > 1 ? "errors" : "error"} in Volume Discount</span>}>
                <List type="number">
                    {
                        errors.map((error, key) => {
                            return <List.Item key={key}>{error}</List.Item>;
                        })
                    }
                </List>
            </Banner>
        </Layout.Section>;

        const tab_1 = <div style={{ padding: "10px 0" }}>
            <Checkbox
                checked={this.state.__enableQty}
                label="Enable Quantity controller"
                onChange={(__enableQty) => {
                    this.setState({ __enableQty })
                }}
            />
            <div style={{ padding: "10px 0" }}>
                <Stack distribution="fill" wrap={false}>
                    <TextField helpText="The quantity set by default. Leave blank if not set" label="Default Quantity" type="number" value={this.state.__dq} onChange={(__dq) => { this.setState({ __dq }) }} min={1} />

                    <TextField helpText="Set minimum quantity allowed to purchase. Leave blank if not set" label="Minimum Quantity" type="number" error={this.state.__minq !== '' && parseInt(this.state.__minq) > parseInt(this.state.__dq) ? "Minimum quantity must be greater than deafult quantity" : (this.state.__minq !== '' && parseInt(this.state.__minq) % parseInt(this.state.__qi) !== 0 ? "Minimum quantity must be multiple of " + this.state.__qi : "")} value={getValue(this.state.__minq)} onChange={(__minq) => { this.setState({ __minq }) }} min={1} />
                </Stack>
            </div>
            <div style={{ padding: "10px 0" }}>
                <Stack distribution="fill" wrap={false}>
                    <TextField helpText="Set maximum quantity allowed to purchase. Leave blank if not set" label="Maximum Quantity" type="number" value={getValue(this.state.__maxq)} onChange={(__maxq) => { this.setState({ __maxq }) }} min={1} />

                    <TextField helpText="Set a quantity increment value. For example, allow to buy packs of 25 items, i.e. (25,50,75, etc). Leave blank if not set" label="Quantity Increments" type="number" value={getValue(this.state.__qi)} onChange={(__qi) => { this.setState({ __qi }) }} min={1} />
                </Stack>
            </div>
        </div>;
        var tabs = [
            {
                id: 'configure-display',
                content: 'Volume Discount Table',
                panelID: 'configure-display',
            },
            {
                id: 'schedule',
                content: 'Schedule',
                panelID: 'schedule-content',
            }
        ];
        let tabs_container = <Layout.Section>
            <Card sectioned>
                <Tabs tabs={tabs} selected={this.state.tab_selected} onSelect={(tab_selected) => { this.setState({ tab_selected }) }}>
                    {

                        tabs[this.state.tab_selected].id === 'qty-controller' && this.props.shop_features && this.props.shop_features.findIndex((x) => x === "quantity_controller") !== -1 ?
                            tab_1 :
                            tabs[this.state.tab_selected].id === 'schedule' ? schedule_section
                                : tabs[this.state.tab_selected].id === 'configure-display' ? configure_display_section
                                    : null
                    }
                </Tabs>
            </Card>
        </Layout.Section>
        var toast = this.state.showToast? (
            <ToastMessage
              onDismiss={this.dissmissToast}
              message={this.state.toastMsg}
              error={this.state.toastError}
            />
          ): (null);

        return (
            <div>
                <PageTitleBar
                    title={this.props.show==="edit"?"Edit":"New"}
                    primaryAction={{
                        content: 'Save Volume Discount',
                        disabled: qb_save_loading,
                        onAction: () => { this.saveQuantityBreak(); }
                    }}
                    secondaryActions={[
                        {
                            target: "APP",
                            content: 'Back',
                            url: "/quantity-breaks"
                        }
                    ]}
                />
                {toast}
                <Layout>
                    {errors.length > 0 ? errors_section : null}
                    {internal_name_section}
                    {customers_section}
                    {discount_type_section}
                    {price_level_section}
                    {products_selection_section}
                    {resource_pikers_and_models}
                    {tabs_container}
                </Layout>
                {create_qb_buttons}
                <FooterHelpLinks help={this.props.help} />
            </div>
        )
    }
}

function getValue(value){
	if(value){
		try {
            value = value.toString();
        } catch (e) { }
    }
    return value;
}