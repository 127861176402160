import React, {useEffect, useRef} from 'react';
import {Banner,Heading,TextStyle} from '@shopify/polaris';

export default function UpgradationMessage() {
  const banner = useRef();

  useEffect(() => banner.current.focus(), []);

  return (
    <Banner
      title="New Version Available!"
      status="info"
      ref={banner}
    >
      <p>A new version of Wholesale app is available! Would you like to upgrade? Send us an email at support@digitalcoo.com</p> 
    </Banner>
  );
}
