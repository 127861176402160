import React, { Component } from 'react';
import InstallationStatus from '../InstallationStatus';
import {  Layout, Button, Banner, Card, TextStyle } from '@shopify/polaris';
import store from 'store2';
import PageTitleBar from '../../Common/TitleBar';

var owner_email = store("owner_email") || null;

export default class AccountSetupInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            install_status: this.props.install_status,
            install_status_page: 1
        }
    }

    render() {
        const {install_status_page} = this.state;
        const page2 = <InstallationStatus app_configured={this.props.app_configured} install_status={this.props.install_status} shop={this.props.shop}/>;

        const page1 = <div>
            <Layout>
                <Layout.Section>

                        <div className="float-right">
                            <Button
                                primary
                                onClick={() => {
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                    this.setState({install_status_page:2})
                                }}
                            > HOME </Button>
                        </div>
                        <div className="clearfix"></div>

                        <div style={{margin:"2rem 0"}}>
                            <Banner status="info">
                            To complete installation of Wholesale All In One application we request that you add us as a Staff Member and add some basic permissions. This is to edit your theme to make all of the necessary adjustments depending on other apps or scripts your theme is running.
                            </Banner>
                        </div>

                        <Card sectioned>
                            1: On your Shopify admin page, click <TextStyle variation="strong">"Settings"</TextStyle> -> <TextStyle variation="strong">"Plan and permissions"</TextStyle>
                        </Card>

                        <Card sectioned>
                            <div style={{margin: "2rem 0rem"}}>
                            2. On the Staff members section, click <TextStyle variation="strong">"Add staff account"</TextStyle>.
                            </div>
                            <div>
                                <img src="/images/add-staff-account.png" alt="add-staff-account" width="100%" style={{ maxWidth: '100%', width: 'auto' }} />
                            </div>
                        </Card>
                        
                        <Card sectioned>
                            3. To add us :
                            <ul>
                                <li>
                                    Enter <TextStyle variation="strong">Wholesale</TextStyle> as first name, <TextStyle variation="strong">Application</TextStyle> as last name & <TextStyle variation="strong">support@digitalcoo.com</TextStyle> as an email address.
                                </li>
                                <li>
                                    Uncheck the checkbox titled <TextStyle variation="strong">"This staff account will have full permissions"</TextStyle>.
                                </li>
                            </ul>
                            <div>
                                <img src="/images/unchecked-option.png" alt="unchecked-option" width="100%" style={{ maxWidth: '100%', width: 'auto' }} />
                            </div>
                        </Card>
                        
                        <Card sectioned>
                            4. Limit access to the following, as per screenshot below :
                            <div>
                                <ul className="permission-list">
                                    <li>Draft Orders</li>
                                    <li>Products</li>
                                    <li>Customers</li>
                                    <li>Discounts</li>
                                    <li>Apps</li>
                                    <li>Settings</li>
                                    <li>Themes</li>
                                    <li>Blog posts and pages</li>
                                </ul>
                            </div>
                            <div>
                                <img src="/images/set-permissions.png" alt="set-permissions" width="100%" style={{ maxWidth: '100%', width: 'auto' }} />
                            </div>
                        </Card>

                        <Card sectioned>
                            <div>
                                5. Click "Send Invite"
                                <div>
                                    - Yay! You're done - please hold tight!
                                </div>
                                <div>
                                    - Once we complete your theme edit for your store we'll send you a notification email to <TextStyle variation="strong"> {owner_email?owner_email: "admin@storeowner.com"} </TextStyle>
                                </div>
                                <div>
                                    - Do you want to receive notification on different email address? Please write us an email to support@digitalcoo.com
                                </div>
                            </div>
                        </Card>
                </Layout.Section>
            </Layout>
        </div>;
        return (
            <div>
                <PageTitleBar
                    title="Installation status"
                />
                { install_status_page === 1?page1:page2 }
            </div>
        );
    }
}