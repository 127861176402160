import React, { Component } from 'react';
import axios from 'axios';
import { Card, Stack, FormLayout, TextField, Checkbox, Tag, Select, Button, RadioButton, DataTable, ButtonGroup, Badge, Link, EmptyState, Banner, TextStyle, Icon } from '@shopify/polaris';
import store from 'store2';
import _Modules from '../../modules.json';
import waio from '../../Helpers/functions';
import PageTitleBar from '../../Common/TitleBar';
import ToastMessage from '../../Common/ToastMessage';
import { DeleteMinor } from '@shopify/polaris-icons';

var snippet_version = store('snippet_version') || "2.0.9";

export default class ManageShipping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showToast: false,
            toastMsg: '',
            toastError: false,
            show: this.props.show,
            shop: this.props.shop,
            edit_rule_id: this.props.match.params.id,
            rules: [{ "column": "cart_original_total_price", "relation": "equal", "condition": "" }],
            rules_operator: "and",
            message: "",
            title: "",
            status: "active",
            customer_group: "all",
            except_logged_in: false,
            except_tags: false,
            excepted_tags: [],
            temp_tag_value: "",
            customer_tags: [],
            temp_customer_tags_value: "",
            saveButtonLoading: false,
            allShippingRules: [],
            page_loaded: false,
            free_shipping: false,
            charges: 0,
            applies_to: 'entire_store',
            products: [],
            collections: [],
            variants: [],
            product_modal_open: false,
            update_collections__modal_open: false,
            update_products__modal_open: false
        };
        store.remove('products');
        store.remove('collections');
    }

    disableSave = false;

    showToastMessage(msg, error){
    	this.setState({
    	  showToast: true,
    	  toastMsg: msg,
    	  toastError: error||false,
    	});
    }

    dissmissToast = () =>{
    	this.setState({
    	  showToast: false,
    	});
    }

    column_cart_options = [
        { label: "Cart total amount (subtotal)", value: "cart_original_total_price", disabled: false },
        { label: "Cart total items (quantity)", value: "cart_item_count", disabled: false },
        { label: `Cart total weight (${this.props.weight_unit})`, value: "cart_total_weight", disabled: false }
    ];
    
    relation_options = [
        { label: "is equal to", value: "equal", disabled: false },
        { label: "is not equal to", value: "not_equal", disabled: false },
        { label: "is greater than or equal to", value: "greater_than_or_equal_to", disabled: false },
        { label: "is less than or equal to", value: "less_than_or_equal_to", disabled: false }
    ];

    needToUpgrade = false;

    componentDidMount(){
        this.getAllShippings();
        if(this.state.show === "edit"){
            this.getARuleForEdit();
        }

        if (snippet_version && waio.compareVersionNumbers(snippet_version, _Modules.find(x => x._id === 209).snippet_version) === -1) {
            this.needToUpgrade = true;
        }
    }

    getARuleForEdit(){
        axios.get(this.props.host_name + '/app/get-shipping/'+this.state.edit_rule_id)
        .then(r => {
            if (r && r.data) {
                var {
                    rules,
                    rules_operator,
                    message,
                    title,
                    status,
                    customer_group,
                    except_logged_in,
                    except_tags,
                    excepted_tags,
                    customer_tags,
                    free_shipping,
                    charges,
                    applies_to,
                    products,
                    collections,
                    variants          
                } = r.data;

                this.setState({
                    rules,
                    rules_operator,
                    message,
                    title,
                    status,
                    customer_group,
                    except_logged_in,
                    except_tags,
                    excepted_tags,
                    customer_tags,
                    free_shipping,
                    charges,
                    applies_to,
                    products,
                    collections,
                    variants
                });
            }
            else {
               
            }
        })
        .catch(error => {
           
        });
    }

    getAllShippings(){
        axios.get(this.props.host_name + '/app/all-shippings/'+this.props.shop )
        .then(r => {
            if (r && r.data) {
                this.setState({allShippingRules:r.data,page_loaded:true});
            }
            else {
               
            }
        })
        .catch(error => {
           
        });
    }

    addCondition() {
        var rules = this.state.rules;
        rules.push(
            { "column": "cart_original_total_price", "relation": "equal", "condition": "" }
        );
        this.setState({rules});
    }

    removeCondition(i) {
        var rules = this.state.rules;
        rules.splice(i, 1);
        this.setState({ rules });
    }

    changeConditionValue(value, id, i) {
        var rules = this.state.rules;
        rules[i] = { ...rules[i], [id]: value };
        this.setState({ rules });
    }

    renderExceptedTags(){
        var excepted_tags = this.state.excepted_tags;
        return excepted_tags.map((tag,i) => {
            return <span key={i} style={{margin:"4px"}}>
                <Tag
                    onRemove={this.removeTag.bind(this,tag,i)}
                >{tag}</Tag>
            </span>
        });
    }

    removeTag(tag,i,el){
        var excepted_tags = this.state.excepted_tags;
        excepted_tags.splice([i],1);
        this.setState({ excepted_tags });
    }

    saveRule(){
        if(this.validation() === true){
            this.setState({saveButtonLoading: true});
            const data = {
                shop: this.props.shop,
                rules: this.state.rules,
                rules_operator: this.state.rules_operator,
                message: this.state.message,
                title: this.state.title,
                charges: this.state.free_shipping?"0.00":this.state.charges,
                free_shipping: this.state.free_shipping,
                status: this.state.status,
                customer_group: this.state.customer_group,
                customer_tags: this.state.customer_tags,
                except_logged_in: this.state.except_logged_in,
                except_tags: this.state.except_tags,
                excepted_tags: this.state.excepted_tags,
                products: this.state.products,
                collections: this.state.collections,
                variants: [],
                applies_to: this.state.applies_to
            };
            if(this.state.show === "edit"){
                // /update-order-control
                axios.put(this.props.host_name + '/app/update-shipping/'+this.state.edit_rule_id, data)
                    .then(r => {
                        if (r && r.data && r.data.message) {
                            if(r.data.status === 1){
                                this.showToastMessage(r.data.message);
                                this.props.history.push('/manage-shipping');
                                this.getAllShippings();
                                this.setState({show:"list"});
                            }
                            else{
                                this.showToastMessage(r.data.message, true);
                            }
                        }
                        else {
                           
                        }
                        this.setState({saveButtonLoading: false})
                    })
                    .catch(error => {
                       
                        this.setState({saveButtonLoading: false});
                    });
            }
            else{
                axios.post(this.props.host_name + '/app/add-shipping', data)
                    .then(r => {
                        if (r && r.data && r.data.message) {
                            if(r.data.status === 1){
                                this.showToastMessage(r.data.message);
                                this.props.history.push('/manage-shipping');
                                this.getAllShippings();
                                this.setState({show:"list"});
                            }
                            else{
                                this.showToastMessage(r.data.message, true);
                            }
                        }
                        else {
                           
                        }
                        this.setState({saveButtonLoading: false})
                    })
                    .catch(error => {
                       
                        this.setState({saveButtonLoading: false});
                    });
            }
        }
    }

    disableSaveButton(){
        if(this.props.user_current_plan){
            var plan_id = this.props.user_current_plan?this.props.user_current_plan.plan_id:null;
            var valid = false;
            if(!plan_id){
                return !this.validation();
            }
            else{
                valid = plan_id.indexOf("basic") > -1;
                if(valid){
                    this.disableSave = true;
                    return true;
                }
                else{
                    return !this.validation();
                }
            }
        }else{
            return !this.validation();
        }
    }

    validation(){
        var valid = true;
        var {excepted_tags,except_tags,rules,customer_group,customer_tags,free_shipping,charges,title,applies_to,products,collections,variants} = this.state;
        if(except_tags && excepted_tags.length < 1) valid = false;
        if(customer_group === "tag_based" && customer_tags.length < 1) valid = false;
        for(var rule of rules){
            if(!rule.condition){ valid = false; break; }
            else if(rule.condition.trim() === "") { valid = false; break; }
        }
        if(!free_shipping && Number(charges) < 0){valid = false;}
        if(!title || title.trim() === ""){valid = false;}
        if(applies_to === "products" && products.length < 1){valid=false;}
        if(applies_to === "collections" && collections.length < 1){valid=false;}
        if(applies_to === "variants" && variants.length < 1){valid=false;}
        return valid;
    }

    renderCustomerTags(){
        
        var customer_tags = this.state.customer_tags;
        return customer_tags.map((tag,i) => {
            return <span key={i} style={{margin:"4px"}}>
                <Tag
                    onRemove={this.removeCustomerTag.bind(this,tag,i)}
                >{tag}</Tag>
            </span>
        });

    }

    removeCustomerTag(tag,i,el){
        var customer_tags = this.state.customer_tags;
        customer_tags.splice([i],1);
        this.setState({ customer_tags });
    }

    deleteRule(item){
        if(window.confirm(`Delete ${item.title}?`)){
            axios.delete(this.props.host_name + '/app/delete-shipping/'+item._id+'/'+ this.props.shop)
            .then(r => {
                if (r && r.data && r.data.message) {
                    if(r.data.status === 1){
                        this.showToastMessage(r.data.message);
                        this.getAllShippings();
                    }
                    else{
                        this.showToastMessage(r.data.message, true);
                    }
                }
                else {
                   
                }
            })
            .catch(error => {
               
            });
        }
    }

    showTag(item){
        return item.customer_tags.map((el,i)=>{
            return i < 1 ? <span key={i}><Badge>{el}</Badge></span>:null
        });
    }

    handleSubmitUpdateProducts = (selected) => {
        let products = store('products') || [];
        this.setState({
            products,
            update_products__modal_open: false
        })
    }
    
    handleSubmitUpdateCollections = (selected) => {
        let collections = store('collections') || [];
        this.setState({
            collections,
            update_collections__modal_open: false
        })
    }

    disableRule(item){
        if(window.confirm(`Disable ${item.title}?`)){
            var data = {
                _id: item._id,
                status: "disabled"
            };
            axios.put(this.props.host_name + '/app/update-status-shipping/'+item._id+'/', data)
            .then(r => {
                if (r && r.data && r.data.message) {
                    if(r.data.status === 1){
                        this.showToastMessage(r.data.message);
                        this.getAllShippings();
                    }
                    else{
                        this.showToastMessage(r.data.message, true);
                    }
                }
                else {
                   
                }
            })
            .catch(error => {
               
            });
        }
    }

    enableRule(item){
        var data = {
            _id: item._id,
            status: "active"
        };
        axios.put(this.props.host_name + '/app/update-status-shipping/'+item._id+'/', data)
        .then(r => {
            if (r && r.data && r.data.message) {
                if(r.data.status === 1){
                    this.showToastMessage(r.data.message);
                    this.getAllShippings();
                }
                else{
                    this.showToastMessage(r.data.message, true);
                }
            }
            else {
               
            }
        })
        .catch(error => {
           
        });
    }

    render() {
        const {
            rules,rules_operator,message,title,customer_group,except_logged_in,except_tags,excepted_tags, temp_tag_value, temp_customer_tags_value,free_shipping,charges
        } = this.state;

        var UpgradeBanner = null;
        if(this.needToUpgrade){
            UpgradeBanner = <div style={{margin:"1rem 0rem"}}>
                <Banner status="warning" title="Upgrade your script!">
                Order Limit feature requires script upgrade. Please email us at <TextStyle variation="strong">support@digitalcoo.com</TextStyle> We will update the relevant script.
                </Banner>
            </div>;
        }


        const customerTagsInputField = <TextField
            label="Customer tags"
            labelHidden
            autoFocus
            value={temp_customer_tags_value}
            placeholder="Enter comma seperated tags"
            onChange={(v)=>{
                if(v && v.indexOf(",") >-1 ){
                    v= v.replace(",","");
                    v = v.trim();
                    if(v === "") return false;
                    var customer_tags = this.state.customer_tags;
                    if(customer_tags.indexOf(v) > -1){
                        this.showToastMessage("Tag already exists", true);
                        return false;
                    }
                    customer_tags.push(v);
                    this.setState({ customer_tags, temp_customer_tags_value: "" });
                }
                else{
                    this.setState({temp_customer_tags_value:v});
                }
            }}
            onBlur={()=>{
                var v = this.state.temp_customer_tags_value;
                v = v.replace(",","");
                v = v.trim();
                if(v === "") return false;
                var customer_tags = this.state.customer_tags;
                if(customer_tags.indexOf(v) > -1){
                    return false;
                }
                customer_tags.push(v);
                this.setState({ customer_tags, temp_customer_tags_value: "" });
            }}
        />;

        const tags_input = <TextField
            label="Tags"
            labelHidden
            autoFocus
            placeholder="Enter comma separated tags"
            value={temp_tag_value}
            onChange={(v)=>{
                if(v && v.indexOf(",") >-1 ){
                    v= v.replace(",","");
                    v = v.trim();
                    if(v === "") return false;
                    var excepted_tags = this.state.excepted_tags;
                    if(excepted_tags.indexOf(v) > -1){
                        this.showToastMessage("Tag already exists", true);
                        return false;
                    }
                    excepted_tags.push(v);
                    this.setState({ excepted_tags, temp_tag_value: "" });
                }
                else{
                    this.setState({temp_tag_value:v});
                }
            }}
            onBlur={()=>{
                var v = this.state.temp_tag_value;
                v = v.replace(",","");
                v = v.trim();
                if(v === "") return false;
                var excepted_tags = this.state.excepted_tags;
                if(excepted_tags.indexOf(v) > -1){
                    return false;
                }
                excepted_tags.push(v);
                this.setState({ excepted_tags, temp_tag_value: "" });
            }}
        ></TextField>;

        const except_logged_in_section = <div>
            <Checkbox
                label="Except logged in"
                id="except_logged_in"
                value={except_logged_in}
                checked={except_logged_in}
                onChange={(checked, value) => {
                    this.setState({ except_logged_in: checked });
                }}
            />
        </div>;

        const except_taged_section = <Stack vertical spacing="tight">
            <Checkbox
                label={<span>Except tags</span>}
                id="except_tags"
                value={except_tags}
                checked={except_tags}
                onChange={(checked, value) => {
                    this.setState({ except_tags: checked });
                }}
            />
            {except_tags?tags_input: null}
            {
                except_tags && excepted_tags.length > 0?
                <Stack>
                    {this.renderExceptedTags()}
                </Stack>
                : null
            }
        </Stack>;

        const Customer_section = <div>
            <FormLayout>
                <RadioButton
                    label="All customers"
                    id="all"
                    name="customers_group"
                    checked={customer_group === "all"}
                    onChange={(checked, value) => {
                        this.setState({ customer_group: value, except_tags: false, except_logged_in: false  });
                    }}
                />
                {
                    customer_group === "all" ?
                        <div style={{marginLeft:"20px"}}>
                            {except_logged_in_section}
                            {except_taged_section}
                        </div>
                    :null
                }
                <RadioButton
                    label="Only logged in customers"
                    id="logged_in"
                    name="customers_group"
                    checked={customer_group === "logged_in"}
                    onChange={(checked, value) => {
                        this.setState({ customer_group: value,except_tags: false, except_logged_in: false });
                    }}
                />
                {
                    customer_group === "logged_in" ?
                        <div style={{marginLeft:"20px"}}>
                            {except_taged_section}
                        </div>
                    :null
                }

                <RadioButton
                    label={<span>Only tag based logged in customers </span>}
                    id="tag_based"
                    name="customers_group"
                    checked={customer_group === "tag_based"}
                    onChange={(checked, value) => {
                        this.setState({ customer_group: value, except_tags: false, except_logged_in: false  });
                    }}
                />
                {customer_group === "tag_based"?customerTagsInputField:null}
                {
                    customer_group === "tag_based" ?
                        <div style={{marginLeft:"20px"}}>
                            <Stack spacing="tight">
                                {this.renderCustomerTags()}
                            </Stack>
                        </div>
                    :null
                }
            </FormLayout>
        </div>;

        const conditions_section = rules.map((el, i) => {
            return <FormLayout.Group condensed key={i} fullWidth>
                <Select
                    label="Column"
                    labelHidden={true}
                    id="column"
                    name="column"
                    value={el.column}
                    options={[
                        { title: "Cart", options: this.column_cart_options }
                    ]}
                    onChange={(selected, id) => { this.changeConditionValue(selected, id, i) }}
                ></Select>
                <Select
                    label="Relation"
                    labelHidden={true}
                    id="relation"
                    name="relation"
                    value={el.relation}
                    options={this.relation_options}
                    onChange={(selected, id) => { this.changeConditionValue(selected, id, i) }}
                ></Select>
                <TextField
                    label="Condition"
                    labelHidden={true}
                    id="condition"
                    name="condition"
                    value={el.condition || ""}
                    onChange={(selected, id) => { this.changeConditionValue(selected, id, i) }}
                    error={el.condition === "" ? "This field can't be blank" : false}
                ></TextField>
                <Button
                    onClick={this.removeCondition.bind(this, i)}
                    disabled={rules.length === 1}
                ><Icon source={DeleteMinor}/></Button>
            </FormLayout.Group>;
        });

        const add_or_edit_section = <div>
            <Card>
            <Card.Section>
                <FormLayout fullWidth>
                    <FormLayout.Group>
                        <TextField
                            label="Shipping title"
                            value={title || ""}
                            onChange={(value) => {
                                this.setState({ title: value });
                            }}
                            helpText="Title of shipping line. e.g Free shipping"
                            maxLength={255}
                            showCharacterCount={true}
                            clearButton={true}
                            autoFocus={true}
                            onClearButtonClick={() => { 
                                this.setState({ title: "" });
                             }}
                            error={title === "" ? "This field can't be blank" : false}
                        />
                        <TextField
                            label="Shipping message (optional)"
                            multiline
                            value={message}
                            onChange={(v) => {
                                this.setState({ message: v });
                            }}
                            helpText="This will display at cart page next to cart subtotal price"
                        />
                    </FormLayout.Group>
                </FormLayout>
            </Card.Section>
            <Card.Section>
                <FormLayout fullWidth>
                    <Checkbox
                        label="Free shipping"
                        value={free_shipping}
                        checked={free_shipping}
                        id={"free_shipping"}
                        onChange={(v) => {
                            if(v){
                                this.setState({charges: 0});
                            }
                            this.setState({free_shipping:v})
                        }}
                    />
                    <TextField
                        label="Shipping charges"
                        type="number"
                        value={getValue(charges)}
                        onChange={(v) => {
                            this.setState({ charges: v });
                        }}
                        onBlur={()=>{
                            var rate = charges;
                            if(Number(rate < 0)){
                                this.setState({charges:0});
                            }
                        }}
                        helpText="This is total shipping amount, your customer will pay"
                        disabled={free_shipping}
                    />
                </FormLayout>
            </Card.Section>
            <Card.Section>
                <FormLayout fullWidth>
                    {Customer_section}
                </FormLayout>
            </Card.Section>
            <Card.Section>
                <FormLayout fullWidth>
                    <FormLayout.Group>
                        <div>
                            Must match:&nbsp;&nbsp;&nbsp;
                            <RadioButton
                                name="condition_operator"
                                checked={rules_operator === "and"}
                                id="and"
                                label="All conditions"
                                onChange={(checked, value) => {
                                    this.setState({ rules_operator: value });
                                }}
                            />
                            &nbsp;&nbsp;&nbsp;
                            <RadioButton
                                name="condition_operator"
                                checked={rules_operator === "or"}
                                id="or"
                                label="Any condition"
                                onChange={(checked, value) => {
                                    this.setState({ rules_operator: value });
                                }}
                            />
                        </div>
                    </FormLayout.Group>
                    {conditions_section}
                    <FormLayout.Group fullWidth>
                        <Button
                            disabled={rules.length >= 3 ? true : false}
                            onClick={this.addCondition.bind(this)}
                        >
                            Add another condition
                        </Button>
                    </FormLayout.Group>
                </FormLayout>
            </Card.Section>
        </Card>
        <div className="bottom-line"></div>
        <div className="bottom-save-buttons">
            {
                this.props.is_basic_plan && <img style={{color:"#ffffff"}} width="36px" height="30px" src='/icons/lock.svg' alt="lock-icon" />
            }
            <Button primary
                onClick = { () => this.saveRule() }
                disabled = { this.props.is_basic_plan?true:this.needToUpgrade?true:this.disableSaveButton()}
                loading = { this.state.saveButtonLoading }
            >Save</Button>
        </div>
        <div className="clearfix"></div>
        </div>;

        const min_orders_list_rows = this.state.allShippingRules.map((item,i)=>[
            <Link url={'/manage-shipping/'+item._id} target="APP">
                {item.title.substring(0,40)}{item.title.length > 40?"...":null}
            </Link>,
            <span style={{textTransform:"capitalize"}}>
            {item.customer_group !== "tag_based" ?item.customer_group.replace("_"," "):null}
            {
                item.customer_group === "tag_based" ?
                    <span>
                        {this.showTag(item)}
                        {
                            item.customer_tags.length > 1?
                            <span className="and-more" style={{textTransform:"lowercase"}}> + {item.customer_tags.length - 1} more tags </span>
                            :null
                        }
                    </span>
                :null
            }
            </span>,
            item.message?
            <span>
                {item.message.substring(0,40)}{item.message.length > 40?"...":null}
            </span>
            :"N/A",
            <span>
                {
                    item.status === "active"?
                    <Badge status="success">Active</Badge>
                    :<Badge>Disabled</Badge>
                }
            </span>,
            <span>
                <ButtonGroup segmented>
                    <Button size="slim"
                        url={'/manage-shipping/'+item._id}
                        target="APP"
                        disabled={this.props.is_basic_plan}
                    >
                    Edit
                    </Button>
                    <Button size="slim" 
                        onClick={() => {this.deleteRule(item)}}
                    >
                    <Icon source={DeleteMinor}/>
                    </Button>
                    {
                        !this.props.is_basic_plan?
                            item.status === "active"?
                            <Button size="slim" 
                                onClick={() => {this.disableRule(item)}}
                            >
                            Disable
                            </Button>
                            :<Button size="slim" 
                                onClick={() => {this.enableRule(item)}}
                            >
                                Enable
                            </Button>
                        :null
                    }
                </ButtonGroup>
            </span>
        ])

        const empty_state = <EmptyState
            heading="Create a shipping"
            action={{content: 'Add shipping', url: '/add-manage-shipping', target: "APP"}}
            image="https://cdn.shopify.com/s/assets/admin/empty-states-fresh/emptystate-transfers-7caef90a7678b9dba63b068c63bc911796252c217b573d989bd4f0d9c15fee81.svg"
        >
        <p>
            Add custom shipping rates, it will override the default shipping.
        </p>
      </EmptyState>

        const disableSaveBanner = <div style={{margin:"1rem 0rem"}}><Banner status="critical" title="Upgrade your plan!">
            <p>
            Advanced Shipping feature is not included in Basic Plan. <Link url="/pricing-plans" target="APP">CLICK HERE</Link> to upgrade to Standard Plan.
            </p>
        </Banner></div>;

var toast = this.state.showToast? (
  <ToastMessage
	onDismiss={this.dissmissToast}
	message={this.state.toastMsg}
	error={this.state.toastError}
  />
): (null);


        if(this.state.show === "add" || this.state.show === "edit" ){
            return (
                <div>
                    <PageTitleBar
                        title={this.state.show === "add"?"Add":"Edit"}
                        breadcrumbs={[{content: 'Manage shipping', url: '/manage-shipping', target: "APP"}]}
                        primaryAction={{content:" Save ", onAction: () => this.saveRule(), disabled: this.disableSaveButton()? true: this.state.saveButtonLoading }}
                    />
                    {toast}
                    {
                        this.props.is_basic_plan ? disableSaveBanner
                        :this.needToUpgrade ? UpgradeBanner: null
                    }
                    {add_or_edit_section}
                </div>
            );
        }
        else{
            if(this.state.page_loaded && this.state.allShippingRules.length < 1){
                return (
                    <div>
                        <PageTitleBar
                            title="Shippings"
                            breadcrumbs={[{content: 'Dashboard', url: '/dashboard', target: "APP"}]}
                            secondaryActions={[{content: 'Back', url: '/dashboard', target: "APP"}]}
                        />
                        {empty_state}
                    </div>
                );
            }
            else{
                return (
                    <div>
                        <PageTitleBar
                            title="Shippings"
                            breadcrumbs={[{content: 'Dashboard', url: '/dashboard', target: "APP"}]}
                            secondaryActions={[{content: 'Back', url: '/dashboard', target: "APP"}]}
                        />
                        {toast}
                        {
                            this.props.is_basic_plan ? disableSaveBanner
                            :this.needToUpgrade ? UpgradeBanner: null
                        }
                        <div style={{ margin: "1rem 0rem" }}>
                            <ButtonGroup>
                                <Button disabled={this.disableSave} primary size="slim" url="/add-manage-shipping" target="APP">Add Shipping</Button>
                            </ButtonGroup>
                        </div>
                        {
                            this.state.allShippingRules.length > 0?
                                <Card>
                                    <DataTable
                                        columnContentTypes={[
                                        'text',
                                        'text',
                                        'text',
                                        'text',
                                        'text',
                                        ]}
                                        headings={[
                                        'Title',
                                        'Customer Group',
                                        'Message',
                                        'Status',
                                        'Actions',
                                        ]}
                                        rows={min_orders_list_rows}
                                    />
                                </Card>
                            :null
                        }
                    </div>
                );
            }
        }

    }
}

function getValue(value){
	if(value){
		try {
            value = value.toString();
        } catch (e) { }
    }
    return value;
}