import React, { Component } from 'react';
import {
  Layout,
  Card,
  TextStyle,
  Link,
  RadioButton,
  Stack,
  Banner 
} from '@shopify/polaris';
import axios from 'axios';
import ToastMessage from '../../../Common/ToastMessage';

export default class ImportRegularDiscount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      host_name: this.props.host_name,
      shop: this.props.shop,
      shop_id: this.props.shop_id,
      upload_file_btn_disable: true,
      upload_file_btn_loading: false,
      showToast: false,
      toastMsg: '',
      toastError: false,
      selection_mode: "individual_products",
      import_option_for_collection: this.props.shop_features.findIndex(x => x === "import_export_collection") > -1?true:false,
      success_banner: false,
      error_banner: false
    }
  }
  showToastMessage(msg, error){
    this.setState({
      showToast: true,
      toastMsg: msg,
      toastError: error||false,
    });
  }

  dissmissToast = () =>{
    this.setState({
      showToast: false,
    });
  }

  handleFileSelect = (e) => {
    this.setState({upload_file_btn_disable: false});
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    // HTML file input, chosen by user
    this.setState({upload_file_btn_disable: true, upload_file_btn_loading: true});
    var $form = document.getElementById("form-upload-files");
    var data = new FormData($form);
    if(this.state.selection_mode === "individual_products"){
      axios.post('https://wholesaleheavyload.digitalcoo.com/ie/import/regular-discount?shop='+this.state.shop, data)
      .then( response => {
        if(response.data.status === 1){
          this.showToastMessage(response.data.message);
          this.setState({success_banner: true, error_banner: false, upload_file_btn_loading: false });
        }
        else{
          this.showToastMessage(response.data.message, true);
          this.setState({upload_file_btn_disable: false, upload_file_btn_loading: false, success_banner: false, error_banner: true});
        }
      })
      .catch(error => {
        this.setState({upload_file_btn_disable: false, upload_file_btn_loading: false, success_banner: false, error_banner: true});
      });
    }
    else{
      axios.post('https://wholesaleheavyload.digitalcoo.com/ie/import/regular-discount/collections?shop='+this.state.shop, data)
      .then( response => {
        if(response.data.status === 1){
          this.showToastMessage(response.data.message);
          this.setState({success_banner: true, error_banner: false, upload_file_btn_loading: false });
        }
        else{
          this.showToastMessage(response.data.message, true);
          this.setState({upload_file_btn_disable: false, upload_file_btn_loading: false, success_banner: false, error_banner: true});
        }
      })
      .catch(error => {
        this.setState({upload_file_btn_disable: false, upload_file_btn_loading: false, success_banner: false, error_banner: true});
      });
    }
  }

  render() {
    const {import_option_for_collection} = this.state;
    var toast = this.state.showToast? (
      <ToastMessage
      onDismiss={this.dissmissToast}
      message={this.state.toastMsg}
      error={this.state.toastError}
      />
    ): (null);
    return (
      <Layout>
        <Layout.Section>
          {toast}
          <Card
            primaryFooterAction={
              {
                content: 'Upload and import',
                onAction: this.handleFormSubmit,
                disabled: this.state.upload_file_btn_disable,
                loading: this.state.upload_file_btn_loading
              }
            }
          >
            {
              import_option_for_collection && 
              <Card.Section>
                <Stack vertical spacing="extraTight">
                  <RadioButton
                    label="Import indivial products level discount rules"
                    onChange={(v) => {
                      this.setState({selection_mode: "individual_products"})
                    }}
                    checked={this.state.selection_mode === "individual_products"}
                  />
                  <RadioButton
                    label="Import collections level discount rules"
                    onChange={(v) => {
                      this.setState({selection_mode: "collection"})
                    }}
                    checked={this.state.selection_mode === "collection"}
                  />
                </Stack>
              </Card.Section>
            }
            {
              import_option_for_collection ?
              this.state.selection_mode === "collection"?
              <Card.Section>
                <div>
                  <form id="form-upload-files" encType="multipart/form-data" action={this.props.host_name+"/ie/import/volume-discount?shop="+this.props.shop} method="post" onSubmit={this.handleFormSubmit}>
                  <input type="file" name="file" accept=".csv" onChange={this.handleFileSelect} className="choose--csv-file" multiple aria-multiline="true"></input>
                  </form>
                </div>
                <div style={{marginTop:'2rem'}}>
                  <TextStyle variation="subdued">
                    Download a <Link url="https://videolink.click/tutorialvideos/regular-discount-link1.csv" external={true}>sample CSV template</Link> to see an example of the format required.
                  </TextStyle>
                </div>
              </Card.Section>
              :
              <Card.Section>
                <div>
                  <form id="form-upload-files" encType="multipart/form-data" action={this.props.host_name+"/ie/import/volume-discount?shop="+this.props.shop} method="post" onSubmit={this.handleFormSubmit}>
                  <input type="file" name="file" accept=".csv" onChange={this.handleFileSelect} className="choose--csv-file" multiple aria-multiline="true"></input>
                  </form>
                </div>
                <div style={{marginTop:'2rem'}}>
                  <TextStyle variation="subdued">
                    Download a <Link url="https://videolink.click/tutorialvideos/regular-discount-link2.csv" external={true}>sample CSV template</Link> to see an example of the format required.
                  </TextStyle>
                </div>
              </Card.Section>
              : 
              <Card.Section>
                <div>
                  <form id="form-upload-files" encType="multipart/form-data" action={this.props.host_name+"/ie/import/volume-discount?shop="+this.props.shop} method="post" onSubmit={this.handleFormSubmit}>
                  <input type="file" name="file" accept=".csv" onChange={this.handleFileSelect} className="choose--csv-file" multiple aria-multiline="true"></input>
                  </form>
                </div>
                <div style={{marginTop:'2rem'}}>
                  <TextStyle variation="subdued">
                    Download a <Link url="https://videolink.click/tutorialvideos/regular-discount-link3.csv" external={true}>sample CSV template</Link> to see an example of the format required.
                  </TextStyle>
                </div>
              </Card.Section>
            }
            {
              this.state.success_banner && <Card.Section>
                <Banner status="success">
                  <p>Rule imported successfully.</p>
                </Banner>
              </Card.Section>
            }
            {
              this.state.error_banner && <Card.Section>
                <Banner status="critical">
                  <p>Unable to upload CSV, Please contact to support@digitalcoo.com</p>
                </Banner>
              </Card.Section>
            }
          </Card>
        </Layout.Section>
      </Layout>
    );
  }
}