import React, { Component } from "react";
import {
    Card, FormLayout, TextField, PageActions
} from "@shopify/polaris";
import axios from 'axios';
import * as PropTypes from 'prop-types';

export default class DeveloperTools extends Component {

    constructor(props) {
        super(props);
        this.state = {
            admin: this.props.admin,
            wholesalePriceClass : '.wholesale-price', 
            waioProductPriceClass: '.waio-product-template',
            waioProductDataPriceAttr: 'waio-product-price',
            waioProductDataIdAttr: 'waio-product-id',
            dataItemPriceAttr: 'data-price', 
            dataItemIdAttr: 'data-item-id',
            quickViewDataAttr: 'quick-view-data-id', 
            wholesaleDataKeyAttr: 'waio-item-total-id',
            waioSingleDataKeyAttr: 'waio-item-single-id',
            cartSubtotalPriceAttr: 'waio-subtotal',
            checkout: '[name*=checkout]', 
            addToCart: "form[action='/cart/add']",
            savedAmount: ".waio-saved-amount-msg", 
            couponInputField: '.waio-coupon-input-field', 
            couponCodeForm: '.waio-coupon-code-form',
            additionalDiscountMsgClass: '.waio-additional-discount-msg', 
            qtyPlusMinusClass: '.ajaxcart__qty-adjust',
            qtyInputFieldClass: '.ajaxcart__qty-num', 
            variantsChangeSelectorClass: '.single-option-selector', 
            qtyMultipler: '[data-quantity-select], [name="quantity"]',
            qtyIncPlus: '.btn-plus span.up-qty',
            qtyIncMinus: '.btn-plus span.down-qty',
            qtyWrapper: '[data-product-form]',
            shopifyPaymentButton: '.shopify-payment-button',
            buyNowClass: '.shopify-payment-button__waio',
        };
    }


    static contextTypes = {
        easdk: PropTypes.object,
    };

    componentDidMount() {
        axios.get(this.props.host_name + '/get-settings/' + this.props.shop)
        .then(response => {
            this.setSelectorsState(response);
        })
        .catch(error => {
            // console.log(error);
        });
    }

    setSelectorsState(response){
        if (response && response.data && response.data.length > 0) {
            var selectors = response.data[0].selectors;
            selectors = selectors?selectors[0]?selectors[0]:{}:{};
            var wholesalePriceClass = selectors.wholesalePriceClass || this.state.wholesalePriceClass;
            var waioProductPriceClass = selectors.waioProductPriceClass || this.state.waioProductPriceClass;
            var waioProductDataPriceAttr = selectors.waioProductDataPriceAttr || this.state.waioProductDataPriceAttr;
            var waioProductDataIdAttr = selectors.waioProductDataIdAttr || this.state.waioProductDataIdAttr;
            var dataItemPriceAttr = selectors.dataItemPriceAttr || this.state.dataItemPriceAttr;
            var dataItemIdAttr = selectors.dataItemIdAttr || this.state.dataItemIdAttr;
            var quickViewDataAttr = selectors.quickViewDataAttr || this.state.quickViewDataAttr;
            var wholesaleDataKeyAttr = selectors.wholesaleDataKeyAttr || this.state.wholesaleDataKeyAttr;
            var cartSubtotalPriceAttr = selectors.cartSubtotalPriceAttr || this.state.cartSubtotalPriceAttr;
            var checkout = selectors.checkout || this.state.checkout;
            var addToCart = selectors.addToCart || this.state.addToCart;
            var savedAmount = selectors.savedAmount || this.state.savedAmount;
            var couponInputField = selectors.couponInputField || this.state.couponInputField;
            var couponCodeForm = selectors.couponCodeForm || this.state.couponCodeForm;
            var additionalDiscountMsgClass = selectors.additionalDiscountMsgClass || this.state.additionalDiscountMsgClass;
            var qtyPlusMinusClass = selectors.qtyPlusMinusClass || this.state.qtyPlusMinusClass;
            var qtyInputFieldClass = selectors.qtyInputFieldClass || this.state.qtyInputFieldClass;
            var variantsChangeSelectorClass = selectors.variantsChangeSelectorClass || this.state.variantsChangeSelectorClass;
            var waioSingleDataKeyAttr = selectors.waioSingleDataKeyAttr || this.state.waioSingleDataKeyAttr;
            var qtyMultipler = selectors.qtyMultipler || this.state.qtyMultipler;
            var qtyIncPlus = selectors.qtyIncPlus || this.state.qtyIncPlus;
            var qtyIncMinus = selectors.qtyIncMinus || this.state.qtyIncMinus;
            var qtyWrapper = selectors.qtyWrapper || this.state.qtyWrapper;
            var shopifyPaymentButton = selectors.shopifyPaymentButton || this.state.shopifyPaymentButton;
            var buyNowClass = selectors.buyNowClass || this.state.buyNowClass;
            wholesalePriceClass = wholesalePriceClass.replace(/(?:\\)/g,'');
            waioProductPriceClass = waioProductPriceClass.replace(/(?:\\)/g,'');
            waioProductDataPriceAttr = waioProductDataPriceAttr.replace(/(?:\\)/g,'');
            waioProductDataIdAttr = waioProductDataIdAttr.replace(/(?:\\)/g,'');
            dataItemPriceAttr= dataItemPriceAttr.replace(/(?:\\)/g,'');
            dataItemIdAttr= dataItemIdAttr.replace(/(?:\\)/g,'');
            quickViewDataAttr= quickViewDataAttr.replace(/(?:\\)/g,'');
            wholesaleDataKeyAttr= wholesaleDataKeyAttr.replace(/(?:\\)/g,'');
            waioSingleDataKeyAttr= waioSingleDataKeyAttr.replace(/(?:\\)/g,'');
            cartSubtotalPriceAttr= cartSubtotalPriceAttr.replace(/(?:\\)/g,'');
            checkout= checkout.replace(/(?:\\)/g,'');
            addToCart= addToCart.replace(/(?:\\)/g,'');
            savedAmount= savedAmount.replace(/(?:\\)/g,'');
            couponInputField= couponInputField.replace(/(?:\\)/g,'');
            couponCodeForm= couponCodeForm.replace(/(?:\\)/g,'');
            additionalDiscountMsgClass= additionalDiscountMsgClass.replace(/(?:\\)/g,'');
            qtyPlusMinusClass= qtyPlusMinusClass.replace(/(?:\\)/g,'');
            qtyInputFieldClass = qtyInputFieldClass.replace(/(?:\\)/g,'');
            variantsChangeSelectorClass= variantsChangeSelectorClass.replace(/(?:\\)/g,'');
            qtyMultipler= qtyMultipler.replace(/(?:\\)/g,'');
            qtyIncPlus= qtyIncPlus.replace(/(?:\\)/g,'');
            qtyIncMinus= qtyIncMinus.replace(/(?:\\)/g,'');
            qtyWrapper= qtyWrapper.replace(/(?:\\)/g,'');
            shopifyPaymentButton = shopifyPaymentButton.replace(/(?:\\)/g,'');
            buyNowClass = buyNowClass.replace(/(?:\\)/g,'');
            this.setState({
                wholesalePriceClass,waioProductPriceClass,waioProductDataPriceAttr,waioProductDataIdAttr,dataItemPriceAttr,dataItemIdAttr,quickViewDataAttr,wholesaleDataKeyAttr,waioSingleDataKeyAttr,cartSubtotalPriceAttr,checkout,addToCart,savedAmount,couponInputField,couponCodeForm,additionalDiscountMsgClass,qtyPlusMinusClass,qtyInputFieldClass, variantsChangeSelectorClass,qtyMultipler,qtyIncPlus,qtyIncMinus,qtyWrapper,shopifyPaymentButton,buyNowClass
            });
        }
    }

    saveSelectors = () => {
        var {
            wholesalePriceClass,
            waioProductPriceClass, 
            waioProductDataPriceAttr, 
            waioProductDataIdAttr, 
            dataItemPriceAttr,
            dataItemIdAttr,
            quickViewDataAttr,
            wholesaleDataKeyAttr,
            waioSingleDataKeyAttr,
            cartSubtotalPriceAttr,
            checkout,
            addToCart,
            savedAmount,
            couponInputField,
            couponCodeForm,
            additionalDiscountMsgClass,
            qtyPlusMinusClass,
            qtyInputFieldClass, 
            variantsChangeSelectorClass,
            qtyMultipler,
            qtyIncPlus,
            qtyIncMinus,
            qtyWrapper,
            shopifyPaymentButton,
            buyNowClass
        } = this.state;
        wholesalePriceClass = wholesalePriceClass.replace(/(?:")/g,'\\"');
        waioProductPriceClass = waioProductPriceClass.replace(/(?:")/g,'\\"');
        waioProductDataPriceAttr = waioProductDataPriceAttr.replace(/(?:")/g,'\\"');
        waioProductDataIdAttr = waioProductDataIdAttr.replace(/(?:")/g,'\\"');
        dataItemPriceAttr= dataItemPriceAttr.replace(/(?:")/g,'\\"');
        dataItemIdAttr= dataItemIdAttr.replace(/(?:")/g,'\\"');
        quickViewDataAttr= quickViewDataAttr.replace(/(?:")/g,'\\"');
        wholesaleDataKeyAttr= wholesaleDataKeyAttr.replace(/(?:")/g,'\\"');
        waioSingleDataKeyAttr= waioSingleDataKeyAttr.replace(/(?:")/g,'\\"');
        cartSubtotalPriceAttr= cartSubtotalPriceAttr.replace(/(?:")/g,'\\"');
        checkout= checkout.replace(/(?:")/g,'\\"');
        addToCart= addToCart.replace(/(?:")/g,'\\"');
        savedAmount= savedAmount.replace(/(?:")/g,'\\"');
        couponInputField= couponInputField.replace(/(?:")/g,'\\"');
        couponCodeForm= couponCodeForm.replace(/(?:")/g,'\\"');
        additionalDiscountMsgClass= additionalDiscountMsgClass.replace(/(?:")/g,'\\"');
        qtyPlusMinusClass= qtyPlusMinusClass.replace(/(?:")/g,'\\"');
        qtyInputFieldClass = qtyInputFieldClass.replace(/(?:")/g,'\\"');
        variantsChangeSelectorClass= variantsChangeSelectorClass.replace(/(?:")/g,'\\"');
        qtyMultipler= qtyMultipler.replace(/(?:")/g,'\\"');
        qtyIncPlus= qtyIncPlus.replace(/(?:")/g,'\\"');
        qtyIncMinus= qtyIncMinus.replace(/(?:")/g,'\\"');
        qtyWrapper= qtyWrapper.replace(/(?:")/g,'\\"');
        shopifyPaymentButton = shopifyPaymentButton.replace(/(?:")/g,'\\"');
        buyNowClass = buyNowClass.replace(/(?:")/g,'\\"');

        var data = {
            "shop": this.props.shop,
            "selectors": {
                wholesalePriceClass,
                waioProductPriceClass, 
                waioProductDataPriceAttr, 
                waioProductDataIdAttr, 
                dataItemPriceAttr,
                dataItemIdAttr,
                quickViewDataAttr,
                wholesaleDataKeyAttr,
                waioSingleDataKeyAttr,
                cartSubtotalPriceAttr,
                checkout,
                addToCart,
                savedAmount,
                couponInputField,
                couponCodeForm,
                additionalDiscountMsgClass,
                qtyPlusMinusClass,
                qtyInputFieldClass, 
                variantsChangeSelectorClass,
                qtyMultipler,
                qtyIncPlus,
                qtyIncMinus,
                qtyWrapper,
                shopifyPaymentButton,
                buyNowClass
            }
        };
        axios.put(this.props.host_name + '/update-selectors-settings', data)
            .then(response => {
                if (response.data.status === 1) {
                    //this.context.easdk.showFlashNotice(response.data.message);
                } else {
                    //this.context.easdk.showFlashNotice(response.data.message, { error: true });
                }
            }).catch(error => {
                
            });
    }

    resetToDefault = () => {
        this.setState({
            wholesalePriceClass : '.wholesale-price', 
            waioProductPriceClass: '.waio-product-template',
            waioProductDataPriceAttr: 'waio-product-price',
            waioProductDataIdAttr: 'waio-product-id',
            dataItemPriceAttr: 'data-price', 
            dataItemIdAttr: 'data-item-id',
            quickViewDataAttr: 'quick-view-data-id', 
            wholesaleDataKeyAttr: 'waio-item-total-id',
            waioSingleDataKeyAttr: 'waio-item-single-id',
            cartSubtotalPriceAttr: 'waio-subtotal',
            checkout: '[name*=checkout]', 
            addToCart: "form[action='/cart/add']",
            savedAmount: ".waio-saved-amount-msg", 
            couponInputField: '.waio-coupon-input-field', 
            couponCodeForm: '.waio-coupon-code-form',
            additionalDiscountMsgClass: '.waio-additional-discount-msg', 
            qtyPlusMinusClass: '.ajaxcart__qty-adjust',
            qtyInputFieldClass: '.ajaxcart__qty-num', 
            variantsChangeSelectorClass: '.single-option-selector', 
            qtyMultipler: '[data-quantity-select], [name="quantity"]',
            qtyIncPlus: '.btn-plus span.up-qty',
            qtyIncMinus: '.btn-plus span.down-qty',
            qtyWrapper: '[data-product-form]',
            shopifyPaymentButton: '.shopify-payment-button',
            buyNowClass: '.shopify-payment-button__waio',
        });
    }

    render() {
        const {
            wholesalePriceClass,
            waioProductPriceClass,
            waioProductDataPriceAttr,
            waioProductDataIdAttr,
            dataItemPriceAttr,
            quickViewDataAttr,
            wholesaleDataKeyAttr,
            cartSubtotalPriceAttr,
            checkout,
            addToCart,
            savedAmount,
            couponInputField,
            couponCodeForm,
            additionalDiscountMsgClass,
            qtyPlusMinusClass,
            qtyInputFieldClass,
            variantsChangeSelectorClass,
            dataItemIdAttr,
            waioSingleDataKeyAttr,
            qtyMultipler,
            qtyIncPlus,
            qtyIncMinus,
            qtyWrapper,
            shopifyPaymentButton,
            buyNowClass,
        } = this.state;

        const cart_template_section = <Card.Section title="Cart template" fullWidth>
            <FormLayout.Group fullWidth>
                <TextField
                    label="Cart item total price id"
                    value={wholesaleDataKeyAttr}
                    onChange={v => { this.setState({ wholesaleDataKeyAttr: v }) }}
                    error={wholesaleDataKeyAttr === "" ? "This field can't be blank" : false}
                    helpText="Wholesale total price and cart total price. Expected: wholesale-data-key"
                />
                <TextField
                    label="Cart item single price id"
                    value={waioSingleDataKeyAttr}
                    onChange={v => { this.setState({ waioSingleDataKeyAttr: v }) }}
                    error={waioSingleDataKeyAttr === "" ? "This field can't be blank" : false}
                    helpText="Cart item total price id attribute. Expected: waio-item-single-id"
                />
                <TextField
                    label="Cart subtotal"
                    value={cartSubtotalPriceAttr}
                    onChange={v => { this.setState({ cartSubtotalPriceAttr: v }) }}
                    error={cartSubtotalPriceAttr === "" ? "This field can't be blank" : false}
                    helpText="Cart subtotal attribute. Expected: data-subtotal"
                />
                <TextField
                    label="Checkout button"
                    value={checkout}
                    onChange={v => { this.setState({ checkout: v }) }}
                    error={checkout === "" ? "This field can't be blank" : false}
                    helpText="Checkout button attribute or class. Expected: [name*=checkout]"
                />
                <TextField
                    label="Saved amount"
                    value={savedAmount}
                    onChange={v => { this.setState({ savedAmount: v }) }}
                    error={savedAmount === "" ? "This field can't be blank" : false}
                    helpText="Saved amount selector class. Expected: .wholesale-saved-amount-msg"
                />
                <TextField
                    label="Coupon code field class"
                    value={couponInputField}
                    onChange={v => { this.setState({ couponInputField: v }) }}
                    error={couponInputField === "" ? "This field can't be blank" : false}
                    helpText="Additional coupon code field class. Expected: coupon_input_field"
                />
                <TextField
                    label="Coupon code form selector"
                    value={couponCodeForm}
                    onChange={v => { this.setState({ couponCodeForm: v }) }}
                    error={couponCodeForm === "" ? "This field can't be blank" : false}
                    helpText="Coupon code form selector. Expected: .coupon_code_form"
                />
                <TextField
                    label="Additional discout message class"
                    value={additionalDiscountMsgClass}
                    onChange={v => { this.setState({ additionalDiscountMsgClass: v }) }}
                    error={additionalDiscountMsgClass === "" ? "This field can't be blank" : false}
                    helpText="Success or failed message will be displayed under this attribute. Expected: .additional_discount_msg"
                />
                <TextField
                    label="Quantity input class"
                    value={qtyInputFieldClass}
                    onChange={v => { this.setState({ qtyInputFieldClass: v }) }}
                    error={qtyInputFieldClass === "" ? "This field can't be blank" : false}
                    helpText="Cart item qty input class. Expected: .ajaxcart__qty-num"
                />
                <TextField
                    label="Quantity change (plus, minus)"
                    value={qtyPlusMinusClass}
                    onChange={v => { this.setState({ qtyPlusMinusClass: v }) }}
                    error={qtyPlusMinusClass === "" ? "This field can't be blank" : false}
                    helpText="Cart item qty increasing or descreasing button (plus, minus)"
                />
            </FormLayout.Group>
        </Card.Section>;
        const product_template_section = <Card.Section title="Product template" fullWidth>
            <FormLayout.Group fullWidth>
                <TextField
                    label="Wholesale price class"
                    value={waioProductPriceClass}
                    onChange={v => { this.setState({ waioProductPriceClass: v }) }}
                    error={waioProductPriceClass === "" ? "This field can't be blank" : false}
                    helpText="Product price selector, usually on product templated and quick view. Expected value: .waio-product-template"
                />
                <TextField
                    label="Product price attribute"
                    value={waioProductDataPriceAttr}
                    onChange={v => { this.setState({ waioProductDataPriceAttr: v }) }}
                    error={waioProductDataPriceAttr === "" ? "This field can't be blank" : false}
                    helpText="Product price selector attribute (product template), e.g waio-product-price"
                />
                <TextField
                    label="Data id attribute"
                    value={waioProductDataIdAttr}
                    onChange={v => { this.setState({ waioProductDataIdAttr: v }) }}
                    error={waioProductDataIdAttr === "" ? "This field can't be blank" : false}
                    helpText="Product id selector attribute (product template), e.g waio-product-id"
                />
                <TextField
                    label="Variant change selector"
                    value={variantsChangeSelectorClass}
                    onChange={v => { this.setState({ variantsChangeSelectorClass: v }) }}
                    error={variantsChangeSelectorClass === "" ? "This field can't be blank" : false}
                    helpText="Variant selector class, when it changes"
                />
            </FormLayout.Group>
        </Card.Section>;
        const HCSRP_template_section = <Card.Section title="Others" fullWidth>
            <FormLayout.Group fullWidth>
                <TextField
                    label="Add to cart button's attribute or class"
                    value={addToCart}
                    onChange={v => { this.setState({ addToCart: v }) }}
                    error={addToCart === "" ? "This field can't be blank" : false}
                    helpText="Add to cart button selector e.g .addToCart"
                />
                <TextField
                    label="Wholesale price class"
                    value={wholesalePriceClass}
                    onChange={v => { this.setState({ wholesalePriceClass: v }) }}
                    error={wholesalePriceClass === "" ? "This field can't be blank" : false}
                    helpText="Add this to home, collection, search and related products templates e.g .wholesale-price"
                />
                <TextField
                    label="Data item id attribute"
                    value={dataItemIdAttr}
                    onChange={v => { this.setState({ dataItemIdAttr: v }) }}
                    error={dataItemIdAttr === "" ? "This field can't be blank" : false}
                    helpText="We will use this id to target the product price. Expected: data-price"
                />
                <TextField
                    label="Data item price attribute"
                    value={dataItemPriceAttr}
                    onChange={v => { this.setState({ dataItemPriceAttr: v }) }}
                    error={dataItemPriceAttr === "" ? "This field can't be blank" : false}
                    helpText="We will use this price to show product price and wholesale price. Expected: data-price"
                />
                <TextField
                    label="Quick view data attribute"
                    value={quickViewDataAttr}
                    onChange={v => { this.setState({ quickViewDataAttr: v }) }}
                    error={quickViewDataAttr === "" ? "This field can't be blank" : false}
                />
                <TextField
                    label="Qty input field class"
                    value={qtyMultipler}
                    onChange={v => { this.setState({ qtyMultipler: v }) }}
                    error={qtyMultipler === "" ? "This field can't be blank" : false}
                    helpText='Quantity selector, i.e. [name="quantity"]'
                />
                <TextField
                    label="Qty btn class (plus)"
                    value={qtyIncPlus}
                    onChange={v => { this.setState({ qtyIncPlus: v }) }}
                    error={qtyIncPlus === "" ? "This field can't be blank" : false}
                    helpText="Plus button from which quantity is increasing"
                />
                <TextField
                    label="Qty btn class (minus)"
                    value={qtyIncMinus}
                    onChange={v => { this.setState({ qtyIncMinus: v }) }}
                    error={qtyIncMinus === "" ? "This field can't be blank" : false}
                    helpText="Minus button from which quantity is decreasing"
                />
                <TextField
                    label="Qty input field class"
                    value={qtyWrapper}
                    onChange={v => { this.setState({ qtyWrapper: v }) }}
                    error={qtyWrapper === "" ? "This field can't be blank" : false}
                    helpText="Where you want to show error message if quantity is not valid"
                />
            </FormLayout.Group>
        </Card.Section>;
        const butnow_section = <Card.Section title="Buy now button" fullWidth>
            <FormLayout.Group fullWidth>
                <TextField
                    label="Shopify buy it now class"
                    value={buyNowClass}
                    onChange={v => { this.setState({ buyNowClass: v }) }}
                    error={buyNowClass === "" ? "This field can't be blank" : false}
                    helpText="Put the buy it now button's class. Expected: .shopify-payment-button__waio"
                />
                <TextField
                    label="Shopify payment button class"
                    value={shopifyPaymentButton}
                    onChange={v => { this.setState({ shopifyPaymentButton: v }) }}
                    error={shopifyPaymentButton === "" ? "This field can't be blank" : false}
                    helpText="This class is the wrapper of buy it now button. Expected: .shopify-payment-button"
                />
            </FormLayout.Group>
        </Card.Section>;
        const selectorsConfigurations = <div>
            <Card sectioned title="Selectors Configurations"
                actions={[
                    {
                        content: "Save configurations",
                        onAction: () => { this.saveSelectors(); }
                    },
                    {
                        content: "Reset to default",
                        onAction: () => { this.resetToDefault(); }
                    }
                ]}
            >
                {cart_template_section}
                {product_template_section}
                {HCSRP_template_section}
                {butnow_section}
            </Card>
        </div>;

        const page_actions = <PageActions
            secondaryActions={{
                content: 'Save configurations',
                onAction: () => { this.saveSelectors(); }
            }}
        ></PageActions>;

        return (
            <div>
                {selectorsConfigurations}
                {page_actions}
            </div>
        );
    }
}
