import React from 'react';
import { Card,Layout,ResourceItem,TextStyle,ResourceList,Stack,Icon,Heading,TextField,Button,Link } from '@shopify/polaris';
import axios from 'axios';
import CustomersFAQ from '../2020-04/CustomersFAQ';
import PageTitleBar from '../../../Common/TitleBar';
import ToastMessage from '../../../Common/ToastMessage';
import WholesaleForms from '../2020-07/WholesaleForms';
import CustomerList from '../2020-07/CustomersList';

export default class ManageCustomerDashboard extends React.Component{
    constructor(props){
        super(props);
        this.state = {
          selected_id: this.props.match.params.id || 'registration_form',
          showToast: false,
          toastMsg: '',
          toastError: false,
          pending_customer_count: 0,
          rejected_customer_count: 0
      }
      axios.defaults.headers.common['X-Ws-Shop-Domain'] = "https://"+this.props.shop;
    }

    componentDidMount(){
      this.getCount("pending");
      this.getCount("rejected");
  }

    getCount(status){
        axios.get(this.props.host_name + '/customer/api/335/admin/count?shop='+this.props.shop+"&status="+status).then(response => {
            if(response && response.data && typeof response.data.count !== "undefined"){
                if(status === "pending"){
                    this.setState({pending_customer_count: response.data.count});
                }
                else if(status === "rejected"){
                    this.setState({rejected_customer_count: response.data.count});
                }
            }
        }).catch(e => {this.flashMessage("Server error",true)});
    }

    showToastMessage(msg, error){
        this.setState({
            showToast: true,
            toastMsg: msg,
            toastError: error||false,
        });
    }

    flashMessage(msg,isError) {
        this.showToastMessage(msg,isError);
    }

    dissmissToast = () =>{
        this.setState({
            showToast: false,
        });
    }

    redirectShopifyCustomerList(query){
        window.open("https://"+this.props.shop+"/admin/customers"+query)
    }

    render(){
      const form_html = <Card.Section title={<Heading>Embed Customers Registration form to your website</Heading>}>
            <Stack>
                <Stack.Item fill>
                    <TextField
                        label="Embed code"
                        labelHidden
                        value={`<div class="wholesale-all-in-one-registration-form"></div>`}
                        // helpText="Copy the HTML code from the bellow box and paste the code into your page or website HTML."
                        id="form-embed-code"
                    />
                </Stack.Item>
                <Stack.Item>
                    <Button
                        onClick={() => {
                            var copyText = document.getElementById("form-embed-code");
                            copyText.select();
                            document.execCommand("copy");
                            this.flashMessage("Copied to clipboard", false);
                        }}
                    >Copy</Button>
                </Stack.Item>
            </Stack>
            <div style={{marginTop: "1rem"}}>
                <Link url="https://support.digitalcoo.com/hc/en-us/articles/360035051292" external>CLICK HERE</Link> to read tutorial
            </div>
        </Card.Section>;
        const pending_content = "Pending Approval ("+this.state.pending_customer_count+")";
        const rejected_content = "Rejected Customers ("+this.state.rejected_customer_count+")";
        const tabs = [
        {
            id: 'registration_form',
            content: 'Embeded Registration Form',
            url:'#',
        },
        {
            id: 'registration_settings',
            content: 'Registration Settings',
            url:'#',
        },
        {
            id: 'registration_options',
            content: 'Registration Form Options',
            url:'#',
        },
        {
            id: 'email_template',
            content: 'Email Templates',
            url:'#',
        },
        {
            id: 'wholesale_approval',
            content: pending_content,
            url:'#',
        },
        {
            id: 'rejected_customers',
            content: rejected_content,
            url:'#',
        },
        {
            id: 'approved_customer',
            content: 'Approved Customers',
            url:'#',
        },
        {
            id: 'faq',
            content: 'Quick Answers',
            url:'#',
        }
        ]; 
        var toast = this.state.showToast? (
            <ToastMessage
                onDismiss={this.dissmissToast}
                message={this.state.toastMsg}
                error={this.state.toastError}
            />
            ): (null);
        return(
            <div className="admin_area_waio">
              {toast}
              <PageTitleBar title="Manage Customers" />
                <div>
                    <Layout>
                        <Layout.Section>
                            <Card>
                                <Card.Header
                                  title="Settings"
                                />
                                <div className="admin_panel_settings">
                                    <div className="admin_leftSide wsaio-leftadmin-manage-customer2021">
                                        <Card.Section>
                                          <ResourceList
                                              items={tabs}
                                              renderItem={(item) => {
                                              const {id, content,url,icon} = item;
                                              return (
                                                  <div className={"grid_items customers-list-tabs-2021-02"}>
                                                      <ResourceItem
                                                      id={(this.state.selected_id===id ? "selected_tab":id)}
                                                      onClick={()=>{
                                                          this.setState({selected_id:id});
                                                      }}
                                                      >
                                                      <TextStyle variation="strong">{content}</TextStyle>
                                                      </ResourceItem>
                                                  </div>
                                              );
                                              }}
                                          />
                                        </Card.Section>
                                    </div>
                                    <div id="admin_righSide">
                                      <Card.Section>
                                        {
                                            this.state.selected_id === "registration_form" &&
                                            <div className="grid_tabs ws-forms-2021">
                                               {form_html}
                                            </div>
                                        }
                                        {this.state.selected_id === "registration_settings" &&
                                           <div className="grid_tabs ws-forms-2021">
                                               <WholesaleForms section={0} current_shop_info={this.props.current_shop_info} snippet_version={this.props.snippet_version} shop_payment={this.props.shop_payment} shop_features={this.state.shop_features} shop={this.props.shop} host_name={this.props.host_name} user_current_plan={this.props.user_current_plan} total_products={this.props.total_products} help={this.props.help}/>
                                           </div>
                                        }
                                        {this.state.selected_id === "registration_options" &&
                                           <div className="grid_tabs ws-forms-2021">
                                               <WholesaleForms section={6} current_shop_info={this.props.current_shop_info} snippet_version={this.props.snippet_version} shop_payment={this.props.shop_payment} shop_features={this.state.shop_features} shop={this.props.shop} host_name={this.props.host_name} user_current_plan={this.props.user_current_plan} total_products={this.props.total_products} help={this.props.help}/>
                                           </div>
                                        }
                                        {this.state.selected_id === "email_template" &&
                                           <div className="grid_tabs ws-forms-2021">
                                               <WholesaleForms section={1} current_shop_info={this.props.current_shop_info} snippet_version={this.props.snippet_version} shop_payment={this.props.shop_payment} shop_features={this.state.shop_features} shop={this.props.shop} host_name={this.props.host_name} user_current_plan={this.props.user_current_plan} total_products={this.props.total_products} help={this.props.help}/>
                                           </div>
                                        }
                                        {this.state.selected_id === "wholesale_approval" && 
                                            <div className="grid_tabs ws-forms-2021">
                                                <CustomerList selected_tab={0} status="pending" current_shop_info={this.props.current_shop_info} snippet_version={this.props.snippet_version} shop_payment={this.props.shop_payment} shop_features={this.props.shop_features} shop={this.props.shop} host_name={this.props.host_name} user_current_plan={this.props.user_current_plan} total_products={this.props.total_products} help={this.props.help} />
                                            </div>
                                        }
                                        {(this.state.selected_id === "approved_customer" || this.state.selected_id
                                        === "approve_active_inactive_customers" || this.state.selected_id === "approved_active"
                                        || this.state.selected_id === "approved_inactive" ) && 
                                            <div className={"grid_tabs ws-forms-2021 "+(this.state.selected_id)}>
                                            
                                            <div className={"Polaris-Card__Section approved_customer_grid"} id={this.state.selected_id === "approve_active_inactive_customers" && "selected_grid"}>
                                                <Stack>
                                                    <Stack.Item fill>
                                                        <p><b>Approved Customers [Active & Inactive]</b></p>
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        <Button
                                                        onClick={() => {
                                                            this.redirectShopifyCustomerList("?tag=VerifiedByWholesaleAllInOne");
                                                        }}
                                                        primary
                                                        size="slim">View</Button>
                                                    </Stack.Item>
                                                </Stack>
                                                <p>List of all customers approved via Wholesale All In One application.</p>
                                            </div>
                                            
                                            <div className={"Polaris-Card__Section approved_customer_grid"} id={this.state.selected_id === "approved_active" && "selected_grid"}>
                                                <Stack>
                                                    <Stack.Item fill>
                                                        <p><b>Approved Customers [Active only]</b></p>
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        <Button
                                                        onClick={() => {
                                                            this.redirectShopifyCustomerList("?state=enabled&tag=VerifiedByWholesaleAllInOne");
                                                        }}
                                                        primary size="slim">View</Button>
                                                    </Stack.Item>
                                                </Stack>
                                                <p>List of all customers approved via Wholesale All In One application and 
                                                    all customer in this list have activated their accounts.</p>
                                            </div>
                                            
                                            <div className={"Polaris-Card__Section approved_customer_grid"} id={this.state.selected_id === "approved_inactive" && "selected_grid"}>
                                                <Stack>
                                                    <Stack.Item fill>
                                                        <p><b>Approved Customers [Inactive only]</b></p>
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        <Button
                                                        onClick={() => {
                                                            this.redirectShopifyCustomerList("?state=disabled&tag=VerifiedByWholesaleAllInOne");
                                                        }}
                                                        primary size="slim">View</Button>
                                                    </Stack.Item>
                                                </Stack>
                                                <p>List of all customers approved via Wholesale All In One application.<br />
                                                 These customers received the account approval email from store owner but haven't activated their account yet.</p>
                                            </div>
                                       </div>
                                        }
                                        {this.state.selected_id === "rejected_customers" && 
                                            <div className="grid_tabs ws-forms-2021">
                                                <CustomerList selected_tab={1} status="rejected" current_shop_info={this.props.current_shop_info} snippet_version={this.props.snippet_version} shop_payment={this.props.shop_payment} shop_features={this.props.shop_features} shop={this.props.shop} host_name={this.props.host_name} user_current_plan={this.props.user_current_plan} total_products={this.props.total_products} help={this.props.help} />
                                            </div>
                                        }
                                        {this.state.selected_id === "faq" && 
                                          <div className="grid_tabs ws-forms-2021">
                                              <CustomersFAQ />
                                          </div>
                                        }
                                      </Card.Section>
                                    </div>
                                </div>
                            </Card>
                        </Layout.Section>
                    </Layout>
                </div>
            </div>
        );
    }
}