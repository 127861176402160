import React, { Component } from "react";
import { FooterHelp,Button } from "@shopify/polaris";

export default class FooterHelpLinks extends Component {
  render() {
    return (
        <div>
            <div className="clearfix"></div>
            <FooterHelp>
            How to Apply Discounts? Read <Button plain={true} external={true} url={this.props.help.link2}>User Guide</Button>
            </FooterHelp>
        </div>
    );
  }
}
