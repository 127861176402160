import React, { Component } from 'react';
import axios from 'axios';
import {Popover,PageActions,DatePicker,Banner,Checkbox,Stack,Layout,FormLayout,TextField,Select,Card,Button,RadioButton,ButtonGroup,List, TextStyle,Tag, Badge, TextContainer, Thumbnail, ActionList, DataTable, Icon, Collapsible, ResourceList, ResourceItem ,Tabs, Link, Heading } from '@shopify/polaris';
import store from 'store2';
import waio from '../../../Helpers/functions';
import SkeletonPageComp from '../../SkeletonPageComp';
import ResourcePicker from '../../../Common/ResourcePicker';
import PageTitleBar from '../../../Common/TitleBar';
import ToastMessage from '../../../Common/ToastMessage';
import {Modal} from '@shopify/app-bridge-react';
import {DeleteMinor, SearchMinor, CaretDownMinor, CaretUpMinor} from '@shopify/polaris-icons';

var temp_headings = ["Actions", "Variant Pricing", "The product title and images", "Price"];
var _this = null;
export default class CreateRegularDiscount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            collectionsListShow: true,
            productsListShow: true,
            product_modal_open: false,
            collection_modal_open: false,
            temp__customer_tag: "",
            tableHeadingArray: temp_headings,
            tags: [],
            tags_excluded: [],
            products: [],
            collections: [],
            volume_discount: [{ qty: '1', value: '1' }],
            extended_view: false,
            applies_to: "entire_store",
            customer_group: "has_tag",
            individual_product: false,
            discount_type: "percentage",
            discount_value: "0",
            title: "",
            state: "published",
            schedule_active: false,
            schedule_end_date: false,
            start_month: new Date().getMonth(),
            start_year: new Date().getFullYear(),
            end_month: new Date().getMonth(),
            end_year: new Date().getFullYear(),
            start_date_selected: new Date(),
            end_date_selected: new Date(),
            start_date_popup: false,
            start_date: new Date().toISOString().split('T')[0],
            end_date_popup: false,
            end_date: new Date().toISOString().split('T')[0],
            start_time: '00:00:00',
            end_time: '23:30:00',
            end_date_time_error: false,
            scheduleExpanded: false,
            displayOptionExpanded: false,
            display_option: "detailed_grid",
            errorList: [],
            save_btn_loading: false,
            page_loaded: typeof this.props.match.params.id !== "undefined"? false : true,
            exclude_collections: [],
            exclude_products: [],
            exclude_collections_checkbox: false,
            exclude_products_checkbox: false,
            exclude_collections_modal_open: false,
            exclude_products_modal_open: false,
            update_ex_collections__modal_open: false,
            update_ex_products__modal_open: false,
            load_more_products: 20,
            search_products_text: "",
            showToast: false,
            toastError: false,
            toastMsg: ""
        }
        axios.defaults.headers.common['X-Ws-Shop-Domain'] = "https://"+this.props.shop;
    }

    componentDidMount() {
        _this = this;
        if(typeof this.props.match.params.id !== "undefined"){
            this.getForEdit();
        }
        document.addEventListener('keydown',this.keydownHandler);
    }

    componentWillUnmount(){
        document.removeEventListener('keydown',this.keydownHandler);
    }

    keydownHandler(event){
        if (((event.key === "s"||event.key === "S") || event.keyCode === 83) && (event.metaKey || event.ctrlKey)) {
            event.preventDefault();
            try {
                _this.saveRegularDiscount();
            } catch (error) {}
        }
    }

    getForEdit(){
        axios.get(this.props.host_name + '/app/regular-discount?shop='+this.props.shop+'&id=' + this.props.match.params.id)
        .then(response => {
            if(response.data){
                let data = response.data;
                let tableHeadingArray = this.state.tableHeadingArray;
                if(data.tags && data.tags.length > 0){
                    tableHeadingArray = tableHeadingArray.concat(data.tags); 
                }
                var products_arr = data.products || this.state.products;
                var $that = this;
                if(products_arr && products_arr.length){
                    try {
                        products_arr = JSON.parse(JSON.stringify(products_arr)).map(function(product){
                            if(product){
                                try {
                                    product.uid = $that.generate_unique_value(7);
                                } catch (error) {}
                            }
                            return product;
                        });
                    } catch (error) {}
                }
                this.setState(
                    {
                        "tableHeadingArray" : tableHeadingArray,
                        "end_date" : data.schedule.end_date ? (data.schedule.end_date).split('T')[0] : data.schedule.start_date ? this.get_est_date_only(data.schedule.start_date) : new Date().toISOString().split('T')[0],
                        "start_date" : data.schedule.start_date ? this.get_est_date_only(data.schedule.start_date) : new Date().toISOString().split('T')[0],
                        "start_time" : data.schedule.start_time_est ? data.schedule.start_time_est : this.state.start_time,
                        "end_time" : data.schedule.end_time_est ? data.schedule.end_time_est : this.state.end_time,
                        "schedule_end_date" : data.schedule.end_date ? true : false,
                        "tags" : data.tags || this.state.tags,
                        "tags_excluded" : data.tags_excluded || this.state.tags_excluded,
                        "products" : products_arr || this.state.products,
                        "collections" : data.collections || this.state.collections,
                        "volume_discount" : data.volume_discount || this.state.volume_discount,
                        "applies_to" : data.applies_to || this.state.applies_to,
                        "customer_group" : data.customer_group || this.state.customer_group,
                        "individual_product" : data.individual_product || this.state.individual_product,
                        "discount_type" : data.discount_type || this.state.discount_type,
                        "discount_value" : data.discount_value || this.state.discount_value,
                        "title" : data.title || this.state.title,
                        "state" : data.state || this.state.state,
                        "schedule_active" : data.schedule_active || this.state.schedule_active,
                        "display_option" : data.display_option || this.state.display_option,
                        "page_loaded": true,
                        "exclude_collections" : data.exclude_collections || this.state.exclude_collections,
                        "exclude_collections_checkbox" : data.exclude_collections ? data.exclude_collections.length > 0 ? true : false : false,
                        "exclude_products" : data.exclude_products || this.state.exclude_products,
                        "exclude_products_checkbox" : data.exclude_products ? data.exclude_products.length > 0 ? true : false : false,
                    }
                )
            }
            else{
                this.toast("Could not load data. Try again", true)
            }
        })
        .catch(error => {
            this.setState({page_loaded: true});
        });
    }

    renderExcludedTags(){
        let tags_excluded = this.state.tags_excluded;
        return tags_excluded.map((tag,j) => {
            return <Tag key={j}
                onRemove={() => {
                    var tags_excluded = [...this.state.tags_excluded];
                    tags_excluded.splice(j,1);
                    this.setState({tags_excluded});
                }}
            >{tag}</Tag>
        })
    }

    renderIncludedTags(){
        let tags = this.state.tags;
        return tags.map((tag,j) => {
            return <Tag key={j}
                onRemove={() => {
                    var tags = [...this.state.tags];
                    tags.splice(j,1);
                    var products = this.state.products;
                    let tableHeadingArray = this.state.tableHeadingArray;
                    tableHeadingArray = tableHeadingArray.filter(x=>x !== tag);
                    for (let x = 0; x < products.length; x++) {
                        products[x].tag_options = products[x].tag_options.filter(x=>x.name.toLowerCase() !== tag.toLowerCase());
                        for (let y = 0; y < products[x].variants.length; y++) {
                            try{
                                products[x].variants[y].tag_options = products[x].variants[y].tag_options.filter(x=>x.name.toLowerCase() !== tag.toLowerCase());
                            }catch(e){}
                        }
                    }
                    this.setState({tags,tableHeadingArray,products});
                }}
            >
            {
                tag === "all" ?
                "All Customers"
                :tag === "no_account" ?
                "Guest Customers"
                :tag === "has_account" ?
                "LoggedIn Customers"
                :tag
            }
            </Tag>
        })
    }

    toast(msg,error){
        this.setState({
            showToast: true,
            toastMsg: msg,
            toastError: error
        });
    }
    
    tableContentTypes(){
        let type = [];
        for (let index = 0,len = this.state.tableHeadingArray.length; index < len; index++) {
            type.push("text");
        }
        type.push("text");// for delete button
        return type;
    }

    resizeImage(image, size) {
        if(typeof size === "undefined"){size = "100x100"}
        try {
            size += "_crop_center";
        } catch (e) { }
        var n;
        if (image) {
            if (image.indexOf(".jpg") > -1) {
                n = image.lastIndexOf(".jpg");
                image = image.substring(0, n) + "_" + size + image.substring(n);
            }
            else if (image.indexOf(".png") > -1) {
                n = image.lastIndexOf(".png");
                image = image.substring(0, n) + "_" + size + image.substring(n);
            }
            else if (image.indexOf(".gif") > -1) {
                n = image.lastIndexOf(".gif");
                image = image.substring(0, n) + "_" + size + image.substring(n);
            }
            return image;
        }
        else {
            return image;
        }
    }

    truncate(str,num, showDots){
        var dots = ""; if(str.length > num && showDots){dots='...';}
        return str.slice(0,num) + dots;
    }

    renderTableRows(){
        var row = [];
        var products = this.state.products || [];
        let search_products_text = this.state.search_products_text;
        try {
            if(search_products_text && search_products_text.trim() !== ""){
                products = products.filter(product => {
                    if(
                    product.title.match(new RegExp(search_products_text,"gi")) ||
                    product.handle.match(new RegExp(search_products_text,"gi")) ||
                    -1<product.variants.findIndex(x => x.sku.match(new RegExp(search_products_text,"gi")))
                    ){
                        return product;
                    }
                });
            }
        } catch (error) {}
        for (let index = 0, len = products.length; index < len; index++) {
            let product = products[index];
            if(index >= Number(this.state.load_more_products)) break;
            let column = [];
            column.push(
                <div>
                    <Button
                        size="slim"
                        onClick={() => {
                            let products = [...this.state.products];
                            for (let abc = 0; abc < products.length; abc++) {
                                try {
                                        if(products[abc].copy["of"] === product.handle){
                                        if(Number(products[abc].copy["counts"]) - 1 === 0){
                                            products[abc].duplicated = false;
                                        }
                                        products[abc].copy["counts"] = Number(products[abc].copy["counts"]) - 1;
                                    }
                                } catch (error) {}
                            }
                            products = products.filter(x => x.uid !== product.uid);
                            this.setState({products});
                        }}
                    >
                    <Icon source={DeleteMinor} />
                    </Button>
                </div>
            );
            column.push(
                <div style={{maxWidth: "25px"}}>
                    <Checkbox
                        label="Variant level"
                        labelHidden
                        disabled={product.duplicated}
                        checked={product.variant_level}
                        onChange={(v)=>{
                            if(Number(product.copy["counts"]) > 0){
                                this.toast("Can't apply on duplicated products", true)
                            }
                            else{
                                var products = [...this.state.products];
                                var fInx = products.findIndex(x => x.uid === product.uid);
                                if(fInx>-1){
                                    try{
                                        products[fInx].variant_level = v;
                                    }catch(e){}
                                }
                                this.setState({products});
                            }
                        }}
                    />
                </div>
            );
            column.push(
                <div data-product-handle={product.handle} style={{maxWidth: "200px"}} className={`data-table-item--title list-item product-item-index--${index}`}>
                    {product.image && <img width="35" height="35" style={{marginRight: "8px"}} alt={product.title} src={this.resizeImage(product.image, "35x35")} />} <TextStyle variation="strong">{
                        product.handle?
                        <a href={'https://'+this.props.shop+'/products/'+product.handle} target="new">{this.truncate(product.title, 50, true)}</a>
                        :this.truncate(product.title, 50, true)
                    }</TextStyle> {product.duplicated && <TextStyle variation="subdued"> &nbsp;&nbsp;(Duplicated)</TextStyle>}
                </div>
            );
            column.push(
                <div data-product-handle={product.handle} className={`list-item product-item-index--${index}`}>
                    {product.variant_level===false?product.variants[0]?product.variants[0].price?waio.Currency.format_money(product.variants[0].price):null:null:null}
                </div>
            );
            if(product && product.tag_options && product.tag_options.length){
                for (let loopIndex = 0; loopIndex < product.tag_options.length; loopIndex++) {
                    let tagOpt = product.tag_options[loopIndex];
                    column.push(
                        <div  style={{minWidth: "200px", maxWidth: "200px"}}>
                            {
                                product.variant_level===false && 
                                <TextField
                                    error={
                                        Number(tagOpt.value) < 0? true
                                        :tagOpt.type !== "percentage"?
                                        product.variant_level===false?
                                        product.variants[0]?
                                        product.variants[0].price?
                                        Number(product.variants[0].price) < Number(tagOpt.value)
                                        :null:null:null
                                        :null
                                    }
                                    disabled={product.variant_level}
                                    type="number"
                                    label="Discount"
                                    placeholder="Value"
                                    labelHidden
                                    value={getValue(tagOpt.value)}
                                    onChange={(v)=>{
                                        var products = [...this.state.products];
                                        var fInds = products.findIndex(x => x.uid === product.uid);
                                        if(fInds > -1){
                                            try{
                                                products[fInds].tag_options[loopIndex].value = v;
                                            }catch(e){}
                                        }
                                        this.setState({products});
                                    }}
                                    connectedRight={
                                        <Select
                                            disabled={product.variant_level}
                                            labelHidden
                                            label="Discount Type"
                                            value={tagOpt.type}
                                            options={[
                                                { label: "% Off", value: "percentage" },
                                                { label: "New Price", value: "fixed_price" },
                                                { label: "Price Off", value: "price_discount" }
                                            ]}
                                            onChange={(v)=>{
                                                var products = [...this.state.products];
                                                var fIndsx = products.findIndex(x => x.uid === product.uid);
                                                if(fIndsx>-1){
                                                    try{
                                                        products[fIndsx].tag_options[loopIndex].type = v;
                                                    }catch(e){}
                                                }
                                                this.setState({products});
                                            }}
                                        />
                                    }
                                />
                            }
                        </div>
                    );
                }
            }
            row.push(column);
            if(!product.variant_level){continue}
            // Render variants and hide them
            var collapse = product.variant_level ? "": "variant--collapsed";
            if(product && product.variants && product.variants.length){
                for (let variantLoop = 0, len = product.variants.length; variantLoop < len; variantLoop++) {
                    let variant = product.variants[variantLoop];
                    column = [];
                    column.push( //empty column
                        <div>
                        </div>
                    );
                    column.push(
                        <div className={collapse}>
                            {
                                variant.duplicated === true && 
                                <Button
                                    size="slim"
                                    onClick={() => {
                                        let variants = [...product.variants];
                                        variants.splice(variantLoop, 1);
                                        var products = [...this.state.products];
                                        var fIdsx = products.findIndex(x => x.uid === product.uid);
                                        if(fIdsx > -1){
                                            try {
                                                products[fIdsx].variants = variants;
                                            } catch (error) {}
                                        }
                                        this.setState({products});
                                    }}
                                >
                                    <Icon source={DeleteMinor} />
                                </Button>
                            }
                        </div>
                    );
                    column.push(
                        <div className={collapse +" data-table-item--title"} style={{minWidth: "90px", maxWidth: "150px"}}>
                            {product.image && <img width="35" height="35" style={{marginRight: "8px"}} alt={variant.title} src={this.resizeImage(product.image, "35x35")} />} {variant.title} {variant.duplicated && <TextStyle variation="subdued"> &nbsp;&nbsp;(Duplicated)</TextStyle>}
                        </div>
                    )
                    column.push(
                        <div className={collapse}>
                            {waio.Currency.format_money(variant.price)}
                        </div>
                    );
                    if(variant && variant.tag_options && variant.tag_options.length){
                        for (let variantLoop2 = 0; variantLoop2 < variant.tag_options.length; variantLoop2++) {
                            let tagOpt2 = variant.tag_options[variantLoop2];
                            column.push(
                                <div className={collapse} style={{minWidth: "180px", maxWidth: "200px"}}>
                                    <TextField
                                        error={
                                            Number(tagOpt2.value) < 0? true
                                            :tagOpt2.type !== "percentage"?
                                            Number(tagOpt2.value) > Number(variant.price)
                                            :null
                                        }
                                        type="number"
                                        label="Discount"
                                        placeholder="Value"
                                        labelHidden
                                        value={getValue(tagOpt2.value)}
                                        onChange={(v)=>{
                                            var products = [...this.state.products];
                                            var fIdext = products.findIndex(x => x.uid === product.uid);
                                            if(fIdext>-1){
                                                try{
                                                    products[fIdext].variants[variantLoop].tag_options[variantLoop2].value = v;
                                                }catch(e){}
                                            }
                                            this.setState({products});
                                        }}
                                        connectedRight={
                                            <Select
                                                labelHidden
                                                label="Discount Type"
                                                value={tagOpt2.type}
                                                options={[
                                                    { label: "% Off", value: "percentage" },
                                                    { label: "New Price", value: "fixed_price" },
                                                    { label: "Price Off", value: "price_discount" }
                                                ]}
                                                onChange={(v)=>{
                                                    var products = [...this.state.products];
                                                    var fIdx = products.findIndex(x => x.uid === product.uid);
                                                    if(fIdx>-1){
                                                        try{
                                                            products[fIdx].variants[variantLoop].tag_options[variantLoop2].type = v;
                                                        }catch(e){}
                                                    }
                                                    this.setState({products});
                                                }}
                                            />
                                        }
                                    />
                                </div>
                            );
                        }
                    }
                    row.push(column);
                }
            }
        }
        return row;
    }

    doValidation(){
        let isValid = true;
        let products_array = [];
        let errors = [];
        for (let asd = 0, len = this.state.products.length; asd < len; asd++) {
            let product = this.state.products[asd];
            try{
                let groups = this.state.products.filter(x => x.handle === product.handle);
                groups.forEach(group => {
                    try{
                        if(products_array.findIndex(function(x){ return (x.handle.toString() === group.handle.toString() )}) === -1){
                            products_array.push(group);
                        }
                    }catch(e){
                        products_array.push(group);
                    }
                });
            }catch(e){
                products_array.push(product);
            }
            try{
                if(products_array.findIndex(function(x){ return (x.handle.toString() === product.handle.toString() )}) === -1){
                    products_array.push(product);
                }
            }catch(e){
                products_array.push(product);
            }
        }// end for
        let products = [];
        products_array.forEach((product,index) => {
            product["position"] = index;
            products.push(product);
        });
        
        if (this.state.customer_group === "has_tag") {
            if (this.state.tags.length === 0) {
                isValid=false;
                errors.push("Tag is required for the customer in Step 4: Customer Groups.");
            }
        }
        if(this.state.applies_to === "collections" && this.state.collections.length === 0){
            isValid=false;
            errors.push("Select one or more collections in Step 2: Apply Discount To.");
        }
        if(this.state.applies_to === "products" && this.state.products.length === 0){
            isValid=false;
            errors.push("Select one or more products in Step 2: Apply Discount To.");
        }
        if (this.state.schedule_active && this.state.schedule_end_date) {
            if (this.diff_days(this.state.end_date, this.state.start_date) === 0) {
                if (this.state.start_time === this.state.end_time) {
                    this.setState({ end_date_time_error: true });isValid=false;
                    errors.push("Schedule Start and End date/time can't be same.");
                }
                else if (this.state.start_time > this.state.end_time) {
                    this.setState({ end_date_time_error: true });isValid=false;
                    errors.push("Schedule End date/time must greater than the Start date/time.");
                }
            }
            else if (this.diff_days(this.state.end_date, this.state.start_date) < 0) {
                this.setState({ end_date_time_error: true });isValid=false;
                errors.push("Schedule Start and End date/time can't be same.");
            }
        }

        return {
            errors: errors,
            isValid:isValid,
            products: products
        };
    }

    scrollToTop(){
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    tags_lower_case(tags){
        if(tags){
            try {
                tags = tags.toString().toLowerCase();
                tags = tags.split(",");
            } catch (e) { }
            try {
                tags = tags.filter(x =>  x !== "" && x.trim() !== "");
            } catch (e) { }
        }
        return tags;
    }

    add_excluded_tags(){
        var tags_excluded = [];
        if(this.state.customer_group === "all" || this.state.customer_group === "has_account" && this.state.individual_product === false){
            tags_excluded = this.tags_lower_case(this.state.tags_excluded);
        }
        return tags_excluded;
    }

    getCustomerGroup(){
        var customer_group = this.state.customer_group;
        if(this.state.applies_to === "products" && this.state.individual_product){
            customer_group = "has_tag";
        }
        return customer_group;
    }

    saveRegularDiscount(){
        this.setState({search_products_text: ""});
        let data = {};
        let validationArray = this.doValidation();
        let products = validationArray.products;
        let errorList = validationArray.errors;
        let volume_discount = this.state.volume_discount;
        volume_discount = volume_discount.filter( x => Number(x.qty) > 0);
        volume_discount.sort(function(a,b){
            return Number(a.qty) - Number(b.qty);
        });
        if(validationArray.isValid){
            this.setState({ save_btn_loading: true });
            let start_date_time = this.get_est_dateTime(this.state.start_date, this.state.start_time);
            let start_time = this.state.start_time;
            let end_date_time = this.get_est_dateTime(this.state.end_date, this.state.end_time);
            let end_time = this.state.end_time;
            if (!this.state.schedule_active) {
                start_date_time = null;
                start_time = null;
                end_date_time = null;
                end_time = null;
            }
            if (!this.state.schedule_end_date) {
                end_date_time = null;
                end_time = null;
            }
            var exclude_collections = [];
            var exclude_products = [];
            if(this.state.applies_to === 'entire_store'){
                if(this.state.exclude_collections_checkbox){
                    exclude_collections = this.state.exclude_collections;
                }
                if(this.state.exclude_products_checkbox){
                    exclude_products = this.state.exclude_products;
                }
            }
            else if(this.state.applies_to === 'collections'){
                if(this.state.exclude_products_checkbox){
                    exclude_products = this.state.exclude_products;
                }
            }
            data = {
                individual_product: this.state.applies_to === "products" && this.state.individual_product,
                customer_group: this.getCustomerGroup(),
                tags_excluded: this.add_excluded_tags(),
                discount_type: this.state.discount_type,
                discount_value: this.state.discount_value,
                collections: this.state.collections,
                applies_to: this.state.applies_to,
                volume_discount: volume_discount,
                display_option: this.state.display_option,
                state: this.state.state,
                title: this.state.title,
                shop: this.props.shop,
                tags: this.tags_lower_case(this.state.tags),
                products: products,
                schedule_active: this.state.schedule_active,
                schedule: {
                    start_date: start_date_time,
                    end_date: end_date_time,
                    start_time_est: start_time,
                    end_time_est: end_time
                },
                exclude_collections: exclude_collections,
                exclude_products: exclude_products
            }
            if(this.props.show === "edit" && this.props.match.params.id){
                var id = this.props.match.params.id;
                axios.put(this.props.host_name + '/app/regular-discount?id='+id, data)
                .then(response => {
                    if (response.data.status === 1) {
                        this.toast(response.data.message);
                        this.props.history.push('/regular-discount/list');
                    } else {
                        this.setState({ save_btn_loading: false });
                        this.toast(response.data.message, true);
                        return false;
                    }
                })
                .catch(error => { });
            }
            else{
                axios.post(this.props.host_name + '/app/regular-discount', data)
                .then(response => {
                    if (response.data.status === 1) {
                        this.toast(response.data.message);
                        this.props.history.push('/regular-discount/list');
                    } else {
                        this.setState({ save_btn_loading: false });
                        this.toast(response.data.message, true);
                        return false;
                    }
                })
                .catch(error => { });
            }
        }
        else{
            let msg = `There ${errorList.length > 1?"are":"is"} ${errorList.length } ${errorList.length > 1?"errors":"error"}`;
            this.toast(msg, true);
            this.setState({ errorList : errorList });
            this.scrollToTop();
        }
    }

    renderCollectionRows(){
        let collections = this.state.collections;
        var rows = [];
        collections.forEach((collection,cIndex) => {
            let column = [];
            column.push(
                <Stack spacing="extraLoose">
                    <Stack.Item fill>
                        <Link url={'https://'+this.props.shop+'/collections/'+collection.handle} external={true}>{collection.title}</Link>
                    </Stack.Item>
                    <Stack.Item>
                        <Button
                                size="slim"
                                onClick={() => {
                                    let collections = this.state.collections;
                                    collections.splice(cIndex, 1);
                                    this.setState({collections}) 
                                }}
                            ><Icon source={DeleteMinor} />
                            </Button>
                    </Stack.Item>
                </Stack>
            );
            rows.push(column)
        });
        return rows;
    }

    renderIndividualProductsRows(){
        let products = this.state.products;
        var rows = [];
        products.forEach((product,cIndex) => {
            let column = [];
            column.push(
                <Stack spacing="extraLoose">
                    <Stack.Item fill>
                        <div className="pp-center-items">
                            {
                                product.image && <div style={{marginRight:"4px"}}><Thumbnail size="small" source={this.resizeImage(product.image, "100x100")}></Thumbnail></div>
                            }
                            <div style={{marginLeft:"4px"}}>{
                                product.handle ?
                                <Link url={'https://'+this.props.shop+'/products/'+product.handle} external={true}>{product.title}</Link>
                                : product.title
                            }</div>
                        </div>
                    </Stack.Item>
                    <Stack.Item>
                        <Button
                            size="slim"
                            onClick={() => {
                                let products = this.state.products;
                                products.splice(cIndex, 1);
                                this.setState({products}) 
                            }}
                        >
                            <Icon source={DeleteMinor} />
                        </Button>
                    </Stack.Item>
                </Stack>
            );
            rows.push(column)
        });
        return rows; 
    }

    getTimezoneOffset() {
        function z(n) { return (n < 10 ? '0' : '') + n }
        var offset = new Date().getTimezoneOffset();
        var sign = offset < 0 ? '+' : '-';
        offset = Math.abs(offset);
        return sign + z(offset / 60 | 0) + z(offset % 60);
    }

    diff_days(new_date, old_date) {
        var t1 = new Date(new_date);
        var t2 = new Date(old_date);
        let result = parseInt((t1 - t2) / (24 * 3600 * 1000), 10);
        if (isNaN(result)) result = 0;
        return result;
    }

    get_est_dateTime(date, time) {
        let dateTimeValue = new Date(date);
        if (typeof dateTimeValue === 'object') {
            dateTimeValue = dateTimeValue.toString();
            if (typeof dateTimeValue === 'string') {
                dateTimeValue = dateTimeValue.replace(this.getTimezoneOffset(), '+0000');
            }
        }
        dateTimeValue = new Date(dateTimeValue).toISOString();
        dateTimeValue = dateTimeValue.split('T')[0] + " " + time;
        return dateTimeValue;
    }

    get_est_date_only(date) {
        let date_only = new Date(date);
        if (typeof date_only === 'object') {
            date_only = date_only.toString();
            if (typeof date_only === 'string') {
                date_only = date_only.replace(this.getTimezoneOffset(), '+0000');
            }
        }
        date_only = new Date(date_only).toISOString();
        date_only = date_only.split('T')[0];
        return date_only;
    }

    change_customer_by_checkout(v){
        var tags = this.state.tags;
        var id = this.state.customer_group;
        var products = this.state.products;
        if(v){
            var temp__customer_tag = id;
            try{
                temp__customer_tag = temp__customer_tag.replace(/,/gi, "");
            }catch(e){}
            if(temp__customer_tag.trim() === "") return;
            try{
                if(-1===tags.findIndex(x=>x.trim().toLowerCase() === temp__customer_tag.trim().toLowerCase())){
                    tags.push(temp__customer_tag.trim());
                    for (let x = 0; x < products.length; x++) {
                        try{
                            if(products[x].tag_options.findIndex(x=>x.name.toLowerCase() === temp__customer_tag.trim().toLowerCase()) === -1){
                                if(typeof products[x].tag_options === "undefined"){products[x].tag_options = [];}
                                products[x].tag_options.push({
                                    name: temp__customer_tag.trim().toLowerCase(),
                                    type: "percentage",
                                    value: ""
                                });
                            }
                        }catch(e){}
                        for (let xyz = 0; xyz < products[x].variants.length; xyz++) {
                            try{
                                if(products[x].variants[xyz].tag_options.findIndex(x=>x.name.toLowerCase() === temp__customer_tag.trim().toLowerCase()) === -1){
                                    if(typeof products[x].variants[xyz].tag_options === "undefined"){products[x].variants[xyz].tag_options = [];}
                                    products[x].variants[xyz].tag_options.push({
                                        name: temp__customer_tag.trim().toLowerCase(),
                                        type: "percentage",
                                        value: ""
                                    });
                                }
                            }catch(e){}
                        }
                    }
                }
                else{
                    this.toast("Tag already exists", true)
                }
            }catch(e){}
            let tableHeadingArray = temp_headings;
            tableHeadingArray = tableHeadingArray.concat(tags);
            this.setState({tags, products, tableHeadingArray});
        }
        else{
            var tag = id;
            tags = tags.filter(x=>x !== tag);
            let tableHeadingArray = this.state.tableHeadingArray;
            tableHeadingArray = tableHeadingArray.filter(x=>x !== tag);
            for (let x = 0; x < products.length; x++) {
                products[x].tag_options = products[x].tag_options.filter(x=>x.name.toLowerCase() !== tag.toLowerCase());
                for (let y = 0; y < products[x].variants.length; y++) {
                    try{
                        products[x].variants[y].tag_options = products[x].variants[y].tag_options.filter(x=>x.name.toLowerCase() !== tag.toLowerCase());
                    }catch(e){}
                }
            }
            this.setState({tags,tableHeadingArray,products});
        }
    }

    handleSubmitUpdateExcCollections = (selected) => {
        this.setState({
            exclude_collections: store('excludedCollections') || [],
            update_ex_collections__modal_open: false
        })
    }

    handleSubmitUpdateExcProducts = (selected) => {
        this.setState({
            exclude_products: store('excludedProducts') || [],
            update_ex_products__modal_open: false
        })
    }

    renderLoadMoreProductsBtns = function(){
        var btns = <ButtonGroup>
            <Button
                onClick={()=>{
                    var load_more_products = this.state.load_more_products || 20;
                    load_more_products = Number(load_more_products)+10;
                    this.setState({load_more_products});
                }}
            >
               Load More Products
           </Button>
        </ButtonGroup>;
        if(Number(this.state.load_more_products) >= Number(this.state.products.length)){
            btns = null;
        }
        if(this.state.search_products_text && this.state.search_products_text.trim() !== ""){
            btns = null;
        }
        return btns;
    }

    searchProductField(){
        return <TextField
            value={this.state.search_products_text}
            label="Search products"
            labelHidden={true}
            placeholder="Search products by title, handle or SKU"
            onChange={(v) => {
                this.setState({search_products_text: v});
            }}
            prefix={<Icon source={SearchMinor}/>}
        ></TextField>
    }

    generate_unique_value(length) {
        var text = "";var result = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for(var i = 0; i < (length || 6); i++) {
            try {
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            } catch (error) {}
        }
        for (var j=0; j < text.length; j++) {
            try {
                result += text.charCodeAt(j).toString(16);
            } catch (error) {}
        }
        return result;
    }

    addIndTags(){
        var tags = this.state.tags;
        var products = this.state.products;
        var temp__customer_tag = this.state.temp__customer_tag;
        try{
            temp__customer_tag = temp__customer_tag.replace(/,/gi, "");
        }catch(e){}
        if(temp__customer_tag.trim() === "") return;
        try{
            if(-1===tags.findIndex(x=>x.trim().toLowerCase() === temp__customer_tag.trim().toLowerCase())){
                tags.push(temp__customer_tag.trim());
                for (let x = 0; x < products.length; x++) {
                    try{
                        if(products[x].tag_options.findIndex(x=>x.name.toLowerCase() === temp__customer_tag.trim().toLowerCase()) === -1){
                            if(typeof products[x].tag_options === "undefined"){products[x].tag_options = [];}
                            products[x].tag_options.push({
                                name: temp__customer_tag.trim().toLowerCase(),
                                type: "percentage",
                                value: ""
                            });
                        }
                    }catch(e){}
                    for (let xyz = 0; xyz < products[x].variants.length; xyz++) {
                        try{
                            if(products[x].variants[xyz].tag_options.findIndex(x=>x.name.toLowerCase() === temp__customer_tag.trim().toLowerCase()) === -1){
                                if(typeof products[x].variants[xyz].tag_options === "undefined"){products[x].variants[xyz].tag_options = [];}
                                products[x].variants[xyz].tag_options.push({
                                    name: temp__customer_tag.trim().toLowerCase(),
                                    type: "percentage",
                                    value: ""
                                });
                            }
                        }catch(e){}
                    }
                }
            }
            else{
                this.toast("Tag already exists", true)
            }
        }catch(e){}
        let tableHeadingArray = temp_headings;
        tableHeadingArray = tableHeadingArray.concat(tags);
        this.setState({tags, products, temp__customer_tag:"", tableHeadingArray});
    }

    render() {
        const {collections,tags_excluded,applies_to,customer_group,tags,discount_value,discount_type,title, individual_product, exclude_collections, exclude_products, exclude_collections_checkbox, exclude_products_checkbox} = this.state;

        const { schedule_active, schedule_end_date, end_month, end_year, start_month, start_year, start_date_selected, end_date_selected, start_date_popup, start_date, end_date_popup, end_date, start_time, end_time, end_date_time_error } = this.state;
        
        var { temp__customer_tag } = this.state;
       
        Date.prototype.addHours = function (h) {
        	this.setTime(this.getTime() + (h * 60 * 60 * 1000));
        	return this;
        }
        let start_date_piker = <DatePicker
            month={start_month}
            year={start_year}
            disableDatesBefore={new Date().addHours(-24)}
            onChange={(value) => {
                let a = value.start;
                if (typeof a === 'object') {
                    a = a.toString();
                    if (typeof a === 'string') {
                        a = a.replace(this.getTimezoneOffset(), '+0000');
                    }
                }
                this.setState({
                    start_date_selected: value,
                    start_date: new Date(a).toISOString().split('T')[0],
                    end_date: this.diff_days(this.state.end_date, a) <= 0 ? new Date(a).toISOString().split('T')[0] : this.state.end_date,
                    start_date_popup: false
                })
            }}
            onMonthChange={(month, year) => {
                this.setState({
                    start_month: month,
                    start_year: year,
                });
            }}
            selected={start_date_selected}
        />;
        let end_date_piker = <DatePicker
            month={end_month}
            year={end_year}
            disableDatesBefore={start_date_selected.start ? start_date_selected.start : new Date().addHours(-24)}
            onChange={(value) => {
                let a = value.start;
                if (typeof a === 'object') {
                    a = a.toString();
                    if (typeof a === 'string') {
                        a = a.replace(this.getTimezoneOffset(), '+0000');
                    }
                }
                this.setState({
                    end_date_selected: value,
                    end_date: new Date(a).toISOString().split('T')[0],
                    end_date_popup: false
                })
            }}
            onMonthChange={(month, year) => {
                this.setState({
                    end_month: month,
                    end_year: year,
                });
            }}
            selected={end_date_selected}
        />;

        const start_date_activator = (
            <TextField
                readOnly={true}
                label="Start date"
                value={start_date}
                autoComplete={false}
                onChange={(value) => {
                    this.setState({ start_date: value, start_date_popup: false });
                }}
                onFocus={() => {
                    this.setState({ start_date_popup: true });
                }}
            />
        );
        const end_date_activator = (
            <TextField
                readOnly={true}
                label="End date"
                value={end_date}
                autoComplete={false}
                onChange={(value) => {
                    this.setState({ end_date: value, end_date_popup: false });
                }}
                onFocus={() => {
                    this.setState({ end_date_popup: true });
                }}
                error={end_date_time_error ? true : false}
            />
        );

        const customer_group_options = [
            {
                label: 'All Customers',
                value: 'all',
                disabled: this.state.tags.indexOf("all") > -1
            },
            {
                label: 'Only Guest Customers',
                value: 'no_account',
                disabled: this.state.tags.indexOf("no_account") > -1
            },
            {
                label: 'Only Logged In Customers',
                value: 'has_account',
                disabled: this.state.tags.indexOf("has_account") > -1
            },
            {
                label: 'Tag Based Customers',
                value: 'has_tag'
            }
        ];

        let resource_pikers_And_models = <span>
            {
                this.state.product_modal_open &&
                <ResourcePicker
                    showVariants={this.state.individual_product?true:false}
                    onCancel={() => this.setState({product_modal_open: false})}
                    onSelection={(resources) => {
                        let products = this.state.products || [];
                        for(var j = 0; j < resources.selection.length; j++){
                            let item = resources.selection[j];
                            if(products.findIndex(x => x.handle === item.handle) === -1){
                                var variants = item.variants;
                                var temVariants = [];
                                for (let index = 0; index < variants.length; index++) {
                                    const variant = variants[index];
                                    var newTagOpts = [];
                                    this.state.tags.forEach(tag => {
                                        newTagOpts.push({
                                            name: tag,
                                            value: "",
                                            type: "percentage"
                                        })
                                    });
                                    temVariants.push({
                                        uid: this.generate_unique_value(7),
                                        id: variant.id,
                                        sku: variant.sku,
                                        title: variant.title,
                                        price: variant.price,
                                        tag_options: newTagOpts,
                                        position: index,
                                        duplicated: false
                                    });
                                }//end for
                                var tagOptions = [];
                                this.state.tags.forEach(tag => {
                                    tagOptions.push({
                                        name: tag,
                                        value: "",
                                        type: "percentage"
                                    })
                                });
                                products.push({
                                    uid: this.generate_unique_value(7),
                                    id: item.id,
                                    handle: item.handle,
                                    title: item.title,
                                    image: item.image?item.image.src:"",
                                    variants: temVariants,
                                    variant_level: false,
                                    tag_options: tagOptions,
                                    copy: {
                                        counts: 0,
                                        of: null
                                    },
                                    position: products.length,
                                    duplicated: false
                                });
                            }
                        }//end for loop
                        this.setState({products,product_modal_open: false, productsListShow: true});
                    }}
                />
            }
            {
                this.state.collection_modal_open &&
                <ResourcePicker
                    resourceType="Collection"
                    shop={this.props.shop}
                    onCancel={() => this.setState({collection_modal_open: false})}
                    onSelection={(resources) => {
                        let collections = this.state.collections || [];
                        for(var i=0;i<resources.selection.length;i++){
                            let collection = resources.selection[i];
                            if(collections.findIndex(x => x.id === collection.id) === -1){
                            collections.push({
                                id: collection.id,
                                title: collection.title,
                                handle: collection.handle
                            });
                        }
                        }//end for
                        this.setState({
                            collections: collections,
                            collection_modal_open: false,
                            collectionsListShow: true
                        });
                    }}
                />
            }
            {
                this.state.exclude_collections_modal_open &&
                <ResourcePicker
                    resourceType="Collection"
                    shop={this.props.shop}
                    onCancel={() => this.setState({exclude_collections_modal_open: false})}
                    onSelection={(resources) => {
                        let exclude_collections = this.state.exclude_collections || [];
                        for(var i=0;i<resources.selection.length;i++){
                            let collection = resources.selection[i];
                            if(exclude_collections.findIndex(x => x.id === collection.id) === -1){
                            exclude_collections.push({
                                id: collection.id,
                                title: collection.title,
                                handle: collection.handle
                            });
                        }
                        }//end for
                        this.setState({
                            exclude_collections: exclude_collections,
                            exclude_collections_modal_open: false,
                            collectionsListShow: true
                        });
                    }}
                />
            }
            {
                this.state.exclude_products_modal_open &&
                <ResourcePicker
                    resourceType="Product"
                    shop={this.props.shop}
                    onCancel={() => this.setState({exclude_products_modal_open: false})}
                    onSelection={(resources) => {
                        let exclude_products = this.state.exclude_products || [];
                        for(var j = 0; j < resources.selection.length; j++){
                            let item = resources.selection[j];
                            if(exclude_products.findIndex(x => x.handle === item.handle) === -1){
                                exclude_products.push({
                                    id: item.id,
                                    handle: item.handle,
                                    title: item.title,
                                    image: item.image?item.image.src:"",
                                });
                            }
                        }//end for loop
                        this.setState({
                            exclude_products: exclude_products,
                            exclude_products_modal_open: false
                        });
                    }}
                />
            }
            {
                this.state.update_ex_products__modal_open && 
                <Modal
                size="Medium"
                src={'/update-excluded-products'}
                open={this.state.update_ex_products__modal_open}
                onClose={() => this.setState({update_ex_products__modal_open: false})}
                title={'Excluded products'}
                primaryAction={{
                    content: 'Update',
                    onAction: this.handleSubmitUpdateExcProducts,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: () => {this.setState({update_ex_products__modal_open: false})},
                    },
                ]}
                sectioned={false}
            >
            </Modal>
            }
            {
                this.state.update_ex_collections__modal_open && 
                <Modal
                size="Medium"
                src={'/update-excluded-collections'}
                open={this.state.update_ex_collections__modal_open}
                onClose={() => this.setState({update_ex_collections__modal_open: false})}
                title={'Excluded collections'}
                primaryAction={{
                    content: 'Update',
                    onAction: this.handleSubmitUpdateExcCollections,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: () => {this.setState({update_ex_collections__modal_open: false})},
                    },
                ]}
                sectioned={false}
            >
            </Modal>
            }
            

        </span>;
        let includeTg_sec = <>
            <Stack vertical spacing="tight">
                <Stack.Item>
                    <div>
                        <Popover
                            fullWidth
                            preventAutofocus={true}
                            ariaHaspopup="true"
                            preferredPosition="mostSpace"
                            active={temp__customer_tag.length > 0}
                            activator={<TextField
                                autoComplete={false}
                                type="text"
                                placeholder={"e.g Wholesale"}
                                label="Enter customer tags"
                                value={temp__customer_tag}
                                onChange={(v) => {this.setState({temp__customer_tag:v})}}
                            />}
                            onClose={() => {this.setState({temp__customer_tag:""})}}
                        >
                            <ActionList items={[
                                {
                                    content: <span><TextStyle variation="strong">Add</TextStyle> {temp__customer_tag}</span>,
                                    onAction: () => {
                                        this.addIndTags()
                                    }
                                }
                            ]}/>
                        </Popover>
                    </div>
                </Stack.Item>
                {
                    tags.length > 0 && <Stack.Item>
                        <Stack>{this.renderIncludedTags()}</Stack>
                    </Stack.Item>
                }
            </Stack>
        </>;

        // let customer_group_section_2 = <>
        //     <Checkbox
        //         label="All customers"
        //         helpText="All website visitors will see the discount price whether or not they are logged in."
        //         checked={tags.indexOf('all') > -1}
        //         id="all"
        //         onChange={(v,id) => {this.change_customer_by_checkout(v,id)}}
        //     />
        //     <Checkbox
        //         label="Guest customers"
        //         helpText="Discount price will appear to only those customers who are not logged in to their account. The customers who are logged in will not receive the discount price."
        //         checked={tags.indexOf('no_account') > -1}
        //         id="no_account"
        //         onChange={(v,id) => {this.change_customer_by_checkout(v,id)}}
        //     />
        //     <Checkbox
        //         label="Logged in customers"
        //         helpText="Discount price will appear to only those customers who are logged in to their account. The customers who are not logged in will not receive the discount price."
        //         checked={tags.indexOf('has_account') > -1}
        //         id="has_account"
        //         onChange={(v,id) => {this.change_customer_by_checkout(v,id)}}
        //     />
        //     <div style={{marginLeft: "2.4rem"}}>{ includeTg_sec }</div>
        // </>;

        let customer_group_section_groups = <Stack spacing="tight" vertical>
            <Stack.Item>
                <Stack spacing="tight">
                    <div style={{minWidth:"240px"}}>
                        <Select
                            label="Select Customer Group"
                            labelHidden
                            placeholder="Select Customer Group"
                            labelHidden options={customer_group_options}
                            value={this.state.customer_group} 
                            onChange={(value)=> {
                                this.setState({customer_group:value});
                            }}
                        />
                    </div>
                    { (customer_group === "has_tag")&& 
                        <TextField
                            label="Tags"
                            placeholder="e.g Wholesale"
                            labelHidden
                            value={this.state.temp__customer_tag}
                            onChange={(v) => {this.setState({temp__customer_tag:v})}}
                        />
                    }
                    { (customer_group === "has_tag") ? 
                        <Button
                            primary
                            onClick={()=>{ this.addIndTags() }}
                        >Add Group</Button>
                        : <Button
                            primary
                            onClick={()=>{ this.change_customer_by_checkout(true) }}
                        >Add Group</Button>
                    }
                </Stack>
            </Stack.Item>
            {
                this.state.tags.length > 0 &&
                <Stack.Item>
                    <Stack>{this.renderIncludedTags()}</Stack>
                </Stack.Item>
            }
        </Stack>;

        let section_0 = <div>
            <Card title="Step 1: Wholesale Pricing Title"
                actions={[{content:"Read tutorial", url:"https://support.digitalcoo.com/hc/en-us/sections/360007029532", external: true}]}
            >
                <Card.Section>
                    <TextField
                        label="Title"
                        placeholder="e.g Discount for all customers"
                        helpText="Write the title of your Wholesale Pricing. This will not appear to the customers."
                        labelHidden
                        value={title}
                        onChange={(v) => {
                            this.setState({title:v})
                        }}
                    />
                </Card.Section>
                <Card.Section>
                    <Stack vertical spacing="extraTight">
                        <RadioButton
                            label="Save as Published"
                            helpText="Make discount rule available for the public to view."
                            checked={"published" === this.state.state}
                            id="published"
                            onChange={(a,b) => {this.setState({state: "published"});}}
                        />
                        <RadioButton
                            label="Save as Unpublished"
                            helpText="Make discount rule unavailable for the public to view."
                            checked={"published" !== this.state.state && "draft" !== this.state.state}
                            id="unpublished"
                            onChange={(a,b) => {this.setState({state: "unpublished"});}}
                        />
                    </Stack>
                </Card.Section>
            </Card>
        </div>;

        let ex_cus = <Stack vertical spacing="tight">
            <Stack.Item>
                <div>
                    <Popover
                        fullWidth
                        preventAutofocus={true}
                        active={temp__customer_tag.length > 0}
                        activator={<TextField
                            autoComplete={false}
                            type="text"
                            placeholder={"e.g Wholesale"}
                            label="Exclude customers"
                            value={temp__customer_tag}
                            onChange={(v) => {this.setState({temp__customer_tag:v})}}
                        />}
                        onClose={() => {this.setState({temp__customer_tag:""})}}
                    >
                        <ActionList items={[
                            {
                                content: <span><TextStyle variation="strong">Add</TextStyle> {temp__customer_tag}</span>, onAction: () => {
                                    var tags_excluded = this.state.tags_excluded;
                                    var temp__customer_tag = this.state.temp__customer_tag;
                                    try{
                                        temp__customer_tag = temp__customer_tag.replace(/,/gi, "");
                                    }catch(e){}
                                    if(temp__customer_tag.trim() === "") return;
                                    try{
                                        if(-1===tags_excluded.findIndex(x=>x.trim().toLowerCase() === temp__customer_tag.trim().toLowerCase())){
                                            tags_excluded.push(temp__customer_tag.trim());
                                        }
                                        else{
                                            this.toast("Tag already exists", true)
                                        }
                                    }catch(e){}
                                    this.setState({tags_excluded, temp__customer_tag:""});
                                }
                            }
                        ]}/>
                    </Popover>
                </div>
            </Stack.Item>
            {
                tags_excluded.length > 0 && <Stack.Item>
                    <Stack>{this.renderExcludedTags()}</Stack>
                </Stack.Item>
            }
        </Stack>;

        let section_2 = <div>
            { individual_product === true && applies_to === "products" ? null :
               <Card title="Step 4: Customer Groups">
                    <Card.Section>
                       <Stack vertical spacing="extraTight">
                           <Stack.Item>
                               <p>Select the target customer group to apply Wholesale Pricing rule</p>
                           </Stack.Item>
                           <Stack.Item>
                                <div className="wsaio_discount_grid">
                                    <div className="wsaio_g_left">
                                    <Stack spacing="tight" vertical>
                                            <RadioButton
                                                label="All Customers"
                                                checked={customer_group === "all"}
                                                value={customer_group === "all"}
                                                onChange={(v) => {
                                                    this.setState({customer_group: "all"});
                                                }}
                                            />
                                            {
                                                ((customer_group === "all") && (individual_product !== true)) && ex_cus
                                            }
                                            <RadioButton
                                                label="Only Guest Customers"
                                                checked={customer_group === "no_account"}
                                                value={customer_group === "no_account"}
                                                onChange={(v) => {
                                                    this.setState({customer_group: "no_account"});
                                                }}
                                            />
                                            <RadioButton
                                                label="Only Logged In Customers"
                                                checked={customer_group === "has_account"}
                                                value={customer_group === "has_account"}
                                                onChange={(v) => {
                                                    this.setState({customer_group: "has_account"});
                                                }}
                                            />
                                            {
                                                ((customer_group === "has_account") && (individual_product !== true)) && ex_cus
                                            }
                                            <RadioButton
                                                label="Only Tagged Customers"
                                                checked={customer_group === "has_tag"}
                                                value={customer_group === "has_tag"}
                                                onChange={(v) => {
                                                    this.setState({customer_group: "has_tag"});
                                                }}
                                            />
                                            {
                                                (customer_group === "has_tag") && 
                                                <div>
                                                    {includeTg_sec}
                                                </div>
                                            }
                                        </Stack>
                                    </div>
                                    <div className="wsaio_g_right">
                                        {
                                            customer_group === "all" && 
                                            <div>
                                                <Heading>All Customers</Heading>
                                                <p>All website visitors will see the discount price whether or not they are logged in.</p>
                                            </div>
                                        }
                                        {
                                            customer_group === "no_account" && 
                                            <div>
                                                <Heading>Only Guest Customers</Heading>
                                                <p>Discount price will appear to only those customers who are not logged in to their account. The customers who are logged in will not receive the discount price.</p>
                                            </div>
                                        }
                                        {
                                            customer_group === "has_account" && 
                                            <div>
                                                <Heading>Only Logged In Customers</Heading>
                                                <p>Discount price will appear to only those customers who are logged in to their account. The customers who are not logged in will not receive the discount price.</p>
                                            </div>
                                        }
                                        {
                                            customer_group === "has_tag" && 
                                            <div>
                                                <Heading>Only Tagged Customers</Heading>
                                                <p>Discount price will appear to only those customers who are logged in and have matching customer tag.
                                            Example: Let’s assume we apply 10% discount for “wholesale” tag based customers, then all the customers having “wholesale” tag will receive the relevant discount.</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                           </Stack.Item>
                       </Stack>
                    </Card.Section>
               </Card>
            }
        </div>

        const indv_product_rendered = <>
            {
                (this.state.individual_product) && 
                <Stack vertical spacing="tight">
                    <div>
                        { this.searchProductField() }
                    </div>
                    <div className="ws--products-ind-table">
                        <DataTable
                          truncate={true}
                          verticalAlign="middle"
                          columnContentTypes={this.tableContentTypes()}
                          headings={this.state.tableHeadingArray}
                          rows={this.renderTableRows()}
                        />
                    </div>
                    <div className="pagination-individual">
                        {this.renderLoadMoreProductsBtns()}
                    </div>
                </Stack>
            }
            {
                (!this.state.individual_product) &&
                 <div className="ws--products-ind-table">
                    <DataTable
                        truncate={true}
                        verticalAlign="middle"
                        columnContentTypes={[
                            "text"
                        ]}
                        headings={[
                            "Product Title"
                        ]}
                        rows={this.renderIndividualProductsRows()}
                    />
                </div>
            }
        </>;

        let section_4 = <div>
            <Card title="Step 2: Apply Discount To">
                <Card.Section>
                    <Stack vertical>
                        <Stack>
                            <Stack.Item>
                                <RadioButton
                                    checked={applies_to === "entire_store"}
                                    label="Entire store"
                                    value={applies_to === "entire_store"}
                                    onChange={(v) => {
                                        this.setState({applies_to: "entire_store"});
                                    }}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <RadioButton
                                    checked={applies_to === "collections"}
                                    label="Specific Collections"
                                    value={applies_to === "collections"}
                                    onChange={(v) => {
                                        this.setState({applies_to: "collections"});
                                    }}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <RadioButton
                                    checked={applies_to === "products"}
                                    label="Specific products"
                                    value={applies_to === "products"}
                                    onChange={(v) => {
                                        this.setState({applies_to: "products"});
                                    }}
                                />
                            </Stack.Item>
                        </Stack>
                        {
                            applies_to === 'products' && 
                           <div>
                               <Stack spacing="tight" alignment="center">
                                    <Stack.Item>
                                        <Button
                                            onClick={() => {this.setState({product_modal_open:true})}}
                                        >
                                        Add products
                                        </Button>
                                    </Stack.Item>
                                    {
                                        (this.state.products.length > 0) && 
                                        <Stack.Item>
                                            <Badge status="info">
                                                {this.state.products.length > 0?this.state.products.length:"No"} {this.state.products.length === 1?" product ":" products "} selected
                                            </Badge>
                                        </Stack.Item>
                                    }
                                </Stack>
                           </div>
                        }
                        <div>
                            <TextContainer>
                                <TextStyle variation="subdued">
                                {
                                    applies_to === 'entire_store' && 
                                    <p>This will allow you to apply the Wholesale Pricing to all products available in your Shopify store. <br></br><b>Example:</b> You can apply a 10% discount for all products. ​</p>
                                }
                                {
                                    applies_to === 'collections' && 
                                    <p>This will allow you to apply the Wholesale Pricing to specify one or more collections.  
                                    <br></br><b>Example:</b> You can apply a 10% discount to “shirts” products only.​ ​</p>
                                }
                                </TextStyle>
                            </TextContainer>
                        </div>
                        {
                            applies_to === "collections" && <div>
                                <Stack alignment="center">
                                    <Stack.Item>
                                        <Button
                                            onClick={() => {this.setState({collection_modal_open:true})}}
                                        >
                                        Add collections
                                        </Button>
                                    </Stack.Item>
                                    {
                                        collections.length > 0 && 
                                        <Stack.Item>
                                            <Badge status="info">
                                                {collections.length > 0?collections.length:"No"} {collections.length === 1?" collection ":" collections "} selected
                                            </Badge>
                                        </Stack.Item>
                                    }
                                    {
                                        collections.length > 0 &&
                                        <Stack.Item>
                                            <Button
                                                plain
                                                ariaExpanded={this.state.collectionsListShow}
                                                ariaControls="collection-collapsible"
                                                onClick={() => {
                                                    this.setState({collectionsListShow: !this.state.collectionsListShow })
                                                }}
                                            >
                                                {this.state.collectionsListShow?"Hide collections":"Show collections"}
                                            </Button>
                                        </Stack.Item>
                                    }
                                </Stack>
                            </div>
                        }
                        {
                            applies_to === "entire_store" && <div>
                                <Stack vertical spacing="extraTight">
                                    <div class="ws--stack-items-center">
                                        <Stack spacing="tight">
                                            <div style={{minWidth: "150px"}}>
                                                <Checkbox
                                                    checked={this.state.exclude_collections_checkbox}
                                                    onChange={(value)=>{
                                                        this.setState({
                                                            exclude_collections_checkbox:value
                                                        });
                                                        
                                                    }}
                                                    label="Exclude Collections"
                                                />
                                            </div>
                                            <div style={{minWidth: "60px"}}>
                                                <Button size="slim" plain
                                                    onClick={()=>{
                                                        this.setState({
                                                            exclude_collections_modal_open:true
                                                        });
                                                    }}
                                                >
                                                [ +Add ]
                                                </Button>
                                            </div>
                                            {
                                                (this.state.exclude_collections_checkbox && exclude_collections.length > 0) && <div style={{minWidth: "60px"}}>
                                                    <Button size="slim" plain
                                                        disabled={!exclude_collections_checkbox}
                                                        onClick={()=>{
                                                            this.setState({
                                                                update_ex_collections__modal_open:true
                                                            });
                                                            store('excludedCollections',this.state.exclude_collections);
                                                        }}
                                                    >
                                                    [ Edit <label style={{borderBottom:'1px dotted'}}>{exclude_collections.length}</label> ]
                                                    </Button>
                                                </div>
                                            }
                                        </Stack>
                                    </div>
                                    <div class="ws--stack-items-center">
                                        <Stack spacing="tight">
                                            <div style={{minWidth: "150px"}}>
                                                <Checkbox
                                                    checked={this.state.exclude_products_checkbox}
                                                    onChange={(value)=>{
                                                        this.setState({
                                                            exclude_products_checkbox:value
                                                        });
                                                        
                                                    }}
                                                    label="Exclude Products"
                                                />
                                            </div>
                                            <div style={{minWidth: "60px"}}>
                                                <Button size="slim" plain
                                                    onClick={()=>{
                                                        this.setState({
                                                            exclude_products_modal_open:true
                                                        });
                                                    }}
                                                >
                                                [ +Add ]
                                                </Button>
                                            </div>
                                            {
                                                (this.state.exclude_products_checkbox && exclude_products.length > 0) && <div  style={{minWidth: "60px"}}>
                                                    <Button size="slim" plain
                                                        disabled={!exclude_products_checkbox}
                                                        onClick={()=>{
                                                            this.setState({
                                                                update_ex_products__modal_open:true
                                                            });
                                                            store('excludedProducts',this.state.exclude_products);
                                                        }}
                                                    >
                                                    [ Edit <label style={{borderBottom:'1px dotted'}}>{exclude_products.length}</label> ]
                                                    </Button>
                                                </div>
                                            }
                                        </Stack>
                                    </div>
                                </Stack>
                            </div>
                        }
                        {
                            this.state.collections.length > 0 && applies_to === "collections" && <div>
                                <Stack vertical spacing="extraTight">
                                    <div class="ws--stack-items-center">
                                        <Stack spacing="tight">
                                            <div style={{minWidth: "160px"}}>
                                                <Checkbox
                                                    checked={this.state.exclude_products_checkbox}
                                                    onChange={(value)=>{
                                                        this.setState({
                                                            exclude_products_checkbox:value
                                                        });
                                                        
                                                    }}
                                                    label="Exclude Products"
                                                />
                                            </div>
                                            <div style={{minWidth: "60px"}}>
                                                <Button size="slim" plain
                                                    onClick={()=>{
                                                        this.setState({
                                                            exclude_products_modal_open:true
                                                        });
                                                    }}
                                                >
                                                [ +Add ]
                                                </Button>
                                            </div>
                                            {
                                                (this.state.exclude_products_checkbox && exclude_products.length > 0) && 
                                                <div style={{minWidth: "60px"}}>
                                                    <Button size="slim" plain
                                                        disabled={!exclude_products_checkbox}
                                                        onClick={()=>{
                                                            this.setState({
                                                                update_ex_products__modal_open:true
                                                            });
                                                            store('excludedProducts',this.state.exclude_products);
                                                        }}
                                                    >
                                                    [ Edit <label style={{borderBottom:'1px dotted'}}>{exclude_products.length}</label> ]
                                                    </Button>
                                                </div>
                                            }
                                        </Stack>
                                    </div>
                                </Stack>
                            </div>
                        }
                    </Stack>
                </Card.Section>
                {
                    (this.state.collections.length > 0 && applies_to === "collections" && this.state.collectionsListShow) && <Card.Section>
                        <Collapsible open={this.state.collectionsListShow} id="collection-collapsible">
                            <div className="ws--collections-table">
                                <DataTable
                                    truncate={true}
                                    verticalAlign="middle"
                                    columnContentTypes={[
                                        "text"
                                    ]}
                                    headings={[
                                        "Collection Title"
                                    ]}
                                    rows={this.renderCollectionRows()}
                                />
                            </div>
                        </Collapsible>
                    </Card.Section>
                }
            </Card>
            {
                (applies_to === "products" && this.state.products.length > 0) && <Card>
                    <Tabs
                        tabs={[
                            {
                                id: 'all-products',
                                content: (<span>
                                    <RadioButton
                                        label="All Products"
                                        checked={!this.state.individual_product}
                                        onChange={() => {
                                            this.setState({individual_product: false, load_more_products: 20})
                                        }}
                                    />
                                    </span>),
                                accessibilityLabel: 'All Products',
                                panelID: 'all-products-content',
                            },
                            {
                                id: 'indi-products',
                                content: (<span>
                                    <RadioButton
                                        label="Individual Products Discount"
                                        checked={this.state.individual_product}
                                        onChange={() => {
                                            this.setState({individual_product: true, load_more_products: 20})
                                        }}
                                    />
                                    </span>),
                                accessibilityLabel: 'Individual Products',
                                panelID: 'indi-products-content',
                            },
                        ]}
                        selected={this.state.individual_product ?1:0}
                        onSelect={(id) => {}}
                    >
                        {
                            this.state.individual_product ?
                            <>
                                <Card.Section>
                                    {customer_group_section_groups}
                                </Card.Section>
                                <Card.Section>
                                    <div className="data_cell_individual--new">
                                        {indv_product_rendered}
                                    </div>
                                </Card.Section>
                            </>
                            :<Card.Section>
                                {indv_product_rendered}
                            </Card.Section>
                        }
                    </Tabs>
                </Card>
            }
            {
                (applies_to === "entire_store" || applies_to === "collections" || (applies_to === "products" && !this.state.individual_product)) &&
                <Card title="Step 3: Discount Methods">
                    <Card.Section>
                        <div className="wsaio_discount_grid">
                            <div className="wsaio_g_left">
                                <Stack spacing="tight" vertical>
                                    <RadioButton
                                        label="Percent Off "
                                        // helpText={<span><TextStyle variation="strong">Percent Off</TextStyle> will set the wholesale price to a % off the current retail price of the product. (not the​ compare-at-price). If you change the price of the item in Shopify, the wholesale price will be adjusted as well.<br></br><TextStyle variation="strong">Example:</TextStyle> Let’s assume retail price of the item is $100 and you set 30% as “Percent Off” then customers will see $70 as final price. </span>}
                                        id="percentage"
                                        name="discount_type"
                                        checked={discount_type === 'percentage'}
                                        onChange={(c, v) => { this.setState({ discount_type: v }) }}
                                    />
                                    <RadioButton
                                        label="Price Off"
                                        // helpText={<span><TextStyle variation="strong">Price Off</TextStyle> will set the wholesale price to a fixed amount off the current retail price of the product.​ (not the compare-at-price). If you change the price of the item in Shopify, the wholesale price will be adjusted as well. <br></br><TextStyle variation="strong">Example:</TextStyle> Let’s assume retail price of the item is $100 and you set $25 as “Price Off” then customers will see $75 as final price.</span>}
                                        id="price_discount"
                                        name="discount_type"
                                        checked={discount_type === 'price_discount'}
                                        onChange={(c, v) => { this.setState({ discount_type: v }) }}
                                    />
                                    <RadioButton
                                        label="Fixed Price"
                                        // helpText={<span><TextStyle variation="strong">Fixed Price</TextStyle> will create a fixed wholesale price for the product. It won’t change if you change the retail price of the product in Shopify. <br></br><TextStyle variation="strong">Example:</TextStyle> Let’s assume retail price of the item is $100 and you set $70 as “Fixed Price” then customers will see $70 as final price. <br></br><TextStyle variation="strong">Note:</TextStyle> Price Per Item should be less than or equal to the retail price of the item.</span>}
                                        id="fixed_price"
                                        name="discount_type"
                                        checked={discount_type === 'fixed_price'}
                                        onChange={(c, v) => { this.setState({ discount_type: v }) }}
                                    />
                                    <TextField
                                        id="rd-discount_value"
                                        type="number"
                                        label={discount_type === "fixed_price"? "Each item price":"Discount per item"}
                                        value={getValue(discount_value)}
                                        onChange={(v) => {
                                            this.setState({discount_value:v})
                                        }}
                                        onBlur={() => {
                                            var v = this.state.discount_value;
                                            if(v){
                                                if(isNaN(v)){v=0}
                                                try{
                                                    if(discount_type !== "percentage"){
                                                        v = Number(v).toFixed(2);
                                                    }
                                                    else if(Number(v) > 100){
                                                        v = 100;
                                                    }
                                                    if(Number(v) < 0){
                                                        v = 0;
                                                    }
                                                }catch(e){}
                                                this.setState({discount_value:v})
                                            }
                                        }}
                                        prefix={
                                            discount_type === "fixed_price"? "New Price":null
                                        }
                                        suffix={
                                            discount_type === "price_discount"? store("store_currency") :
                                            discount_type === "percentage"? "%": discount_type === "fixed_price"? store("store_currency"):null
                                        }
                                    />
                                </Stack>
                            </div>
                            <div className="wsaio_g_right">
                                {
                                    discount_type === 'percentage' && <div><TextStyle variation="strong">Percent Off</TextStyle> will set the wholesale price to a % off the current retail price of the product. (not the​ compare-at-price). If you change the price of the item in Shopify, the wholesale price will be adjusted as well.<br></br><TextStyle variation="strong">Example:</TextStyle> Let’s assume retail price of the item is $100 and you set 30% as “Percent Off” then customers will see $70 as final price. </div>
                                }
                                {
                                    discount_type === 'price_discount' && <div><TextStyle variation="strong">Price Off</TextStyle> will set the wholesale price to a fixed amount off the current retail price of the product.​ (not the compare-at-price). If you change the price of the item in Shopify, the wholesale price will be adjusted as well. <br></br><TextStyle variation="strong">Example:</TextStyle> Let’s assume retail price of the item is $100 and you set $25 as “Price Off” then customers will see $75 as final price.</div>
                                }
                                {
                                    discount_type === 'fixed_price' && <div><TextStyle variation="strong">Fixed Price</TextStyle> will create a fixed wholesale price for the product. It won’t change if you change the retail price of the product in Shopify. <br></br><TextStyle variation="strong">Example:</TextStyle> Let’s assume retail price of the item is $100 and you set $70 as “Fixed Price” then customers will see $70 as final price. <br></br><TextStyle variation="strong">Note:</TextStyle> Price Per Item should be less than or equal to the retail price of the item.</div>
                                }
                            </div>
                        </div>
                    </Card.Section>
                </Card>
            }
        </div>
        
        let section_5 = <div>
            <Card>
                <ResourceList
                    items={[
                        {
                            id: 1,
                            name: 'Set schedule for this Wholesale Pricing',
                            description: "You can set the time frame for the Wholesale Pricing activation and expiration"
                        }
                    ]}
                    renderItem={(item) => {
                        const {id, name, description} = item;
                        return (
                            <ResourceItem
                                id={id}
                                onClick={() => {
                                    if(id===1){
                                        this.setState({scheduleExpanded: !this.state.scheduleExpanded})
                                    }
                                }}
                                    // media={media}
                                    accessibilityLabel={`View details for ${name}`}
                                >
                                <h3>
                                    <div className="mega-menu--display-option">
                                        <div className="mega-menu--display-option--icon">
                                            {
                                                !this.state.scheduleExpanded? <Icon source={CaretDownMinor} /> : <Icon source={CaretUpMinor} />
                                            }
                                        </div>
                                        <div className="mega-menu--display-option--title">
                                            <TextStyle variation="strong">{name}</TextStyle>
                                        </div>
                                    </div>
                                </h3>
                                <div className="mega-menu--display-option--description">{description}</div>
                            </ResourceItem>
                        );
                    }}
                />
                {
                    this.state.scheduleExpanded &&
                    <Card.Section>
                        <Collapsible open={this.state.scheduleExpanded} id="schedule-collapsible">
                            <Stack vertical spacing="tight">
                                <div>
                                    <TextContainer>
                                        <TextStyle variation="subdued">Please note that the START DATE allows you to set the activation date and time and the SET END DATE allows you to set the expiration date and time. </TextStyle>
                                    </TextContainer>
                                </div>
                                <Checkbox
                                    checked={schedule_active}
                                    label="Schedule this Wholesale Pricing"
                                    onChange={(v) => {
                                        this.setState({ schedule_active: v })
                                    }}
                                    disabled={false}
                                />
                                {
                                    schedule_active &&
                                    <FormLayout>
                                        <FormLayout.Group>
                                            <Popover
                                                active={start_date_popup}
                                                activator={start_date_activator}
                                                onClose={(value) => { this.setState({ start_date_popup: false }) }}
                                                preferredPosition="above"
                                                sectioned
                                            >
                                                {start_date_piker}
                                            </Popover>
                                            <Select
                                                label={'Start time (EST)'}
                                                placeholder="Select time"
                                                options={this.time_options}
                                                onChange={(value) => { this.setState({ start_time: value }) }}
                                                value={start_time}
                                            >
                                            </Select>
                                        </FormLayout.Group>
                                    </FormLayout>
                                }
                                {
                                    schedule_active && <Checkbox
                                        checked={schedule_end_date}
                                        label="Set end date"
                                        onChange={(value) => {
                                            this.setState({ schedule_end_date: value })
                                        }}
                                    />
                                }
                                {
                                    (schedule_active && schedule_end_date) && <FormLayout>
                                    <FormLayout.Group>
                                            <Popover
                                                active={end_date_popup}
                                                activator={end_date_activator}
                                                onClose={(value) => { this.setState({ end_date_popup: false }) }}
                                                preferredPosition="above"
                                                sectioned
                                            >
                                                {end_date_piker}
                                            </Popover>
                                            <Select
                                                label={'End time (EST)'}
                                                placeholder="Select time"
                                                options={this.time_options}
                                                onChange={(value) => { this.setState({ end_time: value }) }}
                                                value={end_time}
                                                error={end_date_time_error ? true : false}
                                            ></Select>
                                        </FormLayout.Group>
                                    </FormLayout>
                                }
                            </Stack>
                        </Collapsible>
                    </Card.Section>
                }
            </Card>
        </div>;

        let page_actions = <PageActions
            secondaryActions={[
                { content: 'Cancel', url: '/regular-discount/list', target: "APP" },
            ]}
            primaryAction={{
                loading: this.state.save_btn_loading,
                content: "Save Wholesale Pricing",
                onAction: () => {this.saveRegularDiscount()}
            }}
        />;

        let errors_section = <div>
            <Banner status="critical" title={`There ${this.state.errorList.length > 1?"are":"is"} ${this.state.errorList.length } ${this.state.errorList.length > 1?"errors":"error"}`}>
                <List type="number">
                {
                    this.state.errorList.map((error,iN) => {
                        return <List.Item key={iN}>{error}</List.Item>
                    })
                }
                </List>
            </Banner>
        </div>

        let renderSections = <Layout>
            {
                this.state.errorList.length > 0 &&
                <Layout.Section>{errors_section}</Layout.Section>
            }
            <Layout.Section>{section_0}</Layout.Section>
            <Layout.Section>{section_4}</Layout.Section>
            <Layout.Section>{section_2}</Layout.Section>
            <Layout.Section>{section_5}</Layout.Section>
            <Layout.Section>{page_actions}</Layout.Section>
        </Layout>;
        
        var toast = this.state.showToast? (<ToastMessage message={this.state.toastMsg} error={this.state.toastError} onDismiss={()=>{this.setState({showToast: false})}} />):(null);
        if(this.state.page_loaded){
            return(
                <div>
                    <PageTitleBar
                        title={this.props.show === "edit"?"Edit Wholesale Pricing":"Create Wholesale Pricing"}
                        secondaryActions={[
                            { content: 'Back', url: '/regular-discount/list', target: "APP" },
                        ]}
                        primaryAction={{
                            disabled: this.state.save_btn_loading,
                            content: "Save Wholesale Pricing",
                            onAction: () => {this.saveRegularDiscount()}
                        }}
                    />
                    {resource_pikers_And_models}
                    {renderSections}
                    {toast}
                </div>
            )
        }
        else{
            return(
                <SkeletonPageComp />
            )
        }
    }

    time_options = [
        { label: '12:00 am', value: '00:00:00' },
        { label: '12:30 am', value: '00:30:00' },
        { label: '01:00 am', value: '01:00:00' },
        { label: '01:30 am', value: '01:30:00' },
        { label: '02:00 am', value: '02:00:00' },
        { label: '02:30 am', value: '02:30:00' },
        { label: '03:00 am', value: '03:00:00' },
        { label: '03:30 am', value: '03:30:00' },
        { label: '04:00 am', value: '04:00:00' },
        { label: '04:30 am', value: '04:30:00' },
        { label: '05:00 am', value: '05:00:00' },
        { label: '05:30 am', value: '05:30:00' },
        { label: '06:00 am', value: '06:00:00' },
        { label: '06:30 am', value: '06:30:00' },
        { label: '07:00 am', value: '07:00:00' },
        { label: '07:30 am', value: '07:30:00' },
        { label: '08:00 am', value: '08:00:00' },
        { label: '08:30 am', value: '08:30:00' },
        { label: '09:00 am', value: '09:00:00' },
        { label: '09:30 am', value: '09:30:00' },
        { label: '10:00 am', value: '10:00:00' },
        { label: '10:30 am', value: '10:30:00' },
        { label: '11:00 am', value: '11:00:00' },
        { label: '11:30 am', value: '11:30:00' },
        { label: '12:00 pm', value: '12:00:00' },
        { label: '12:30 pm', value: '12:30:00' },
        { label: '01:00 pm', value: '13:00:00' },
        { label: '01:30 pm', value: '13:30:00' },
        { label: '02:00 pm', value: '14:00:00' },
        { label: '02:30 pm', value: '14:30:00' },
        { label: '03:00 pm', value: '15:00:00' },
        { label: '03:30 pm', value: '15:30:00' },
        { label: '04:00 pm', value: '16:00:00' },
        { label: '04:30 pm', value: '16:30:00' },
        { label: '05:00 pm', value: '17:00:00' },
        { label: '05:30 pm', value: '17:30:00' },
        { label: '06:00 pm', value: '18:00:00' },
        { label: '06:30 pm', value: '18:30:00' },
        { label: '07:00 pm', value: '19:00:00' },
        { label: '07:30 pm', value: '19:30:00' },
        { label: '08:00 pm', value: '20:00:00' },
        { label: '08:30 pm', value: '20:30:00' },
        { label: '09:00 pm', value: '21:00:00' },
        { label: '09:30 pm', value: '21:30:00' },
        { label: '10:00 pm', value: '22:00:00' },
        { label: '10:30 pm', value: '22:30:00' },
        { label: '11:00 pm', value: '23:00:00' },
        { label: '11:30 pm', value: '23:30:00' }
    ]
}

function getValue(value){
	if(value){
		try {
            value = value.toString();
        } catch (e) { }
    }
    return value;
}