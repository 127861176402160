import React, { Component } from 'react';
import axios from 'axios';
import {Popover,PageActions,DatePicker,Banner,Checkbox,Stack,Layout,FormLayout,TextField,Select,Card,Button,RadioButton,ButtonGroup,List, TextStyle,Tag, Badge, TextContainer, Thumbnail, ActionList, DataTable, Icon, Collapsible, ResourceList, ResourceItem } from '@shopify/polaris';
import store from 'store2';
import waio from '../../../Helpers/functions';
import SkeletonPageComp from '../../SkeletonPageComp';
import ResourcePicker from '../../../Common/ResourcePicker';
import PageTitleBar from '../../../Common/TitleBar';
import ToastMessage from '../../../Common/ToastMessage';
import {Modal} from '@shopify/app-bridge-react';
import {DuplicateMinor, DeleteMinor, HideMinor, ViewMinor, MaximizeMinor, MinimizeMinor, CaretUpMinor, CaretDownMinor} from '@shopify/polaris-icons';

var temp_headings = ["Actions", "Variant Pricing", "The product title and images", "Price", "Quantity"];
var _this = null;
export default class CreateVolumeDiscount extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            collectionsListShow: true,
            productsListShow: true,
            product_modal_open: false,
            collection_modal_open: false,
            temp__customer_tag: "",
            tableHeadingArray: temp_headings,
            tags: [],
            tags_excluded: [],
            products: [],
            collections: [],
            volume_discount: [{ qty: '1', value: '1' }],
            extended_view: false,
            applies_to: "entire_store",
            customer_group: "all",
            individual_product: false,
            discount_type: "percentage",
            target_selection: "variant",
            title: "",
            state: "published",
            schedule_active: false,
            schedule_end_date: false,
            start_month: new Date().getMonth(),
            start_year: new Date().getFullYear(),
            end_month: new Date().getMonth(),
            end_year: new Date().getFullYear(),
            start_date_selected: new Date(),
            end_date_selected: new Date(),
            start_date_popup: false,
            start_date: new Date().toISOString().split('T')[0],
            end_date_popup: false,
            end_date: new Date().toISOString().split('T')[0],
            start_time: '00:00:00',
            end_time: '23:30:00',
            end_date_time_error: false,
            scheduleExpanded: false,
            displayOptionExpanded: false,
            display_option: "detailed_grid",
            errorList: [],
            save_btn_loading: false,
            page_loaded: typeof this.props.match.params.id !== "undefined"? false : true,
            exclude_collections: [],
            exclude_products: [],
            exclude_collections_checkbox: false,
            exclude_products_checkbox: false,
            exclude_collections_modal_open: false,
            exclude_products_modal_open: false,
            update_ex_collections__modal_open: false,
            update_ex_products__modal_open: false,
            load_more_products: 20,
            search_products_text: "",
            showToast: false,
            toastError: false,
            toastMsg: ""
        }
        axios.defaults.headers.common['X-Ws-Shop-Domain'] = "https://"+this.props.shop;
    }

    componentDidMount() {
        _this = this;
        if(typeof this.props.match.params.id !== "undefined"){
            this.getForEdit();
        }
        document.addEventListener('keydown',this.keydownHandler);
    }

    componentWillUnmount(){
        document.removeEventListener('keydown',this.keydownHandler);
    }

    keydownHandler(event){
        if (((event.key === "s"||event.key === "S") || event.keyCode === 83) && (event.metaKey || event.ctrlKey)) {
            event.preventDefault();
            try {
                _this.saveQuantityBreak();
            } catch (error) {}
        }
    }

    getForEdit(){
        axios.get(this.props.host_name + '/app/volume-discount?shop='+this.props.shop+'&id=' + this.props.match.params.id)
        .then(response => {
            if(response.data){
                let data = response.data.rule;
                let tableHeadingArray = this.state.tableHeadingArray;
                if(data.tags && data.tags.length > 0){
                    tableHeadingArray = tableHeadingArray.concat(data.tags); 
                }
                var products_arr = data.products || this.state.products;
                var $that = this;
                if(products_arr && products_arr.length){
                    try {
                        products_arr = JSON.parse(JSON.stringify(products_arr)).map(function(product){
                            if(product){
                                try {
                                    product.uid = $that.generate_unique_value(7);
                                } catch (error) {}
                            }
                            return product;
                        });
                    } catch (error) {}
                }
                this.setState(
                    {
                        "tableHeadingArray" : tableHeadingArray,
                        "end_date" : data.schedule.end_date ? (data.schedule.end_date).split('T')[0] : data.schedule.start_date ? this.get_est_date_only(data.schedule.start_date) : new Date().toISOString().split('T')[0],
                        "start_date" : data.schedule.start_date ? this.get_est_date_only(data.schedule.start_date) : new Date().toISOString().split('T')[0],
                        "start_time" : data.schedule.start_time_est ? data.schedule.start_time_est : this.state.start_time,
                        "end_time" : data.schedule.end_time_est ? data.schedule.end_time_est : this.state.end_time,
                        "schedule_end_date" : data.schedule.end_date ? true : false,
                        "tags" : data.tags || this.state.tags,
                        "tags_excluded" : data.tags_excluded || this.state.tags_excluded,
                        "products" : products_arr || this.state.products,
                        "collections" : data.collections || this.state.collections,
                        "volume_discount" : data.volume_discount || this.state.volume_discount,
                        "applies_to" : data.applies_to || this.state.applies_to,
                        "customer_group" : data.customer_group || this.state.customer_group,
                        "individual_product" : data.individual_product || this.state.individual_product,
                        "discount_type" : data.discount_type || this.state.discount_type,
                        "target_selection" : data.target_selection || this.state.target_selection,
                        "title" : data.title || this.state.title,
                        "state" : data.state || this.state.state,
                        "schedule_active" : data.schedule_active || this.state.schedule_active,
                        "display_option" : data.display_option || this.state.display_option,
                        "page_loaded": true,
                        "exclude_collections" : data.exclude_collections || this.state.exclude_collections,
                        "exclude_collections_checkbox" : data.exclude_collections ? data.exclude_collections.length > 0 ? true : false : false,
                        "exclude_products" : data.exclude_products || this.state.exclude_products,
                        "exclude_products_checkbox" : data.exclude_products ? data.exclude_products.length > 0 ? true : false : false,
                    }
                )
            }
            else{
                this.toast("Could not load data. Try again", true)
            }
        })
        .catch(error => {
            this.setState({page_loaded: true});
        });
    }

    renderExcludedTags(){
        let tags_excluded = this.state.tags_excluded;
        return tags_excluded.map((tag,j) => {
            return <Tag key={j}
                onRemove={() => {
                    var tags_excluded = [...this.state.tags_excluded];
                    tags_excluded.splice(j,1);
                    this.setState({tags_excluded});
                }}
            >{tag}</Tag>
        })
    }

    renderIncludedTags(){
        let tags = this.state.tags;
        return tags.map((tag,j) => {
            if(tag === "all" || tag === "no_account" || tag === "has_account"){
                return null;
            }
            else{
                return <Tag key={j}
                    onRemove={() => {
                        var tags = [...this.state.tags];
                        tags.splice(j,1);
                        var products = this.state.products;
                        let tableHeadingArray = this.state.tableHeadingArray;
                        tableHeadingArray = tableHeadingArray.filter(x=>x !== tag);
                        for (let x = 0; x < products.length; x++) {
                            products[x].tag_options = products[x].tag_options.filter(x=>x.name !== tag);
                            for (let y = 0; y < products[x].variants.length; y++) {
                                try{
                                    products[x].variants[y].tag_options = products[x].variants[y].tag_options.filter(x=>x.name !== tag);
                                }catch(e){}
                            }
                        }
                        this.setState({tags,tableHeadingArray,products});
                    }}
                >{tag}</Tag>
            }
        })
    }

    toast(msg,error){
        this.setState({
            showToast: true,
            toastMsg: msg,
            toastError: error
        });
    }
    
    tableContentTypes(){
        let type = [];
        for (let index = 0,len = this.state.tableHeadingArray.length; index < len; index++) {
            type.push("text");
        }
        type.push("text");// for delete button
        return type;
    }

    resizeImage(image, size) {
        if(typeof size === "undefined"){size = "100x100"}
        var n;
        if (image) {
            if (image.indexOf(".jpg") > -1) {
                n = image.lastIndexOf(".jpg");
                image = image.substring(0, n) + "_" + size + image.substring(n);
            }
            else if (image.indexOf(".png") > -1) {
                n = image.lastIndexOf(".png");
                image = image.substring(0, n) + "_" + size + image.substring(n);
            }
            else if (image.indexOf(".gif") > -1) {
                n = image.lastIndexOf(".gif");
                image = image.substring(0, n) + "_" + size + image.substring(n);
            }
            return image;
        }
        else {
            return image;
        }
    }

    truncate(str,num, showDots){
        var dots = ""; if(str.length > num && showDots){dots='...';}
        return str.slice(0,num) + dots;
    }

    renderTableRows(){
        var row = [];
        var products = this.state.products || [];
        let search_products_text = this.state.search_products_text;        
        try {
            if(search_products_text && search_products_text.trim() !== ""){
                products = products.filter(product => {
                    if(
                    product.title.match(new RegExp(search_products_text,"gi")) ||
                    product.handle.match(new RegExp(search_products_text,"gi")) ||
                    -1<product.variants.findIndex(x => x.sku.match(new RegExp(search_products_text,"gi")))
                    ){
                        return product;
                    }
                });
            }
        } catch (error) {

        }
        for (let index = 0, len = products.length; index < len; index++) {
            let product = products[index];
            if(index >= Number(this.state.load_more_products)) break;
            let column = [];
            column.push(
                <div style={{minWidth: "90px", maxWidth: "150px"}}>
                    <ButtonGroup segmented>
                        <Button
                            size="slim"
                            disabled={product.variant_level}
                            onClick={() => {
                                let products = [...this.state.products];
                                var tagOptions = [];
                                var tagOptions1 = [];
                                this.state.tags.forEach(tag => {
                                    tagOptions.push({
                                        name: tag,
                                        value: "",
                                        type: "percentage"
                                    });
                                    tagOptions1.push({
                                        name: tag,
                                        value: "",
                                        type: "percentage"
                                    });
                                });
                                var p_variants = [];
                                product.variants.forEach(variant => {
                                    p_variants.push({
                                        uid: this.generate_unique_value(7),
                                        id: variant.id,
                                        sku: variant.sku,
                                        title: variant.title,
                                        price: variant.price,
                                        tag_options: tagOptions1,
                                        quantity: "1",
                                        position: p_variants.length,
                                        duplicated: false
                                    });
                                });
                                let pCopyCounts = Number(product.copy["counts"])+1;
                                products.push({
                                    uid: this.generate_unique_value(7),
                                    id: product.id,
                                    handle: product.handle,
                                    title: product.title,
                                    image: product.image,
                                    variants: p_variants,
                                    variant_level: product.variant_level? true:false,
                                    tag_options: tagOptions,
                                    quantity: (1 + Number(product.quantity)).toString(),
                                    copy: {
                                        counts: pCopyCounts,
                                        of: product.handle
                                    },
                                    position: product.position,
                                    duplicated: true
                                });
                                products[index].copy = {
                                    counts: pCopyCounts,
                                    of: product.handle
                                };
                                products.sort(function(a,b){
                                    return a.position - b.position;
                                });
                                for (let abv = 0; abv < products.length; abv++) {
                                    products[abv].position = abv;
                                    if(products[abv].handle === product.handle){
                                        products[abv].copy["counts"] = pCopyCounts;
                                    }
                                }
                                var load_more_products = Number(this.state.load_more_products)+1;
                                this.setState({products: products,load_more_products: load_more_products});
                            }}
                        >
                        <Icon source={DuplicateMinor} />
                        </Button>
                        <Button
                            size="slim"
                            onClick={() => {
                                let products = [...this.state.products];
                                for (let abc = 0; abc < products.length; abc++) {
                                    try {
                                        if(products[abc].copy["of"] === product.handle){
                                            if(Number(products[abc].copy["counts"]) - 1 === 0){
                                                products[abc].duplicated = false;
                                            }
                                            products[abc].copy["counts"] = Number(products[abc].copy["counts"]) - 1;
                                        }
                                    } catch (error) {}
                                }
                                products = products.filter(x => x.uid !== product.uid);
                                this.setState({products});
                            }}
                        >
                        <Icon source={DeleteMinor} />
                        </Button>
                    </ButtonGroup>
                </div>
            );
            column.push(
                <div style={{width: "25px", maxWidth: "150px"}}>
                    <Checkbox
                        label="Variant level"
                        labelHidden
                        disabled={product.duplicated}
                        checked={product.variant_level}
                        onChange={(v)=>{
                            if(Number(product.copy["counts"]) > 0){
                                this.toast("Can't apply on duplicated products", true)
                            }
                            else{
                                var products = [...this.state.products];
                                var fInx = products.findIndex(x => x.uid === product.uid);
                                if(fInx>-1){
                                    try{
                                        products[fInx].variant_level = v;
                                    }catch(e){}
                                }
                                this.setState({products});
                            }
                        }}
                    />
                </div>
            );
            column.push(
                <div data-product-handle={product.handle} className={`data-table-item--title list-item product-item-index--${index}`}>
                    {product.image && <img width="35" height="35" style={{marginRight: "8px"}} alt={product.title} src={this.resizeImage(product.image, "35x35")} />} <TextStyle variation="strong">{
                        product.handle?
                        <a href={'https://'+this.props.shop+'/products/'+product.handle} target="new">{this.truncate(product.title, 50, true)}</a>
                        :this.truncate(product.title, 50, true)
                    }</TextStyle> {product.duplicated && <TextStyle variation="subdued"> &nbsp;&nbsp;(Duplicated)</TextStyle>}
                </div>
            );
            column.push(
                <div data-product-handle={product.handle} className={`list-item product-item-index--${index}`}>
                    {product.variant_level===false?product.variants[0]?product.variants[0].price?waio.Currency.format_money(product.variants[0].price):null:null:null}
                </div>
            );
            column.push(
                <div data-product-handle={product.handle} className={`list-item product-item-index--${index}`} style={{minWidth: "100px", maxWidth: "110px"}}>
                    {
                        product.variant_level===false &&
                        <TextField
                            disabled={product.variant_level}
                            type="number"
                            label="Quantity"
                            labelHidden
                            value={getValue(product.quantity)}
                            onChange={(v)=>{
                                var products = [...this.state.products];
                                var fIndexs = products.findIndex(x => x.uid === product.uid);
                                if(fIndexs > -1){
                                    try{
                                        products[fIndexs].quantity = v;
                                    }catch(e){}
                                }
                                this.setState({products});
                            }}
                        />
                    }
                </div>
            );
            if(product && product.tag_options && product.tag_options.length){
                for (let loopIndex = 0; loopIndex < product.tag_options.length; loopIndex++) {
                    let tagOpt = product.tag_options[loopIndex];
                    column.push(
                        <div  style={{minWidth: "180px", maxWidth: "190px"}}>
                            {
                                product.variant_level===false && 
                                <TextField
                                    disabled={product.variant_level}
                                    type="number"
                                    label="Discount"
                                    labelHidden
                                    value={getValue(tagOpt.value)}
                                    onChange={(v)=>{
                                        var products = [...this.state.products];
                                        var fInds = products.findIndex(x => x.uid === product.uid);
                                        if(fInds > -1){
                                            try{
                                                products[fInds].tag_options[loopIndex].value = v;
                                            }catch(e){}
                                        }
                                        this.setState({products});
                                    }}
                                    connectedRight={
                                        <Select
                                            disabled={product.variant_level}
                                            labelHidden
                                            label="Discount Type"
                                            value={tagOpt.type}
                                            options={[
                                                { label: "% Off", value: "percentage" },
                                                { label: "New Price", value: "fixed_price" },
                                                { label: "Price Off", value: "price_discount" }
                                            ]}
                                            onChange={(v)=>{
                                                var products = [...this.state.products];
                                                var fIndsx = products.findIndex(x => x.uid === product.uid);
                                                if(fIndsx>-1){
                                                    try{
                                                        products[fIndsx].tag_options[loopIndex].type = v;
                                                    }catch(e){}
                                                }
                                                this.setState({products});
                                            }}
                                        />
                                    }
                                />
                            }
                        </div>
                    );
                }
            }
            row.push(column);
            if(!product.variant_level){continue}
            // Render variants and hide them
            var collapse = product.variant_level ? "": "variant--collapsed";
            if(product && product.variants && product.variants.length){
                for (let variantLoop = 0, len = product.variants.length; variantLoop < len; variantLoop++) {
                    let variant = product.variants[variantLoop];
                    column = [];
                    column.push( //empty column
                        <div>
                        </div>
                    );
                    column.push(
                        <div className={collapse}>
                            <ButtonGroup segmented>
                                <Button
                                    size="slim"
                                    onClick={() => {
                                        let variants = [...product.variants];
                                        let newTagOptions = [];
                                        this.state.tags.forEach(tag => {
                                            newTagOptions.push({
                                                name: tag,
                                                value: "",
                                                type: "percentage"
                                            })
                                        });
                                        variants.push({
                                            uid: this.generate_unique_value(7),
                                            id: variant.id,
                                            sku: variant.sku,
                                            title: variant.title,
                                            price: variant.price,
                                            quantity: "",
                                            tag_options: newTagOptions,
                                            position: variant.position,
                                            duplicated: true
                                        });
                                        variants.sort(function(a,b){
                                            return a.position - b.position;
                                        });
                                        for (let yui = 0, leng = variants.length; yui < leng; yui++) {
                                            variants[yui].position = yui;
                                        }
                                        var products = [...this.state.products];
                                        var fIdex = products.findIndex(x => x.uid === product.uid);
                                        if(fIdex > -1){
                                            try {
                                                products[fIdex].variants = variants;
                                            } catch (error) {}
                                        }
                                        this.setState({products});
                                    }}
                                >
                                <Icon source={DuplicateMinor} />
                                </Button>
                                {
                                    variant.duplicated === true && 
                                    <Button
                                        size="slim"
                                        onClick={() => {
                                            let variants = [...product.variants];
                                            variants.splice(variantLoop, 1);
                                            var products = [...this.state.products];
                                            var fIdsx = products.findIndex(x => x.uid === product.uid);
                                            if(fIdsx > -1){
                                                try {
                                                    products[fIdsx].variants = variants;
                                                } catch (error) {}
                                            }
                                            this.setState({products});
                                        }}
                                    >
                                    <Icon source={DeleteMinor} />
                                    </Button>
                                }
                            </ButtonGroup>
                        </div>
                    );
                    column.push(
                        <div className={collapse +" data-table-item--title"} style={{minWidth: "90px", maxWidth: "150px"}}>
                            {product.image && <img width="35" height="35" style={{marginRight: "8px"}} alt={variant.title} src={this.resizeImage(product.image, "35x35")} />} {variant.title} {variant.duplicated && <TextStyle variation="subdued"> &nbsp;&nbsp;(Duplicated)</TextStyle>}
                        </div>
                    )
                    column.push(
                        <div className={collapse}>
                            {waio.Currency.format_money(variant.price)}
                        </div>
                    );
                    column.push(
                        <div className={collapse} style={{minWidth: "100px", maxWidth: "110px"}}>
                            <TextField
                                type="number"
                                label="Quantity"
                                labelHidden
                                value={getValue(variant.quantity)}
                                onChange={(v)=>{
                                    let products = [...this.state.products];
                                    try{
                                        let variants = [...product.variants];
                                        variants[variantLoop] = { ...variants[variantLoop], quantity: v.toString()}
                                        var fIdeex = products.findIndex(x => x.uid === product.uid);
                                        if(fIdeex>-1){
                                            try {
                                                products[fIdeex].variants = variants;
                                            } catch (error) {}
                                        }
                                    }catch(e){}
                                    this.setState({products});
                                }}
                            />
                        </div>
                    );
                    if(variant && variant.tag_options && variant.tag_options.length){
                        for (let variantLoop2 = 0; variantLoop2 < variant.tag_options.length; variantLoop2++) {
                            let tagOpt2 = variant.tag_options[variantLoop2];
                            column.push(
                                <div className={collapse} style={{minWidth: "180px", maxWidth: "190px"}}>
                                    <TextField
                                        type="number"
                                        label="Discount"
                                        labelHidden
                                        value={getValue(tagOpt2.value)}
                                        onChange={(v)=>{
                                            var products = [...this.state.products];
                                            var fIdext = products.findIndex(x => x.uid === product.uid);
                                            if(fIdext>-1){
                                                try{
                                                    products[fIdext].variants[variantLoop].tag_options[variantLoop2].value = v;
                                                }catch(e){}
                                            }
                                            this.setState({products});
                                        }}
                                        connectedRight={
                                            <Select
                                                labelHidden
                                                label="Discount Type"
                                                value={tagOpt2.type}
                                                options={[
                                                    { label: "% Off", value: "percentage" },
                                                    { label: "New Price", value: "fixed_price" },
                                                    { label: "Price Off", value: "price_discount" }
                                                ]}
                                                onChange={(v)=>{
                                                    var products = [...this.state.products];
                                                    var fIdx = products.findIndex(x => x.uid === product.uid);
                                                    if(fIdx>-1){
                                                        try{
                                                            products[fIdx].variants[variantLoop].tag_options[variantLoop2].type = v;
                                                        }catch(e){}
                                                    }
                                                    this.setState({products});
                                                }}
                                            />
                                        }
                                    />
                                </div>
                            );
                        }
                    }
                    row.push(column);
                }
            }
        }
        return row;
    }

    doValidation(){
        let isValid = true;
        let products_array = [];
        let errors = [];
        for (let asd = 0, len = this.state.products.length; asd < len; asd++) {
            let product = this.state.products[asd];
            try{
                let groups = this.state.products.filter(x => x.handle === product.handle);
                groups.sort(function(a,b){
                    return Number(a.quantity) - Number(b.quantity);
                });
                groups.forEach(group => {
                    try{
                        if(products_array.findIndex(function(x){ return (x.handle.toString() === group.handle.toString() && x.quantity.toString() === group.quantity.toString())}) === -1){
                            products_array.push(group);
                        }
                    }catch(e){
                        products_array.push(group);
                    }
                });
            }catch(e){
                products_array.push(product);
            }
            try{
                if(products_array.findIndex(function(x){ return (x.handle.toString() === product.handle.toString() && x.quantity.toString() === product.quantity.toString())}) === -1){
                    products_array.push(product);
                }
            }catch(e){
                products_array.push(product);
            }
        }// end for
        let products = [];
        products_array.forEach((product,index) => {
            product["position"] = index;
            products.push(product);
        });
        
        if (this.state.customer_group === "has_tag") {
            if (this.state.tags.length === 0) {
                isValid=false;
                errors.push("Add at least 1 tag for tag based customers");
            }
        }
        if(this.state.applies_to === "collections" && this.state.collections.length === 0){
            isValid=false;
            errors.push("Select at least 1 collection");
        }
        if(this.state.applies_to === "products" && this.state.products.length === 0){
            isValid=false;
            errors.push("Select at least 1 product");
        }
        if (this.state.schedule_active && this.state.schedule_end_date) {
            if (this.diff_days(this.state.end_date, this.state.start_date) === 0) {
                if (this.state.start_time === this.state.end_time) {
                    this.setState({ end_date_time_error: true });isValid=false;
                    errors.push("Start date and end date can't be same");
                }
                else if (this.state.start_time > this.state.end_time) {
                    this.setState({ end_date_time_error: true });isValid=false;
                    errors.push("End date must be after the start date");
                }
            }
            else if (this.diff_days(this.state.end_date, this.state.start_date) < 0) {
                this.setState({ end_date_time_error: true });isValid=false;
                errors.push("Start date and end date can't be same");
            }
        }
        if (this.state.volume_discount.length > 0 && this.state.individual_product === false) {
            let error; let less_than_zero_price = false; let percent_greater100 = false;
            let discount_type = this.state.discount_type;
            let total = this.state.volume_discount.length;
            this.state.volume_discount.forEach(function (item, idx) {
                if ((item.value === '' || item.qty === '') && total === 1) {
                    error = true;
                }
                else if ((item.value === '' || item.qty === '')) {
                    // error = true;
                }
                else if (Number(item.value) < 0) {
                    less_than_zero_price = true;
                }
                if (discount_type === 'percentage' && Number(item.value) > 100) {
                    error = true;
                    percent_greater100 = true;
                }
            });
            if (less_than_zero_price) {
                errors.push("Discount value must be greater than zero");isValid=false;
            }
            if (percent_greater100) {
                errors.push("Percentage value can not exceed 100");isValid=false;
            }
            if (error) {
                errors.push("Volume Pricing value can not be blank");isValid=false;
            }
        }

        return {
            errors: errors,
            isValid:isValid,
            products: products
        };
    }

    scrollToTop(){
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    saveQuantityBreak(){
        this.setState({search_products_text: ""});
        let data = {};
        let validationArray = this.doValidation();
        let products = validationArray.products;
        let errorList = validationArray.errors;
        let volume_discount = this.state.volume_discount;
        volume_discount = volume_discount.filter( x => Number(x.qty) > 0);
        volume_discount.sort(function(a,b){
            return Number(a.qty) - Number(b.qty);
        });
        if(validationArray.isValid){
            this.setState({ save_btn_loading: true });
            let start_date_time = this.get_est_dateTime(this.state.start_date, this.state.start_time);
            let start_time = this.state.start_time;
            let end_date_time = this.get_est_dateTime(this.state.end_date, this.state.end_time);
            let end_time = this.state.end_time;
            if (!this.state.schedule_active) {
                start_date_time = null;
                start_time = null;
                end_date_time = null;
                end_time = null;
            }
            if (!this.state.schedule_end_date) {
                end_date_time = null;
                end_time = null;
            }
            var exclude_collections = [];
            var exclude_products = [];
            if(this.state.applies_to === 'entire_store'){
                if(this.state.exclude_collections_checkbox){
                    exclude_collections = this.state.exclude_collections;
                }
                if(this.state.exclude_products_checkbox){
                    exclude_products = this.state.exclude_products;
                }
            }
            else if(this.state.applies_to === 'collections'){
                if(this.state.exclude_products_checkbox){
                    exclude_products = this.state.exclude_products;
                }
            }
            data = {
                target_selection: this.state.target_selection,
                individual_product: this.state.individual_product,
                customer_group: this.state.customer_group,
                tags_excluded: this.state.tags_excluded,
                discount_type: this.state.discount_type,
                collections: this.state.collections,
                applies_to: this.state.applies_to,
                volume_discount: volume_discount,
                display_option: this.state.display_option,
                state: this.state.state,
                title: this.state.title,
                shop: this.props.shop,
                tags: this.state.tags,
                products: products,
                schedule_active: this.state.schedule_active,
                schedule: {
                    start_date: start_date_time,
                    end_date: end_date_time,
                    start_time_est: start_time,
                    end_time_est: end_time
                },
                exclude_collections: exclude_collections,
                exclude_products: exclude_products
            }
            if(this.props.show === "edit" && this.props.match.params.id){
                var id = this.props.match.params.id;
                axios.put(this.props.host_name + '/app/volume-discount?id='+id, data)
                .then(response => {
                    if (response.data.status === 1) {
                        this.toast(response.data.message);
                        this.props.history.push('/volume-discount/list');
                    } else {
                        this.setState({ save_btn_loading: false });
                        this.toast(response.data.message, true);
                        return false;
                    }
                })
                .catch(error => { });
            }
            else{
                axios.post(this.props.host_name + '/app/volume-discount', data)
                .then(response => {
                    if (response.data.status === 1) {
                        this.toast(response.data.message);
                        this.props.history.push('/volume-discount/list');
                    } else {
                        this.setState({ save_btn_loading: false });
                        this.toast(response.data.message, true);
                        return false;
                    }
                })
                .catch(error => { });
            }
        }
        else{
            let msg = `There ${errorList.length > 1?"are":"is"} ${errorList.length } ${errorList.length > 1?"errors":"error"}`;
            this.toast(msg, true);
            this.setState({ errorList : errorList });
            this.scrollToTop();
        }
    }

    renderCollectionRows(){
        let collections = this.state.collections;
        var rows = [];
        collections.forEach((collection,cIndex) => {
            let column = [];
            column.push(
                <div style={{minWidth: "90px", maxWidth: "150px"}}>
                    <ButtonGroup>
                        <Button
                            size="slim"
                            onClick={() => {
                                let collections = this.state.collections;
                                collections.splice(cIndex, 1);
                                this.setState({collections}) 
                            }}
                        ><Icon source={DeleteMinor} /></Button>
                    </ButtonGroup>
                </div>
            );
            column.push(
                <div style={{minWidth: "90px", maxWidth: "150px"}}>
                    <a href={'https://'+this.props.shop+'/collections/'+collection.handle} target="new">{collection.title}</a>
                </div>
            );
            rows.push(column)
        });
        return rows;
    }

    renderIndividualProductsRows(){
        let products = this.state.products;
        var rows = [];
        products.forEach((product,cIndex) => {
            let column = [];
            column.push(
                <div style={{minWidth: "90px", maxWidth: "150px", minHeight: "48px"}}>
                    <ButtonGroup>
                        <Button
                            size="slim"
                            onClick={() => {
                                let products = this.state.products;
                                products.splice(cIndex, 1);
                                this.setState({products}) 
                            }}
                        ><Icon source={DeleteMinor} /></Button>
                    </ButtonGroup>
                </div>
            );
            column.push(
                <div style={{minWidth: "90px", maxWidth: "150px"}} className="pp-center-items">
                    {
                        product.image && <div style={{marginRight:"4px"}}><Thumbnail size="small" source={this.resizeImage(product.image, "100x100")}></Thumbnail></div>
                    }
                    <div style={{marginLeft:"4px"}}>{product.title}</div>
                </div>
            );
            rows.push(column)
        });
        return rows; 
    }

    volumeDiscountFields(){
        let html = this.state.volume_discount.map((item,vIndex) => {
            return <div>
                <Stack alignment="center" distribution="leading">
                    <Stack.Item fill>
                        <Stack spacing="extraTight">
                            <TextField
                                autoComplete={false}
                                labelHidden
                                type="number"
                                label="Quantity"
                                placeholder="Quantity"
                                value={getValue(item.qty)}
                                onChange={(v)=>{
                                    let volume_discount = this.state.volume_discount;
                                    volume_discount[vIndex]["qty"] = v;
                                    this.setState({volume_discount});
                                }}
                            />
                            <TextField
                                autoComplete={false}
                                labelHidden
                                type="number"
                                label="Discount"
                                placeholder="Discount"
                                value={getValue(item.value)}
                                onChange={(v)=>{
                                    let volume_discount = this.state.volume_discount;
                                    volume_discount[vIndex]["value"] = v;
                                    this.setState({volume_discount});
                                }}
                            />
                            <Button
                                size="slim"
                                disabled={this.state.volume_discount.length === 1}
                                onClick={() => {
                                    let volume_discount = this.state.volume_discount;
                                    volume_discount.splice(vIndex,1);
                                    this.setState({volume_discount});
                                }}
                            >
                            <Icon source={DeleteMinor} />
                            </Button>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </div>;
        });
        let ui = <Stack vertical>
            <Stack.Item>
                <FormLayout>{html}</FormLayout>
            </Stack.Item>
            <Stack.Item>
                <ButtonGroup>
                    <Button onClick={() => {
                        let volume_discount = this.state.volume_discount;
                        volume_discount.push({
                            qty: "",
                            value: ""
                        });
                        this.setState({volume_discount})
                    }}>
                        {this.state.volume_discount.length >= 1 ? 'Add more' : 'Add'}
                    </Button>
                    {
                        this.state.volume_discount.length > 20 &&
                        <Button
                            onClick={() => {
                                let volume_discount = this.state.volume_discount;
                                // volume_discount = volume_discount.filter(x=> x.qty !== "");
                                volume_discount.sort(function(a,b){
                                    return Number(a.qty) - Number(b.qty)
                                });
                                this.setState({volume_discount});
                            }}
                        >Sort by quantity [0-9]</Button>
                    }
                </ButtonGroup>
            </Stack.Item>
        </Stack>;
        return ui;
    }

    getTimezoneOffset() {
        function z(n) { return (n < 10 ? '0' : '') + n }
        var offset = new Date().getTimezoneOffset();
        var sign = offset < 0 ? '+' : '-';
        offset = Math.abs(offset);
        return sign + z(offset / 60 | 0) + z(offset % 60);
    }

    diff_days(new_date, old_date) {
        var t1 = new Date(new_date);
        var t2 = new Date(old_date);
        let result = parseInt((t1 - t2) / (24 * 3600 * 1000), 10);
        if (isNaN(result)) result = 0;
        return result;
    }

    get_est_dateTime(date, time) {
        let dateTimeValue = new Date(date);
        if (typeof dateTimeValue === 'object') {
            dateTimeValue = dateTimeValue.toString();
            if (typeof dateTimeValue === 'string') {
                dateTimeValue = dateTimeValue.replace(this.getTimezoneOffset(), '+0000');
            }
        }
        dateTimeValue = new Date(dateTimeValue).toISOString();
        dateTimeValue = dateTimeValue.split('T')[0] + " " + time;
        return dateTimeValue;
    }

    get_est_date_only(date) {
        let date_only = new Date(date);
        if (typeof date_only === 'object') {
            date_only = date_only.toString();
            if (typeof date_only === 'string') {
                date_only = date_only.replace(this.getTimezoneOffset(), '+0000');
            }
        }
        date_only = new Date(date_only).toISOString();
        date_only = date_only.split('T')[0];
        return date_only;
    }

    change_customer_by_checkout = (v, id) => {
        var tags = this.state.tags;
        var products = this.state.products;
        if(v){
            var temp__customer_tag = id;
            try{
                temp__customer_tag = temp__customer_tag.replace(/,/gi, "");
            }catch(e){}
            if(temp__customer_tag.trim() === "") return;
            try{
                if(-1===tags.findIndex(x=>x.trim().toLowerCase() === temp__customer_tag.trim().toLowerCase())){
                    tags.push(temp__customer_tag.trim());
                    for (let x = 0; x < products.length; x++) {
                        try{
                            if(products[x].tag_options.findIndex(x=>x.name === temp__customer_tag.trim()) === -1){
                                if(typeof products[x].tag_options === "undefined"){products[x].tag_options = [];}
                                products[x].tag_options.push({
                                    name: temp__customer_tag.trim(),
                                    type: "percentage",
                                    value: ""
                                });
                            }
                        }catch(e){}
                        for (let xyz = 0; xyz < products[x].variants.length; xyz++) {
                            try{
                                if(products[x].variants[xyz].tag_options.findIndex(x=>x.name === temp__customer_tag.trim()) === -1){
                                    if(typeof products[x].variants[xyz].tag_options === "undefined"){products[x].variants[xyz].tag_options = [];}
                                    products[x].variants[xyz].tag_options.push({
                                        name: temp__customer_tag.trim(),
                                        type: "percentage",
                                        value: ""
                                    });
                                }
                            }catch(e){}
                        }
                    }
                }
                else{
                    this.toast("Tag already exists", true)
                }
            }catch(e){}
            let tableHeadingArray = temp_headings;
            tableHeadingArray = tableHeadingArray.concat(tags);
            this.setState({tags, products, tableHeadingArray});
        }
        else{
            var tag = id;
            tags = tags.filter(x=>x !== tag);
            let tableHeadingArray = this.state.tableHeadingArray;
            tableHeadingArray = tableHeadingArray.filter(x=>x !== tag);
            for (let x = 0; x < products.length; x++) {
                products[x].tag_options = products[x].tag_options.filter(x=>x.name !== tag);
                for (let y = 0; y < products[x].variants.length; y++) {
                    try{
                        products[x].variants[y].tag_options = products[x].variants[y].tag_options.filter(x=>x.name !== tag);
                    }catch(e){}
                }
            }
            this.setState({tags,tableHeadingArray,products});
        }
    }

    handleSubmitUpdateExcCollections = (selected) => {
        this.setState({
            exclude_collections: store('excludedCollections') || [],
            update_ex_collections__modal_open: false
        })
    }

    handleSubmitUpdateExcProducts = (selected) => {
        this.setState({
            exclude_products: store('excludedProducts') || [],
            update_ex_products__modal_open: false
        })
    }

    renderLoadMoreProductsBtns = function(){
        var btns = <ButtonGroup>
            <Button
                onClick={()=>{
                    var load_more_products = this.state.load_more_products || 20;
                    load_more_products = Number(load_more_products)+10;
                    this.setState({load_more_products});
                }}
            >
               Load More Products
           </Button>
           <Button
                onClick={()=>{
                    var load_more_products = this.state.load_more_products || 20;
                    load_more_products = Number(this.state.products.length)+1;
                    this.setState({load_more_products});
                }}
            >
               Load All Products
           </Button>
        </ButtonGroup>;
        if(Number(this.state.load_more_products) >= Number(this.state.products.length)){
            btns = null;
        }
        if(this.state.search_products_text && this.state.search_products_text.trim() !== ""){
            btns = null;
        }
        return btns;
    }

    pageNumbers (n, p) {
        n = Number(n) || 1;
        p = Number(p) || 1;
        var a = [];
        if (p >= 4) {
        a = [1];
        }
        for (var x = p - 2; x <= n; x++) {
            if (x < 1) continue;
            if (x < p) a.push(x);
            else if (x === p || x === p + 1 || x === p + 2) a.push(x);
            else {
            if (n - 2 < x) a.push(x);
            }
        }
        return a;
    }

    searchProductField(){
        return <TextField
            value={this.state.search_products_text}
            label="Search products"
            labelHidden={true}
            placeholder="Search products by title, handle or SKU"
            onChange={(v) => {
                this.setState({search_products_text: v});
            }}
        ></TextField>
    }

    generate_unique_value(length) {
        var text = "";var result = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for(var i = 0; i < (length || 6); i++) {
            try {
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            } catch (error) {}
        }
        for (var j=0; j < text.length; j++) {
            try {
                result += text.charCodeAt(j).toString(16);
            } catch (error) {}
        }
        return result;
    }

    render() {
        const {display_option, collections,tags_excluded,applies_to,customer_group,tags,discount_type,target_selection,title,individual_product, exclude_collections, exclude_products, exclude_collections_checkbox,exclude_products_checkbox} = this.state;

        const { schedule_active, schedule_end_date, end_month, end_year, start_month, start_year, start_date_selected, end_date_selected, start_date_popup, start_date, end_date_popup, end_date, start_time, end_time, end_date_time_error } = this.state;

        var { temp__customer_tag } = this.state;
        
      Date.prototype.addHours = function (h) {
	this.setTime(this.getTime() + (h * 60 * 60 * 1000));
	return this;
}
        let start_date_piker = <DatePicker
            month={start_month}
            year={start_year}
            disableDatesBefore={new Date().addHours(-24)}
            onChange={(value) => {
                let a = value.start;
                if (typeof a === 'object') {
                    a = a.toString();
                    if (typeof a === 'string') {
                        a = a.replace(this.getTimezoneOffset(), '+0000');
                    }
                }
                this.setState({
                    start_date_selected: value,
                    start_date: new Date(a).toISOString().split('T')[0],
                    end_date: this.diff_days(this.state.end_date, a) <= 0 ? new Date(a).toISOString().split('T')[0] : this.state.end_date,
                    start_date_popup: false
                })
            }}
            onMonthChange={(month, year) => {
                this.setState({
                    start_month: month,
                    start_year: year,
                });
            }}
            selected={start_date_selected}
        />;
        let end_date_piker = <DatePicker
            month={end_month}
            year={end_year}
            disableDatesBefore={start_date_selected.start ? start_date_selected.start : new Date().addHours(-24)}
            onChange={(value) => {
                let a = value.start;
                if (typeof a === 'object') {
                    a = a.toString();
                    if (typeof a === 'string') {
                        a = a.replace(this.getTimezoneOffset(), '+0000');
                    }
                }
                this.setState({
                    end_date_selected: value,
                    end_date: new Date(a).toISOString().split('T')[0],
                    end_date_popup: false
                })
            }}
            onMonthChange={(month, year) => {
                this.setState({
                    end_month: month,
                    end_year: year,
                });
            }}
            selected={end_date_selected}
        />;

        const start_date_activator = (
            <TextField
                readOnly={true}
                label="Start date"
                value={start_date}
                autoComplete={false}
                onChange={(value) => {
                    this.setState({ start_date: value, start_date_popup: false });
                }}
                onFocus={() => {
                    this.setState({ start_date_popup: true });
                }}
            />
        );
        const end_date_activator = (
            <TextField
                readOnly={true}
                label="End date"
                value={end_date}
                autoComplete={false}
                onChange={(value) => {
                    this.setState({ end_date: value, end_date_popup: false });
                }}
                onFocus={() => {
                    this.setState({ end_date_popup: true });
                }}
                error={end_date_time_error ? true : false}
            />
        );

        let resource_pikers_And_models = <span>
            {
                this.state.product_modal_open &&
                <ResourcePicker
                    showVariants={this.state.individual_product?true:false}
                    onCancel={() => this.setState({product_modal_open: false})}
                    onSelection={(resources) => {
                        let products = this.state.products || [];
                        for(var j = 0; j < resources.selection.length; j++){
                            let item = resources.selection[j];
                            if(products.findIndex(x => x.handle === item.handle) === -1){
                                var variants = item.variants;
                                var temVariants = [];
                                for (let index = 0; index < variants.length; index++) {
                                    const variant = variants[index];
                                    var newTagOpts = [];
                                    this.state.tags.forEach(tag => {
                                        newTagOpts.push({
                                            name: tag,
                                            value: "",
                                            type: "percentage"
                                        })
                                    });
                                    temVariants.push({
                                        uid: this.generate_unique_value(7),
                                        id: variant.id,
                                        sku: variant.sku,
                                        title: variant.title,
                                        price: variant.price,
                                        tag_options: newTagOpts,
                                        quantity: "1",
                                        position: index,
                                        duplicated: false
                                    });
                                }//end for
                                var tagOptions = [];
                                this.state.tags.forEach(tag => {
                                    tagOptions.push({
                                        name: tag,
                                        value: "",
                                        type: "percentage"
                                    })
                                });
                                products.push({
                                    uid: this.generate_unique_value(7),
                                    id: item.id,
                                    handle: item.handle,
                                    title: item.title,
                                    image: item.image?item.image.src:"",
                                    variants: temVariants,
                                    variant_level: false,
                                    tag_options: tagOptions,
                                    quantity: "1",
                                    copy: {
                                        counts: 0,
                                        of: null
                                    },
                                    position: products.length,
                                    duplicated: false
                                });
                            }
                        }//end for loop
                        this.setState({products,product_modal_open: false, productsListShow: true});
                    }}
                />
            }
            {
                this.state.collection_modal_open &&
                <ResourcePicker
                    resourceType="Collection"
                    shop={this.props.shop}
                    onCancel={() => this.setState({collection_modal_open: false})}
                    onSelection={(resources) => {
                        let collections = this.state.collections || [];
                        for(var i=0;i<resources.selection.length;i++){
                            let collection = resources.selection[i];
                            if(collections.findIndex(x => x.id === collection.id) === -1){                            
                            collections.push({
                                id: collection.id,
                                title: collection.title,
                                handle: collection.handle
                            });
                        }
                        }//end for
                        this.setState({
                            collections: collections,
                            collection_modal_open: false,
                            collectionsListShow: true
                        });
                    }}
                />
            }
            {
                this.state.exclude_collections_modal_open &&
                <ResourcePicker
                    resourceType="Collection"
                    shop={this.props.shop}
                    onCancel={() => this.setState({exclude_collections_modal_open: false})}
                    onSelection={(resources) => {
                        let exclude_collections = this.state.exclude_collections || [];
                        for(var i=0;i<resources.selection.length;i++){
                            let collection = resources.selection[i];
                            if(exclude_collections.findIndex(x => x.id === collection.id) === -1){
                            exclude_collections.push({
                                id: collection.id,
                                title: collection.title,
                                handle: collection.handle
                            });
                        }
                        }//end for
                        this.setState({
                            exclude_collections: exclude_collections,
                            exclude_collections_modal_open: false,
                            collectionsListShow: true
                        });
                    }}
                />
            }
            {
                this.state.exclude_products_modal_open &&
                <ResourcePicker
                    resourceType="Product"
                    shop={this.props.shop}
                    onCancel={() => this.setState({exclude_products_modal_open: false})}
                    onSelection={(resources) => {
                        let exclude_products = this.state.exclude_products || [];
                        for(var j = 0; j < resources.selection.length; j++){
                            let item = resources.selection[j];
                            if(exclude_products.findIndex(x => x.handle === item.handle) === -1){
                                exclude_products.push({
                                    id: item.id,
                                    handle: item.handle,
                                    title: item.title,
                                    image: item.image?item.image.src:"",
                                });
                            }
                        }//end for loop
                        this.setState({
                            exclude_products: exclude_products,
                            exclude_products_modal_open: false
                        });
                    }}
                />
            }
            {
                this.state.update_ex_products__modal_open && 
                <Modal
                size="Medium"
                src={'/update-excluded-products'}
                open={this.state.update_ex_products__modal_open}
                onClose={() => this.setState({update_ex_products__modal_open: false})}
                title={'Excluded products'}
                primaryAction={{
                    content: 'Update',
                    onAction: this.handleSubmitUpdateExcProducts,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: () => {this.setState({update_ex_products__modal_open: false})},
                    },
                ]}
                sectioned={false}
            >
            </Modal>
            }
            {
                this.state.update_ex_collections__modal_open && 
                <Modal
                size="Medium"
                src={'/update-excluded-collections'}
                open={this.state.update_ex_collections__modal_open}
                onClose={() => this.setState({update_ex_collections__modal_open: false})}
                title={'Excluded collections'}
                primaryAction={{
                    content: 'Update',
                    onAction: this.handleSubmitUpdateExcCollections,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: () => {this.setState({update_ex_collections__modal_open: false})},
                    },
                ]}
                sectioned={false}
            >
            </Modal>
            }
            
        </span>;
        let includeTg_sec = <>
            <Stack vertical spacing="tight">
               <Stack.Item>
                   <FormLayout>
                       <FormLayout.Group>
                        <div>
                            <Popover
                                fullWidth
                                preventAutofocus={true}
                                ariaHaspopup="true"
                                preferredPosition="mostSpace"
                                active={temp__customer_tag.length > 0}
                                activator={<TextField
                                    autoComplete={false}
                                    type="text"
                                    placeholder={"e.g Wholesale"}
                                    label="Enter customer tags"
                                    value={temp__customer_tag}
                                    onChange={(v) => {this.setState({temp__customer_tag:v})}}
                                />}
                                onClose={() => {this.setState({temp__customer_tag:""})}}
                            >
                                <ActionList items={[
                                    {
                                        content: <span><TextStyle variation="strong">Add</TextStyle> {temp__customer_tag}</span>, onAction: () => {
                                            var tags = this.state.tags;
                                            var products = this.state.products;
                                            var temp__customer_tag = this.state.temp__customer_tag;
                                            try{
                                                temp__customer_tag = temp__customer_tag.replace(/,/gi, "");
                                            }catch(e){}
                                            if(temp__customer_tag.trim() === "") return;
                                            try{
                                                if(-1===tags.findIndex(x=>x.trim().toLowerCase() === temp__customer_tag.trim().toLowerCase())){
                                                    tags.push(temp__customer_tag.trim());
                                                    for (let x = 0; x < products.length; x++) {
                                                        try{
                                                            if(products[x].tag_options.findIndex(x=>x.name === temp__customer_tag.trim()) === -1){
                                                                if(typeof products[x].tag_options === "undefined"){products[x].tag_options = [];}
                                                                products[x].tag_options.push({
                                                                    name: temp__customer_tag.trim(),
                                                                    type: "percentage",
                                                                    value: ""
                                                                });
                                                            }
                                                        }catch(e){}
                                                        for (let xyz = 0; xyz < products[x].variants.length; xyz++) {
                                                            try{
                                                                if(products[x].variants[xyz].tag_options.findIndex(x=>x.name === temp__customer_tag.trim()) === -1){
                                                                    if(typeof products[x].variants[xyz].tag_options === "undefined"){products[x].variants[xyz].tag_options = [];}
                                                                    products[x].variants[xyz].tag_options.push({
                                                                        name: temp__customer_tag.trim(),
                                                                        type: "percentage",
                                                                        value: ""
                                                                    });
                                                                }
                                                            }catch(e){}
                                                        }
                                                    }
                                                }
                                                else{
                                                    this.toast("Tag already exists", true)
                                                }
                                            }catch(e){}
                                            let tableHeadingArray = temp_headings;
                                            tableHeadingArray = tableHeadingArray.concat(tags);
                                            this.setState({tags, products, temp__customer_tag:"", tableHeadingArray});
                                        }
                                    }
                                ]}/>
                            </Popover>
                        </div>
                        <div></div>
                        <div></div>
                       </FormLayout.Group>
                   </FormLayout>
               </Stack.Item>
                {
                    tags.length > 0 && <Stack.Item>
                        <Stack>{this.renderIncludedTags()}</Stack>
                    </Stack.Item>
                }
            </Stack>
        </>;

        let customer_group_section = <>
            <RadioButton
                label="All customers"
                helpText="All website visitors will see the discount price whether or not they are logged in."
                checked={customer_group === "all"}
                value={customer_group === "all"}
                onChange={(v) => {
                    this.setState({customer_group: "all"});
                }}
            />
            <RadioButton
                label="Only Guest customers"
                helpText="Discount price will appear to only those customers who are not logged in to their account. The customers who are logged in will not receive the discount price."
                checked={customer_group === "no_account"}
                value={customer_group === "no_account"}
                onChange={(v) => {
                    this.setState({customer_group: "no_account"});
                }}
            />
            <RadioButton
                label="Only logged in customers"
                helpText="Discount price will appear to only those customers who are logged in to their account. The customers who are not logged in will not receive the discount price."
                checked={customer_group === "has_account"}
                value={customer_group === "has_account"}
                onChange={(v) => {
                    this.setState({customer_group: "has_account"});
                }}
            />
            <RadioButton
                label="Only Tagged customers"
                helpText="Discount price will appear to only those customers who are logged in and have matching customer tag.
                Example: Let’s assume we apply 10% discount for “wholesale” tag based customers, then all the customers having “wholesale” tag will receive the relevant discount."
                checked={customer_group === "has_tag"}
                value={customer_group === "has_tag"}
                onChange={(v) => {
                    this.setState({customer_group: "has_tag"});
                }}
            />
        </>;

        let customer_group_section_2 = <>
            <Checkbox
                label="All customers"
                helpText="All website visitors will see the discount price whether or not they are logged in."
                checked={tags.indexOf('all') > -1}
                id="all"
                onChange={this.change_customer_by_checkout}
            />
            <Checkbox
                label="Guest customers"
                helpText="Discount price will appear to only those customers who are not logged in to their account. The customers who are logged in will not receive the discount price."
                checked={tags.indexOf('no_account') > -1}
                id="no_account"
                onChange={this.change_customer_by_checkout}
            />
            <Checkbox
                label="Logged in customers"
                helpText="Discount price will appear to only those customers who are logged in to their account. The customers who are not logged in will not receive the discount price."
                checked={tags.indexOf('has_account') > -1}
                id="has_account"
                onChange={this.change_customer_by_checkout}
            />
            <div style={{marginLeft: "2.4rem"}}>{ includeTg_sec }</div>
        </>;

        let section_0 = <div>
            <Card title="Step 1: Volume Pricing Title"
                actions={[{content:"Read tutorial", url:"https://digitalcoo.com/wholesale-all-in-one/", external: true}]}
            >
                <Card.Section>
                    <TextField
                        label="Title"
                        placeholder="e.g Discount for all customers"
                        helpText="Write the title of your Volume Pricing. This will not appear to the customers."
                        labelHidden
                        value={title}
                        onChange={(v) => {
                            this.setState({title:v})
                        }}
                    />
                </Card.Section>
                <Card.Section>
                    <Stack vertical spacing="extraTight">
                        {/* <RadioButton
                            label="Save as Test Mode"
                            helpText={<span>Test the discount rule before making it live to the customers. <a style={{textDecoration: "underline"}} href={`https://${this.props.shop}/?test=yes`} target="new">View Test Mode</a> </span>}
                            checked={"draft" === this.state.state}
                            id="draft"
                            onChange={(a,b) => {this.setState({state: "draft"});}}
                        /> */}
                        <RadioButton
                            label="Save as Published"
                            helpText="Make discount rule available for the public to view."
                            checked={"published" === this.state.state}
                            id="published"
                            onChange={(a,b) => {this.setState({state: "published"});}}
                        />
                        <RadioButton
                            label="Save as Unpublished"
                            helpText="Make discount rule unavailable for the public to view."
                            checked={"published" !== this.state.state && "draft" !== this.state.state}
                            id="unpublished"
                            onChange={(a,b) => {this.setState({state: "unpublished"});}}
                        />
                    </Stack>
                </Card.Section>
            </Card>
        </div>;

        let section_1 = <div>
            <Card title="Step 4: Discount Types" sectioned
            actions={[{content:"Read tutorial", url:"https://digitalcoo.com/wholesale-all-in-one/", external: true}]}
            >
                <Stack vertical spacing="tight">
                    <RadioButton
                        label="Apply Volume Pricing per Variant (Discount applies per individual item)"
                        helpText="Volume Pricing will apply to specific variants (ie. Buy 5 of the same Large Red shirt to get price break)"
                        id="variant"
                        name="target_selection"
                        checked={target_selection === 'variant'}
                        onChange={(c, v) => { this.setState({ target_selection: v }) }}
                    />
                    <RadioButton
                        label="Apply Volume Pricing across Group (Mix and match products for discount)"
                        helpText="Volume Pricing will apply to a group of variants (ie. Buy ANY 5 shirts (any size, any colour) to receive price break)"
                        id="product"
                        name="target_selection"
                        checked={target_selection === 'product'}
                        onChange={(c, v) => { this.setState({ target_selection: v }) }}
                    />
                    <RadioButton
                        label="Apply Volume Pricing within each group of product"
                        helpText="Volume Pricing will apply to the products of this group only (ie. Buy any 5 quantity from selected products (any variant) to receive price break)"
                        id="within_group"
                        name="target_selection"
                        checked={target_selection === 'within_group'}
                        onChange={(c, v) => { this.setState({ target_selection: v }) }}
                    />
                    <RadioButton
                        label="Apply Volume Pricing across all Groups"
                        helpText="Volume Pricing will apply to all groups. Buy 5 products of group A and 5 products of group B and get the Volume Pricing at 10 products with the price created in group B"
                        id="across_all_groups"
                        name="target_selection"
                        checked={target_selection === 'across_all_groups'}
                        onChange={(c, v) => { this.setState({ target_selection: v }) }}
                    />
                </Stack>
            </Card>
        </div>;

        let section_2 = <div>
            { individual_product === true && applies_to === "products" ? null :
               <Card title="Step 3: Customer Groups">
                   <Card.Section>
                       <Stack vertical spacing="extraTight">
                           <Stack.Item>
                               <p>The Customer Groups section will allow you to select the target customers that will receive discount pricing. Select the customer group that will receive the offer discount</p>
                           </Stack.Item>
                           <Stack.Item>
                            { customer_group_section }
                           </Stack.Item>
                       </Stack>
                   </Card.Section>
                    {
                        (customer_group === "has_tag") && 
                        <Card.Section>
                            {includeTg_sec}
                        </Card.Section>
                    }
                    {
                       ((customer_group === "has_account" || customer_group === "all") && (individual_product !== true)) && 
                       <Card.Section>
                            <Stack vertical spacing="tight">
                               <Stack.Item>
                                   <FormLayout>
                                       <FormLayout.Group>
                                        <div>
                                            <Popover
                                                fullWidth
                                                preventAutofocus={true}
                                                active={temp__customer_tag.length > 0}
                                                activator={<TextField
                                                    autoComplete={false}
                                                    type="text"
                                                    placeholder={"e.g Wholesale"}
                                                    label="Exclude customers"
                                                    value={temp__customer_tag}
                                                    onChange={(v) => {this.setState({temp__customer_tag:v})}}
                                                />}
                                                onClose={() => {this.setState({temp__customer_tag:""})}}
                                            >
                                                <ActionList items={[
                                                    {
                                                        content: <span><TextStyle variation="strong">Add</TextStyle> {temp__customer_tag}</span>, onAction: () => {
                                                            var tags_excluded = this.state.tags_excluded;
                                                            var temp__customer_tag = this.state.temp__customer_tag;
                                                            try{
                                                                temp__customer_tag = temp__customer_tag.replace(/,/gi, "");
                                                            }catch(e){}
                                                            if(temp__customer_tag.trim() === "") return;
                                                            try{
                                                                if(-1===tags_excluded.findIndex(x=>x.trim().toLowerCase() === temp__customer_tag.trim().toLowerCase())){
                                                                    tags_excluded.push(temp__customer_tag.trim());
                                                                }
                                                                else{
                                                                    this.toast("Tag already exists", true)
                                                                }
                                                            }catch(e){}
                                                            this.setState({tags_excluded, temp__customer_tag:""});
                                                        }
                                                    }
                                                ]}/>
                                            </Popover>
                                        </div>
                                        <div></div>
                                        <div></div>
                                       </FormLayout.Group>
                                   </FormLayout>
                               </Stack.Item>
                                {
                                    tags_excluded.length > 0 && <Stack.Item>
                                        <Stack>{this.renderExcludedTags()}</Stack>
                                    </Stack.Item>
                                }
                            </Stack>
                       </Card.Section>
                    }
               </Card>
            }      
        </div>

        let section_4 = <div>
            <Card title="Step 2: Apply Discount To">
                <Card.Section>
                    <Stack vertical>
                        <Stack>
                            <Stack.Item>
                                <RadioButton
                                    checked={applies_to === "entire_store"}
                                    label="Entire store"
                                    value={applies_to === "entire_store"}
                                    onChange={(v) => {
                                        this.setState({applies_to: "entire_store"});
                                    }}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <RadioButton
                                    checked={applies_to === "collections"}
                                    label="Specific Collections"
                                    value={applies_to === "collections"}
                                    onChange={(v) => {
                                        this.setState({applies_to: "collections"});
                                    }}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <RadioButton
                                    checked={applies_to === "products"}
                                    label="Specific products"
                                    value={applies_to === "products"}
                                    onChange={(v) => {
                                        this.setState({applies_to: "products"});
                                    }}
                                />
                            </Stack.Item>
                        </Stack>
                        <div>
                            <TextContainer>
                                <TextStyle variation="subdued">
                                {
                                    applies_to === 'entire_store' && 
                                    <p>This will allow you to apply the Volume Pricing to all products available in your Shopify store. <br></br><b>Example:</b> You can apply a 10% discount for all products. ​</p>
                                }
                                {
                                    applies_to === 'collections' && 
                                    <p>This will allow you to apply the Volume Pricing to specify one or more collections.  
                                    <br></br><b>Example:</b> You can apply a 10% discount to “shirts” products only.​ ​</p>
                                }
                                {
                                    applies_to === 'products' && 
                                    <p>You can use the “Specific Products” option to apply the Volume Pricing for selected products of your choice. In this case the Volume Pricing will apply to all variants of selected products.  ​</p>
                                }
                                </TextStyle>
                            </TextContainer>
                        </div>
                        {
                            applies_to === "products" && <div>
                                <Stack vertical>
                                    <Stack.Item>
                                        <Checkbox
                                            label="Set Volume Pricing on individual product"
                                            checked={this.state.individual_product}
                                            onChange={(v) => {
                                                this.setState({individual_product:v, customer_group: "has_tag", load_more_products: 20})
                                            }}
                                        />
                                    </Stack.Item>
                                    {
                                        this.state.individual_product&&this.state.applies_to === 'products' ?
                                        <Stack.Item>{customer_group_section_2}</Stack.Item>
                                        :null
                                    }
                                    <Stack.Item>
                                        <Stack spacing="tight" alignment="center">
                                            <Button
                                                onClick={() => {this.setState({product_modal_open:true})}}
                                            >
                                            Add products
                                            </Button>
                                            {
                                                (!this.state.individual_product && this.state.products.length > 0) && 
                                                <Stack.Item>
                                                    <Badge status="info">
                                                        {this.state.products.length > 0?this.state.products.length:"No"} {this.state.products.length === 1?" product ":" products "} selected
                                                    </Badge>
                                                </Stack.Item>
                                            }
                                            {
                                                (!this.state.individual_product && this.state.products.length > 0) &&
                                                <Button
                                                    plain
                                                    ariaExpanded={this.state.productsListShow}
                                                    ariaControls="collection-collapsible"
                                                    onClick={() => {
                                                        this.setState({productsListShow: !this.state.productsListShow })
                                                    }}
                                                >
                                                {
                                                    this.state.productsListShow ?
                                                    <span><Icon source={HideMinor}/></span>
                                                    :<span><Icon source={ViewMinor}/></span>
                                                }
                                                </Button>
                                            }
                                        </Stack>
                                    </Stack.Item>
                                </Stack>
                            </div>
                        }
                        {
                            applies_to === "collections" && <div>
                                <Stack alignment="center">
                                    <Stack.Item>
                                        <Button
                                            onClick={() => {this.setState({collection_modal_open:true})}}
                                        >
                                        Add collections
                                        </Button>
                                    </Stack.Item>
                                    {
                                        collections.length > 0 && 
                                        <Stack.Item>
                                            <Badge status="info">
                                                {collections.length > 0?collections.length:"No"} {collections.length === 1?" collection ":" collections "} selected
                                            </Badge>
                                        </Stack.Item>
                                    }
                                    {
                                        collections.length > 0 &&
                                        <Stack.Item>
                                            <Button
                                                plain
                                                ariaExpanded={this.state.collectionsListShow}
                                                ariaControls="collection-collapsible"
                                                onClick={() => {
                                                    this.setState({collectionsListShow: !this.state.collectionsListShow })
                                                }}
                                            >
                                                {this.state.collectionsListShow?"Hide collections":"Show collections"}
                                            </Button>
                                        </Stack.Item>
                                    }
                                </Stack>
                            </div>
                        }
                        {
                            applies_to === "entire_store" && <div>
                                <Stack vertical spacing="extraTight">
                                    <Stack.Item>
                                        <Checkbox
                                            checked={this.state.exclude_collections_checkbox}
                                            onChange={(value)=>{
                                                this.setState({
                                                    exclude_collections_checkbox:value,
                                                    exclude_collections_modal_open:value
                                                });
                                                
                                            }}
                                            label={
                                                <Stack spacing="tight">
                                                    <Stack.Item>Exclude Collections</Stack.Item>
                                                    <Stack.Item>
                                                        <Button size="slim" plain
                                                            onClick={()=>{
                                                                if( exclude_collections.length < 1 ){
                                                                    this.setState({
                                                                        exclude_collections_modal_open:true
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                        [ +Add ]
                                                        </Button>
                                                    </Stack.Item>
                                                    {
                                                        exclude_collections.length > 0 && <Stack.Item>
                                                            <Button size="slim" plain
                                                                disabled={!exclude_collections_checkbox}
                                                                onClick={()=>{
                                                                    this.setState({
                                                                        update_ex_collections__modal_open:true
                                                                    });
                                                                    store('excludedCollections',this.state.exclude_collections);
                                                                }}
                                                            >
                                                            [ Edit <label style={{borderBottom:'1px dotted'}}>{exclude_collections.length}</label> ]
                                                            </Button>
                                                        </Stack.Item>
                                                    }
                                                </Stack>
                                            }
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Checkbox
                                            checked={this.state.exclude_products_checkbox}
                                            onChange={(value)=>{
                                                this.setState({
                                                    exclude_products_checkbox:value,
                                                    exclude_products_modal_open:value
                                                });
                                                
                                            }}
                                            label={
                                                <Stack spacing="tight">
                                                    <Stack.Item>Exclude Products</Stack.Item>
                                                    <Stack.Item>
                                                        <Button size="slim" plain
                                                            onClick={()=>{
                                                                if(exclude_products.length < 1){
                                                                    this.setState({
                                                                        exclude_products_modal_open:true
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                        [ +Add ]
                                                        </Button>
                                                    </Stack.Item>
                                                    {
                                                        exclude_products.length > 0 && <Stack.Item>
                                                            <Button size="slim" plain
                                                                disabled={!exclude_products_checkbox}
                                                                onClick={()=>{
                                                                    this.setState({
                                                                        update_ex_products__modal_open:true
                                                                    });
                                                                    store('excludedProducts',this.state.exclude_products);
                                                                }}
                                                            >
                                                            [ Edit <label style={{borderBottom:'1px dotted'}}>{exclude_products.length}</label> ]
                                                            </Button>
                                                        </Stack.Item>
                                                    }
                                                </Stack>
                                            }
                                        />
                                    </Stack.Item>
                                </Stack>
                            </div>
                        }
                        {
                             this.state.collections.length > 0 && applies_to === "collections" && <div>
                                <Stack vertical spacing="extraTight">
                                    <Stack.Item>
                                        <Checkbox
                                            checked={this.state.exclude_products_checkbox}
                                            onChange={(value)=>{
                                                this.setState({
                                                    exclude_products_checkbox:value,
                                                    exclude_products_modal_open:value
                                                });
                                                
                                            }}
                                            label={
                                                <Stack spacing="tight">
                                                    <Stack.Item>Exclude Products</Stack.Item>
                                                    <Stack.Item>
                                                        <Button size="slim" plain
                                                            onClick={()=>{
                                                                this.setState({
                                                                    exclude_products_modal_open:true
                                                                });
                                                            }}
                                                        >
                                                        [ +Add ]
                                                        </Button>
                                                    </Stack.Item>
                                                    {
                                                        exclude_products.length > 0 && <Stack.Item>
                                                            <Button size="slim" plain
                                                                disabled={!exclude_products_checkbox}
                                                                onClick={()=>{
                                                                    this.setState({
                                                                        update_ex_products__modal_open:true
                                                                    });
                                                                    store('excludedProducts',this.state.exclude_products);
                                                                }}
                                                            >
                                                            [ Edit <label style={{borderBottom:'1px dotted'}}>{exclude_products.length}</label> ]
                                                            </Button>
                                                        </Stack.Item>
                                                    }
                                                </Stack>
                                            }
                                        />
                                    </Stack.Item>
                                </Stack>
                            </div>
                        }
                    </Stack>
                </Card.Section>
                {
                    (this.state.individual_product && this.state.products.length > 0 && applies_to === "products") &&
                    <Card.Section>
                        <Stack vertical spacing="tight">
                            <Stack.Item>
                                <div className="item--right">
                                    <Stack alignment="center">
                                        <ButtonGroup>
                                            <Button
                                                plain
                                                onClick={() => {
                                                    this.setState({extended_view:!this.state.extended_view})
                                                }}
                                            >
                                                {
                                                    this.state.extended_view?
                                                    <span><Icon source={MinimizeMinor} /></span>
                                                    :<span><Icon source={MaximizeMinor} /></span>
                                                }
                                            </Button>
                                            <Button
                                                plain
                                                ariaExpanded={this.state.productsListShow}
                                                ariaControls="collection-collapsible"
                                                onClick={() => {
                                                    this.setState({productsListShow: !this.state.productsListShow })
                                                }}
                                            >
                                                {
                                                    this.state.productsListShow ?
                                                    <span><Icon source={HideMinor}/></span>
                                                    :<span><Icon source={ViewMinor}/></span>
                                                }
                                            </Button>
                                        </ButtonGroup>
                                    </Stack>
                                </div>
                            </Stack.Item>
                            
                            <Stack.Item>
                                <Collapsible open={this.state.productsListShow} id="product-collapsible">
                                    <Stack vertical spacing="tight">
                                        <Stack.Item>
                                            { this.searchProductField() }
                                        </Stack.Item>
                                        <Stack.Item>
                                            <DataTable
                                              truncate={true}
                                              verticalAlign="middle"
                                              columnContentTypes={this.tableContentTypes()}
                                              headings={this.state.tableHeadingArray}
                                              rows={this.renderTableRows()}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                </Collapsible>
                            </Stack.Item>
                            {
                                this.state.productsListShow && 
                                <Stack.Item>
                                    <div className="pagination-individual">
                                        {this.renderLoadMoreProductsBtns()}
                                    </div>
                                </Stack.Item>
                            }
                        </Stack>
                    </Card.Section>
                }
                {
                    (!this.state.individual_product && this.state.products.length > 0 && applies_to === "products" && this.state.productsListShow) &&
                    <Card.Section>
                        <Stack vertical>
                            <Stack.Item>
                                <Collapsible open={this.state.productsListShow} id="products-collapsible">
                                    <DataTable
                                        truncate={true}
                                        verticalAlign="middle"
                                        columnContentTypes={[
                                            "text",
                                            "text"
                                        ]}
                                        headings={[
                                            "Actions",
                                            "Product"
                                        ]}
                                        rows={this.renderIndividualProductsRows()}
                                    />
                                </Collapsible>
                            </Stack.Item>
                        </Stack>
                    </Card.Section>
                }
                {/* collections */}
                {
                    (this.state.collections.length > 0 && applies_to === "collections" && this.state.collectionsListShow) && <Card.Section>
                        <Stack vertical>
                            <Stack.Item>
                                <Collapsible open={this.state.collectionsListShow} id="collection-collapsible">
                                    <DataTable
                                        truncate={true}
                                        verticalAlign="middle"
                                        columnContentTypes={[
                                            "text",
                                            "text"
                                        ]}
                                        headings={[
                                            "Actions",
                                            "Title"
                                        ]}
                                        rows={this.renderCollectionRows()}
                                    />
                                </Collapsible>
                            </Stack.Item>
                        </Stack>
                    </Card.Section>
                }
                {
                    (applies_to === "entire_store" || applies_to === "collections" || (applies_to === "products" && !this.state.individual_product)) &&
                    <Card.Section>
                        <Stack vertical>
                            <Stack.Item>
                                <Stack spacing="tight">
                                    <RadioButton
                                        label="Percent Off "
                                        // helpText={<span><TextStyle variation="strong">Percent Off</TextStyle> will set the wholesale price to a % off the current retail price of the product. (not the​ compare-at-price). If you change the price of the item in Shopify, the wholesale price will be adjusted as well.<br></br><TextStyle variation="strong">Example:</TextStyle> Let’s assume retail price of the item is $100 and you set 30% as “Percent Off” then customers will see $70 as final price. </span>}
                                        id="percentage"
                                        name="discount_type"
                                        checked={discount_type === 'percentage'}
                                        onChange={(c, v) => { this.setState({ discount_type: v }) }}
                                    />
                                    <RadioButton
                                        label="Price Off"
                                        // helpText={<span><TextStyle variation="strong">Price Off</TextStyle> will set the wholesale price to a fixed amount off the current retail price of the product.​ (not the compare-at-price). If you change the price of the item in Shopify, the wholesale price will be adjusted as well. <br></br><TextStyle variation="strong">Example:</TextStyle> Let’s assume retail price of the item is $100 and you set $25 as “Price Off” then customers will see $75 as final price.</span>}
                                        id="price_discount"
                                        name="discount_type"
                                        checked={discount_type === 'price_discount'}
                                        onChange={(c, v) => { this.setState({ discount_type: v }) }}
                                    />
                                    <RadioButton
                                        label="Fixed Price"
                                        // helpText={<span><TextStyle variation="strong">Fixed Price</TextStyle> will create a fixed wholesale price for the product. It won’t change if you change the retail price of the product in Shopify. <br></br><TextStyle variation="strong">Example:</TextStyle> Let’s assume retail price of the item is $100 and you set $70 as “Fixed Price” then customers will see $70 as final price. <br></br><TextStyle variation="strong">Note:</TextStyle> Price Per Item should be less than or equal to the retail price of the item.</span>}
                                        id="fixed_price"
                                        name="discount_type"
                                        checked={discount_type === 'fixed_price'}
                                        onChange={(c, v) => { this.setState({ discount_type: v }) }}
                                    />
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                {
                                    discount_type === 'percentage' && <span><TextStyle variation="strong">Percent Off</TextStyle> will set the wholesale price to a % off the current retail price of the product. (not the​ compare-at-price). If you change the price of the item in Shopify, the wholesale price will be adjusted as well.<br></br><TextStyle variation="strong">Example:</TextStyle> Let’s assume retail price of the item is $100 and you set 30% as “Percent Off” then customers will see $70 as final price. </span>
                                }
                                {
                                    discount_type === 'price_discount' && <span><TextStyle variation="strong">Price Off</TextStyle> will set the wholesale price to a fixed amount off the current retail price of the product.​ (not the compare-at-price). If you change the price of the item in Shopify, the wholesale price will be adjusted as well. <br></br><TextStyle variation="strong">Example:</TextStyle> Let’s assume retail price of the item is $100 and you set $25 as “Price Off” then customers will see $75 as final price.</span>
                                }
                                {
                                    discount_type === 'fixed_price' && <span><TextStyle variation="strong">Fixed Price</TextStyle> will create a fixed wholesale price for the product. It won’t change if you change the retail price of the product in Shopify. <br></br><TextStyle variation="strong">Example:</TextStyle> Let’s assume retail price of the item is $100 and you set $70 as “Fixed Price” then customers will see $70 as final price. <br></br><TextStyle variation="strong">Note:</TextStyle> Price Per Item should be less than or equal to the retail price of the item.</span>
                                }
                            </Stack.Item>
                        </Stack>
                        {this.volumeDiscountFields()}
                    </Card.Section>
                }
            </Card>
        </div>
        
        let section_5 = <div>
            <Card>
                <ResourceList
                    items={[
                        {
                            id: 1,
                            name: 'Set schedule for this Volume Pricing',
                            description: "You can set the time frame for the Volume Pricing activation and expiration"
                        }
                    ]}
                    renderItem={(item) => {
                        const {id, name, description} = item;
                        return (
                            <ResourceItem
                                id={id}
                                onClick={() => {
                                    if(id===1){
                                        this.setState({scheduleExpanded: !this.state.scheduleExpanded})
                                    }
                                }}
                                    // media={media}
                                    accessibilityLabel={`View details for ${name}`}
                                >
                                <h3>
                                    <div className="mega-menu--display-option">
                                        <div className="mega-menu--display-option--icon">
                                            {
                                                !this.state.scheduleExpanded? <Icon source={CaretDownMinor} /> : <Icon source={CaretUpMinor} />
                                            }
                                        </div>
                                        <div className="mega-menu--display-option--title">
                                            <TextStyle variation="strong">{name}</TextStyle>
                                        </div>
                                    </div>
                                </h3>
                                <div className="mega-menu--display-option--description">{description}</div>
                            </ResourceItem>
                        );
                    }}
                />
                {
                    this.state.scheduleExpanded &&
                    <Card.Section>
                        <Collapsible open={this.state.scheduleExpanded} id="schedule-collapsible">
                            <Stack vertical spacing="tight">
                                <div>
                                    <TextContainer>
                                        <TextStyle variation="subdued">Please note that the START DATE allows you to set the activation date and time and the SET END DATE allows you to set the expiration date and time. </TextStyle>
                                    </TextContainer>
                                </div>
                                <Checkbox
                                    checked={schedule_active}
                                    label="Schedule this Volume Pricing"
                                    onChange={(v) => {
                                        this.setState({ schedule_active: v })
                                    }}
                                    disabled={false}
                                />
                                {
                                    schedule_active &&
                                    <FormLayout>
                                        <FormLayout.Group>
                                            <Popover
                                                active={start_date_popup}
                                                activator={start_date_activator}
                                                onClose={(value) => { this.setState({ start_date_popup: false }) }}
                                                preferredPosition="above"
                                                sectioned
                                            >
                                                {start_date_piker}
                                            </Popover>
                                            <Select
                                                label={'Start time (EST)'}
                                                placeholder="Select time"
                                                options={this.time_options}
                                                onChange={(value) => { this.setState({ start_time: value }) }}
                                                value={start_time}
                                            >
                                            </Select>
                                        </FormLayout.Group>
                                    </FormLayout>
                                }
                                {
                                    schedule_active && <Checkbox
                                        checked={schedule_end_date}
                                        label="Set end date"
                                        onChange={(value) => {
                                            this.setState({ schedule_end_date: value })
                                        }}
                                    />
                                }
                                {
                                    (schedule_active && schedule_end_date) && <FormLayout>
                                    <FormLayout.Group>
                                            <Popover
                                                active={end_date_popup}
                                                activator={end_date_activator}
                                                onClose={(value) => { this.setState({ end_date_popup: false }) }}
                                                preferredPosition="above"
                                                sectioned
                                            >
                                                {end_date_piker}
                                            </Popover>
                                            <Select
                                                label={'End time (EST)'}
                                                placeholder="Select time"
                                                options={this.time_options}
                                                onChange={(value) => { this.setState({ end_time: value }) }}
                                                value={end_time}
                                                error={end_date_time_error ? true : false}
                                            ></Select>
                                        </FormLayout.Group>
                                    </FormLayout>
                                }
                            </Stack>
                        </Collapsible>
                    </Card.Section>
                }
            </Card>
        </div>;

        let section_6 = <div>
            <Card>
                <ResourceList
                    items={[
                        {
                            id: 2,
                            name: 'Volume Pricing table',
                            description: "Select Volume Pricing table design on product detail page"
                        }
                    ]}
                    renderItem={(item) => {
                        const {id, name, description} = item;
                        return (
                            <ResourceItem
                                id={id}
                                onClick={() => {
                                    if(id===2){
                                        this.setState({displayOptionExpanded: !this.state.displayOptionExpanded})
                                    }
                                }}
                                    // media={media}
                                    accessibilityLabel={`View details for ${name}`}
                                >
                                <h3>
                                    <div className="mega-menu--display-option">
                                        <div className="mega-menu--display-option--icon">
                                        {
                                            !this.state.displayOptionExpanded? <Icon source={CaretDownMinor} /> : <Icon source={CaretUpMinor} />
                                        }
                                        </div>
                                        <div className="mega-menu--display-option--title">
                                        <TextStyle variation="strong">{name}</TextStyle></div>
                                    </div>
                                </h3>
                                <div className="mega-menu--display-option--description">{description}</div>
                            </ResourceItem>
                        );
                    }}
                />
                {
                    this.state.displayOptionExpanded &&
                    <Card.Section>
                        <Collapsible open={this.state.displayOptionExpanded} id="displayOption-collapsible">
                            <div className="display-configurations" style={{ padding: "10px 0" }}>
                                <div className="main-div">
                                    <div className="width-div">
                                        <div className="custom-third">
                                            <label className="" htmlFor="custom_id_1">
                                                <RadioButton
                                                    label="Detailed Grid"
                                                    helpText=""
                                                    id="detailed_grid"
                                                    name="display_option"
                                                    checked={display_option === 'detailed_grid'}
                                                    onChange={(c, v) => { this.setState({ display_option: v }) }}
                                                />
                                            </label>
                                            <table className="custom-table">
                                                <thead>
                                                    <tr>
                                                        <th>Qty</th>
                                                        <th>Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Buy 1</td>
                                                        <td>$ 100.00 ea</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Buy 50</td>
                                                        <td>$ 95.00 ea</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Buy 100</td>
                                                        <td>$ 90.00 ea</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Buy 1000</td>
                                                        <td>$ 85.00 ea</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="custom-third">
                                            <label className="" htmlFor="custom_id_2">
                                                <RadioButton
                                                    label="Basic Grid"
                                                    helpText=""
                                                    id="basic_grid"
                                                    name="display_option"
                                                    checked={display_option === 'basic_grid'}
                                                    onChange={(c, v) => { this.setState({ display_option: v }) }}
                                                />
                                            </label>
                                            <table className="custom-table">
                                                <thead>
                                                    <tr>
                                                        <th>Qty</th>
                                                        <th>Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>$ 100.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td>50</td>
                                                        <td>$ 95.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td>100</td>
                                                        <td>$ 90.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td>1000</td>
                                                        <td>$ 85.00</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="custom-third">
                                            <label className="" htmlFor="custom_id_3">
                                                <RadioButton
                                                    label="Grid Range"
                                                    helpText=""
                                                    id="grid_range"
                                                    name="display_option"
                                                    checked={display_option === 'grid_range'}
                                                    onChange={(c, v) => { this.setState({ display_option: v }) }}
                                                />
                                            </label>
                                            <table className="custom-table">
                                                <thead>
                                                    <tr>
                                                        <th>Minimum Qty</th>
                                                        <th>Maximum Qty</th>
                                                        <th>Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>49</td>
                                                        <td>$ 100.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td>50</td>
                                                        <td>99</td>
                                                        <td>$ 95.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td>100</td>
                                                        <td>999</td>
                                                        <td>$ 90.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td>1000</td>
                                                        <td>+</td>
                                                        <td>$ 85.00</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="custom-third">
                                            <label className="" htmlFor="custom_id_4">
                                                <RadioButton
                                                    label="Detailed Grid Percent"
                                                    helpText=""
                                                    id="detailed_grid_percent"
                                                    name="display_option"
                                                    checked={display_option === 'detailed_grid_percent'}
                                                    onChange={(c, v) => { this.setState({ display_option: v }) }}
                                                />
                                            </label>
                                            <table className="custom-table">
                                                <thead>
                                                    <tr>
                                                        <th>Qty</th>
                                                        <th>Price</th>
                                                        <th>Discount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Buy 1</td>
                                                        <td>$ 100.00 ea</td>
                                                        <td>0% Off</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Buy 50</td>
                                                        <td>$ 95.00 ea</td>
                                                        <td>5% Off</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Buy 100</td>
                                                        <td>$ 90.00 ea</td>
                                                        <td>10% Off</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Buy 1000</td>
                                                        <td>$ 85.00 ea</td>
                                                        <td>15% Off</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="custom-third">
                                            <label className="" htmlFor="custom_id_5">
                                                <RadioButton
                                                    label="Percent Grid"
                                                    helpText=""
                                                    id="percent_grid"
                                                    name="display_option"
                                                    checked={display_option === 'percent_grid'}
                                                    onChange={(c, v) => { this.setState({ display_option: v }) }}
                                                />
                                            </label>
                                            <table className="custom-table">
                                                <thead>
                                                    <tr>
                                                        <th>Qty</th>
                                                        <th>Discount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Buy 1</td>
                                                        <td>0% Off</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Buy 50</td>
                                                        <td>5% Off</td>
                                                    </tr>     <tr>
                                                        <td>Buy 100</td>
                                                        <td>10% Off</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Buy 1000</td>
                                                        <td>15% Off</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="custom-third">
                                            <label className="" htmlFor="custom_id_6">
                                                <RadioButton
                                                    label="Grid Range Alternate"
                                                    helpText=""
                                                    id="grid_range_alternate"
                                                    name="display_option"
                                                    checked={display_option === 'grid_range_alternate'}
                                                    onChange={(c, v) => { this.setState({ display_option: v }) }}
                                                />
                                            </label>
                                            <table className="custom-table">
                                                <thead>
                                                    <tr>
                                                        <th>Qty</th>
                                                        <th>Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1 - 49</td>
                                                        <td>$ 100.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td>50 - 99</td>
                                                        <td>$ 95.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td>100 - 999</td>
                                                        <td>$ 90.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td>1000 - +</td>
                                                        <td>$ 85.00</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Collapsible>
                    </Card.Section>
                }
            </Card>
        </div>;

        let page_actions = <PageActions
            secondaryActions={[
                { content: 'Cancel', url: '/volume-discount/list', target: "APP" },
            ]}
            primaryAction={{
                loading: this.state.save_btn_loading,
                content: "Save Volume Pricing",
                onAction: () => {this.saveQuantityBreak()}
            }}
        />;

        let errors_section = <div>
            <Banner status="critical" title={`There ${this.state.errorList.length > 1?"are":"is"} ${this.state.errorList.length } ${this.state.errorList.length > 1?"errors":"error"}`}>
                <List type="number">
                {
                    this.state.errorList.map((error,iN) => {
                        return <List.Item key={iN}>{error}</List.Item>
                    })
                }
                </List>
            </Banner>
        </div>

        let renderSections = <Layout>
            {
                this.state.errorList.length > 0 &&
                <Layout.Section>{errors_section}</Layout.Section>
            }
            <Layout.Section>{section_0}</Layout.Section>
            <Layout.Section>{section_4}</Layout.Section>
            <Layout.Section>{section_2}</Layout.Section>
            <Layout.Section>{section_1}</Layout.Section>
            <Layout.Section>{section_5}</Layout.Section>
            <Layout.Section>{section_6}</Layout.Section>
            <Layout.Section>{page_actions}</Layout.Section>
        </Layout>;
        
        if(this.state.extended_view){
            renderSections = <Layout><Layout.Section>
                <Card>
                    <Card.Section>
                        <Stack vertical spacing="tight">
                            <Stack.Item>
                                <Stack>
                                    <Stack.Item>
                                        <Button
                                            onClick={() => {
                                                this.setState({ product_modal_open: true });
                                            }}
                                        >Add products
                                        </Button>
                                    </Stack.Item>
                                    <Stack.Item fill>
                                        <div className="minimize--window">
                                            <Button
                                                plain
                                                onClick={() => {
                                                    this.setState({extended_view:!this.state.extended_view})
                                                }}
                                            >
                                            {
                                                this.state.extended_view?
                                                <span><Icon source={MinimizeMinor} /></span>
                                                :<span><Icon source={MaximizeMinor} /></span>
                                            }
                                            </Button>
                                        </div>
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                { this.searchProductField() }
                            </Stack.Item>
                            <Stack.Item>
                                <DataTable
                                  truncate={true}
                                  verticalAlign="middle"
                                  columnContentTypes={this.tableContentTypes()}
                                  headings={this.state.tableHeadingArray}
                                  rows={this.renderTableRows()}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <div className="pagination-individual">
                                    {this.renderLoadMoreProductsBtns()}
                                </div>
                            </Stack.Item>
                        </Stack>
                    </Card.Section>
                </Card>
            </Layout.Section></Layout>
        }
        var toast = this.state.showToast? (<ToastMessage message={this.state.toastMsg} error={this.state.toastError} onDismiss={()=>{this.setState({showToast: false})}} />):(null);
        if(this.state.page_loaded){
            if(this.state.extended_view){
                return(
                    <div>
                        {resource_pikers_And_models}
                        {renderSections}
                        {toast}
                    </div>
                )
            }
            else{
                return(
                    <div>
                        <PageTitleBar
                            title={this.props.show === "edit"?"Edit Volume Pricing":"Create Volume Pricing"}
                            secondaryActions={[
                                { content: 'Back', url: '/volume-discount/list', target: 'APP' }
                            ]}
                            primaryAction={{
                                disabled: this.state.save_btn_loading,
                                content: "Save Volume Pricing",
                                onAction: () => {this.saveQuantityBreak()}
                            }}
                        />
                        {resource_pikers_And_models}
                        {renderSections}
                        {toast}
                    </div>
                )
            }
        }
        else{
            return(
                <SkeletonPageComp />
            )
        }
    }

    time_options = [
        { label: '12:00 am', value: '00:00:00' },
        { label: '12:30 am', value: '00:30:00' },
        { label: '01:00 am', value: '01:00:00' },
        { label: '01:30 am', value: '01:30:00' },
        { label: '02:00 am', value: '02:00:00' },
        { label: '02:30 am', value: '02:30:00' },
        { label: '03:00 am', value: '03:00:00' },
        { label: '03:30 am', value: '03:30:00' },
        { label: '04:00 am', value: '04:00:00' },
        { label: '04:30 am', value: '04:30:00' },
        { label: '05:00 am', value: '05:00:00' },
        { label: '05:30 am', value: '05:30:00' },
        { label: '06:00 am', value: '06:00:00' },
        { label: '06:30 am', value: '06:30:00' },
        { label: '07:00 am', value: '07:00:00' },
        { label: '07:30 am', value: '07:30:00' },
        { label: '08:00 am', value: '08:00:00' },
        { label: '08:30 am', value: '08:30:00' },
        { label: '09:00 am', value: '09:00:00' },
        { label: '09:30 am', value: '09:30:00' },
        { label: '10:00 am', value: '10:00:00' },
        { label: '10:30 am', value: '10:30:00' },
        { label: '11:00 am', value: '11:00:00' },
        { label: '11:30 am', value: '11:30:00' },
        { label: '12:00 pm', value: '12:00:00' },
        { label: '12:30 pm', value: '12:30:00' },
        { label: '01:00 pm', value: '13:00:00' },
        { label: '01:30 pm', value: '13:30:00' },
        { label: '02:00 pm', value: '14:00:00' },
        { label: '02:30 pm', value: '14:30:00' },
        { label: '03:00 pm', value: '15:00:00' },
        { label: '03:30 pm', value: '15:30:00' },
        { label: '04:00 pm', value: '16:00:00' },
        { label: '04:30 pm', value: '16:30:00' },
        { label: '05:00 pm', value: '17:00:00' },
        { label: '05:30 pm', value: '17:30:00' },
        { label: '06:00 pm', value: '18:00:00' },
        { label: '06:30 pm', value: '18:30:00' },
        { label: '07:00 pm', value: '19:00:00' },
        { label: '07:30 pm', value: '19:30:00' },
        { label: '08:00 pm', value: '20:00:00' },
        { label: '08:30 pm', value: '20:30:00' },
        { label: '09:00 pm', value: '21:00:00' },
        { label: '09:30 pm', value: '21:30:00' },
        { label: '10:00 pm', value: '22:00:00' },
        { label: '10:30 pm', value: '22:30:00' },
        { label: '11:00 pm', value: '23:00:00' },
        { label: '11:30 pm', value: '23:30:00' }
    ]

}

function getValue(value){
	if(value){
		try {
            value = value.toString();
        } catch (e) { }
    }
    return value;
}