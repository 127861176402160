import React, { Component } from 'react';
import {Layout,Card,TextStyle} from '@shopify/polaris';
export default class FreeInstallationSupport extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <Layout>
        <Layout.Section>
          <Card sectioned>
          <div clas="margin8px">
          Do you want help to configure wholesale application to your theme? You're at right place. Please go ahead and create staff account for us.
          </div>

          <div className="margin8px">Here is direct link to create staff account :</div>
          <div className="margin8px">
            <a href={"https://"+this.props.shop+"/admin/settings/account"} target="new">https://{this.props.shop}/admin/settings/account</a>
          </div>

          <div className="staff-account-info">
          <div><TextStyle variation="strong">Staff Information:</TextStyle></div>
            <div><TextStyle variation="code">First Name: Wholesale</TextStyle></div>
            <div><TextStyle variation="code">Last Name: Installation</TextStyle></div>
            <div><TextStyle variation="code">Email: support@digitalcoo.com</TextStyle></div>
          </div>
          {/* <p>Here are instructions to add staff account, Please visit this link</p>
           */}
          {/* <div className="margin8px">
          After adding as staff, please send an email to <TextStyle variation="strong">support@digitalcoo.com</TextStyle> so that we can start the installation process.
          </div> */}
          <div className="margin8px">
          For any additional information write us an email to <TextStyle variation="strong">support@digitalcoo.com</TextStyle>
          </div> 
          </Card>
        </Layout.Section>
      </Layout>
    );
  }
}