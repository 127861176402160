import React, { Component } from 'react';
import { Link } from '@shopify/polaris';
import axios from 'axios';
import {
  Card,
  ResourceList,
  TextStyle,
  Button,
  SkeletonPage,
  Layout,
  SkeletonBodyText,
  TextContainer,
  SkeletonDisplayText,
  EmptyState,
  Badge,
  Tooltip,
  ButtonGroup,
  Banner, ResourceItem
} from '@shopify/polaris';
import * as PropTypes from 'prop-types';
import FooterHelpLinks from './FooterHelpLinks';
import ToastMessage from '../Common/ToastMessage';
import {Modal} from '@shopify/app-bridge-react';
import PageTitleBar from '../Common/TitleBar';

var interval = 0;
export default class QuantityBreaksV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      qb_data: [],
      filter_qb_data: [],
      host_name: this.props.host_name,
      shop_id: this.props.shop_id,
      searchValue: '',
      appliedFilters: [
        // {
        //   key: 'accountStatusFilter',
        //   value: 'Account enabled',
        // },
      ],
      shop: this.props.shop,
      page_loaded: false,
      est_time: undefined,
      selected_quantity_breaks: [],
      enable_btn_loading: false,
      disable_btn_loading: false,
      delete_btn_loading: false,
      loading: false,
      import_qb_modal: false,
      sort_by: 'DATE_CREATED_DESC',
      showToast: false,
      toastMsg: '',
      toastError: false
    };
    this.handleSortChange = this.handleSortChange.bind(this);
  }
  static contextTypes = {
      easdk: PropTypes.object,
  };
  showToastMessage(msg, error){
    this.setState({
      showToast: true,
      toastMsg: msg,
      toastError: error||false,
    });
  }
  
  dissmissToast = () =>{
    this.setState({
      showToast: false,
    });
  }

  handleSortChange(sort_by) {
    this.setState({ sort_by });
    this.fetch_all_qb(sort_by);
  }
  handleSelectionChange = (selectedItems) => {
    var temp_items = [];
    if(selectedItems.length > 0){
      selectedItems.forEach(x=>{
        temp_items.push(this.state.qb_data[x]._id);
      })
    }
    this.setState({
      selectedItems:selectedItems,
      selected_quantity_breaks: temp_items
    });
    this.fetch_est_time();
  };
  renderItem = (item,id) => {
    const{_id,qb_internal_name,customers_group,customer_tag,qb_applies_to,products,variants,collections,status }=item;
    const shortcutActions = qb_applies_to ?
    [
      {content: 'Edit', target:'app' ,url: '/edit-quantity-breaks/'+_id,disabled:this.status_button_en_dis(item)},
      {content: status === 'published'?'Disable':'Enable',disabled:this.status_button_en_dis(item), onAction: () =>{status === 'published'?  this.disable_quantity_break(item): this.enable_quantity_break(item)}},
      {content: 'Delete', onAction: ()=>{ this.delete_qb(item)}}
    ]
    :null;
    const mainTitleSection = (
      <div className="CustomerListItem__Profile ">
        <h3 className="CustomerListItem__Title">
        <TextStyle variation="strong">{
            <span>
              {qb_internal_name || 'No title'} {customers_group === "tag_based"?<span className="custom-tag-style">{customer_tag}</span>:null}
            </span>
          }</TextStyle>
          </h3>
        <div className="CustomerListItem__Location">
            {customers_group === "all"?"All customers":
            customers_group === "tag_based"?"Tag based customers":
            "Logged in customers"} - {qb_applies_to.replace(/_/g,' ')}
            {
              qb_applies_to === 'selected_products'?' ('+products.length+')':
              qb_applies_to === 'selected_collections'?' ('+collections.length+')':
              qb_applies_to === 'selected_variants'?' ('+variants.length+')':null
            } - {
                  <span style={{ padding: '75px 0' }}>
                    <Tooltip light={false} content={
                      item.volume_discount.map((element,key) => {
                        return <div key={key}>
                          {element.qty} - {item.volume_discount[key+1]?item.volume_discount[key+1].qty-1:'+'} => 
                          {
                            (item.qb_calculation_type!=='percent_discount'?' $':' ') +  element.value + (item.qb_calculation_type==='percent_discount'?'% ':'')
                          }
                        </div>
                      })
                    }>
                    <Link onClick={this.doNotUpdate} className="show-discount-levels-tooltip" to='#'>Show discount levels</Link>
                    </Tooltip>
                  </span>
                }
        </div>
      </div>
    );
    const contentSection = (
      <div className="CustomerListItem__Status">
        {/* <div className="CustomerListItem__UsageCount">
          {usage} times used
        </div> */}
        <div className="CustomerListItem__StatusBadge">
          {
            this.status_button_en_dis(item)?
            <Badge status='warning' progress="incomplete">
            <Link className="no_link_style" to={'/pricing-plans'}>Upgrade plan</Link>
            </Badge>
            :item.status === 'published'?
            <Badge status="success" progress="complete">Active</Badge>:
            item.status==='unpublished'?<Badge>Expired</Badge>
            :item.status === 'expired'?
            <Badge>Expired</Badge>
            :item.schedule_active && item.status !== 'expired'?
              <Tooltip content={
                <span>
                  <div>Start:
                  {
                    new Date(this.get_est_dateTime(item.schedule.start_date,item.schedule.start_time_est)).toLocaleDateString('en-US',{year: 'numeric', month: 'short', day: 'numeric',hour:'numeric',minute:'numeric'})
                  }
                  </div>
                  <div>
                    End: 
                    {
                      item.schedule.end_date?
                      new Date(this.get_est_dateTime(item.schedule.end_date,item.schedule.end_time_est)).toLocaleDateString('en-US',{year: 'numeric', month: 'short', day: 'numeric',hour:'numeric',minute:'numeric'})
                      :"Never expires"
                    }
                  </div>
                </span>
              }>
                <Badge status="attention">
                <Link onClick={this.doNotUpdate} className="help_and_info_text" style={{color:'#595130'}} to='#'>Scheduled</Link>
                </Badge>
              </Tooltip>
            :<Badge>Expired</Badge>
          }
          {
            item.schedule_active && item.status !== 'expired' && item.status !== 'schedule' && item.schedule.end_date?
            <span id={'id'+id} className="custom-badge">
              {
                this.schedule_ends_in(item,id)
              }
              </span>
            :null
          }
        </div>
      </div>
    );
    return (
      <div className="CustomerListItem">
        <ResourceItem
          id={id}
          shortcutActions={shortcutActions}
        >
        <div className="CustomerListItem__Main">
          {mainTitleSection}
          {contentSection}
        </div>
        </ResourceItem>
      </div>
    );
  };
  componentDidMount() {
    this.clearAllInterval(interval);
    this.fetch_all_qb(this.state.sort_by);
  }
  componentWillUnmount() {
    this.clearAllInterval(interval);
  }
  componentDidCatch(){
    this.clearAllInterval(interval);
  }
  clearAllInterval = (interval) =>{
    for (var i = 1; i <= interval; i++){
      clearInterval(i);
    }
  };
  fetch_all_qb(sort_by){
    this.clearAllInterval(interval);
    axios.get(this.state.host_name+'/qb/get-all/'+this.props.shop+'/sort-by/'+sort_by)
    .then(response => {
      this.setState({
          page_loaded: true,
          qb_data: response.data,
          loading: false,
          filter_qb_data:response.data,
          selectedItems: []
      });
      this.fetch_est_time();
    })
    .catch(error => {
      this.setState({loading:false});
     
      this.fetch_est_time();
    });
  }
  fetch_est_time(){
    this.clearAllInterval(interval);
    axios.get(this.state.host_name+'/est-time-now/v2')
    .then(time=>{
      this.clearAllInterval(interval);
      this.setState({est_time:time.data.current_est_time});
    }).catch(err=>{
    });
  }
  delete_qb(item){
    if(window.confirm("Delete quantity break?")){
      const newData = this.state.qb_data.filter(i => i._id !== item._id);
      this.setState({qb_data:newData,loading:false});
      this.setState({loading:true});
      this.fetch_est_time();
      axios.delete(this.state.host_name+'/qb/delete/'+item._id+'/'+this.props.shop)
      .then( response => {
          if(response.data.status === 1){
            this.showToastMessage(response.data.message);
            this.fetch_all_qb(this.state.sort_by);
          }
          else{
            this.setState({loading:false});
            this.showToastMessage(response.data.message, true);
            this.fetch_est_time();
          }
          this.setState({selectedItems: []});
        }
      ).catch(err => {
        this.setState({loading:true});
        this.fetch_est_time();
      });
    }
  }
  disable_quantity_break(item){
      this.setState({loading:true});
      this.fetch_est_time();
      axios.put(this.state.host_name+'/qb/disable/'+this.props.shop,item)
      .then( response => {
          if(response.data.status === 1){
            this.showToastMessage(response.data.message);
            this.fetch_all_qb(this.state.sort_by);
          }else{
            this.setState({loading:false});
            this.fetch_est_time();
            this.showToastMessage(response.data.message, true);
          }
          this.setState({selectedItems: []});
        }
      ).catch(err => {
        this.setState({loading:false});
        this.fetch_est_time();
       
      });
  }
  enable_quantity_break(item){
    this.setState({loading:true});
    this.fetch_est_time();
    axios.put(this.state.host_name+'/qb/enable/'+this.props.shop,item)
    .then( response => {
        if(response.data.status === 1){
          this.showToastMessage(response.data.message);
          this.fetch_all_qb(this.state.sort_by);
        }
        else{
          this.setState({loading:false});
          this.fetch_est_time();
          this.showToastMessage(response.data.message, true);
        }
        this.setState({selectedItems: []});
      }
    ).catch(err => {
      this.setState({loading:false});
      this.fetch_est_time();
     
    });
  }
  getTimezoneOffset() {
    try{
      function z(n){return (n<10? '0' : '') + n}
      var offset = new Date().getTimezoneOffset();
      var sign = offset < 0? '+' : '-';
      offset = Math.abs(offset);
      return sign + z(offset/60 | 0) + z(offset%60);
    }catch(err){}
  }
  get_est_dateTime(date,time){
    try{
      let dateTimeValue = new Date(date);
      if(typeof dateTimeValue === 'object'){
        dateTimeValue = dateTimeValue.toString();
        if(typeof dateTimeValue === 'string'){
          dateTimeValue = dateTimeValue.replace(this.getTimezoneOffset(), '+0000');
        }
      }
      dateTimeValue = new Date(dateTimeValue).toISOString();
      dateTimeValue = dateTimeValue.split('T')[0] + " " +time;
      return dateTimeValue;
    }catch(err){}
  }
  schedule_ends_in(item,counter){
    let clockId = 'id'+counter;
    try{
      this.countDown(new Date((item.schedule.end_date).split('T')[0]+" "+item.schedule.end_time_est),clockId);
    }catch(error){}
  }
  countDown(end,clockId){
    let result;
    let est_time = this.state.est_time;
    let start = new Date(est_time.year,est_time.month,est_time.date,est_time.hours,est_time.minutes,est_time.seconds,est_time.milliseconds);
    try{
      if(typeof start !== 'object' || typeof end !== 'object'){return undefined; }
      start = start.getTime();
      end = end.getTime();
      var timeSpan = end - start;
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(timeSpan / (1000 * 60 * 60 * 24));
      var hours = Math.floor((timeSpan % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((timeSpan % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((timeSpan % (1000 * 60)) / 1000);
      result = {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds
      };
      if(result.seconds < 0){
        result.minutes--;
        result.seconds += 60;
      }
      if(result.minutes < 0){
        result.hours--;
        result.minutes += 60;
      }
      if(result.hours < 0){
        result.days--;
        result.hours += 24;
      }
      if(result.days > 0 || result.hours > 0 || result.minutes > 0 || result.seconds > 0){
        interval = setInterval(startCountDown,1000);
      }
      function startCountDown(){
        result.seconds--;
        if(result.seconds < 0){
          result.minutes--;
          result.seconds += 60;
        }
        if(result.minutes < 0){
          result.hours--;
          result.minutes += 60;
        }
        if(result.hours < 0){
          result.days--;
          result.hours += 24;
        }
        if(result.days < 0 || result.hours < 0 || result.minutes < 0 || result.seconds < -1){
          clearInterval(interval);
          if(clockId !== null){
            try{
              let CLOCKID = document.getElementById(clockId);
              if(CLOCKID !== null){
                CLOCKID.innerHTML = '';
              }
            }catch(e){}
          }
          try{
            window.location.reload(true);
          }catch(e){}
        }
        else{
          let ends_message = '';
          if(result.days > 0){
            ends_message += result.days+'d ';
          }
          if(result.hours > 0){
            ends_message += result.hours+'h ';
          }
          if(result.minutes > 0){
            let minutes = result.minutes;
            if(result.minutes < 10){
              minutes = '0'+minutes;
            }
            ends_message += minutes+'m ';
          }
          if(result.seconds >= 0){
            let seconds = result.seconds;
            if(result.seconds < 10){
              seconds = '0'+seconds;
            }
            ends_message += seconds+'s ';
          }
          if(ends_message !== ''){
            if(clockId !== null){
              try{
                let CLOCKID = document.getElementById(clockId);
                if(CLOCKID !== null){
                  CLOCKID.innerHTML = ends_message;
                }
              }catch(e){
                clearInterval(interval);
              }
            }
          }
        }
        window.onbeforeunload = function(){
          clearInterval(interval);
        };
      }
      return result;
    }
    catch(e){
      clearInterval(interval);
    }
  }
  doNotUpdate = (e) => {
    e.preventDefault();
  }
  upgrade = () => {
    this.props.history.push('/pricing-plans');
  }
  status_button_en_dis = (item) => {
    let plan = this.props.user_current_plan;
    if(plan.plan_permissions){
      if(!plan.plan_permissions[0].volume_discount){
        return true;
      }
      else if(!plan.plan_permissions[0].variants && item.qb_applies_to === 'selected_variants'){
        return true;
      }
      else{
        return false;
      }
    }
    else{
      return false;
    }
  };
  bulk_delete = (selected_items) => {
    if(window.confirm("Delete all selected rules permanently?")){
      this.setState({loading:true});
      this.fetch_est_time();
      axios.put(this.state.host_name+'/bulk-delete-quantity-break/'+this.props.shop,{
        selected_items: selected_items,
        shop: this.props.shop
      })
      .then( response => {
          if(response.data.status === 1){
            this.showToastMessage(response.data.message);
            this.fetch_all_qb(this.state.sort_by);
          }else{
            this.showToastMessage(response.data.message, true);
            this.setState({loading:false});
            this.fetch_est_time();
          }
          this.setState({selectedItems: []});
        }
      ).catch(err => {
       
        this.setState({loading:false});
        this.fetch_est_time();
      });
    }
  }
  handleSearchChange = (searchValue) => {
    // console.log(this.state.qb_data);
    var _data = this.state.qb_data;
    
    _data = _data.filter((x)=>x.qb_internal_name.toLowerCase().indexOf(searchValue.toLowerCase())!==-1);
    // console.log(_data);
    this.setState({
      filter_qb_data: _data,
      searchValue:searchValue
    });
  };

  handleFiltersChange = (appliedFilters) => {
    // this.setState({appliedFilters});
    var _data = this.state.qb_data;
    
    _data = _data.filter((x)=> appliedFilters.findIndex((y)=>y.key==="tagBased" && y.value.toLowerCase().indexOf(x.customer_tag.toLowerCase()!==-1))!==-1);
    // console.log(_data);
    this.setState({
      filter_qb_data: _data,
      appliedFilters:appliedFilters
    });
  };
  render() {
    const {sort_by} = this.state;
    this.clearAllInterval(interval);
    const plan = this.props.user_current_plan;
    const skeletonPage = <SkeletonPage fullWidth={true}>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>;
    const add_qb_buttons = <div style={{margin:"1rem 0rem"}}>
      <ButtonGroup>
        <Button primary size="slim" url="/create-quantity-break" target="APP">Create Quantity Break</Button>
        <Button outline size="slim" onClick={()=>{
          this.setState({import_qb_modal:true});
          this.fetch_est_time();
        }}
        >Import</Button>
        <Button outline size="slim" onClick={()=>{
          this.fetch_est_time();
          window.top.location.href = this.props.host_name+'/admin/export/csv/quantity-breaks/'+this.props.shop;
        }}
        >Export All</Button>
      </ButtonGroup>
    </div>
    const plan_upgrade_message = <div style={{marginBottom:'2rem'}}>
      <Banner status="warning" title="Upgrade Your Payment Plan to Premium"
      action={{content: 'Upgrade Plan', url: '/pricing-plans', target: "APP"}}
      >
      Quantity Break feature only works with Premium plan.
      </Banner>
    </div>;
    const resourceName = {
      singular: 'Quantity break',
      plural: 'Quantity breaks',
    };
    const sortOptions = [
      { label: 'Newest', value: 'DATE_CREATED_DESC' },
      { label: 'Oldest', value: 'DATE_CREATED_ASC' },
      { label: 'Most used', value: 'MOST_USED_DESC' },
      { label: 'Least used', value: 'MOST_USED_ASC' }
    ];
    const promotedBulkActions = [
      {
        content: 'Export selected',
        onAction: () => {
          var selected_ids = JSON.stringify(this.state.selected_quantity_breaks);
          window.top.location.href = this.props.host_name+'/admin/bulk-export/csv/quantity-break/'+this.props.shop+'/'+selected_ids;
        }
      },
      {
        content: 'Delete selected',
        onAction: () => {
          this.bulk_delete(this.state.selected_quantity_breaks);
        },
      }
    ];
    const importModalSection = <div>
      <Modal
        size="Medium"
        src={'/import-quantity-breaks'}
        open={this.state.import_qb_modal}
        onClose={() => {
          this.setState({import_qb_modal: false});
          this.fetch_est_time();
        }}
        title={'Import quantity break by CSV file'}
      >
      </Modal>
    </div>;

    var toast = this.state.showToast? (
      <ToastMessage
      onDismiss={this.dissmissToast}
      message={this.state.toastMsg}
      error={this.state.toastError}
      />
    ): (null);
    
    
    if(this.state.page_loaded === false){
      return(
        <div>{skeletonPage}</div>
      );
    }
    else{
      if(this.state.qb_data.length < 1){
        return(
          <div>
            <PageTitleBar
              title="Volume Discount"
            />
            {toast}
            {importModalSection}
            <Layout>
            <EmptyState
              heading="Create a quantity break"
              action={{content: 'Create Quantity Break', url: '/create-quantity-break', target: "APP"}}
              // secondaryAction={{content: 'Import quantity break',onClick: ()=>{
              //   this.setState({import_qb_modal:true});
              //   this.fetch_est_time();
              // }}}
              image="https://cdn.shopifycloud.com/web/assets/6abcecfa5637ef0dced77481e9c7069e.svg"
            >
            <p>Quantity Breaks feature for offering volume pricing in your shop. Add tiered pricing and quantity breaks to any product or collection in just a few clicks.</p>
            </EmptyState>
            </Layout>
            <div className="youtube-video-player">
            </div>
            <FooterHelpLinks help={this.props.help}/>
          </div>
        );
      }
      else{
        return (
          <div>
            <PageTitleBar
              title="Volume Discounts"
            />
            {toast}
            {importModalSection}
            {add_qb_buttons}
            {plan.plan_permissions?!plan.plan_permissions[0].volume_discount?plan_upgrade_message:null:null}
            <Card>
              {
                this.state.filter_qb_data && this.state.filter_qb_data.length > 0 ?
                  (<ResourceList
                    showHeader={true}
                    resourceName={resourceName}
                    items={this.state.filter_qb_data}
                    renderItem={this.renderItem} 
                    selectedItems={this.state.selectedItems}
                    onSelectionChange={this.handleSelectionChange}
                    promotedBulkActions={promotedBulkActions}
                    persistActions
                    loading={this.state.loading}
                    sortOptions={sortOptions}
                    sortValue={sort_by}
                    onSortChange={this.handleSortChange}
                  />): null
              }
            </Card>
            <FooterHelpLinks help={this.props.help}/>
          </div>
        );
      }
    }
  }
}