import React, { Component } from "react";
import { ExceptionList } from "@shopify/polaris";

export default class Unlock extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            q1_expanded: false,
            q2_expanded: false,
            q3_expanded: false,
            q4_expanded: false
        };
    }

  state = {};
  render() {
    // const {q1_expanded,q2_expanded,q3_expanded,q4_expanded} = this.state;

    // const q1 = <div style={{margin: "12px"}}>
    //     <Stack vertical>
    //     <Button plain id="text-left"
    //         onClick={ () => {
    //                 this.setState({
    //                     q1_expanded: !q1_expanded
    //                 });
    //             }
    //         }
    //         ariaExpanded={q1_expanded}
    //     >
    //         I’ve downloaded the app and set up my Discounts [ Automatic Disoucnts or Quantity Breaks ], but my discounts aren’t working. Have you had Wholesale All In One configured by one of our developers?
    //     </Button>
    //     <Collapsible open={q1_expanded} id="duplicate-theme">
    //         <p>
    //         The first installation is free and all you have to do is email us at support@digitalcoo.com and include your Shopify URL in the email. I changed my theme and my discounts no longer work. Wholesale All In One relies on custom code added to your theme files. If you've recently changed or updated your theme, you may have affected this code and require a reinstallation. Please write us an email at support@digitalcoo for reinstallation. Does discounts work when a customer uses Paypal. Wholesale discounts are applied when the customer moves from the cart page to the checkout. When a customer selects PayPal in the cart page, they bypass this stage and their wholesale discounts will not be applied to the order. We move PayPal button from the cart page to the checkout during the configurations process as results customer can choose to pay with PayPal at the checkout instead of cart page.
    //         </p>
    //     </Collapsible>
    //     </Stack>
    // </div>;
    
    // const q2 = <div style={{margin: "12px"}}>
    //     <Stack vertical>
    //     <Button plain id="text-left"
    //         onClick={ () => {
    //                 this.setState({
    //                     q2_expanded: !q2_expanded
    //                 });
    //             }
    //         }
    //         ariaExpanded={q2_expanded}
    //     >
    //         Can I allow wholesale customers to use additional discount codes with their order?
    //     </Button>
    //     <Collapsible open={q2_expanded} id="get-started">
    //         <p>
    //         Yes. You can enable this under Dashbarod -> Settings, as result coupon code input field will apear on the cart page.
    //         </p>
    //     </Collapsible>
    //     </Stack>
    // </div>;
    
    // const q3 = <div style={{margin: "12px"}}>
    //     <Stack vertical>
    //     <Button plain id="text-left"
    //         onClick={ () => {
    //                 this.setState({
    //                     q3_expanded: !q3_expanded
    //                 });
    //             }
    //         }
    //         ariaExpanded={q3_expanded}
    //     >
    //         Can I add special shipping rates for wholesale customers?
    //     </Button>
    //     <Collapsible open={q3_expanded} id="usage-tracking">
    //         <p>
    //         By default, wholesale customers receive the same shipping rate as retail customers. However, You can create a flat shipping rate or a free shipping rate for wholesale customers under Dashboard -> Shipping Control.
    //         </p>
    //     </Collapsible>
    //     </Stack>
    // </div>;

    // const q4 = <div style={{margin: "12px"}}>
    //     <Stack vertical>
    //     <Button plain id="text-left"
    //         onClick={ () => {
    //                 this.setState({
    //                     q4_expanded: !q4_expanded
    //                 });
    //             }
    //         }
    //         ariaExpanded={q4_expanded}
    //     >
    //         How to setup discount rule usage?
    //     </Button>
    //     <Collapsible open={q4_expanded} id="usage-tracking">
    //         <TextContainer>
    //             <p>To setup discount rule usage follow these steps</p>
    //             <List type="number">
    //                 <List.Item>
    //                     Go to <TextStyle variation="strong">Installations</TextStyle>
    //                 </List.Item>
    //                 <List.Item>
    //                     From the <TextStyle variation="strong">Usage tracking </TextStyle> section, copy text from <TextStyle variation="strong">Additional script </TextStyle> text box.
    //                 </List.Item>
    //                 <List.Item>
    //                 From the Shopify Settings, click <TextStyle variation="strong">Checkout.</TextStyle>
    //                 </List.Item>
    //                 <List.Item>
    //                     From the <TextStyle variation="strong">Additional scripts </TextStyle> text box, paste the code that was copied in step 2.
    //                 </List.Item><List.Item>
    //                     Save the changes. That's all!
    //                 </List.Item>
    //             </List>
    //         </TextContainer>
            
    //     </Collapsible>
    //     </Stack>
    // </div>;

    return (
      <div>
        <ExceptionList
            items={[
                {
                    icon: "NoteMinor",
                    description: 'Manage Shipping feature is not included in Basic Plan.  CLICK HERE to upgrade your plan.',
                },
                {
                    icon: "NoteMinor",
                    description: 'Manage Shipping requires script upgrade. Please email us at support@digitalcoo.com We will update the relevant script.',
                },
                {
                    icon: "NoteMinor",
                    description: 'Manage Locks feature is not included in Basic Plan.  CLICK HERE to upgrade your plan.',
                },
                {
                    icon: "NoteMinor",
                    description: 'Manage Locks feature requires script upgrade. Please email us at support@digitalcoo.com We will update the relevant script.',
                },
                {
                    icon: "NoteMinor",
                    description: 'Order Limit feature is not included in Basic Plan.  CLICK HERE to upgrade your plan.',
                },
                {
                    icon: "NoteMinor",
                    description: 'Order Limit feature requires script upgrade. Please email us at support@digitalcoo.com We will update the relevant script.',
                },
            ]}
        />
      </div>
    );
  }
}
