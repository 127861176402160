import React, { Component } from 'react';
import store from 'store2';
import {Card, TextStyle, Stack, Button, ResourceList, Tabs, Banner, Link, Avatar, Heading, ResourceItem, EmptyState} from '@shopify/polaris';
import axios from 'axios';
import _Modules from '../../../modules.json';
import waio from '../../../Helpers/functions';
import PageTitleBar from '../../../Common/TitleBar';
import ToastMessage from '../../../Common/ToastMessage';
import SkeletonPageComp from '../../SkeletonPageComp';

var snippet_version = store('snippet_version') || "2.1.8";

export default class CustomersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_current_plan: this.props.user_current_plan,
            shop: this.props.shop,
            all_customers: [],
            selectedCustomers: [],
            loading: true,
            appliedFilters: [],
            searchValue: "",
            filtered_all_customers: [],
            page_loaded: false,
            selected_tab: this.props.match.params.status === "pending"?0:1,
            customer_status: this.props.match.params.status || "pending",
            wholesale_form_exists: false,
            wholesale_form_fetched: false,
            pending_customer_count: 0,
            rejected_customer_count: 0,
            showToast: false,
            toastMsg: '',
            toastError: false
        }
        axios.defaults.headers.common['X-Ws-Shop-Domain'] = "https://"+this.props.shop;
    }

    handleTabChange = (selected_tab) => {
        if(selected_tab !== 2){
            this.setState({loading:true});
        }
        switch (selected_tab) {
            case 0:
                this.setState({selected_tab,customer_status: "pending"});
                this.fetchCustomers("pending");
                break;
            case 1:
                this.setState({selected_tab,customer_status: "rejected"});
                this.fetchCustomers("rejected");
                break;
            case 2:
                this.setState({selected_tab});
                // this.fetchCustomers("approved");
                // window.open("https://"+this.props.shop+"/admin/customers?query=&tag=VerifiedByWholesaleAllInOne"); 
                break;
            case 3:
                this.setState({selected_tab});
                // this.fetchCustomers("approved");
                // window.open("https://"+this.props.shop+"/admin/customers?query=&tag=VerifiedByWholesaleAllInOne"); 
                break;
            default:
                this.setState({selected_tab,customer_status: "pending"});
                this.fetchCustomers("pending");
                break;
        }
    };

    showToastMessage(msg, error){
        this.setState({
          showToast: true,
          toastMsg: msg,
          toastError: error||false,
        });
    }
    
    dissmissToast = () =>{
        this.setState({
          showToast: false,
        });
    }

    needToUpgrade = false;

    componentDidMount() {
        if(this.props.shop){
            this.getCount("pending");
            this.getCount("rejected");
            this.fetchCustomers(this.state.customer_status);
            this.getWholesaleForm();
        }
        else{
            this.setState({page_loaded: true, loading: false});
        }

        if (snippet_version && waio.compareVersionNumbers(snippet_version, _Modules.find(x => x._id === 218).snippet_version) === -1) {
            this.needToUpgrade = true;
        }
    }

    getWholesaleForm(){
        if(this.props.shop){
            axios.get(this.props.host_name + '/app/get-wholesale-form/'+ this.props.shop).then(r=>{
                if(r && r.data && r.data.status === 1){
                    if(r.data.wholesale_form){
                        this.setState({wholesale_form_exists:true,wholesale_form_fetched:true});
                    }
                    else{
                        this.setState({wholesale_form_exists:false,wholesale_form_fetched:true});
                    }
                }
                else{
                    this.setState({wholesale_form_exists:false,wholesale_form_fetched:true});
                }
            }).catch(e=>{
                this.setState({wholesale_form_exists:false,wholesale_form_fetched:true});
            })
        }
    }

    fetchCustomers(status){
        this.setState({loading:true});
        this.getCount("pending");
        this.getCount("rejected");
        axios.get(this.props.host_name + '/customer/api/335/admin/get?shop='+ this.props.shop+'&status='+status).then(r=>{
            if(r && r.data){
                var all_customers = r.data;
                var filtered_all_customers = r.data;
                this.setState({all_customers,filtered_all_customers})
            }
            else{
                this.flashMessage("Server error. Rrefresh page", true);
            }
            this.setState({page_loaded: true,loading:false});
        }).catch(e=>{
            this.flashMessage("Server error", true);
            this.setState({page_loaded: true,loading:false});
        });
    }

    flashMessage(message, error) {
        this.showToastMessage(message, error);
    }

    getCount(status){
        axios.get(this.props.host_name + '/customer/api/335/admin/count?shop='+this.props.shop+"&status="+status).then(response => {
            if(response && response.data && typeof response.data.count !== "undefined"){
                if(status === "pending"){
                    this.setState({pending_customer_count: response.data.count});
                }
                else if(status === "rejected"){
                    this.setState({rejected_customer_count: response.data.count});
                }
            }
        }).catch(e => {this.flashMessage("Server error",true)});
    }

    approveCustomer(item){
        try{
            item.version = "234";
        }catch(e){}
        axios.post(this.props.host_name + '/customer/v/2/approve', item).then(response => {
            if(response && response.data.status === 1){
                this.flashMessage(response.data.message);
                this.fetchCustomers("pending");
            }
            else{
                this.flashMessage(response.data.message,true);
            }
        }).catch(e => {this.flashMessage("Server error",true)});
    }

    denyCustomer(item){
        try{
            item.version = "234";
        }catch(e){}
        axios.post(this.props.host_name + '/customer/v/2/deny', item).then(response => {
            if(response && response.data.status === 1){
                this.flashMessage(response.data.message);
                this.fetchCustomers("pending");
            }
            else{
                this.flashMessage(response.data.message,true);
            }
        }).catch(e => {this.flashMessage("Server error",true)});
    }

    renderItem = (item, id) => {
        const {customer} = item;
        const key = item.id || item._id;
        var media = <Avatar customer size="medium" name={customer.first_name+" "+customer.last_name} />;
        var url_edit = this.state.selected_tab===2?"/customer/shopify/"+key:"/customer/"+item._id;
        
        if(!this.isPremiumPlan()){
            url_edit = "/pricing-plans";
        }

        return (
            <div className="CustomerListItem">
                <ResourceItem
                    id={id}
                    media={media}
                    url={url_edit}
                >
                <div>
                    {
                        !this.isPremiumPlan() ?
                        <span>
                            {customer.first_name} {customer.last_name}
                        </span>
                        :<Link url={this.state.selected_tab===2?"/customer/shopify/"+key:"/customer/"+key} target="APP">{customer.first_name} {customer.last_name}</Link>
                    }
                </div>
                <div>{customer.email}</div>
                </ResourceItem>
            </div>
        );
    };

    handleSelectionChange = (selectedItems) => {
        this.setState({
            selectedCustomers: selectedItems
        });
    };

    handleFiltersChange = (appliedFilters) => {
        this.setState({appliedFilters});
    };

    handleSearchChange = (searchValue) => {
        try{
            var tem_items = this.state.all_customers.filter(x => x.customer.first_name.match(new RegExp(searchValue, 'i')));
            if(tem_items.length < 1){
                tem_items = this.state.all_customers.filter(x => x.customer.last_name.match(new RegExp(searchValue, 'i')));
            }
            if(tem_items.length < 1){
                tem_items = this.state.all_customers.filter(x => x.customer.email.match(new RegExp(searchValue, 'i')));
            }
            this.setState({
                filtered_all_customers: tem_items,
                searchValue: searchValue
            })
        }catch(e){}
    };

    isPremiumPlan(){
        var isPremium = false;
        if(this.props.user_current_plan){
            var plan_id = this.props.user_current_plan?this.props.user_current_plan.plan_id:null;
            isPremium = false;
            if(!plan_id){
                isPremium = false;
            }
            else{
                isPremium = plan_id.indexOf("premium") > -1;
            }
        }
        return isPremium;
    }
    
    redirectShopifyCustomerList(query){
        window.open("https://"+this.props.shop+"/admin/customers"+query)
    }

    render() {
        const { selected_tab } = this.state;
        var UpgradeBanner = null;
        if(this.needToUpgrade){
            UpgradeBanner = <div style={{marginBottom:"2rem"}}>
                <Banner status="warning" title="Upgrade your script!">
                This feature requires script upgrade. Please email us at <TextStyle variation="strong">support@digitalcoo.com</TextStyle> We will update the relevant script.
                </Banner>
            </div>;
        }

        const tabs = [
            {
                id: 'pending-approval',
                content: `Customers waiting for approval (${this.state.pending_customer_count})`,
                panelID: 'accepts-marketing-content',
            },
            {
                id: 'declined',
                content: `Rejected customers (${this.state.rejected_customer_count})`,
                panelID: 'declined-content',
            },
            {
                id: 'approved',
                content: "Approved Customers",
                panelID: 'approved-content',
            }
        ];

        const resourceName = {
            singular: 'customer',
            plural: 'customers',
        };

        const emptyStateMarkup = <EmptyState
            heading="No customers found"
        >
        <p>
            Nobody applied for wholesale account.
        </p>
      </EmptyState>;

        const resourceList = this.state.filtered_all_customers.length > 0 ?
        <span>
            <ResourceList
              resourceName={resourceName}
              items={this.state.filtered_all_customers}
              renderItem={this.renderItem}
              selectedItems={this.state.selectedCustomers}
              onSelectionChange={this.handleSelectionChange}
              loading={this.state.loading}
            ></ResourceList>
        </span>:
        <span>
            <ResourceList
              emptyState={emptyStateMarkup}
              resourceName={resourceName}
              items={[]}
              renderItem={() => {}}
              loading={this.state.loading}
            ></ResourceList>
        </span>;

        const customersListSection = <Card>
            <Tabs tabs={tabs} selected={selected_tab} onSelect={this.handleTabChange}>
                {
                    selected_tab < 2 && <Card.Section>
                        <TextStyle variation="subdued">
                            {
                                selected_tab === 0 && <span>See list of customers who are waiting for account approval</span>
                            }
                            {
                                selected_tab === 1 && <span>See rejected customers list</span>
                            }
                        </TextStyle>
                    </Card.Section>
                }
                {
                    selected_tab < 2 && resourceList
                }
                {
                    selected_tab === 2 && <Card.Section>
                        <Stack vertical spacing="extraTight">
                            <div>
                                <Stack>
                                    <Stack.Item fill>
                                        <Heading>Approved Customers [Active & Inactive]</Heading>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Button
                                            onClick={() => {
                                                this.redirectShopifyCustomerList("?tag=VerifiedByWholesaleAllInOne");
                                            }}
                                        >View</Button>
                                        </Stack.Item>
                                </Stack>
                            </div>
                            <div>
                                <TextStyle variation="subdued">
                                List of all customers approved via Wholesale All In One application. <br></br>
                                Active customers are those who received the approved account email and successfully activated their account. <br></br>
                                Inactive customers are those who received the approved account email but haven't actived their accounts yet.
                                </TextStyle>
                            </div>
                        </Stack>
                    </Card.Section>
                }
                {
                    selected_tab === 2 && <Card.Section>
                        <Stack vertical spacing="extraTight">
                            <div>
                                <Stack>
                                    <Stack.Item fill>
                                        <Heading>Approved Customers [Active only]</Heading>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Button
                                            onClick={() => {
                                                this.redirectShopifyCustomerList("?state=enabled&tag=VerifiedByWholesaleAllInOne");
                                            }}
                                        >View</Button>
                                        </Stack.Item>
                                </Stack>
                            </div>
                            <div>
                                <TextStyle variation="subdued">
                                List of all customers approved via Wholesale All In One application and all customer in this list have activated their accounts. 
                                </TextStyle>
                            </div>
                        </Stack>
                    </Card.Section>
                }
                {
                    selected_tab === 2 && <Card.Section>
                        <Stack vertical spacing="extraTight">
                            <div>
                                <Stack>
                                    <Stack.Item fill>
                                        <Heading>Approved Customers [Inactive only]</Heading>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Button
                                            onClick={() => {
                                                this.redirectShopifyCustomerList("?state=disabled&tag=VerifiedByWholesaleAllInOne");
                                            }}
                                        >View</Button>
                                        </Stack.Item>
                                </Stack>
                            </div>
                            <div>
                                <TextStyle variation="subdued">
                                List of all customers approved via Wholesale All In One application. These customers received the account approval email from store owner but haven't activated their account yet.
                                </TextStyle>
                            </div>
                        </Stack>
                    </Card.Section>
                }
                {
                    selected_tab === 3 && <Card.Section>
                        <div>section 3</div>
                    </Card.Section>
                }
            </Tabs>
        </Card>;

        var toast = this.state.showToast? (
        <ToastMessage
          onDismiss={this.dissmissToast}
          message={this.state.toastMsg}
          error={this.state.toastError}
        />
        ): (null);

        if(this.state.page_loaded){
            return (
                <div>
                    <PageTitleBar
                        title="Customers list"
                        breadcrumbs={[{content: 'Dashboard', url: '/customers', target: "APP"}]}
                        secondaryActions={[{content: 'Back', url: '/customers', target: "APP"}]}
                    />
                    {toast}
                    {
                        this.needToUpgrade ? UpgradeBanner :null
                    }
                    {customersListSection}
                </div>
            );
        }
        else{
            return (
                <div>
                    {toast}
                    <SkeletonPageComp />
                </div>
            )
        }
    }
}