import React from 'react';
import ReactDOM from 'react-dom';
import 'dotenv';
import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider, Page} from '@shopify/polaris';
import { Provider, TitleBar } from '@shopify/app-bridge-react';
import Install from './Components/Install';
import CommonHelper from './Helpers/Common';

// routing proble solved by these modules
import { Router as BrowserRouter} from 'react-router';
import RoutePropagator from './RoutePropagator';
import MyRouter from './MyRouter';
import { createBrowserHistory } from "history";

import LoadApp from './LoadApp'; // for version 2.3.7 and below

const hashHistory = createBrowserHistory();
const config = {
  forceRedirect: false,
  apiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
  shopOrigin: CommonHelper.getShop(),
  host: CommonHelper.getHost()
};

if(CommonHelper.getShop()){
  ReactDOM.render(
    <BrowserRouter history={hashHistory}>
      <AppProvider i18n={enTranslations} features={{newDesignLanguage: true}} linkComponent={AppBridgeLink}>
        <Provider config={config}>
          <RoutePropagator />
          <MyRouter />
          <Page fullWidth>
            <LoadApp />
          </Page>
        </Provider>
      </AppProvider>
    </BrowserRouter>,
    document.getElementById('root')
  );
}
else{
  ReactDOM.render(
    <AppProvider i18n={enTranslations}>
      <Install />
    </AppProvider>,
    document.getElementById('root')
  );
}


function AppBridgeLink({ url, children, external, ...rest }) {

  const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;

  if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
    return (
      <a {...rest} href={url} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }

  // append existing query string to url
  url = CommonHelper.mergeQueryParams(url);

  return (
    <a {...rest} href={url} >
      {children}
    </a>
  );
}